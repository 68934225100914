import React, { useEffect, useState } from "react";
import TaskEdit from "./TaskEdit";
import { useLocation, useNavigate } from "react-router-dom";
import TaskModal from "../Component/CreateTaskModal";
import Select from "react-select";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { toast } from "react-toastify";
import { taskFeedback } from "../../../services/TaskService";
import { getMyDay } from "../../../services/OrganizationService";
import { Dropdown } from "react-bootstrap";
import GoalModal from "../Component/GoalModal";
import { getGoalByScope } from "../../../store/actions/GoalsActions";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createTaskService, editTask } from "../../../services/TaskService";
import { getAllGoalsByScope, getGoalById } from "../../../services/GoalService";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import "bootstrap/dist/css/bootstrap.css";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "@mui/joy/Button";
import ReactDOM from "react-dom";
import ShareTaskModal from "../Component/ShareTaskModal";
import { Modal } from "react-bootstrap";
import { deleteTask } from "../../../services/TaskService";
import Vector3 from "../../../images/vector/Vector3.png";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import LoadingSpinner from "../Component/LoadingSpinner";
import { TextField, Skeleton } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { FaPlus } from "react-icons/fa6";
import {
  updateStreak,
  updateProgressBar,
  updatedGoals,
  updatedStreakWins,
} from "../../../store/actions/TaskActions";
import { Typography, LinearProgress } from "@mui/material";
import yellowFlag from "../../../images/yellowFlag.svg";
import redFlag from "../../../images/redFlag.svg";
import greenFlag from "../../../images/greenFlag.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { fetchAllTasksDataOnMyDay } from "../../../store/actions/NotificationAction";

const PlannedTasks = React.memo(({ taskList, taskTitle }) => {
  const location = useLocation();
  const [tasks, setTasks] = useState([]);
  const [taskDetails, setTaskDetails] = useState();
  const [showTaskMoadal, setshowTaskMoadal] = useState(false);
  const [showObjectiveModal, setShowObjectiveModal] = useState(false);
  const userDetail = localStorage.getItem("userDetails");
  const userInfoDetails = userDetail ? JSON.parse(userDetail) : null;
  const [CreateTask, setCreateTask] = useState(false);
  const dispatch = useDispatch();
  const [loadingAllTasks, setLoadingAllTasks] = useState(true);
  const [selectedShareTask, setSelectedShareTask] = useState();
  const [shareModal, setShareModal] = useState(false);
  const [OpenDeleteModal, SetOpenDeleteModal] = useState(false);
  const [SelectedTaskDelete, setSelectedTaskDelete] = useState();
  const [goalAlignId, setGoalAlign] = useState();
  const [defaultOptions, setDefaultOptions] = useState();
  const orgId = localStorage.getItem("organization_id");
  const [goalOption, setGoalOption] = useState([]);
  const [options, setOptions] = useState([]);
  const stateData = useSelector((state) => state);
  const [tasksdelete, setTasksdelete] = useState(false);
  const [markAsStatus, setMarkAsStatus] = useState("");
  const [UpdateTaskRefresh, setUpdateTaskRefresh] = useState(false);
  const [filterScheduleTaskIcon, setFilterScheduleTaskIcon] = useState(true);
  const [ScheduledTaskInputFieldOpen, setScheduledTaskInputFieldOpen] =
    useState(false);
  const [ScheduledTaskNameFilter, setScheduledTaskNameFilter] = useState();
  const [ScheduledTaskEffortFieldOpen, setScheduledTaskEffortFieldOpen] =
    useState();
  const [ScheduledTaskEffortFilter, setScheduledTaskEffortFilter] = useState();
  const [
    ScheduledTaskEffortFilterForShow,
    setScheduledTaskEffortFilterForShow,
  ] = useState();
  const [
    ScheduledTasksGoalInputFieldOpen,
    setScheduledTasksGoalInputFieldOpen,
  ] = useState(false);

  const [ScheduledTasksGoalNameFilter, setScheduledTasksGoalNameFilter] =
    useState();
  const [selectedScheduledTaskImpact, setselectedScheduledTaskImpact] =
    useState();
  const navigate = useNavigate();
  const [validationMessages, setValidationMessages] = useState("");
  const [taskStatus, setTaskStatusValue] = useState({ value: "", label: "" });
  const [taskStatuses, setTaskStatuses] = useState({});
  const [selectedTaskStatusItem, setSelectedTaskStatusItem] = useState();
  const [showSpinner, setShowSpinner] = useState(false);
  const [rowAddTask, setRowAddTask] = useState(false);

  const outComeOption = [
    {
      name: "Epic",
      value: "Beat",
    },
    {
      name: "Ok",
      value: "Hit",
    },
    {
      name: "Miss",
      value: "Miss",
    },
    {
      name: "Not Complete",
      value: "Not Complete",
    },
  ];
  const statusOption = [
    { value: "beat", label: "Epic" },
    { value: "hit", label: "OK" },
    { value: "miss", label: "Miss" },
  ];

  const TaskImpact = [
    {
      name: "Low",
      value: "Low",
    },
    {
      name: "Medium",
      value: "Medium",
    },
    {
      name: "High",
      value: "High",
    },
  ];

  const tasktype = [
    {
      name: "Game changers",
      value: "Game changers",
    },
    {
      name: "Support",
      value: "Support",
    },
    {
      name: "Quick wins",
      value: "Quick wins",
    },
  ];
  const [seletcedScheduledTaskType, setselectedScheduledTaskType] = useState();
  const [seletcedScheduledTaskStatus, setseletcedScheduledTaskStatus] =
    useState();
  const [taskStatusOptions, settaskStatusOptions] = useState(statusOption);

  useEffect(() => {
    setScheduledTaskInputFieldOpen(ScheduledTaskInputFieldOpen);
  }, [ScheduledTaskInputFieldOpen]);

  const clearAllScheduleTaskFilter = (e) => {
    e.preventDefault();
    setScheduledTaskNameFilter("");
    setScheduledTaskEffortFilter("");
    setScheduledTasksGoalNameFilter("");
    setselectedScheduledTaskImpact("");
    setselectedScheduledTaskType("");
    setseletcedScheduledTaskStatus("");
  };

  const getMyPanDetails = async () => {
    setLoadingAllTasks(true);
    try {
      const data = await getMyDay(
        userInfoDetails?.data?.accessToken,
        ScheduledTaskNameFilter,
        ScheduledTaskEffortFilter,
        ScheduledTasksGoalNameFilter,
        selectedScheduledTaskImpact,
        seletcedScheduledTaskType,
        seletcedScheduledTaskStatus
      );
      if (localStorage.getItem("OnboardingSkeleton") === "true") {
        setLoadingAllTasks(true);
        // setTasks([]);
      } else {
        setLoadingAllTasks(false);
        setTasks(data?.data?.data?.task_list);
      }
      dispatch(fetchAllTasksDataOnMyDay(false));
      setUpdateTaskRefresh(false);
      setShowSpinner(false);
      dispatch(updatedStreakWins(false));
    } catch (err) {}
  };

  useEffect(() => {
    getMyPanDetails();
  }, [UpdateTaskRefresh]);

  useEffect(() => {
    if (stateData.task.updatedStreakWins) {
      getMyPanDetails();
    }
  }, [stateData.task.updatedStreakWins]);

  useEffect(() => {
    getMyPanDetails();
  }, [
    ScheduledTaskNameFilter,
    ScheduledTaskEffortFilter,
    ScheduledTasksGoalNameFilter,
    selectedScheduledTaskImpact,
    seletcedScheduledTaskType,
    seletcedScheduledTaskStatus,
  ]);

  const showGoals = (event, goal) => {
    event.preventDefault();
    navigate("/goals", { state: { goal: goal, key: true } });
  };

  useEffect(() => {
    if (stateData?.messages?.fetchAllTasksDataOnMyDay) {
      console.log("in lskmdl", stateData?.messages?.fetchAllTasksDataOnMyDay);
      getMyPanDetails();
    }
  }, [stateData?.messages?.fetchAllTasksDataOnMyDay]);

  const handleTaskClick = (task) => {
    setTaskDetails(task);
  };

  const handleClose = (event) => {
    setshowTaskMoadal(false);
    setShowObjectiveModal(false);
    setCreateTask(false);
  };

  const handleModalOpenCase = () => {
    setShowObjectiveModal(false);
    dispatch(
      getGoalByScope(
        localStorage.getItem("scope"),
        localStorage?.getItem("organization_id"),
        localStorage.getItem("team_id"),
        userInfoDetails.data.accessToken
      )
    );
  };

  const changeTaskStatus = (selectedOption, task) => {
    setTaskStatusValue((prev) => ({ ...prev, [task?.id]: selectedOption }));
    setValidationMessages((prev) => ({ ...prev, [task?.id]: "" }));
    if (markAsStatus?.taskId == task?.id) {
      if (markAsStatus[task?.id] == true) {
        getfeedbackTask(task, selectedOption);
      }
    }
  };

  function getfeedbackTask(task, selectedTaskStatus) {
    const data = taskFeedback(
      task?.id,
      userInfoDetails?.data?.accessToken,
      true,
      selectedTaskStatus,
      task?.task_effort
    );
    data?.then(async (res) => {
      getMyPanDetails();
      dispatch(updateStreak(true));
      dispatch(updateProgressBar(true));
      dispatch(updatedGoals(true));
      setTaskStatusValue({ value: "", label: "" });
      toast(
        <>
          <div className="m-1 d-flex">
            <i
              class="bi bi-check-circle fs-26 me-3"
              style={{ color: "#131F4C" }}
            ></i>
            <div>
              <h2
                style={{
                  // color: "#131F4C",
                  fontSize: "22px",
                }}
              >
                Completed
              </h2>
              <h3
                style={{
                  // color: "#131F4C",
                  fontSize: "18px",
                }}
              >
                Your task has been mark as complete successfully!
              </h3>
            </div>
          </div>
        </>,
        {
          toastId: "task-schedule",
          className: "toast-success-container toast-success-container-after ",
        }
      );
    });
    data?.catch(async (err) => {
      toast(
        <>
          <div className="m-1 d-flex">
            <i
              className="bi bi-check-circle fs-26 me-3"
              style={{ color: "#131F4C" }}
            ></i>
            <div>
              <h2 style={{ fontSize: "22px" }}>Failed</h2>
              <h3 style={{ fontSize: "18px" }}>
                {err?.response?.data?.message}
              </h3>
            </div>
          </div>
        </>,
        {
          toastId: "task-schedule",
          className: "toast-error-container toast-error-container-after",
        }
      );
    });
  }

  const handleMarkAsComplete = (task, status) => {
    if (status) {
      getfeedbackTask(task, status);
    } else {
      setValidationMessages((prev) => ({
        ...prev,
        [task.id]: `* Please select Task Success to mark it as complete`,
      }));
      setMarkAsStatus((prev) => ({
        ...prev,
        [task.id]: true,
        taskId: task.id,
      }));
    }
  };

  useEffect(() => {
    if (stateData?.team?.successMessage === "Organization Members") {
      let optionData = stateData?.team?.orgMembers?.data;
      let optionList = [];
      optionData?.forEach((option) => {
        const data = {
          label: option.first_name
            ? option.first_name + " " + option.last_name
            : option?.email,
          value: option.first_name
            ? option.first_name + " " + option.last_name
            : option?.email,
          userId: option.id,
        };
        optionList.push(data);
        if (option.id === userInfoDetails.data.id) {
          const data = {
            label: option?.first_name + " " + option?.last_name,
            value: option?.first_name + " " + option?.last_name,
            userId: option?.id,
          };
          // setGoalOwnerValue(data);
        }
      });
      setOptions(optionList);
    }
  }, [stateData]);

  const changeGoalOption = (userRole) => {
    setDefaultOptions(userRole);
    setGoalAlign(userRole?.userId);
  };

  const getGaol = () => {
    let optionGoalLists = [];
    let orgdata;
    orgdata = getAllGoalsByScope(
      orgId,
      // scope,
      userInfoDetails?.data?.accessToken,
      true,
      false
    );
    orgdata?.then(async (res) => {
      let optionGoalData = res?.data?.data;
      if (optionGoalData.length !== 0) {
        optionGoalData?.forEach((option) => {
          const data = {
            label: option.name,
            value: option.name,
            userId: option.id,
          };
          optionGoalLists.push(data);
        });
      }
    });
    orgdata?.catch(async (err) => {});
    setGoalOption(optionGoalLists);
  };

  useEffect(() => {
    getGaol();
  }, []);

  const formik = useFormik({
    initialValues: {
      task_name: "",
      task_effort: "",
      task_owner: "",
      task_impact: { value: "medium", label: "Medium" },
      task_control: { value: "medium", label: "Medium" },
      task_success: { value: "", label: "" },
      goal_align: "",
    },
    validationSchema: Yup.object({
      task_name: Yup.string().required("*Task name is required"),
      task_effort: Yup.string().required("*Task effort is required"),
    }),
    onSubmit: (values, { setSubmitting, setFieldValue, resetForm }) => {
      const [hours, minutes] = values?.task_effort.split(":").map(Number);
      const decimalMinutes = (minutes / 60).toFixed(2).slice(1);
      const fieldValue = parseFloat(hours + decimalMinutes);

      const createTask = {
        task_name: values?.task_name,
        task_effort: fieldValue,
      };
      if (goalAlignId != undefined) {
        const data = getGoalById(
          goalAlignId,
          userInfoDetails?.data?.accessToken
        );
        data?.then(async (res) => {
          const data = createTaskService(
            orgId,
            userInfoDetails.data.id,
            values?.task_owner?.userId,
            createTask,
            values?.task_impact?.value,
            values?.task_control?.value,
            res?.data?.data?.status,
            { id: goalAlignId },
            "",
            userInfoDetails.data.accessToken
          );
          data?.then(async (res) => {
            getMyPanDetails();
            setRowAddTask(false);
            toast(
              <>
                <div className="m-1 d-flex">
                  <i
                    class="bi bi-check-circle fs-26 me-3"
                    style={{ color: "#131F4C" }}
                  ></i>
                  <div>
                    <h2
                      style={{
                        fontSize: "22px",
                      }}
                    >
                      Completed
                    </h2>
                    <h3
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      {res?.data?.message}
                    </h3>
                  </div>
                </div>
              </>,
              {
                toastId: "task-schedule",
                className:
                  "toast-success-container toast-success-container-after ",
              }
            );
            if (res?.data?.message === "Tasks created successfully") {
              resetForm();
            }
          });
          data?.catch(async (err) => {
            toast(
              <>
                <div className="m-1 d-flex">
                  <i
                    class="bi bi-check-circle fs-26 me-3"
                    style={{ color: "#131F4C" }}
                  ></i>
                  <div>
                    <h2
                      style={{
                        fontSize: "22px",
                      }}
                    >
                      Failed
                    </h2>
                    <h3
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      {err?.response?.data?.message}
                    </h3>
                  </div>
                </div>
              </>,
              {
                toastId: "task-create",
                className: "toast-error-container toast-error-container-after ",
              }
            );
          });
          // }
          setSubmitting(false);
        });
      } else {
        const data = createTaskService(
          orgId,
          userInfoDetails.data.id,
          values?.task_owner?.userId,
          createTask,
          values?.task_impact?.value,
          values?.task_control?.value,
          "Inactive",
          { id: goalAlignId },
          "",
          userInfoDetails.data.accessToken
        );
        data?.then(async (res) => {
          getMyPanDetails();
          setRowAddTask(false);
          toast(
            <>
              <div className="m-1 d-flex">
                <i
                  class="bi bi-check-circle fs-26 me-3"
                  style={{ color: "#131F4C" }}
                ></i>
                <div>
                  <h2
                    style={{
                      fontSize: "22px",
                    }}
                  >
                    Completed
                  </h2>
                  <h3
                    style={{
                      fontSize: "18px",
                    }}
                  >
                    {res?.data?.message}
                  </h3>
                </div>
              </div>
            </>,
            {
              toastId: "task-schedule",
              className:
                "toast-success-container toast-success-container-after ",
            }
          );
          if (res?.data?.message === "Tasks created successfully") {
            resetForm();
          }
        });
        data?.catch(async (err) => {
          toast(
            <>
              <div className="m-1 d-flex">
                <i
                  class="bi bi-check-circle fs-26 me-3"
                  style={{ color: "#131F4C" }}
                ></i>
                <div>
                  <h2
                    style={{
                      fontSize: "22px",
                    }}
                  >
                    Failed
                  </h2>
                  <h3
                    style={{
                      fontSize: "18px",
                    }}
                  >
                    {err?.response?.data?.message}
                  </h3>
                </div>
              </div>
            </>,
            {
              toastId: "task-create",
              className: "toast-error-container toast-error-container-after ",
            }
          );
        });
        // }
        setSubmitting(false);
      }
    },
  });

  const handleCircleClick = (index, event, task) => {
    setTaskStatuses((prevStatuses) => ({
      ...prevStatuses,
      [index]: event?.value,
    }));

    handleMarkAsComplete(task, event?.value);
    // changeTaskStatus("hit", task);
    changeTaskStatus(event?.value, task);
  };

  const selectedTask = (event, Task) => {
    event.preventDefault();
    setSelectedTaskDelete(Task);
  };

  const handleDeleteTaskClick = () => {
    // setTasksupdate(false);
    setTasksdelete(false);
    const data = deleteTask(
      SelectedTaskDelete?.id,
      userInfoDetails.data.accessToken
    );
    data?.then(async (res) => {
      getMyPanDetails();
    });
    data?.catch(async (err) => {});
  };

  const getAdjustedTaskEffort = (effort) => {
    if (typeof effort !== "number") return effort;
    const integerPart = Math.floor(effort);
    const decimalPart = effort - integerPart;
    const adjustedMinutes = Math.round(decimalPart * 60);
    const formattedMinutes = adjustedMinutes.toString().padStart(2, "0");
    return `${integerPart}:${formattedMinutes}`;
  };

  const reverseAdjustedTaskEffort = (timeString) => {
    setScheduledTaskEffortFilter(timeString);
    const [hours, minutes] = timeString.split(":").map(Number);
    if (isNaN(hours) || isNaN(minutes)) return timeString;

    const decimalMinutes = (minutes / 60).toFixed(2).slice(1);
    setScheduledTaskEffortFilter(parseFloat(hours + decimalMinutes));
    return parseFloat(hours + decimalMinutes);
  };

  const moveGoal = (dragIndex, hoverIndex) => {
    // const dragItem = goals[dragIndex];
  };

  const TableRows = ({ task, index }) => {
    const [{ isDragging }, drag, preview] = useDrag({
      type: "ROW",
      item: { index, task },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [, drop] = useDrop({
      accept: "ROW",
      drop: (item) => {
        if (item.index !== index) {
          moveGoal(item.index, index);
          item.index = index;
          const data = editTask(
            orgId,
            item?.task?.id,
            item?.task?.team?.id,
            item?.task?.goal?.id,
            item?.task?.task_owner,
            item?.task?.task_creator,
            item?.task?.task_impact?.toLowerCase(),
            item?.task?.task_control?.toLowerCase(),
            item?.task,
            userInfoDetails.data.accessToken,
            item?.task?.task_status,
            "",
            item?.task?.status,
            task?.task_order
          );
          data?.then((res) => {
            getMyPanDetails();
          });
          data?.catch((err) => {});
        }
      },
    });

    return (
      <tr
        ref={(node) => drag(drop(node))}
        key={index}
        role="row"
        style={{ border: "1px solid #d7dae3", verticalAlign: "center" }}
      >
        <td
          onClick={() => handleTaskClick(task)}
          style={{ cursor: "pointer", border: "1px solid #d7dae3" }}
        >
          {task?.name}
        </td>
        <td
          onClick={() => handleTaskClick(task)}
          style={{ cursor: "pointer", border: "1px solid #d7dae3" }}
        >
          {task?.goal?.name}
          <LinearProgress
            sx={{
              height: 8,
              marginTop: 2,
              borderRadius: 5,
              backgroundColor: "#DDDEE4",
              "& .MuiLinearProgress-bar": {
                bgcolor:
                  task?.goal?.status === "Inactive"
                    ? "#DDDEE4"
                    : task?.goal?.goal_status === "On track"
                    ? "#0B9060"
                    : task?.goal?.goal_status === "At risk"
                    ? "#FAAD14"
                    : "#FF4D4F",
              },
            }}
            variant="determinate"
            value={task?.goal?.goal_completed_percent}
          />
        </td>
        <td
          onClick={() => handleTaskClick(task)}
          style={{
            cursor: "pointer",
            border: "1px solid #d7dae3",
          }}
        >
          <span style={{ marginRight: "5px" }}>
            {getImpactFlag(task?.task_impact?.toLowerCase())}
          </span>
          {task?.task_impact}
        </td>
        <td
          style={{ cursor: "pointer", border: "1px solid #d7dae3" }}
          onClick={() => handleTaskClick(task)}
        >
          {getAdjustedTaskEffort(task.task_effort)}
        </td>
        <td
          onClick={() => handleTaskClick(task)}
          style={{
            cursor: "pointer",
            border: "1px solid #d7dae3",
          }}
        >
          {task?.task_type}
        </td>
        <td
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          <>
            <div className="d-flex  m-0 p-0">
              <div className="d-flex mb-1 align-items-center">
                <a
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={
                    userInfoDetails?.data?.id !== task?.task_owner?.id &&
                    userInfoDetails?.data?.id !== task?.task_creator?.id
                      ? "You don't have permission to edit this field!"
                      : ""
                  }
                  style={
                    userInfoDetails?.data?.id !== task?.task_owner?.id ||
                    userInfoDetails?.data?.id !== task?.task_creator?.id
                      ? { cursor: "not-allowed" }
                      : {}
                  }
                >
                  <Select
                    className="p"
                    name="task_status"
                    placeholder={"Select"}
                    value={
                      task?.task_success === "Beat"
                        ? { value: "beat", label: "Epic" }
                        : task?.task_success === "Hit"
                        ? { value: "hit", label: "Ok" }
                        : task?.task_success === "Miss"
                        ? { value: "miss", label: "Miss" }
                        : task?.task_success === "Not Complete"
                        ? { value: "not_complete", label: "Not Complete" }
                        : { value: "not_complete", label: "Not Complete" }
                    }
                    options={taskStatusOptions}
                    onChange={(event) => handleCircleClick(index, event, task)}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "100px",
                      }),
                    }}
                    isDisabled={
                      task?.task_owner?.id !== userInfoDetails?.data?.id &&
                      task?.task_creator?.id !== userInfoDetails?.data?.id
                    }
                  />
                </a>
                <ReactTooltip effect="solid" id="my-tooltip" />
              </div>
            </div>
            <div>
              {validationMessages[task.id] && (
                <span className="text-danger">
                  {validationMessages[task.id]}
                </span>
              )}
            </div>
          </>
        </td>

        <td style={{ cursor: "pointer", border: "1px solid #d7dae3" }}>
          {task?.task_owner?.id === userInfoDetails?.data?.id ||
          task?.task_creator?.id === userInfoDetails?.data?.id ||
          userInfoDetails?.data?.user_role === "owner" ? (
            <>
              <Dropdown className="ms-auto">
                <Dropdown.Toggle
                  variant=""
                  as="div"
                  className="btn-link i-false"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12.4999" cy="3.5" r="2.5" fill="#223A69" />
                    <circle cx="12.4999" cy="11.5" r="2.5" fill="#223A69" />
                    <circle cx="12.4999" cy="19.5" r="2.5" fill="#223A69" />
                  </svg>
                </Dropdown.Toggle>

                {ReactDOM.createPortal(
                  <Dropdown.Menu
                    alignRight={true}
                    data-testid="org-menu"
                    className="dropdown-menu-right"
                    style={{ position: "absolute", zIndex: 1050 }}
                  >
                    <Dropdown.Item
                      data-testid="org-edit"
                      onClick={() => handleTaskClick(task)}
                    >
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                      data-testid="org-delete"
                      onClick={(event) => {
                        setShareModal(true);
                        setSelectedShareTask(task);
                      }}
                    >
                      Share
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="text-danger"
                      data-testid="org-delete"
                      onClick={(event) => {
                        selectedTask(event, task);
                        SetOpenDeleteModal(true);
                      }}
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>,
                  document.body
                )}
              </Dropdown>
            </>
          ) : (
            <>-</>
          )}
        </td>
      </tr>
    );
  };

  const SkeletonTableRows = ({ index }) => {
    return (
      <tr
        key={index}
        role="row"
        style={{ border: "1px solid #d7dae3", verticalAlign: "center" }}
      >
        <td style={{ cursor: "pointer", border: "1px solid #d7dae3" }}>
          <Skeleton style={{ marginBottom: "0%" }} />
        </td>
        <td style={{ cursor: "pointer", border: "1px solid #d7dae3" }}>
          <Skeleton style={{ marginBottom: "0%" }} />
        </td>
        <td
          style={{
            cursor: "pointer",
            border: "1px solid #d7dae3",
          }}
        >
          <Skeleton style={{ marginBottom: "0%" }} />
        </td>
        <td style={{ cursor: "pointer", border: "1px solid #d7dae3" }}>
          <Skeleton style={{ marginBottom: "0%" }} />
        </td>
        <td
          style={{
            cursor: "pointer",
            border: "1px solid #d7dae3",
          }}
        >
          <Skeleton style={{ marginBottom: "0%" }} />
        </td>
        <td
          style={{
            cursor: "pointer",
            height: "100%",
          }}
        >
          <Skeleton style={{ marginBottom: "0%" }} />
        </td>

        <td style={{ cursor: "pointer", border: "1px solid #d7dae3" }}>
          <Skeleton style={{ marginBottom: "0%" }} />
        </td>
      </tr>
    );
  };

  const getImpactFlag = (impact) => {
    if (impact == "low") return <img src={greenFlag} alt="green flag" />;
    else if (impact == "medium")
      return <img src={yellowFlag} alt="yellow flag" />;
    else return <img src={redFlag} alt="red flag" />;
  };

  return (
    <>
      {shareModal && (
        <ShareTaskModal
          shareModel={shareModal}
          task={selectedShareTask}
          setShareModelParent={setShareModal}
        ></ShareTaskModal>
      )}
      {showTaskMoadal && (
        <TaskModal
          show={showTaskMoadal}
          goal={null}
          owner=""
          handle={handleClose}
          // setIsTaskCreated={setIsTaskCreated}
        ></TaskModal>
      )}
      {showObjectiveModal && (
        <GoalModal
          show={showObjectiveModal}
          changeState={handleModalOpenCase}
          goal={CreateTask}
          handle={handleClose}
          // Subgoal={{ createSubGoal, selectedGoal, EditTask }}
        ></GoalModal>
      )}
      <Modal
        show={OpenDeleteModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ borderRadius: "0rem" }}
      >
        <Modal.Title className="my-3 mx-4" id="contained-modal-title-vcenter">
          <img
            className="float-start me-1 mt-1 p-2"
            alt="delete-modal"
            title="delete-modal"
            src={Vector3}
          />
          <h4 className="fs-20 p-2">Are you sure you want to delete Task?</h4>
        </Modal.Title>
        <Modal.Body className="modal-body mx-4 p-0">
          <p className="p-2 pt-0" style={{ color: "#717994" }}>
            You will not be able to recover this Task!
          </p>
        </Modal.Body>
        <div className="d-flex  justify-content-end mx-4 mb-3">
          <Link
            className="figma-link mt-2 me-3"
            to="/my-day"
            onClick={() => SetOpenDeleteModal(false)}
          >
            No, cancel it!
          </Link>
          <button
            className="figma-btn text-white"
            onClick={() => {
              handleDeleteTaskClick();
              SetOpenDeleteModal(false);
            }}
          >
            Yes, I am Sure!
          </button>
        </div>
      </Modal>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            {taskTitle}
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#fff",
              border: "1px solid #DDDEE4",
              color: "#05060E",
              fontWeight: "bold",
              borderRadius: "20px",
              textTransform: "none",
              padding: "10px 20px",
              mb: 2,
              "&:hover": {
                backgroundColor: "#fff",
                color: "#05060E",
                boxShadow: "none",
              },
            }}
            onClick={(e) => {
              e.preventDefault();
              navigate("/tasks");
            }}
          >
            <span style={{ color: "#05060E", marginRight: "5px" }}>
              View all
            </span>
            <ArrowForwardIosIcon style={{ fontSize: "12px" }} />
          </Button>
          {(ScheduledTaskNameFilter ||
            ScheduledTaskEffortFilter ||
            ScheduledTasksGoalNameFilter ||
            selectedScheduledTaskImpact ||
            seletcedScheduledTaskType ||
            seletcedScheduledTaskStatus) && (
            <span
              className=" mx-3 py-0 float-end"
              title="Clear all filter"
              onClick={(e) => {
                clearAllScheduleTaskFilter(e);
              }}
              style={{
                cursor: "pointer",
                color: "#DC5D74",
              }}
            >
              Clear all filter
              <i className="fas fa-filter fs-18"></i>
              <span>x</span>
            </span>
          )}
        </div>
        <DndProvider backend={HTML5Backend}>
          <div className="table-responsive ">
            {showSpinner ? (
              <div
                style={{
                  position: "relative",
                  top:
                    tasks.length < 3
                      ? "6rem"
                      : tasks.length >= 3
                      ? "9rem"
                      : "1rem",
                }}
                className="m-0 p-0"
              >
                <LoadingSpinner />
              </div>
            ) : (
              ""
            )}
            <form onSubmit={formik.handleSubmit}>
              <table
                className="display w-100 dataTable mt-4 mb-4"
                id="example5"
                role="grid"
                aria-describedby="example5_info"
                style={{ marginBottom: "40px" }}
              >
                <thead style={{ border: "1px solid #d7dae3" }}>
                  <tr
                    role="row"
                    style={{
                      border: "1px solid #d7dae3",
                      verticalAlign: "center",
                    }}
                  >
                    <th
                      className="sorting"
                      style={{
                        border: "1px solid #d7dae3",
                        textAlign: "center",
                        verticalAlign: "middle",
                        width: "30%",
                      }}
                    >
                      <div className="d-flex align-items-center m-0 p-0">
                        <span
                          className="float-start"
                          style={{
                            paddingRight: "10px",
                            fontWeight: "bold",
                            color: "#131F4C",
                          }}
                        >
                          Task
                        </span>

                        {filterScheduleTaskIcon ? (
                          <Dropdown
                            title="Filter"
                            onClick={(e) => {
                              e.preventDefault();
                              setScheduledTaskInputFieldOpen(
                                !ScheduledTaskInputFieldOpen
                              );
                            }}
                          >
                            <Dropdown.Toggle
                              style={{
                                background: "none",
                                border: "none",
                              }}
                              className="m-0 p-0"
                            >
                              <i
                                className="fas fa-filter fs-12"
                                style={{
                                  cursor: "pointer",
                                  color: ScheduledTaskNameFilter
                                    ? "#DC5D74"
                                    : "#898fa5",
                                }}
                              ></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <input
                                type="text"
                                name="name"
                                className="figma-input m-0 p-2"
                                placeholder="Enter task name"
                                autoComplete="off"
                                value={ScheduledTaskNameFilter}
                                onChange={(e) =>
                                  setScheduledTaskNameFilter(e.target.value)
                                }
                                style={{
                                  border: "1px solid black",
                                  width: "150px",
                                }}
                              />
                            </Dropdown.Menu>
                          </Dropdown>
                        ) : (
                          ""
                        )}
                      </div>
                    </th>
                    <th className="sorting ">
                      <div className="d-flex align-items-center m-0 p-0">
                        <span
                          className=""
                          style={{
                            paddingRight: "10px",
                            fontWeight: "bold",
                            color: "#131F4C",
                          }}
                        >
                          {/* <i
                                      class="fas fa-sort"
                                      onClick={() => {
                                        handleClick1("goal", !sortAsc);
                                      }}
                                    ></i> */}
                          Goal
                        </span>
                        {filterScheduleTaskIcon ? (
                          <Dropdown
                            // className="setDropDownposition float-end"
                            title="Filter"
                            // style={{ right: "68%" }}
                            onClick={() => {
                              setScheduledTasksGoalInputFieldOpen(
                                !ScheduledTasksGoalInputFieldOpen
                              );
                            }}
                          >
                            <Dropdown.Toggle
                              style={{
                                background: "none",
                                border: "none",
                              }}
                              className="m-0 p-0"
                            >
                              <i
                                className="fas fa-filter fs-12"
                                style={{
                                  cursor: "pointer",
                                  color: ScheduledTasksGoalNameFilter
                                    ? "#DC5D74"
                                    : "#898fa5",
                                }}
                              ></i>
                            </Dropdown.Toggle>
                            <div>
                              <Dropdown.Menu
                                menuposition="top-right"
                                className=""
                                style={{
                                  width: "auto",
                                  height: "200px",
                                  overflowY: "scroll",
                                  scrollbarWidth: "0px",
                                }}
                              >
                                {goalOption?.map((goal, i) => (
                                  <Dropdown.Item
                                    key={i}
                                    style={
                                      ScheduledTasksGoalNameFilter ===
                                      goal?.userId
                                        ? { backgroundColor: "#B8E5F1" }
                                        : { backgroundColor: "#ffffff" }
                                    }
                                    value={goal?.value}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setScheduledTasksGoalNameFilter(
                                        goal?.userId
                                      );
                                    }}
                                    className="p fs-14"
                                  >
                                    {goal?.label}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </div>
                          </Dropdown>
                        ) : (
                          ""
                        )}
                      </div>
                    </th>
                    <th
                      className="sorting perFields"
                      style={{ border: "1px solid #d7dae3", width: "7%" }}
                    >
                      <div className="d-flex align-items-center m-0 p-0">
                        <span
                          className=""
                          style={{
                            paddingRight: "10px",
                            fontWeight: "bold",
                            color: "#131F4C",
                          }}
                        >
                          Impact
                        </span>
                        {filterScheduleTaskIcon ? (
                          <Dropdown
                            // className="setDropDownposition float-end"
                            title="Filter"
                            // style={{ right: "49%" }}
                          >
                            <Dropdown.Toggle
                              style={{
                                background: "none",
                                border: "none",
                              }}
                              className="m-0 p-0"
                            >
                              <i
                                className="fas fa-filter fs-12"
                                style={{
                                  cursor: "pointer",
                                  color: selectedScheduledTaskImpact
                                    ? "#DC5D74"
                                    : "#898fa5",
                                }}
                              ></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {TaskImpact?.map((scope, i) => (
                                <Dropdown.Item
                                  style={
                                    selectedScheduledTaskImpact === scope.value
                                      ? { backgroundColor: "#B8E5F1" }
                                      : { backgroundColor: "#ffffff" }
                                  }
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setselectedScheduledTaskImpact(scope.value);
                                  }}
                                  key={i}
                                  className="p fs-14"
                                >
                                  {scope?.name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        ) : (
                          ""
                        )}
                      </div>
                    </th>
                    <th className="sorting perFields" style={{ width: "9%" }}>
                      <div className="d-flex align-items-center m-0 p-0">
                        <span
                          className="float-start"
                          style={{
                            paddingRight: "10px",
                            fontWeight: "bold",
                            color: "#131F4C",
                          }}
                        >
                          {/* <i
                                      class="fas fa-sort"
                                      onClick={() => {
                                        handleClick1("effort", !sortAsc);
                                      }}
                                    ></i> */}
                          Effort
                        </span>
                        {filterScheduleTaskIcon ? (
                          <Dropdown
                            // className="setDropDownposition float-end"
                            title="Filter"
                            // style={{ right: "39%" }}
                            onClick={(e) => {
                              e.preventDefault();
                              setScheduledTaskEffortFieldOpen(
                                !ScheduledTaskEffortFieldOpen
                              );
                            }}
                          >
                            <Dropdown.Toggle
                              style={{
                                background: "none",
                                border: "none",
                              }}
                              className="m-0 p-0"
                            >
                              <i
                                className="fas fa-filter fs-12"
                                style={{
                                  cursor: "pointer",
                                  color: ScheduledTaskEffortFilter
                                    ? "#DC5D74"
                                    : "#898fa5",
                                }}
                              ></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {
                                <input
                                  type="string"
                                  name="name"
                                  className="figma-input p m-0 p-2"
                                  placeholder="Enter task effort"
                                  autocomplete="off"
                                  value={ScheduledTaskEffortFilterForShow}
                                  onChange={(e) =>
                                    // setScheduledTaskEffortFilter(e.target.value)
                                    {
                                      setScheduledTaskEffortFilterForShow(
                                        e.target.value
                                      );
                                      reverseAdjustedTaskEffort(e.target.value);
                                    }
                                  }
                                  style={{
                                    border: "1px solid black",
                                    width: "150px",
                                  }}
                                />
                              }
                            </Dropdown.Menu>
                          </Dropdown>
                        ) : (
                          ""
                        )}
                      </div>
                    </th>
                    <th style={{ border: "1px solid #d7dae3", width: "8.5%" }}>
                      <OverlayTrigger
                        delay={{ hide: 100, show: 10 }}
                        overlay={
                          <Tooltip id="tooltip-top">
                            <span className="fs-18">
                              AI sorted this task as [core/quick/support] to
                              help you prioritize effectively. Learn more here!
                            </span>
                          </Tooltip>
                        }
                        placement="top"
                      >
                        <div className="d-flex align-items-center m-0 p-0">
                          <span
                            className="float-start"
                            style={{
                              paddingRight: "10px",
                              fontWeight: "bold",
                              color: "#131F4C",
                            }}
                          >
                            {" "}
                            {/* <i
                                      class="fas fa-sort"
                                      onClick={() => {
                                        handleClick1("type", !sortAsc);
                                      }}
                                    ></i> */}
                            Type
                          </span>
                          {filterScheduleTaskIcon ? (
                            <Dropdown
                              // className="setDropDownposition float-end"
                              title="Filter"
                              // style={{ right: "79.5%" }}
                            >
                              <Dropdown.Toggle
                                style={{
                                  background: "none",
                                  border: "none",
                                }}
                                className="m-0 p-0"
                              >
                                <i
                                  className="fas fa-filter fs-12"
                                  style={{
                                    cursor: "pointer",
                                    color: seletcedScheduledTaskType
                                      ? "#DC5D74"
                                      : "#898fa5",
                                  }}
                                ></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                style={{
                                  width: "auto",
                                  height: "120px",
                                }}
                              >
                                {tasktype?.map((scope, i) => (
                                  <Dropdown.Item
                                    style={
                                      seletcedScheduledTaskType === scope.value
                                        ? { backgroundColor: "#B8E5F1" }
                                        : { backgroundColor: "#ffffff" }
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setselectedScheduledTaskType(scope.value);
                                    }}
                                    key={i}
                                    className="p fs-14"
                                  >
                                    {scope?.name}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          ) : (
                            ""
                          )}
                        </div>
                      </OverlayTrigger>
                    </th>
                    <th style={{ border: "1px solid #d7dae3", width: "9%" }}>
                      <div className="d-flex align-items-center m-0 p-0">
                        <span
                          className="float-start"
                          style={{
                            paddingRight: "10px",
                            fontWeight: "bold",
                            color: "#131F4C",
                          }}
                        >
                          {" "}
                          {/* <i
                                      class="fas fa-sort"
                                      onClick={() => {
                                        handleClick1("type", !sortAsc);
                                      }}
                                    ></i> */}
                          Outcome
                        </span>
                        {filterScheduleTaskIcon ? (
                          <Dropdown
                            // className="setDropDownposition float-end"
                            title="Filter"
                            // style={{ right: "79.5%" }}
                          >
                            <Dropdown.Toggle
                              style={{
                                background: "none",
                                border: "none",
                              }}
                              className="m-0 p-0"
                            >
                              <i
                                className="fas fa-filter fs-12"
                                style={{
                                  cursor: "pointer",
                                  color: selectedTaskStatusItem
                                    ? "#DC5D74"
                                    : "#898fa5",
                                }}
                              ></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              style={{
                                width: "auto",
                                height: "auto",
                              }}
                            >
                              {outComeOption?.map((scope, i) => (
                                <Dropdown.Item
                                  style={
                                    selectedTaskStatusItem === scope.value
                                      ? { backgroundColor: "#B8E5F1" }
                                      : { backgroundColor: "#ffffff" }
                                  }
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setseletcedScheduledTaskStatus(scope.value);
                                  }}
                                  key={i}
                                  className="p fs-14"
                                >
                                  {scope?.name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        ) : (
                          ""
                        )}
                      </div>
                    </th>
                    <th style={{ border: "1px solid #d7dae3", width: "9%" }}>
                      <span style={{ fontWeight: "bold", color: "#131F4C" }}>
                        Action
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody style={{ border: "1px solid #d7dae3" }}>
                  {tasks.length === 0 ? (
                    loadingAllTasks ? (
                      <>
                        {Array.from({ length: 5 }).map((_, index) => (
                          <SkeletonTableRows key={index} />
                        ))}
                      </>
                    ) : (
                      <tr role="row" className="mb-2">
                        <td colSpan="3" style={{ textAlign: "center" }}>
                          No data available
                        </td>
                      </tr>
                    )
                  ) : (
                    tasks.map((task, index) => (
                      <TableRows
                        key={task.id}
                        task={task}
                        index={index}
                        moveGoal={moveGoal}
                      />
                    ))
                  )}
                </tbody>
                {rowAddTask ? (
                  <tr style={{ height: "90px" }}>
                    <td className="p-2" style={{ position: "relative" }}>
                      <input
                        type="text"
                        name="task_name"
                        onChange={formik.handleChange}
                        placeholder={"Enter task name"}
                        onBlur={formik.handleBlur}
                        value={formik.values.task_name}
                        style={{
                          width: "100%",
                          border: "1px solid #e2e2e2",
                          height: "37px",
                          padding: "6px",
                        }}
                      />
                      {formik.touched.task_name && formik.errors.task_name ? (
                        <div
                          style={{
                            color: "red",
                            position: "absolute",
                            left: "0",
                          }}
                        >
                          {formik.errors.task_name}
                        </div>
                      ) : null}
                    </td>
                    <td className="p-2">
                      <Select
                        name="goal_owner"
                        className="p"
                        isClearable
                        options={goalOption}
                        value={defaultOptions}
                        onChange={changeGoalOption}
                        menuPlacement="auto"
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </td>
                    <td className="p-2">
                      <Select
                        options={[
                          { value: "high", label: "High" },
                          { value: "medium", label: "Medium" },
                          { value: "low", label: "Low" },
                        ]}
                        onChange={(selectedOption) =>
                          formik.setFieldValue("task_impact", selectedOption)
                        }
                        value={formik.values.task_impact}
                        menuPlacement="auto"
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </td>
                    <td className="p-2" style={{ position: "relative" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          sx={{
                            "& .MuiInputBase-root": {
                              height: 39,
                              overflow: "hidden",
                            },
                            "& .MuiInputBase-input": {
                              height: 39,
                              overflow: "hidden",
                              border: "none",
                            },
                          }}
                          height={80}
                          ampm={false}
                          views={["hours", "minutes"]}
                          timeSteps={{ minutes: 1 }}
                          minTime={dayjs().set("hour", 0).set("minute", 0)}
                          maxTime={dayjs().set("hour", 19).set("minute", 59)}
                          value={
                            formik.values.task_effort
                              ? dayjs(formik.values.task_effort, "HH:mm")
                              : null
                          }
                          onChange={(newValue) => {
                            const formattedTime = newValue
                              ? newValue.format("HH:mm")
                              : "";
                            formik.setFieldValue("task_effort", formattedTime);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // variant="outlined"
                              size="small"
                              placeholder="Select Time"
                              fullWidth
                            />
                          )}
                          // slots={{ actionBar: () => null }}
                          slotProps={{
                            actionBar: {
                              actions: [""],
                            },
                            textField: {
                              InputAdornmentProps: {
                                sx: { display: "none" }, // Hides the input adornment
                              },
                            },
                          }}
                          InputProps={{
                            // ...params.InputProps,
                            endAdornment: null,
                          }}
                        />
                      </LocalizationProvider>
                      {formik.touched.task_effort &&
                      formik.errors.task_effort ? (
                        <div
                          style={{
                            color: "red",
                            position: "absolute",
                            left: "0",
                          }}
                        >
                          {formik.errors.task_effort}
                        </div>
                      ) : null}
                    </td>
                    <td className="p-4">-</td>
                    <td className="p-4">-</td>
                    <td className="p-2">
                      <button
                        className="submitButtonformikForm figma-btn text-white goal-save-button mx-3"
                        style={{ width: "87px" }}
                        type="submit"
                      >
                        Add
                      </button>
                    </td>
                  </tr>
                ) : (
                  <tr
                    style={{
                      borderTop: "1px solid #d7dae3",
                      marginTop: "20px",
                    }}
                  >
                    <div className="mt-3">
                      <OverlayTrigger
                        delay={{ hide: 100, show: 10 }}
                        overlay={
                          <Tooltip id="tooltip-right">
                            <span className="fs-18">Quick Add Task</span>
                          </Tooltip>
                        }
                        placement="right"
                      >
                        <Button
                          style={{
                            background: "#356FD4",
                            border: "1px solid #356FD4",
                            height: "50px",
                            width: "50px",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            setRowAddTask(true);
                          }}
                          // onClick={handleDoneButton}
                          // onContextMenu={handleClick} // Right-click handler
                        >
                          <FaPlus color="#FFFFFF"></FaPlus>
                        </Button>
                      </OverlayTrigger>
                    </div>
                  </tr>
                )}
              </table>
            </form>
          </div>
        </DndProvider>
      </div>
      {taskDetails && (
        <TaskEdit
          task={taskDetails}
          setIsTaskUpdated={() => setTaskDetails()}
          setTask={() => setTaskDetails()}
          setUpdateTaskRefresh={() => setUpdateTaskRefresh()}
        />
      )}
    </>
  );
});

export default PlannedTasks;
