import React from "react";
import { Button, Row, Typography, Flex } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { LeftOutlined } from "@ant-design/icons";
import StarBackground from "./StarBackground";
import { useNavigate } from "react-router-dom";
const { Text } = Typography;

const BlogPostSection = ({ children }) => {
  const screens = useBreakpoint();
  const navigate = useNavigate();
  return (
    <StarBackground>
      <Row
        align="middle"
        style={{
          // marginTop: "5rem",
          zIndex: "100",

          padding: screens.lg
            ? "5rem 7.5rem " // Large screens: top/bottom 4rem, left/right 6.25rem
            : screens.md
            ? "2.5rem 2.5rem" // Medium screens: top/bottom 2rem, left/right 2rem
            : "2.5rem 1rem",
          width: "100%",
          // background: "#E8EBF7",
        }}
      >
        <Row
          style={{
            padding: !screens.xl ? "0rem" : "0rem 6.375rem",
            width: "100%",
            zIndex: "100",
          }}
        >
          <Row>
            <Button type="text" style={{ height: "2.5rem", padding: "0rem" }}>
              <Flex style={{ fontSize: "1rem", color: "var(--black)" }}>
                {" "}
                <LeftOutlined />{" "}
                <Text
                  className="fw-600 f-inter"
                  style={{
                    fontSize: "1rem",
                    color: "var(--black)",
                    paddingLeft: "0.5rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  {" "}
                  Back
                </Text>{" "}
              </Flex>
            </Button>
          </Row>
          {children}
        </Row>
      </Row>
    </StarBackground>
  );
};

export default BlogPostSection;
