import React from "react";
import { Row, Col, Typography, Card, Button, Image, Flex } from "antd";

import getDynamicPadding from "../../config/paddingUtility";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { LinkedinFilled } from "@ant-design/icons";
import founder1 from "../../assets/founder1.svg";
import founder2 from "../../assets/founder2.svg";
import linkedin from "../../assets/LinkedIn.svg";

const { Title, Paragraph, Text } = Typography;

const FoundersSection = () => {
  const screens = useBreakpoint();
  return (
    <Row
      style={{
        padding: getDynamicPadding(screens),
        width: "100%",
        background: "#F7F8FC",
        rowGap: !screens.md ? "1.25rem" : "1.5rem",
      }}
    >
      <Row
        align="middle"
        justify="center"
        style={{ width: "100%", padding: "0" }}
      >
        <Col span={24}>
          <Paragraph
            style={{
              textAlign: "center",
              fontWeight: "600",
              fontSize: !screens.lg ? "1.75rem" : "2.375rem",
              lineHeight: "2.875rem",
              marginBottom: !screens.md ? "0rem" : "1rem",
            }}
          >
            <span
              style={{ fontFamily: "Bricolage Grotesque", color: "#05060E" }}
            >
              Meet the
            </span>{" "}
            <span
              style={{ fontFamily: "Bricolage Grotesque", color: "#1D3BAF" }}
            >
              Founders
            </span>
          </Paragraph>
        </Col>
      </Row>
      <Row
        align="middle"
        justify="center"
        style={{
          margin: 0,
          width: "100%",
        }}
      >
        <Col sm={24} md={24} lg={24} xl={18} xxl={18}>
          <Card
            style={{
              borderRadius: "1.25rem",
              boxShadow: "4px 0px 20px 0px rgba(5, 6, 14, 0.06)",
              width: "100%",
            }}
            styles={{
              body: {
                padding: "0",
              },
            }}
          >
            <Row
              align="middle"
              wrap={true}
              className="aboutus-card"
              style={{
                width: "100%",
                display: "flex",
                alignItems: "stretch",
              }}
            >
              <Col
                xs={24}
                md={10}
                lg={8}
                xl={8}
                xxl={8}
                style={{
                  padding: "0",
                  width: "100%",
                  height: !screens.md && "18rem",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    backgroundImage: `url(${founder1})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    borderTopLeftRadius: "1rem",
                    borderTopRightRadius: !screens.md ? "1rem" : "0",
                    borderBottomLeftRadius: !screens.md ? "0" : "1rem",
                    backgroundSize: "cover",
                  }}
                ></div>
              </Col>
              <Col
                xs={24}
                md={14}
                lg={16}
                xl={16}
                xxl={16}
                style={{
                  padding: !screens.lg
                    ? "1rem"
                    : "2.5rem 2.5rem 2.5rem 1.75rem",
                }}
              >
                <Flex vertical style={{ gap: "1rem" }}>
                  <Flex vertical>
                    <Text
                      level={4}
                      style={{
                        color: "#05060E",
                        fontSize: "1.75rem",
                        fontFamily: "Bricolage Grotesque",
                        fontWeight: "600",
                        lineHeight: "2.25rem",
                      }}
                    >
                      Anders Schonberg
                    </Text>
                    <Text
                      style={{
                        color: "#193295",
                        fontFamily: "Inter",
                        fontWeight: "600",
                        lineHeight: "1.75rem",
                        fontSize: "1.25rem",
                      }}
                    >
                      Founder & CEO
                    </Text>
                  </Flex>

                  <Text
                    className="paragraph-style"
                    style={{
                      fontSize: "1rem",
                      color: "#565C76",
                    }}
                  >
                    Anders, the visionary behind Plansom, is a seasoned
                    entrepreneur with a passion for innovative technology.
                    Having built three successful technology companies, he
                    combines technical expertise with a deep understanding of
                    the challenges facing today’s workforce. Anders is committed
                    to helping individuals and teams achieve more while
                    maintaining a healthy work-life balance.
                  </Text>
                  <Button
                    size="large"
                    type="text"
                    style={{
                      width: "fit-content",
                      color: "#000",
                      fontFamily: "Inter",
                      fontWeight: "600",
                      fontSize: "1rem",
                      fontStyle: "normal",
                      lineHeight: "1.5rem",
                      paddingLeft: "0",
                    }}
                  >
                    <Image src={linkedin} preview={false} />
                    LinkedIn
                  </Button>
                </Flex>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row
        align="middle"
        justify="center"
        style={{
          margin: 0,
          width: "100%",
        }}
      >
        <Col sm={24} md={24} lg={24} xl={18} xxl={18}>
          <Card
            style={{
              borderRadius: "1.25rem",
              boxShadow: "4px 0px 20px 0px rgba(5, 6, 14, 0.06)",
              width: "100%",
            }}
            styles={{
              body: {
                padding: "0",
              },
            }}
          >
            <Row
              align="middle"
              wrap={true}
              className="aboutus-card"
              style={{
                width: "100%",
                display: "flex",
                alignItems: "stretch",
              }}
            >
              {!screens.md ? (
                ""
              ) : (
                <Col
                  xs={24}
                  md={14}
                  lg={16}
                  xl={16}
                  xxl={16}
                  style={{
                    padding: !screens.lg
                      ? "1rem"
                      : "2.5rem 1.75rem 2.5rem 2.5rem",
                  }}
                >
                  <Flex vertical style={{ gap: "1rem" }}>
                    <Flex vertical>
                      <Text
                        level={4}
                        style={{
                          color: "#05060E",
                          fontSize: "1.75rem",
                          fontFamily: "Bricolage Grotesque",
                          fontWeight: "600",
                          lineHeight: "2.25rem",
                        }}
                      >
                        Chloe Schonberg
                      </Text>
                      <Text
                        style={{
                          color: "#193295",
                          fontFamily: "Inter",
                          fontWeight: "600",
                          lineHeight: "1.75rem",
                          fontSize: "1.25rem",
                        }}
                      >
                        Co-Founder & COO
                      </Text>
                    </Flex>

                    <Text
                      className="paragraph-style"
                      style={{
                        fontSize: "1rem",
                        color: "#565C76",
                      }}
                    >
                      Chloe, who has been instrumental in bringing Plansom to
                      life, ensures that our transformative vision is seamlessly
                      integrated into every aspect of the product. With a
                      background as Head of People Business Partners EMEA at
                      Cloudflare, Chloe oversees our operations, ensuring that
                      our users experience the full benefits of Plansom’s
                      user-centric design. 
                    </Text>
                    <Button
                      size="large"
                      type="text"
                      style={{
                        width: "fit-content",
                        color: "#000",
                        fontFamily: "Inter",
                        fontWeight: "600",
                        fontSize: "1rem",
                        fontStyle: "normal",
                        lineHeight: "1.5rem",
                        paddingLeft: "0",
                      }}
                    >
                      <Image src={linkedin} preview={false} />
                      LinkedIn
                    </Button>
                  </Flex>
                </Col>
              )}

              <Col
                xs={24}
                md={10}
                lg={8}
                xl={8}
                xxl={8}
                style={{
                  padding: "0",
                  width: "100%",
                  height: !screens.md && "18rem",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    backgroundImage: `url(${founder2})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    borderTopLeftRadius: !screens.md ? "1rem" : "0rem",
                    borderTopRightRadius: "1rem",
                    borderBottomRightRadius: !screens.md ? "0rem" : "1rem",

                    backgroundSize: "cover",
                  }}
                ></div>
              </Col>

              {!screens.md ? (
                <Col
                  xs={24}
                  md={16}
                  lg={16}
                  xl={16}
                  xxl={16}
                  style={{
                    padding: !screens.lg
                      ? "1rem"
                      : "2.5rem 1.75rem 2.5rem 1.75rem",
                  }}
                >
                  <Flex vertical style={{ gap: "1rem" }}>
                    <Flex vertical>
                      <Text
                        level={4}
                        style={{
                          color: "#05060E",
                          fontSize: "1.75rem",
                          fontFamily: "Bricolage Grotesque",
                          fontWeight: "600",
                          lineHeight: "2.25rem",
                        }}
                      >
                        Chloe Schonberg
                      </Text>
                      <Text
                        style={{
                          color: "#193295",
                          fontFamily: "Inter",
                          fontWeight: "600",
                          lineHeight: "1.75rem",
                          fontSize: "1.25rem",
                        }}
                      >
                        Co-Founder & COO
                      </Text>
                    </Flex>

                    <Text
                      className="paragraph-style"
                      style={{
                        fontSize: "1rem",
                        color: "#565C76",
                      }}
                    >
                      Chloe, who has been instrumental in bringing Plansom to
                      life, ensures that our transformative vision is seamlessly
                      integrated into every aspect of the product. With a
                      background as Head of People Business Partners EMEA at
                      Cloudflare, Chloe oversees our operations, ensuring that
                      our users experience the full benefits of Plansom’s
                      user-centric design. 
                    </Text>
                    <Button
                      size="large"
                      type="text"
                      style={{
                        width: "fit-content",
                        color: "#000",
                        fontFamily: "Inter",
                        fontWeight: "600",
                        fontSize: "1rem",
                        fontStyle: "normal",
                        lineHeight: "1.5rem",
                        paddingLeft: "0",
                      }}
                    >
                      <Image src={linkedin} preview={false} />
                      LinkedIn
                    </Button>
                  </Flex>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </Card>
        </Col>
      </Row>
    </Row>
  );
};

export default FoundersSection;
