import React from "react";
import { Row, Col, Typography, Flex } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

const { Text } = Typography;

const CardsSection = ({ children }) => {
  const screens = useBreakpoint();
  return (
    <Row
      align="middle"
      style={{
        padding: screens.lg
          ? "5rem 7.5rem " // Large screens: top/bottom 4rem, left/right 6.25rem
          : screens.md
          ? "2.5rem 2.5rem" // Medium screens: top/bottom 2rem, left/right 2rem
          : "2.5rem 1rem",
        width: "100%",
        background: "#F7F8FC",
      }}
    >
      <Row
        style={{
          width: "100%",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col span={24} style={{ width: "100%" }}>
            <Flex justify="center" style={{ width: "100%" }}>
              <Text
                className="qasubsec-heading"
                style={{
                  lineHeight: !screens.md ? "" : "2.875rem",
                  fontSize: !screens.md ? "" : "2.375rem",
                  textAlign: "center",
                }}
              >
                Discover our latest{" "}
                <span style={{ color: "var(--dark-blue)" }}>posts</span>
              </Text>
            </Flex>
          </Col>
        </Row>
        <Row>
          <Col span={24}>{children}</Col>
        </Row>
      </Row>
    </Row>
  );
};

export default CardsSection;
