import React from "react";
import ReactApexChart from "react-apexcharts";

function RedialApex(props) {
  const series = [props?.value];
  const options = {
    chart: {
      type: "radialBar",
      height: 100,
      width: 100,
      offsetY: 0,
      sparkline: {
        enabled: true,
      },
    },
    tooltip: {
      enabled: true,
      theme: "light",
      style: {
        background: "red", // Background color
        color: "#fff", // Text color
      },
      y: {
        formatter: function (val) {
          return `${val}%`;
        },
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -0,
        endAngle: 360,
        track: {
          background: "var(--rgba-primary-1)",
          strokeWidth: 100,
          margin: 1,
        },
        dataLabels: {
          enabled: true,
          name: {
            show: false,
          },
          value: {
            offsetY: 1,
            fontSize: "22px",
            color: "var(--primary)",
            fontWeight: 100,
            show: false,
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 1600,
        options: {
          chart: {
            height: 100,
          },
        },
      },
    ],
    grid: {
      padding: {
        top: 0,
      },
    },
    fill: {
      type: "gradient",
      colors: props?.color || "var(--primary)",
    },
    labels: props?.label,
  };

  return (
    <div
      id="complete-percentage-chart"
      className=" row h-100 w-100 p-0 m-0"
      data-testid="redial-chart"
      data-type="radialBar"
    >
      <ReactApexChart
        className="m-0 p-0"
        options={options}
        series={series}
        type="radialBar"
        height="auto"
      />
    </div>
  );
}

export default RedialApex;
