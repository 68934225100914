import React from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import logoFooter from "../../../images/Strategy/logoFooter.png";
import instagram from "../../../images/Strategy/Instagram.svg";
import linkedin from "../../../images/Strategy/Linkedin.svg";
import youtube from "../../../images/Strategy/Youtube.svg";
import facebook from "../../../images/Strategy/Facebook.svg";
import { Button, Input } from "antd";
import { useNavigate } from "react-router-dom";

const LINKEDIN = "https://www.linkedin.com/company/plansom/";
const YOUTUBE = "https://www.youtube.com/@Plansom";
const FACEBOOK = "https://www.facebook.com/people/Plansom/61568509101315/";
const INSTAGRAM = "https://www.instagram.com/plansomapp/";

function PageFooter() {
  const navigate = useNavigate();

  const handlePricing = () => {
    document
      .querySelector(".header-section")
      ?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Box style={{}}>
        <Box>
          <Grid
            sx={{
              display: "flex",
              background: "#112264",
              color: "#FFFFFF",
              justifyContent: "center",
              padding: { xs: "30px 10px 0px 10px", md: "100px 10px 10px 10px" },
            }}
          >
            <Grid
              item
              md={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "center" },
              }}
            >
              <img src={logoFooter} alt="logo" style={{ maxWidth: "100%" }} />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid
            sx={{
              display: "flex",
              background: "#112264",
              color: "#FFFFFF",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              md={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "center" },
                marginBottom: { xs: "1rem", md: "0" },
                padding: {
                  xs: "1rem 1rem 0rem 1rem",
                  md: "0px 10px 40px 10px",
                },
              }}
            >
              <Typography sx={{ color: "#FFFFFF", textAlign: "center" }}>
                Start Your Journey to Success Today – Create Your First Plan
                with Plansom!
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            background: "#112264",
            padding: {
              xs: "0rem 2rem ",
              sm: "2rem 2rem",
              md: "1rem 7rem 1rem 7rem",
            },
            color: "#FFF",
          }}
        >
          <Grid
            sx={{
              backgroundColor: "#112264",
              padding: "1rem 0rem 1rem 0rem",
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Typography sx={{ color: "#FFF" }}>6th Floor,</Typography>
            <Typography sx={{ color: "#FFF" }}>South Bank House,</Typography>
            <Typography sx={{ color: "#FFF" }}>
              Barrow Street Dublin 4D04 TR29
            </Typography>
            <Typography sx={{ color: "#FFF" }}>+35315262724 </Typography>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{
              display: "flex",
            }}
          >
            {/* Logo and Pricing */}
            <Grid
              item
              container
              lg={4}
              md={12}
              xs={12}
              sm={12}
              sx={{
                display: "flex",
                flexDirection: { xs: "row", md: "row" },
                justifyContent: {
                  xs: "center",
                  lg: "flex-start",
                  md: "center",
                },
                alignItems: "center",
                gap: { xs: "10px", md: "0px" },
              }}
            >
              <Grid
                item
                md={2}
                xs={2}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", md: "flex-start" },
                  marginBottom: { xs: "1rem", md: "1rem" },
                }}
              >
                <a href={LINKEDIN} target="_blank" rel="noopener noreferrer">
                  <img
                    src={linkedin}
                    alt="Instagram"
                    style={{ width: "48px", height: "48px" }}
                  />
                </a>
              </Grid>
              <Grid
                item
                md={2}
                xs={2}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", md: "flex-start" },
                  marginBottom: { xs: "1rem", md: "1rem" },
                }}
              >
                <a href={YOUTUBE} target="_blank" rel="noopener noreferrer">
                  <img
                    src={youtube}
                    alt="YouTube"
                    style={{ width: "48px", height: "48px" }}
                  />
                </a>
              </Grid>
              <Grid
                item
                md={2}
                xs={2}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", md: "flex-start" },
                  marginBottom: { xs: "1rem", md: "1rem" },
                }}
              >
                <a href={FACEBOOK} target="_blank" rel="noopener noreferrer">
                  <img
                    src={facebook}
                    alt="logo"
                    style={{ width: "48px", height: "48px" }}
                  />
                </a>
              </Grid>
              <Grid
                item
                md={2}
                xs={2}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", md: "flex-start" },
                  marginBottom: { xs: "1rem", md: "1rem" },
                }}
              >
                <a href={INSTAGRAM} target="_blank" rel="noopener noreferrer">
                  <img
                    src={instagram}
                    alt="logo"
                    style={{ width: "48px", height: "48px" }}
                  />
                </a>
              </Grid>
            </Grid>

            {/* Input and Subscribe Section */}
            <Grid
              item
              container
              lg={4}
              spacing={2}
              sx={{
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={12}
                md={8}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Input
                  className="custom-input"
                  placeholder="Stay up to date"
                  style={{
                    height: "40px",
                    width: "100%",
                    borderRadius: "20px",
                    fontSize: "16px",
                    backgroundColor: "#0D1B4F",
                    color: "#FFF",
                    borderColor: "#193295",
                    // marginRight: "12px",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  type="primary"
                  className="custom-login-button"
                  style={{
                    height: "40px",
                    width: "100%",
                    // maxWidth: "150px",
                    borderRadius: "24px",
                    fontSize: "16px",
                    backgroundColor: "#FFFFFF",
                    color: "#05060E",
                  }}
                >
                  Subscribe
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            borderTop: "1px solid #193295",
            background: "#112264",
            padding: { xs: "2rem 2rem", sm: "2rem 1rem", md: "3rem 7rem" },
            color: "#FFF",
          }}
        >
          <Grid container spacing={2} alignItems="center">
            {/* Left Column: Links */}
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: "1.5rem",
                  justifyContent: { xs: "center", sm: "flex-start" },
                  textAlign: { xs: "center", sm: "left" },
                }}
              >
                <Button
                  type="text"
                  style={{
                    color: "#FFF",
                    fontWeight: "600",
                    fontSize: "1rem",
                    font: "inter",
                    lineHeight: "24px",
                  }}
                  onClick={() => {
                    navigate("/about-us");
                  }}
                >
                  About Us
                </Button>

                <Button
                  type="text"
                  style={{
                    color: "#FFF",
                    fontWeight: "600",
                    fontSize: "1rem",
                    font: "inter",
                    lineHeight: "24px",
                  }}
                  onClick={handlePricing}
                >
                  Pricing
                </Button>
                <Button
                  type="text"
                  style={{
                    color: "#FFF",
                    fontWeight: "600",
                    fontSize: "1rem",
                    font: "inter",
                    lineHeight: "24px",
                  }}
                  onClick={() => {
                    navigate("/cookie-policy");
                  }}
                >
                  Cookie Policy
                </Button>

                <Button
                  type="text"
                  style={{
                    color: "#FFF",
                    fontWeight: "600",
                    fontSize: "1rem",
                    font: "inter",
                    lineHeight: "24px",
                  }}
                  onClick={() => {
                    navigate("/terms-conditions");
                  }}
                >
                  Terms and Conditions
                </Button>
                <Button
                  type="text"
                  style={{
                    color: "#FFF",
                    fontWeight: "600",
                    fontSize: "1rem",
                    font: "inter",
                    lineHeight: "24px",
                  }}
                  onClick={() => {
                    navigate("/plansom/privacy-policy");
                  }}
                >
                  Privacy Policy
                </Button>
                <Button
                  type="text"
                  style={{
                    color: "#FFF",
                    fontWeight: "600",
                    fontSize: "1rem",
                    font: "inter",
                    lineHeight: "24px",
                  }}
                  onClick={() => {
                    navigate("/blogs/one");
                  }}
                >
                  Blogs
                </Button>
              </Box>
            </Grid>

            {/* Right Column: Copyright */}
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{
                  textAlign: { xs: "center", sm: "right" },
                  color: "#9EABDD",
                  fontSize: "16px",
                }}
              >
                © 2024 Plansom. All rights reserved.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default PageFooter;
