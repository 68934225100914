import React from "react";
import { Button, Row, Typography, Flex } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { LeftOutlined } from "@ant-design/icons";
const { Text } = Typography;

const QASection = ({ children }) => {
  const screens = useBreakpoint();
  return (
    <Row
      align="middle"
      style={{
        padding: screens.lg
          ? "5rem 7.5rem "
          : screens.md
          ? "2.5rem 2.5rem"
          : "2.5rem 1rem",
        width: "100%",
        background:
          "linear-gradient(10.85deg, rgba(125, 105, 255, 0.1) -16.28%, rgba(255, 255, 255, 0.5) 20.31%)",
      }}
    >
      <Row
        style={{
          padding: !screens.xl ? "0rem" : "0rem 6.375rem",
          width: "100%",
        }}
      >
        {children}
      </Row>
    </Row>
  );
};

export default QASection;
