import React from "react";
import star from "../../../../../../../src/images/blogs/star.svg";

import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

const StarBackground = ({ children }) => {
  const screens = useBreakpoint();
  return (
    <div
      style={{
        background: "#E8EBF7",
        marginTop: "5rem",
        position: "relative",
        width: "100%",
      }}
    >
      {!screens.lg ? (
        <>
          <img
            src={star}
            alt=""
            style={{
              width: "4rem",
              height: "4rem",
              position: "absolute",
              right: "2.8rem",
              top: "-0.5rem",
              zIndex: "1",
            }}
          />
          <img
            src={star}
            alt=""
            style={{
              width: "2.5rem",
              height: "2.5rem",
              position: "absolute",
              right: "6.5rem",
              top: "2rem",
              zIndex: "1",
            }}
          />
        </>
      ) : (
        <>
          <img
            src={star}
            alt=""
            style={{
              zIndex: "1",
              width: "5.375rem",
              height: "5.3125rem",
              position: "absolute",
              right: "16.4375rem",
              top: "4.5rem",
            }}
          />
          <img
            src={star}
            alt=""
            style={{
              width: "4rem",
              height: "4rem",
              position: "absolute",
              right: "5.3125rem",
              top: "11.75rem",
              zIndex: "1",
            }}
          />
          <img
            src={star}
            alt=""
            style={{
              width: "3rem",
              height: "3rem",
              position: "absolute",
              right: "9.75rem",
              top: "15.4375rem",
              zIndex: "1",
            }}
          />
          <img
            src={star}
            alt=""
            style={{
              width: "3rem",
              height: "3rem",
              position: "absolute",
              right: "23.125rem",
              top: "18.1875rem",
              zIndex: "1",
            }}
          />
        </>
      )}

      {children}
    </div>
  );
};

export default StarBackground;
