import React, { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { getTasksByGoalIdAction } from "../../../store/actions/GoalsActions";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Modal, Row, Dropdown } from "react-bootstrap";
import {
  getOrgMembers,
  getTeamMemByTeamId,
} from "../../../store/actions/TeamAction";
import { getAllGoalsByScope, getGoalById } from "../../../services/GoalService";
import {
  deleteTask,
  taskSelector,
  taskScheduleAlgo,
  getScheduledTasks,
  userSpecificTaskHistory,
  userSpecificTask,
  editTaskForActiveInactive,
} from "../../../services/TaskService";
// import Pagination from "../../components/Pagination/Pagination";
// import TaskModal from "../Component/CreateTaskModal";
// import TaskEdit from "./TaskEdit";
import Vector3 from "../../../images/vector/Vector3.png";
import { getThreadList } from "../../../store/actions/NotificationAction";
import LoadingSpinner from "../Component/LoadingSpinner";
import { lazy } from "react";
import ArchieveGoal from "../Component/ArchiveGoal";
import ShareTaskModal from "../Component/ShareTaskModal";
import { markTaskAsIncomplete } from "../../../services/TaskService";
import { editTask } from "../../../services/TaskService";
import { toBeScheduledTask } from "../../../services/TaskService";

import Select from "react-select";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { taskFeedback } from "../../../services/TaskService";

import { useFormik } from "formik";
import * as Yup from "yup";
import { createTaskService } from "../../../services/TaskService";
import Button from "@mui/joy/Button";
import ButtonGroup from "@mui/joy/ButtonGroup";
import {
  refreshTaskList,
  resetTaskPage,
  taskShowingFilter,
} from "../../../store/actions/TaskActions";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaPlus } from "react-icons/fa6";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import ReactDOM from "react-dom";
import { Stack } from "@mui/material";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ProgressBar from "react-bootstrap/ProgressBar";
import { TextField } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const Pagination = lazy(() => import("../../components/Pagination/Pagination"));
const TaskModal = lazy(() => import("../Component/CreateTaskModal"));
const TaskEdit = lazy(() => import("./TaskEdit"));

const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 9999, // Set a high z-index to ensure the dropdown is above other elements
  }),
};

const Tasks = () => {
  let errorsObj = { taskName: "", taskDescription: "", taskEffort: "" };
  const timeScope = localStorage.getItem("timescope");
  // const startDate = localStorage.getItem("startDate");
  // const endDate = localStorage.getItem("endDate");
  const stateData = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userDetail = localStorage.getItem("userDetails");
  const orgId = localStorage.getItem("organization_id");
  // const [scope, setScope] = useState(localStorage.getItem("scope"));
  const teamId = localStorage.getItem("team_id");
  const userInfoDetails = userDetail ? JSON.parse(userDetail) : null;
  const [goalDetails, setGoalDetails] = useState(location?.state?.goal);
  const [options, setOptions] = useState([]);
  const [taskOwner, setTaskOwnerValue] = useState("");
  const [taskCreater, setTaskCreatorValue] = useState("");
  const [taskImpact, setTaskImpactValue] = useState({
    value: "medium",
    label: "Medium",
  });
  const [taskGoalEdit, setTaskGoalEdit] = useState();
  const [Shows, setShows] = useState(false);
  const [tasksdelete, setTasksdelete] = useState(false);
  const [tasksupdate, setTasksupdate] = useState(false);
  const [taskscreated, setTaskscreate] = useState(false);
  const [taskDetails, setTaskDetails] = useState();
  const [goals, setGoals] = useState([]);
  const [ScheduleTaskSelected, setScheduledTasksSelected] = useState();
  const [taskDetailsinfo, setTaskDetailsinfo] = useState(
    location?.state?.task || location?.state?.calendarEvent || ""
  );
  const [taskKey, settaskKey] = useState();
  const [statusKey, setstatusKey] = useState();

  const [schedulesTasksList, setScheduledTasks] = useState("");
  const [datas, setDatas] = useState([]);
  const [goToPage, setGoToPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [pagination, setPagination] = useState(false);
  const [pageUpcoming, setUpcomingPage] = useState();
  const [goToUpcomingPage, setGoToUpcomingPage] = useState(0);
  const [pageUpcomingCount, setUpcomingPageCount] = useState(1);
  const [paginationUpcoming, setPaginationUpcoming] = useState(false);
  const [goToHistoryPage, setGoToHistoryPage] = useState(0);
  const [pageHistoryCount, setHistoryPageCount] = useState(1);
  const [paginationHistory, setPaginationHistory] = useState(false);
  const [OpenDeleteModal, SetOpenDeleteModal] = useState(false);
  const [openWarningModalForActive, setOpenWarningModalForActive] =
    useState(false);
  const [selectedTaskForActive, setSelecteTaskForActive] = useState();
  const [SelectedTaskDelete, setSelectedTaskDelete] = useState();
  const [OpenWarningModal, setOpenWarningModal] = useState(false);
  const [WarningMessage, setWarningMessage] = useState("");
  const [ScheduleWarning, setScheduleWarning] = useState(false);
  const [feddbackButton, setFeedbackButton] = useState(false);
  const [showSubTableSpinner, setSubShowTableSpinner] = useState(true);
  const [showHistoryTableSpinner, setHistoryTableSpinner] = useState(true);
  const [showTaskTableSpinner, setTaskShowTableSpinner] = useState(true);
  const [taskList, getTasksDetails] = useState([]);
  const [historyTaskList, setHistoryTasksDetails] = useState([]);
  const initialTaskState = {
    task_name: "",
    description: "",
    task_effort: "",
  };
  const [createTask, setCreateTaskForm] = useState({
    task_name: "",
    description: "",
    task_effort: "",
  });

  const [sortAsc, setSortAsc] = useState(false);
  const [sortBy, setSortBy] = useState();
  const [sortOrder, setsortOrder] = useState();
  const [ScheduledTaskOwnerFilter, setScheduledTaskOwnerFilter] = useState();
  const [UpcomingTaskOwnerFilter, setUpcomingTaskOwnerFilter] = useState();
  const [HistoryTaskOwnerFilter, setHistoryTaskOwnerFilter] = useState();
  const [ScheduledTaskEffortFieldOpen, setScheduledTaskEffortFieldOpen] =
    useState();
  const [UpcomingTaskEffortFieldOpen, setUpcomingTaskEffortFieldOpen] =
    useState(false);
  const [HistoryTaskEffortFieldOpen, setHistoryTaskEffortFieldOpen] =
    useState();
  const [seletcedUpcomingTaskType, setselectedUpcomingTaskType] = useState();
  const [seletcedScheduledTaskType, setselectedScheduledTaskType] = useState();
  const [seletcedHistoryTaskType, setselectedHistoryTaskType] = useState();
  const [selectedScheduledTaskImpact, setselectedScheduledTaskImpact] =
    useState();
  const [selectedScheduledTaskControl, setselectedScheduledTaskControl] =
    useState();
  const [selectedScheduledTaskRiskStatus, setselectedScheduledTaskRiskStatus] =
    useState();
  const [selectedUpcomingTaskImpact, setselectedUpcomingTaskImpact] =
    useState();
  const [selectedUpcomingTaskControl, setselectedUpcomingTaskControl] =
    useState();
  const [selectedUpcomingTaskRiskStatus, setselectedUpcomingTaskRiskStatus] =
    useState();
  const [selectedHistoryTaskImpact, setselectedHistoryTaskImpact] = useState();
  const [selectedHistoryTaskControl, setselectedHistoryTaskControl] =
    useState();
  const [selectedHistoryTaskRiskStatus, setselectedHistoryTaskRiskStatus] =
    useState();
  const [ScheduledTaskEffortFilter, setScheduledTaskEffortFilter] = useState();
  const [
    ScheduledTaskEffortFilterForShow,
    setScheduledTaskEffortFilterForShow,
  ] = useState();
  const [UpcomingTaskEffortFilter, setUpcomingTaskEffortFilter] = useState();
  const [HistoryTaskEffortFilter, setHistoryTaskEffortFilter] = useState();
  const [selectedTaskStatusItem, setSelectedTaskStatusItem] = useState();
  const [ScheduledTaskInputFieldOpen, setScheduledTaskInputFieldOpen] =
    useState(false);
  const [UpcomingTaskInputFieldOpen, setUpcomingTaskInputFieldOpen] =
    useState(false);
  const [HistoryTaskInputFieldOpen, setHistoryTaskInputFieldOpen] =
    useState(false);
  const [ScheduledTaskNameFilter, setScheduledTaskNameFilter] = useState();
  const [UpcomingTaskNameFilter, setUpcomingTaskNameFilter] = useState();
  const [HistoryTaskNameFilter, setHistoryTaskNameFilter] = useState();
  const [scheduledTaskDueInFilter, setScheduledTaskDueInFilter] = useState();
  const [
    ScheduledTasksGoalInputFieldOpen,
    setScheduledTasksGoalInputFieldOpen,
  ] = useState(false);
  const [UpcomingTasksGoalInputFieldOpen, setUpcomingTasksGoalInputFieldOpen] =
    useState(false);
  const [HistoryTasksGoalInputFieldOpen, setHistoryTasksGoalInputFieldOpen] =
    useState(false);
  const [ScheduledTasksGoalNameFilter, setScheduledTasksGoalNameFilter] =
    useState();
  const [UpcomingTasksGoalNameFilter, setUpcomingTasksGoalNameFilter] =
    useState();
  const [HistoryTasksGoalNameFilter, setHistoryTasksGoalNameFilter] =
    useState();
  const [filterUpcomingIcon, setFilterUpcomingIcon] = useState(true);
  const [filterScheduleTaskIcon, setFilterScheduleTaskIcon] = useState(true);
  const [filterHistoryTaskIcon, setFilterHistoryTaskIcon] = useState(true);
  const [selectedHistoryTaskStatus, setselectedHistoryTaskStatus] = useState();
  const [
    selectedHistoryTaskScheduleStatus,
    setselectedHistoryTaskScheduleStatus,
  ] = useState();
  const [isTaskCreated, setIsTaskCreated] = useState(false);
  const [isTaskUpdated, setIsTaskUpdated] = useState(false);
  const [isTaskCompleted, setIsTaskCompleted] = useState(false);
  const [taskSelectorError, setTaskSelectorError] = useState(false);
  const [selectedScheduleTaskStatus, setselectedScheduleTaskStatus] =
    useState();
  const [scheduledTaskDueInFieldOpen, setScheduledTaskDueInFieldOpen] =
    useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [inputValue, setInputValue] = useState("10");
  const [inputScheduled, setinputScheduled] = useState(
    localStorage.getItem("scheduled_tasks_row_per_page") || "10"
  );
  const [inputHistory, setinputHistory] = useState(
    localStorage.getItem("history_tasks_row_per_page") || "10"
  );
  const [selectedShareTask, setSelectedShareTask] = useState();
  const [shareModal, setShareModal] = useState(false);

  const [validationMessages, setValidationMessages] = useState("");
  const [taskStatus, setTaskStatusValue] = useState({ value: "", label: "" });

  const [goalOption, setGoalOption] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState();
  const [attachment, setAttachment] = useState([]);
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const [goalAlignId, setGoalAlign] = useState();

  const [markAsStatus, setMarkAsStatus] = useState("");

  const [lateTaskCount, setLateTaskCount] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [rowAddTask, setRowAddTask] = useState(false);
  // const teamId = localStorage.getItem("team_id");
  // const [teamList, getTeamDetails] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(teamId);
  const [taskStatuses, setTaskStatuses] = useState({});
  const [statusFilter, setStatusFilter] = useState();
  const [seletcedScheduledTaskStatus, setseletcedScheduledTaskStatus] =
    useState();

  const OutcomeFilter = [
    { value: "Beat", name: "Epic" },
    { value: "Hit", name: "OK" },
    { value: "Miss", name: "Miss" },
    {
      name: "Not Complete",
      value: "Not Complete",
    },
  ];

  const statusOption = [
    { value: "beat", label: "Epic" },
    { value: "hit", label: "OK" },
    { value: "miss", label: "Miss" },
  ];

  const StatusFilter = [
    {
      name: "Active",
      value: "Active",
    },
    {
      name: "Inactive",
      value: "Inactive",
    },
  ];
  const [taskStatusOptions, settaskStatusOptions] = useState(statusOption);

  useEffect(() => {
    if (stateData?.task?.taskShowingFilter) {
      setScheduledTaskNameFilter(location?.state?.tasks?.name);
    }
  }, [stateData?.task?.taskShowingFilter]);
  const handleCircleClick = (index, event, task) => {
    setTaskStatuses((prevStatuses) => ({
      ...prevStatuses,
      [task.id]: event?.value, // Update the status for the specific row (task) by its index
    }));

    handleMarkAsComplete(task, event?.value);
    // changeTaskStatus("hit", task);
    changeTaskStatus(event?.value, task); // Trigger your custom function to handle the task status change
  };

  const changeTaskStatus = (selectedOption, task) => {
    setTaskStatusValue((prev) => ({ ...prev, [task?.id]: selectedOption }));
    setValidationMessages((prev) => ({ ...prev, [task?.id]: "" }));
    if (markAsStatus?.taskId == task?.id) {
      if (markAsStatus[task?.id] == true) {
        getfeedbackTask(task, selectedOption);
      }
    }
  };

  function getfeedbackTask(task, selectedTaskStatus) {
    const data = taskFeedback(
      task?.id,
      userInfoDetails?.data?.accessToken,
      true,
      selectedTaskStatus,
      task?.task_effort
    );
    data?.then(async (res) => {
      setTaskStatuses({});
      getAllScheduledTasks();
      getUserSpecificTask();
      getHistoryTask();
      // getMyPanDetails()
      setTaskStatusValue({ value: "", label: "" });
      toast(
        <>
          <div className="m-1 d-flex">
            <i
              class="bi bi-check-circle fs-26 me-3"
              style={{ color: "#131F4C" }}
            ></i>
            <div>
              <h2
                style={{
                  // color: "#131F4C",
                  fontSize: "22px",
                }}
              >
                Completed
              </h2>
              <h3
                style={{
                  // color: "#131F4C",
                  fontSize: "18px",
                }}
              >
                Your task has been mark as complete successfully!
              </h3>
            </div>
          </div>
        </>,
        {
          toastId: "task-schedule",
          className: "toast-success-container toast-success-container-after ",
        }
      );
    });
    data?.catch(async (err) => {
      setTaskStatuses({});
      toast(
        <>
          <div className="m-1 d-flex">
            <i
              className="bi bi-check-circle fs-26 me-3"
              style={{ color: "#131F4C" }}
            ></i>
            <div>
              <h2 style={{ fontSize: "22px" }}>Failed</h2>
              <h3 style={{ fontSize: "18px" }}>
                {err?.response?.data?.message}
              </h3>
            </div>
          </div>
        </>,
        {
          toastId: "task-schedule",
          className: "toast-error-container toast-error-container-after",
        }
      );
    });
  }

  const handleToggle = (task) => (event) => {
    // setScheduledTasksSelected(task);
    if (task?.goal?.status === "Active" || task?.status === "Active") {
      const newStatus = event.target.checked ? "Active" : "Inactive";
      const data = editTaskForActiveInactive(
        orgId,
        task?.id,
        selectedTeam,
        task?.goal?.id,
        taskOwner,
        taskCreater,
        (task?.task_impact).toLowerCase(),
        (task?.task_control).toLowerCase(),
        createTask,
        userInfoDetails.data.accessToken,
        taskDetailsinfo?.task_status,
        "",
        newStatus
      );
      data?.then(async (res) => {
        getAllScheduledTasks();
        setTaskOwnerValue("");
      });
      data?.catch(async (res) => {});
    } else {
      setSelecteTaskForActive(task);
      setOpenWarningModalForActive(true);
    }
  };

  const handleMarkAsComplete = (task, status) => {
    if (status) {
      getfeedbackTask(task, status);
    } else {
      setValidationMessages((prev) => ({
        ...prev,
        [task.id]: `* Please select Task Success to mark it as complete`,
      }));

      setMarkAsStatus((prev) => ({
        ...prev,
        [task.id]: true,
        taskId: task.id,
      }));
    }
  };

  const numberOptions = [5, 10, 25, 50, 100];

  let timeout1;

  const handleInputChange = (event) => {
    clearTimeout(timeout1);
    const newValue = event?.target?.value;

    setInputValue(newValue);
    setTimeout(() => {
      const data = userSpecificTask(
        orgId,
        userInfoDetails?.data?.accessToken,
        goToUpcomingPage,
        sortAsc,
        sortBy,
        UpcomingTaskNameFilter,
        seletcedUpcomingTaskType,
        selectedUpcomingTaskImpact,
        selectedUpcomingTaskControl,
        selectedUpcomingTaskRiskStatus,
        selectedTaskStatusItem,
        UpcomingTaskEffortFilter,
        UpcomingTaskOwnerFilter,
        UpcomingTasksGoalNameFilter,
        event?.target?.value
      );
      setTaskShowTableSpinner(true);
      data?.then(async (res) => {
        setTaskShowTableSpinner(false);
        setUpcomingPage(res?.data?.count);
        setUpcomingPageCount(Math.ceil(res?.data?.count / event.target.value));
        setPaginationUpcoming(
          res?.data?.next || res?.data?.previous ? true : false
        );
        getTasksDetails(res?.data?.data);
        if (
          (UpcomingTaskNameFilter ||
            seletcedUpcomingTaskType ||
            selectedUpcomingTaskImpact ||
            selectedUpcomingTaskControl ||
            selectedUpcomingTaskRiskStatus ||
            selectedTaskStatusItem ||
            UpcomingTaskEffortFilter ||
            UpcomingTaskOwnerFilter ||
            UpcomingTasksGoalNameFilter) &&
          res?.data?.length === 0
        ) {
          setFilterUpcomingIcon(false);
        }
      });
      data?.catch(async (err) => {
        setTaskShowTableSpinner(false);
      });
    }, 1500);
  };

  let timeout;
  const handleScheduleChange = (event) => {
    clearTimeout(timeout);
    const newValue = event?.target?.value;
    setinputScheduled(newValue);
    localStorage.setItem("scheduled_tasks_row_per_page", newValue);
    setTimeout(() => {
      setSubShowTableSpinner(true);
      const data = getScheduledTasks(
        orgId,
        teamId,
        timeScope,
        userInfoDetails?.data?.accessToken,
        goToPage,
        sortAsc,
        sortBy,
        ScheduledTaskNameFilter,
        seletcedScheduledTaskType,
        selectedScheduledTaskImpact,
        selectedScheduledTaskControl,
        selectedScheduledTaskRiskStatus,
        ScheduledTaskEffortFilter,
        ScheduledTaskOwnerFilter,
        ScheduledTasksGoalNameFilter,
        selectedScheduleTaskStatus,
        scheduledTaskDueInFilter,
        event?.target?.value,
        stateData?.task?.taskLateCountStatus
      );
      data?.then(async (res) => {
        localStorage.setItem("clearAllScheduledFilter", false);
        setPageCount(
          Math.ceil(
            res?.data?.count /
              (localStorage.getItem("scheduled_tasks_row_per_page") ||
                event?.target?.value)
          )
        );
        setPagination(res?.data?.next || res?.data?.previous ? true : false);
        setScheduledTasks(res?.data?.data);
        setSubShowTableSpinner(false);
        if (
          (ScheduledTaskNameFilter ||
            seletcedScheduledTaskType ||
            scheduledTaskDueInFilter ||
            selectedScheduledTaskImpact ||
            selectedScheduledTaskControl ||
            selectedScheduledTaskRiskStatus ||
            ScheduledTaskEffortFilter ||
            ScheduledTaskOwnerFilter ||
            ScheduledTasksGoalNameFilter ||
            statusFilter ||
            seletcedScheduledTaskStatus) &&
          res?.data?.length === 0
        ) {
          setFilterScheduleTaskIcon(false);
        }
      });
      data?.catch(async (err) => {
        setSubShowTableSpinner(false);
      });
    }, 1500);
  };

  let timeout2;
  const handleHistoryChange = (event) => {
    clearTimeout(timeout2);
    const newValue = event.target.value;
    setinputHistory(newValue);
    localStorage.setItem("history_tasks_row_per_page", newValue);
    setTimeout(() => {
      const data = userSpecificTaskHistory(
        orgId,
        userInfoDetails?.data?.accessToken,
        goToHistoryPage,
        sortAsc,
        sortBy,
        HistoryTaskNameFilter,
        seletcedHistoryTaskType,
        selectedHistoryTaskImpact,
        selectedHistoryTaskControl,
        selectedHistoryTaskRiskStatus,
        HistoryTaskEffortFilter,
        HistoryTaskOwnerFilter,
        HistoryTasksGoalNameFilter,
        selectedHistoryTaskStatus,
        selectedHistoryTaskScheduleStatus,
        event.target.value
      );
      setHistoryTableSpinner(true);
      data?.then((res) => {
        setHistoryPageCount(
          Math.ceil(
            res?.data?.count /
              (localStorage.getItem("history_tasks_row_per_page") ||
                event?.target?.value)
          )
        );
        setPaginationHistory(
          res?.data?.next || res?.data?.previous ? true : false
        );
        setHistoryTasksDetails(res?.data?.data);
        if (
          (HistoryTaskNameFilter ||
            seletcedHistoryTaskType ||
            selectedHistoryTaskImpact ||
            selectedHistoryTaskControl ||
            selectedHistoryTaskRiskStatus ||
            HistoryTaskEffortFilter ||
            HistoryTaskOwnerFilter ||
            HistoryTasksGoalNameFilter ||
            selectedHistoryTaskScheduleStatus ||
            selectedHistoryTaskStatus) &&
          res?.data?.length === 0
        ) {
          setFilterHistoryTaskIcon(false);
        }
        setHistoryTableSpinner(false);
      });
      data?.catch((error) => {
        setHistoryTableSpinner(false);
        return error.response.data;
      });
    }, 1500);
  };

  const tasktype = [
    {
      name: "Game changers",
      value: "Game changers",
    },
    {
      name: "Support",
      value: "Support",
    },
    {
      name: "Quick wins",
      value: "Quick wins",
    },
    // {
    //   name: "Thankless",
    //   value: "Thankless",
    // },
    // {
    //   name: "Black hole",
    //   value: "Black hole",
    // },
    // {
    //   name: "Low hanging fruit",
    //   value: "Low hanging fruit",
    // },
    // {
    //   name: "Game changer",
    //   value: "Game changer",
    // },
    // {
    //   name: "Core",
    //   value: "Core",
    // },
    // {
    //   name: "Hard-core",
    //   value: "Hard-core",
    // },
    // {
    //   name: "Support",
    //   value: "Support",
    // },
    // {
    //   name: "Time sink",
    //   value: "Time sink",
    // },
  ];

  const TaskImpact = [
    {
      name: "Low",
      value: "Low",
    },
    {
      name: "Medium",
      value: "Medium",
    },
    {
      name: "High",
      value: "High",
    },
  ];

  const TaskControl = [
    {
      name: "Low",
      value: "Low",
    },
    {
      name: "Medium",
      value: "Medium",
    },
    {
      name: "High",
      value: "High",
    },
  ];

  const Taskstatus = [
    {
      name: "Epic",
      value: "Beat",
    },
    {
      name: "Ok",
      value: "Hit",
    },
    {
      name: "Miss",
      value: "Miss",
    },
  ];

  const TaskRiskStatus = [
    {
      name: "Low",
      value: "Low",
    },
    {
      name: "Medium",
      value: "Medium",
    },
    {
      name: "High",
      value: "High",
    },
  ];

  const ScheduleTaskStatus = [
    {
      name: "Scheduled",
      value: "Scheduled",
    },
    {
      name: "Late",
      value: "Late",
    },
  ];

  const Taskschedulestatus = [
    {
      name: "On time",
      value: "On time",
    },
    {
      name: "Ok",
      value: "Ok",
    },
    {
      name: "Late",
      value: "Late",
    },
  ];

  const TaskStatus = [
    {
      name: "To be scheduled",
      value: "To be scheduled",
    },
    {
      name: "Scheduled",
      value: "Scheduled",
    },
    {
      name: "Awaiting acceptance",
      value: "Awaiting acceptance",
    },
    {
      name: "Completed",
      value: "Completed",
    },
  ];

  const handleMarkAscomplte = (event, task) => {
    event.preventDefault();
  };

  const getAdjustedTaskEffort = (effort) => {
    if (typeof effort !== "number") return effort;
    const integerPart = Math.floor(effort);
    const decimalPart = effort - integerPart;
    const adjustedMinutes = Math.round(decimalPart * 60);
    const formattedMinutes = adjustedMinutes.toString().padStart(2, "0");
    return `${integerPart}:${formattedMinutes}`;
  };

  const reverseAdjustedTaskEffort = (timeString) => {
    setScheduledTaskEffortFilter(timeString);
    const [hours, minutes] = timeString.split(":").map(Number);
    if (isNaN(hours) || isNaN(minutes)) return timeString;

    const decimalMinutes = (minutes / 60).toFixed(2).slice(1);
    setScheduledTaskEffortFilter(parseFloat(hours + decimalMinutes));
    return parseFloat(hours + decimalMinutes);
  };

  useEffect(() => {
    if (isTaskCreated || isTaskUpdated || tasksdelete || isTaskCompleted) {
      if (isTaskCreated) {
        setTaskscreate(() => true);
        // setGoToUpcomingPage(goToUpcomingPage => pageUpcoming % 5 === 0 ? pageUpcomingCount: pageUpcomingCount+1)
        // setTimeout(() => {
        //   setIsTaskCreated(() => false)
        //   setTaskscreate(() => false)
        // }, 3000)
      }
      if (isTaskUpdated) {
        setTasksupdate(() => true);
        // setTimeout(() => {
        //   setIsTaskUpdated(() => false)
        //   setTasksupdate(() => false)
        // }, 3000)
      }
      // if (isTaskCompleted) {
      // setTimeout(() => {
      //   setIsTaskCompleted(() => false)
      // }, 3000)
      // }
      // if (tasksdelete) {
      // setGoToUpcomingPage(goToUpcomingPage => pageUpcoming % 5 === 0 ? pageUpcomingCount-2 : goToUpcomingPage-1)
      // setTimeout(() => {
      //   setTasksdelete(() => false)
      // }, 3000)
      // }
      setTimeout(() => {
        getUserSpecificTask(setGoToUpcomingPage(() => 0));
        getAllScheduledTasks(setGoToPage(() => 0));
        getHistoryTask(setGoToHistoryPage(() => 0));
      }, 200);
      setTimeout(() => {
        setTasksdelete(false);
        setTasksupdate(false);
        setTaskscreate(false);
        setTaskSelectorError(false);
      }, 3000);
    }
    setIsTaskCreated(false);
    dispatch(refreshTaskList(false));
  }, [isTaskCreated || isTaskUpdated || tasksdelete || isTaskCompleted]);

  useEffect(() => {
    if (location?.state?.task) {
      setTaskDetailsinfo(location?.state?.task);
    }
  }, [location?.state?.task]);

  useEffect(() => {
    if (stateData.task.refreshTaskList) {
      setIsTaskCreated(true);
    }
  }, [stateData.task.refreshTaskList]);

  useEffect(() => {
    setScheduledTaskInputFieldOpen(ScheduledTaskInputFieldOpen);
    setScheduledTaskEffortFieldOpen(ScheduledTaskEffortFieldOpen);
    setScheduledTasksGoalInputFieldOpen(ScheduledTasksGoalInputFieldOpen);
  }, [
    ScheduledTaskInputFieldOpen,
    ScheduledTaskEffortFieldOpen,
    ScheduledTasksGoalInputFieldOpen,
  ]);

  useEffect(() => {
    setUpcomingTaskInputFieldOpen(UpcomingTaskInputFieldOpen);
    setUpcomingTaskEffortFieldOpen(UpcomingTaskEffortFieldOpen);
    setUpcomingTasksGoalInputFieldOpen(UpcomingTasksGoalInputFieldOpen);
  }, [
    UpcomingTaskInputFieldOpen,
    UpcomingTaskEffortFieldOpen,
    UpcomingTasksGoalInputFieldOpen,
  ]);

  useEffect(() => {
    setHistoryTaskInputFieldOpen(HistoryTaskInputFieldOpen);
    setHistoryTaskEffortFieldOpen(HistoryTaskEffortFieldOpen);
    setHistoryTasksGoalInputFieldOpen(HistoryTasksGoalInputFieldOpen);
  }, [
    HistoryTaskInputFieldOpen,
    HistoryTaskEffortFieldOpen,
    HistoryTasksGoalInputFieldOpen,
  ]);

  useEffect(() => {
    setselectedScheduleTaskStatus();
    setScheduledTaskOwnerFilter();
    getAllScheduledTasks();
    setClearFilter(false);
  }, [localStorage.getItem("TaskScheduledLateCount")]);

  useEffect(() => {
    if (statusKey) {
      if (statusKey === "Scheduled") {
        getAllScheduledTasks();
      }
      if (
        statusKey === "To be scheduled" ||
        statusKey === "Awaiting acceptance"
      ) {
        getUserSpecificTask();
      }
      if (statusKey === "Completed") {
        getHistoryTask();
      }
    }
  }, [taskKey, statusKey]);

  useEffect(() => {
    if (ScheduleTaskSelected || taskOwner) {
      const updatedTask = {
        task_name: ScheduleTaskSelected?.name,
        description: ScheduleTaskSelected?.description,
        task_effort: ScheduleTaskSelected?.task_effort,
      };
      setTaskDetails(ScheduleTaskSelected);
      setTaskGoalEdit({
        value: ScheduleTaskSelected?.goal?.id,
        label: ScheduleTaskSelected?.goal?.name,
      });
      setCreateTaskForm(updatedTask);
      setTaskOwnerValue({
        label:
          ScheduleTaskSelected?.task_owner?.first_name +
          " " +
          ScheduleTaskSelected?.task_owner?.last_name,
        value:
          ScheduleTaskSelected?.task_owner?.first_name +
          " " +
          ScheduleTaskSelected?.task_owner?.last_name,
        userId: ScheduleTaskSelected?.task_owner?.id,
      });
    }
  }, [ScheduleTaskSelected, taskOwner]);

  useEffect(() => {
    setTaskOwnerValue(taskOwner);
  }, [taskGoalEdit, taskOwner]);

  useEffect(() => {
    if (
      location?.state?.goalId !== undefined &&
      location?.state?.goalId !== null &&
      taskList?.length === 0 &&
      historyTaskList?.length === 0 &&
      schedulesTasksList?.length === 0
    ) {
      setGoalDetails(location?.state?.goalId);
      setShows(true);
    }
  }, [location?.state?.goalId]);

  useEffect(() => {
    getAllScheduledTasks(sortAsc ? "asc" : "desc", sortBy);
  }, [goToPage, stateData?.fileUpload?.FileUploadId]);

  useEffect(() => {
    // if (ScheduledTaskNameFilter ||
    //   seletcedScheduledTaskType ||
    //   selectedScheduledTaskImpact ||
    //   ScheduledTaskEffortFilter ||
    //   ScheduledTaskOwnerFilter ||
    //   ScheduledTasksGoalNameFilter) {
    setGoToPage(0);
    getAllScheduledTasks(
      sortOrder,
      sortBy,
      ScheduledTaskNameFilter,
      seletcedScheduledTaskType,
      selectedScheduledTaskImpact,
      selectedScheduledTaskControl,
      selectedScheduledTaskRiskStatus,
      ScheduledTaskEffortFilter,
      ScheduledTaskOwnerFilter,
      ScheduledTasksGoalNameFilter,
      selectedScheduleTaskStatus,
      statusFilter,
      seletcedScheduledTaskStatus,
      scheduledTaskDueInFilter
    );
    // }
  }, [
    ScheduledTaskNameFilter,
    seletcedScheduledTaskType,
    selectedScheduledTaskImpact,
    selectedScheduledTaskControl,
    selectedScheduledTaskRiskStatus,
    ScheduledTaskEffortFilter,
    ScheduledTaskOwnerFilter,
    ScheduledTasksGoalNameFilter,
    selectedScheduleTaskStatus,
    statusFilter,
    seletcedScheduledTaskStatus,
    scheduledTaskDueInFilter,
  ]);

  const clearAllScheduleTaskFilter = (e) => {
    e.preventDefault();
    dispatch(taskShowingFilter(false));
    if (localStorage.getItem("TaskScheduledLateCount") == "true") {
      setClearFilter(true);
    }
    setScheduledTaskEffortFilter("");
    setScheduledTasksGoalNameFilter("");
    setScheduledTaskNameFilter("");
    setScheduledTaskDueInFilter("");
    setScheduledTaskOwnerFilter();
    setselectedScheduledTaskImpact();
    setselectedScheduledTaskControl();
    setselectedScheduledTaskRiskStatus();
    setselectedScheduledTaskType();
    setselectedScheduleTaskStatus();
    setStatusFilter("");
    setseletcedScheduledTaskStatus("");
    setGoToPage(0);
  };

  useEffect(() => {
    getUserSpecificTask();
  }, [goToUpcomingPage, stateData?.fileUpload?.FileUploadId]);

  useEffect(() => {
    setGoToUpcomingPage(0);
    getUserSpecificTask(
      sortOrder,
      sortBy,
      UpcomingTaskNameFilter,
      seletcedUpcomingTaskType,
      selectedUpcomingTaskImpact,
      selectedUpcomingTaskControl,
      selectedUpcomingTaskRiskStatus,
      selectedTaskStatusItem,
      UpcomingTaskEffortFilter,
      UpcomingTaskOwnerFilter,
      UpcomingTasksGoalNameFilter
    );
    // }
  }, [
    UpcomingTaskNameFilter,
    seletcedUpcomingTaskType,
    selectedUpcomingTaskImpact,
    selectedUpcomingTaskControl,
    selectedUpcomingTaskRiskStatus,
    selectedTaskStatusItem,
    UpcomingTaskEffortFilter,
    UpcomingTaskOwnerFilter,
    UpcomingTasksGoalNameFilter,
  ]);

  const clearAllUpcomingTaskFilter = (e) => {
    e.preventDefault();
    setUpcomingTaskEffortFilter("");
    setUpcomingTaskNameFilter("");
    setUpcomingTaskOwnerFilter();
    setselectedUpcomingTaskType();
    setselectedUpcomingTaskImpact();
    setselectedUpcomingTaskControl();
    setselectedUpcomingTaskRiskStatus();
    setSelectedTaskStatusItem();
    setUpcomingTasksGoalNameFilter();
    setGoToUpcomingPage(0);
  };

  useEffect(() => {
    getHistoryTask();
  }, [goToHistoryPage, stateData?.fileUpload?.FileUploadId]);

  useEffect(() => {
    setGoToHistoryPage(0);
    getHistoryTask(
      sortOrder,
      sortBy,
      HistoryTaskNameFilter,
      seletcedHistoryTaskType,
      selectedHistoryTaskImpact,
      selectedHistoryTaskControl,
      selectedHistoryTaskRiskStatus,
      HistoryTaskEffortFilter,
      HistoryTaskOwnerFilter,
      HistoryTasksGoalNameFilter,
      selectedHistoryTaskStatus,
      selectedHistoryTaskScheduleStatus
    );
  }, [
    HistoryTaskNameFilter,
    seletcedHistoryTaskType,
    selectedHistoryTaskImpact,
    selectedHistoryTaskControl,
    selectedHistoryTaskRiskStatus,
    HistoryTaskEffortFilter,
    HistoryTaskOwnerFilter,
    HistoryTasksGoalNameFilter,
    selectedHistoryTaskStatus,
    selectedHistoryTaskScheduleStatus,
  ]);

  const clearAllHistoryTaskFilter = (e) => {
    e.preventDefault();
    setHistoryTaskEffortFilter("");
    setHistoryTaskNameFilter("");
    setHistoryTaskOwnerFilter();
    setselectedHistoryTaskType();
    setselectedHistoryTaskImpact();
    setselectedHistoryTaskControl();
    setselectedHistoryTaskRiskStatus();
    setHistoryTasksGoalNameFilter();
    setselectedHistoryTaskStatus();
    setselectedHistoryTaskScheduleStatus();
    goToHistoryPage(0);
  };

  useEffect(() => {
    setHistoryTasksDetails(historyTaskList);
  }, [historyTaskList]);

  useEffect(() => {
    if (goalDetails === undefined) {
      let optionList = [];
      const data = {
        label: userInfoDetails.data.email,
        value: userInfoDetails.data.email,
        userId: userInfoDetails.data.id,
      };
      optionList.push(data);
      setOptions(optionList);
      setTaskOwnerValue(optionList);
    }
    getTasksDetails("");
    setTaskImpactValue({ value: "medium", label: "Medium" });
    getAllGoals();
    getAllScheduledTasks();
    getUserSpecificTask();
    getHistoryTask();
  }, []);

  useEffect(() => {
    if (location?.state?.task) {
      getAlOrgMem();
      getTeamMem();
      getHistoryTask();
      getTasksByGoalId();
      getUserSpecificTask();
    }
  }, [location?.state?.task]);

  useEffect(() => {
    if (
      localStorage.getItem("previousLocation") === "/stripe" &&
      sessionStorage.getItem("strategyImported") === "false"
    ) {
      toast.info(
        <div className="m-1">
          <h3>Payment Pending for Strategy </h3>
        </div>,
        { toastId: "strategyPaymentFail" }
      );
      sessionStorage.removeItem("strategyImported");
    }
    setTaskDetailsinfo("");
    getAlOrgMem();
  }, []);

  function getAllScheduledTasks(sortAsc, sortby) {
    setSubShowTableSpinner(true);
    if (stateData?.task?.taskLateCountStatus == true && !clearFilter) {
      // setselectedScheduleTaskStatus("Late");
      // setScheduledTaskOwnerFilter(userInfoDetails?.data?.id);
      setLateTaskCount(true);
    } else if (stateData?.task?.taskLateCountStatus == true && clearFilter) {
      setselectedScheduleTaskStatus();
      // setScheduledTaskOwnerFilter();
      setLateTaskCount(true);
    } else if (stateData?.task?.taskLateCountStatus == false) {
      setLateTaskCount(false);
    }

    const data = getScheduledTasks(
      orgId,
      teamId,
      timeScope,
      userInfoDetails?.data?.accessToken,
      goToPage,
      sortAsc,
      sortby,
      ScheduledTaskNameFilter,
      seletcedScheduledTaskType,
      selectedScheduledTaskImpact,
      selectedScheduledTaskControl,
      selectedScheduledTaskRiskStatus,
      ScheduledTaskEffortFilter,
      ScheduledTaskOwnerFilter,
      ScheduledTasksGoalNameFilter,
      selectedScheduleTaskStatus,
      scheduledTaskDueInFilter,
      inputScheduled,
      stateData?.task?.taskLateCountStatus,
      statusFilter,
      seletcedScheduledTaskStatus
    );

    data?.then(async (res) => {
      localStorage.setItem("clearAllScheduledFilter", false);
      setPageCount(Math.ceil(res?.data?.count / inputScheduled));
      setPagination(res?.data?.next || res?.data?.previous ? true : false);
      setScheduledTasks(res?.data?.data);
      setTaskOwnerValue("");
      setSubShowTableSpinner(false);
      if (
        (ScheduledTaskNameFilter ||
          seletcedScheduledTaskType ||
          scheduledTaskDueInFilter ||
          selectedScheduledTaskImpact ||
          selectedScheduledTaskControl ||
          selectedScheduledTaskRiskStatus ||
          ScheduledTaskEffortFilter ||
          ScheduledTaskOwnerFilter ||
          ScheduledTasksGoalNameFilter ||
          statusFilter ||
          seletcedScheduledTaskStatus) &&
        res?.data?.length === 0
      ) {
        setFilterScheduleTaskIcon(false);
      }
    });
    data?.catch(async (err) => {});
  }

  const handleClick1 = (sort_by, sortAsc) => {
    setSortBy(sort_by);
    setsortOrder(sortAsc ? "asc" : "desc");
    getAllScheduledTasks(sortAsc ? "asc" : "desc", sort_by);
    setSortAsc(sortAsc);
  };

  const selectedTask = (event, Task) => {
    event.preventDefault();
    setSelectedTaskDelete(Task);
  };

  const handleDeleteTaskClick = () => {
    setTasksupdate(false);
    setTasksdelete(false);
    const data = deleteTask(
      SelectedTaskDelete?.id,
      userInfoDetails.data.accessToken
    );
    data?.then(async (res) => {
      setTasksdelete(true);
      getAlOrgMem();
      getTeamMem();
      getMessagingList();

      if (taskList?.length == 1) {
        setGoToUpcomingPage(goToUpcomingPage - 1);
      } else {
        setGoToUpcomingPage(goToUpcomingPage);
      }

      navigate(location?.pathname, { state: { refreshCounter: true } });
    });
    data?.catch(async (err) => {});
  };

  const handleActivateTaskClick = () => {
    const newStatus = "Active";
    const data = editTaskForActiveInactive(
      orgId,
      selectedTaskForActive?.id,
      selectedTeam,
      selectedTaskForActive?.goal?.id,
      taskOwner,
      taskCreater,
      taskImpact.value,
      (selectedTaskForActive?.task_control).toLowerCase(),
      createTask,
      userInfoDetails.data.accessToken,
      taskDetailsinfo?.task_status,
      "",
      newStatus
    );
    data?.then(async (res) => {
      getAllScheduledTasks();
      setTaskOwnerValue("");
      setSelecteTaskForActive();
    });
    data?.catch(async (res) => {});
  };

  const getMessagingList = () => {
    const start = 0;
    const end = 10;
    dispatch(
      getThreadList(
        localStorage.getItem("organization_id"),
        start,
        end,
        userInfoDetails.data.accessToken
      )
    );
    localStorage.removeItem("ActiveChat");
  };
  const taskSchedule = (event) => {
    if (localStorage.getItem("org_access") === "false") {
      setOpenWarningModal(true);
    } else {
      // const data = taskSelector(orgId, userInfoDetails.data.accessToken);
      // data?.then(async (res) => {
      //   if (res?.data?.data?.warning === false) {
      const data2 = taskScheduleAlgo(orgId, userInfoDetails.data.accessToken);
      data2?.then(async (res2) => {
        getUserSpecificTask();
        getAllScheduledTasks();
        setGoToUpcomingPage(0);
        getAlOrgMem();
        getTeamMem();
        toast(
          <>
            <div className="m-1 d-flex">
              <i
                class="bi bi-check-circle fs-26 me-3"
                style={{ color: "#131F4C" }}
              ></i>
              <div>
                <h2
                  style={{
                    // color: "#131F4C",
                    fontSize: "22px",
                  }}
                >
                  Completed
                </h2>
                <h3
                  style={{
                    // color: "#131F4C",
                    fontSize: "18px",
                  }}
                >
                  Your task has been scheduled successfully!
                </h3>
              </div>
            </div>
          </>,
          {
            toastId: "task-schedule",
            className: "toast-success-container toast-success-container-after ",
          }
        );
      });
      data2?.catch(async (err) => {
        toast(
          <>
            <div className="m-1 d-flex">
              <i
                class="bi bi-check-circle fs-26 me-3"
                style={{ color: "#131F4C" }}
              ></i>
              <div>
                <h2
                  style={{
                    fontSize: "22px",
                  }}
                >
                  Failed
                </h2>
                <h3
                  style={{
                    fontSize: "18px",
                  }}
                >
                  {err?.response?.data?.message}
                </h3>
              </div>
            </div>
          </>,
          {
            toastId: "task-schedule",
            className: "toast-error-container toast-error-container-after",
          }
        );
      });
    }
  };

  useEffect(() => {
    setTaskDetailsinfo(taskDetailsinfo);
  }, [taskDetailsinfo]);

  const changeTaskOwner = (userRole) => {
    setTaskOwnerValue(userRole);
  };

  const getUserSpecificTask = (sortAsc, sortby) => {
    setTaskShowTableSpinner(true);
    const data = userSpecificTask(
      orgId,
      userInfoDetails?.data?.accessToken,
      goToUpcomingPage,
      sortAsc,
      sortby,
      UpcomingTaskNameFilter,
      seletcedUpcomingTaskType,
      selectedUpcomingTaskImpact,
      selectedUpcomingTaskControl,
      selectedUpcomingTaskRiskStatus,
      selectedTaskStatusItem,
      UpcomingTaskEffortFilter,
      UpcomingTaskOwnerFilter,
      UpcomingTasksGoalNameFilter,
      inputValue
    );

    data?.then(async (res) => {
      setTaskShowTableSpinner(false);
      setUpcomingPage(res?.data?.count);
      setUpcomingPageCount(Math.ceil(res?.data?.count / inputValue));
      setPaginationUpcoming(
        res?.data?.next || res?.data?.previous ? true : false
      );
      getTasksDetails(res?.data?.data);
      if (
        (UpcomingTaskNameFilter ||
          seletcedUpcomingTaskType ||
          selectedUpcomingTaskImpact ||
          selectedUpcomingTaskControl ||
          selectedUpcomingTaskRiskStatus ||
          selectedTaskStatusItem ||
          UpcomingTaskEffortFilter ||
          UpcomingTaskOwnerFilter ||
          UpcomingTasksGoalNameFilter) &&
        res?.data?.length === 0
      ) {
        setFilterUpcomingIcon(false);
      }
      // if (
      //   statusKey == "To be scheduled" ||
      //   statusKey == "Awaiting acceptance"
      // ) {
      //   let check = false;
      //   for (let k = 0; k < res?.data?.data?.length; k++) {
      //     if (res?.data?.data[k]?.id === taskKey) {
      //       check = true;
      //       setstatusKey("");
      //       break;
      //     }
      //   }
      //   if (check == false) {
      //     setGoToUpcomingPage(goToUpcomingPage + 1);
      //   }
      // }
    });
    data?.catch(async (err) => {});
  };

  const handleClick = (sort_by, sortAsc) => {
    setSortBy(sort_by);
    setsortOrder(sortAsc ? "asc" : "desc");
    getUserSpecificTask(sortAsc ? "asc" : "desc", sort_by);
    setSortAsc(sortAsc);
  };

  const handleMarkAsTobeScheduled = (event, task) => {
    event.preventDefault();

    const data2 = toBeScheduledTask(task?.id, userInfoDetails.data.accessToken);
    data2?.then(async (res) => {
      getAllScheduledTasks();
      getUserSpecificTask();
      getHistoryTask();
      toast(
        <>
          <div className="m-1 d-flex">
            <i
              class="bi bi-check-circle fs-26 me-3"
              style={{ color: "#131F4C" }}
            ></i>
            <div>
              <h2
                style={{
                  // color: "#131F4C",
                  fontSize: "22px",
                }}
              >
                Completed
              </h2>
              <h3
                style={{
                  // color: "#131F4C",
                  fontSize: "18px",
                }}
              >
                Your task has been mark as incomplete successfully!
              </h3>
            </div>
          </div>
        </>,
        {
          toastId: "task-schedule",
          className: "toast-success-container toast-success-container-after ",
        }
      );
    });
    data2?.catch(async (err) => {
      toast(
        <>
          <div className="m-1 d-flex">
            <i
              class="bi bi-check-circle fs-26 me-3"
              style={{ color: "#131F4C" }}
            ></i>
            <div>
              <h2
                style={{
                  fontSize: "22px",
                }}
              >
                Failed
              </h2>
              <h3
                style={{
                  fontSize: "18px",
                }}
              >
                {err?.response?.data?.message}
              </h3>
            </div>
          </div>
        </>,
        {
          toastId: "task-schedule",
          className: "toast-error-container toast-error-container-after",
        }
      );
    });
  };

  const handleMarkAsIncomplte = (event, task) => {
    event.preventDefault();

    const data2 = markTaskAsIncomplete(
      task?.id,
      userInfoDetails.data.accessToken
    );
    data2?.then(async (res) => {
      getAllScheduledTasks();
      getUserSpecificTask();
      getHistoryTask();
      toast(
        <>
          <div className="m-1 d-flex">
            <i
              class="bi bi-check-circle fs-26 me-3"
              style={{ color: "#131F4C" }}
            ></i>
            <div>
              <h2
                style={{
                  // color: "#131F4C",
                  fontSize: "22px",
                }}
              >
                Completed
              </h2>
              <h3
                style={{
                  // color: "#131F4C",
                  fontSize: "18px",
                }}
              >
                Your task has been mark as incomplete successfully!
              </h3>
            </div>
          </div>
        </>,
        {
          toastId: "task-schedule",
          className: "toast-success-container toast-success-container-after ",
        }
      );
    });
    data2?.catch(async (err) => {
      toast(
        <>
          <div className="m-1 d-flex">
            <i
              class="bi bi-check-circle fs-26 me-3"
              style={{ color: "#131F4C" }}
            ></i>
            <div>
              <h2
                style={{
                  fontSize: "22px",
                }}
              >
                Failed
              </h2>
              <h3
                style={{
                  fontSize: "18px",
                }}
              >
                {err?.response?.data?.message}
              </h3>
            </div>
          </div>
        </>,
        {
          toastId: "task-schedule",
          className: "toast-error-container toast-error-container-after",
        }
      );
    });
  };

  function getTasksByGoalId() {
    if (goalDetails)
      dispatch(
        getTasksByGoalIdAction(
          goalDetails?.id,
          userInfoDetails.data.accessToken
        )
      );
  }

  function getAlOrgMem() {
    dispatch(getOrgMembers(userInfoDetails?.data?.accessToken));
  }

  function getTeamMem() {
    if (teamId)
      dispatch(getTeamMemByTeamId(teamId, userInfoDetails.data.accessToken));
  }

  const errorObj = { ...errorsObj };

  function getHistoryTask(sortAsc, sortby) {
    setHistoryTableSpinner(true);
    const data = userSpecificTaskHistory(
      orgId,
      userInfoDetails?.data?.accessToken,
      goToHistoryPage,
      sortAsc,
      sortby,
      HistoryTaskNameFilter,
      seletcedHistoryTaskType,
      selectedHistoryTaskImpact,
      selectedHistoryTaskControl,
      selectedHistoryTaskRiskStatus,
      HistoryTaskEffortFilter,
      HistoryTaskOwnerFilter,
      HistoryTasksGoalNameFilter,
      selectedHistoryTaskStatus,
      selectedHistoryTaskScheduleStatus,
      inputHistory
    );
    data?.then((res) => {
      setHistoryPageCount(Math.ceil(res?.data?.count / inputHistory));
      setPaginationHistory(
        res?.data?.next || res?.data?.previous ? true : false
      );
      setHistoryTasksDetails(res?.data?.data);
      if (
        (HistoryTaskNameFilter ||
          seletcedHistoryTaskType ||
          selectedHistoryTaskImpact ||
          selectedHistoryTaskControl ||
          selectedHistoryTaskRiskStatus ||
          HistoryTaskEffortFilter ||
          HistoryTaskOwnerFilter ||
          HistoryTasksGoalNameFilter ||
          selectedHistoryTaskScheduleStatus ||
          selectedHistoryTaskStatus) &&
        res?.data?.length === 0
      ) {
        setFilterHistoryTaskIcon(false);
      }
      setHistoryTableSpinner(false);
    });
    data?.catch((error) => {
      return error.response.data;
    });
  }

  const handleClick2 = (sort_by, sortAsc) => {
    setSortBy(sort_by);
    setsortOrder(sortAsc ? "asc" : "desc");
    getHistoryTask(sortAsc ? "asc" : "desc", sort_by);
    setSortAsc(sortAsc);
  };

  function getAllGoals() {
    const data = getAllGoalsByScope(
      orgId,
      // scope,
      userInfoDetails?.data?.accessToken,
      "all"
    );
    data?.then(async (res) => {
      let optionData = res.data.data;
      let optionList = [];
      optionData.forEach((option) => {
        const data = {
          label: option.name,
          value: option.name,
          id: option.id,
        };
        optionList.push(data);
      });
      setGoals(optionList);
    });
    data?.catch(async (err) => {});
  }

  const changedDateFormat = (date) => {
    if (date) {
      var today1 = new Date(date);
      return (
        String(today1.getDate()).padStart(2, "0") +
        "/" +
        (today1.getMonth() + 1)
      );
    } else {
      return "-";
    }
  };

  useEffect(() => {
    if (stateData.task?.errorMessage === "Tasks not found") {
      getTasksDetails("");
    }
    if (stateData.task?.successMessage === "No tasks assigned to user") {
      getTasksDetails(stateData.task?.successMessage.data);
    }
    if (stateData?.goal?.successMessage === "Goals found successfully") {
      let optionData = stateData.goal.goals;
      let optionList = [];
      optionData.forEach((option) => {
        const data = {
          label: option.name,
          value: option.name,
          id: option.id,
        };
        optionList.push(data);
      });
    }
    if (stateData?.team?.orgMembers?.message === "Organization Members") {
      let optionList = [];
      let optionData = stateData?.team?.orgMembers?.data;
      optionData.forEach((option) => {
        const data = {
          label: option.first_name
            ? option.first_name + " " + option.last_name
            : option?.email,
          value: option.first_name
            ? option.first_name + " " + option.last_name
            : option?.email,
          userId: option.id,
        };
        optionList.push(data);
      });
      setOptions(optionList);
    }
  }, [stateData]);

  useEffect(() => {
    setTimeout(() => {
      setTaskSelectorError(false);
      setWarningMessage();
    }, 3000);
  }, [taskSelectorError]);

  const toastInfo = () => {
    toast.info(
      <div className="m-1">
        <h3>
          You have successfully created a task! Plansome helps schedule your
          task to make you as successful as possible. To schedule your task now
          click
          <span
            style={{ cursor: "pointer", color: "red" }}
            className="badge badge-light"
            onClick={() => {
              taskSchedule();
            }}
          >
            <u>here</u>
          </span>
        </h3>
      </div>,
      { toastId: "success1" }
    );
  };

  const formik = useFormik({
    initialValues: {
      task_name: "",
      task_effort: "",
      task_owner: "",
      task_impact: { value: "medium", label: "Medium" },
      task_control: { value: "medium", label: "Medium" },
      task_success: { value: "", label: "" },
      goal_align: "",
    },
    validationSchema: Yup.object({
      task_name: Yup.string().required("*Task name is required"),
      task_effort: Yup.string().required("*Task effort is required"),
    }),
    onSubmit: (values, { setSubmitting, setFieldValue, resetForm }) => {
      const [hours, minutes] = values?.task_effort.split(":").map(Number);
      const decimalMinutes = (minutes / 60).toFixed(2).slice(1);
      const fieldValue = parseFloat(hours + decimalMinutes);

      const createTask = {
        task_name: values?.task_name,
        // description: "",
        task_effort: fieldValue,
      };
      if (goalAlignId != undefined) {
        const data = getGoalById(
          goalAlignId,
          userInfoDetails?.data?.accessToken
        );
        data?.then(async (res) => {
          const data = createTaskService(
            orgId,
            userInfoDetails.data.id,
            values?.task_owner?.userId,
            createTask,
            values?.task_impact?.value,
            values?.task_control?.value,
            res?.data?.data?.status,
            { id: goalAlignId },
            "",
            userInfoDetails.data.accessToken
          );
          // setSpinner(true);
          data?.then(async (res) => {
            getAllScheduledTasks();
            setRowAddTask(false);
            toast(
              <>
                <div className="m-1 d-flex">
                  <i
                    class="bi bi-check-circle fs-26 me-3"
                    style={{ color: "#131F4C" }}
                  ></i>
                  <div>
                    <h2
                      style={{
                        // color: "#131F4C",
                        fontSize: "22px",
                      }}
                    >
                      Completed
                    </h2>
                    <h3
                      style={{
                        // color: "#131F4C",
                        fontSize: "18px",
                      }}
                    >
                      {res?.data?.message}
                    </h3>
                  </div>
                </div>
              </>,
              {
                toastId: "task-schedule",
                className:
                  "toast-success-container toast-success-container-after ",
              }
            );
            if (res?.data?.message === "Tasks created successfully") {
              resetForm();
            }
          });
          data?.catch(async (err) => {
            toast(
              <>
                <div className="m-1 d-flex">
                  <i
                    class="bi bi-check-circle fs-26 me-3"
                    style={{ color: "#131F4C" }}
                  ></i>
                  <div>
                    <h2
                      style={{
                        fontSize: "22px",
                      }}
                    >
                      Failed
                    </h2>
                    <h3
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      {err?.response?.data?.message}
                    </h3>
                  </div>
                </div>
              </>,
              {
                toastId: "task-create",
                className: "toast-error-container toast-error-container-after ",
              }
            );
          });
          // }
          setSubmitting(false);
        });
      } else {
        const data = createTaskService(
          orgId,
          userInfoDetails.data.id,
          values?.task_owner?.userId,
          createTask,
          values?.task_impact?.value,
          values?.task_control?.value,
          "Inactive",
          { id: goalAlignId },
          "",
          userInfoDetails.data.accessToken
        );
        // setSpinner(true);
        data?.then(async (res) => {
          getAllScheduledTasks();
          setRowAddTask(false);
          toast(
            <>
              <div className="m-1 d-flex">
                <i
                  class="bi bi-check-circle fs-26 me-3"
                  style={{ color: "#131F4C" }}
                ></i>
                <div>
                  <h2
                    style={{
                      // color: "#131F4C",
                      fontSize: "22px",
                    }}
                  >
                    Completed
                  </h2>
                  <h3
                    style={{
                      // color: "#131F4C",
                      fontSize: "18px",
                    }}
                  >
                    {res?.data?.message}
                  </h3>
                </div>
              </div>
            </>,
            {
              toastId: "task-schedule",
              className:
                "toast-success-container toast-success-container-after ",
            }
          );
          if (res?.data?.message === "Tasks created successfully") {
            resetForm();
          }
        });
        data?.catch(async (err) => {
          toast(
            <>
              <div className="m-1 d-flex">
                <i
                  class="bi bi-check-circle fs-26 me-3"
                  style={{ color: "#131F4C" }}
                ></i>
                <div>
                  <h2
                    style={{
                      fontSize: "22px",
                    }}
                  >
                    Failed
                  </h2>
                  <h3
                    style={{
                      fontSize: "18px",
                    }}
                  >
                    {err?.response?.data?.message}
                  </h3>
                </div>
              </div>
            </>,
            {
              toastId: "task-create",
              className: "toast-error-container toast-error-container-after ",
            }
          );
        });
        // }
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (stateData?.team?.orgMembers?.data) {
      const optionsList = stateData?.team?.orgMembers?.data.map((option) => ({
        label: option.first_name
          ? `${option.first_name} ${option.last_name}`
          : option.email,
        value: option.first_name
          ? `${option.first_name} ${option.last_name}`
          : option.email,
        userId: option.id,
      }));
      setOptions(optionsList);
    }
  }, [stateData]);

  const getGaol = () => {
    let optionGoalLists = [];
    let orgdata;
    orgdata = getAllGoalsByScope(
      orgId,
      // scope,
      userInfoDetails?.data?.accessToken,
      true,
      false
    );
    orgdata?.then(async (res) => {
      let optionGoalData = res?.data?.data;
      if (optionGoalData.length !== 0) {
        optionGoalData?.forEach((option) => {
          const data = {
            label: option.name,
            value: option.name,
            userId: option.id,
          };
          optionGoalLists.push(data);
        });
      }
    });
    orgdata?.catch(async (err) => {});
    setGoalOption(optionGoalLists);
  };

  useEffect(() => {
    getGaol();
  }, []);

  const convertUrlsToLinks = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text
      ? text
          .match(urlRegex)
          ?.map(
            (url) =>
              `<a style="color:#81A7E4;" href="${url}" target="_blank">${url}</a> <br/>`
          )
          .join(" ") || ""
      : "";
  };

  useEffect(() => {
    const timer = setTimeout(() => setFileErrorMessage(""), 4000);
    return () => clearTimeout(timer);
  }, [fileErrorMessage]);

  const changeGoalOption = (userRole) => {
    setDefaultOptions(userRole);
    setGoalAlign(userRole?.userId);
  };

  const moveGoal = (dragIndex, hoverIndex) => {
    const dragItem = goals[dragIndex];
  };

  const TableRows = ({ task, index }) => {
    const [{ isDragging }, drag, preview] = useDrag({
      type: "ROW",
      item: { index, task },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [, drop] = useDrop({
      accept: "ROW",
      drop: (item) => {
        if (item.index !== index) {
          moveGoal(item.index, index);
          item.index = index;
          const data = editTask(
            orgId,
            item?.task?.id,
            item?.task?.team?.id,
            item?.task?.goal?.id,
            item?.task?.task_owner,
            item?.task?.task_creator,
            item?.task?.task_impact?.toLowerCase(),
            item?.task?.task_control?.toLowerCase(),
            item?.task,
            userInfoDetails.data.accessToken,
            item?.task?.task_status,
            "",
            item?.task?.status,
            task?.task_order
          );
          data?.then((res) => {
            getAllScheduledTasks();
          });
          data?.catch((err) => {});
        }
      },
    });

    useEffect(() => {
      if (stateData?.task?.isResetTaskPage) {
        setGoToPage(0);
        dispatch(resetTaskPage(false));
      }
    }, [stateData?.goal?.isResetTaskPage]);

    return (
      <tr
        ref={(node) => drag(drop(node))}
        role="row"
        className="odd goals"
        // onClick={() => close()}
        style={{
          ...(taskKey === task?.id ? { backgroundColor: "#faeabb" } : {}),
          // textAlign: "center",
          // verticalAlign: "center"
        }}
      >
        <td
          onClick={(event) => {
            event.preventDefault();
            setTaskDetailsinfo(task);
          }}
          style={{
            border: 2,
            borderColor: "white",
            borderStyle: "solid",
            textAlign: "start",
          }}
        >
          {task.name}
        </td>
        <td
          onClick={(event) => {
            event.preventDefault();
            setTaskDetailsinfo(task);
          }}
          style={{
            border: 2,
            borderColor: "white",
            borderStyle: "solid",
            textAlign: "start",
          }}
        >
          <OverlayTrigger
            placement="bottom" // Tooltip position
            overlay={(props) => renderTooltip(props, task?.goal)} // Pass `objective` to `renderTooltip`
          >
            <div style={{ position: "relative", width: "100%" }}>
              <ProgressBar
                now={40} // Adjust the progress value as per your need
                label={null} // Label is handled manually
                style={
                  task?.goal?.goal_status === "On track"
                    ? progressBarStyle
                    : task?.goal?.goal_status === "At risk"
                    ? progressWarningBarStyle
                    : progressFailingBarStyle
                }
              >
                <div
                  style={{
                    ...(task?.goal?.goal_status === "On track"
                      ? progressBarFilledStyle
                      : task?.goal?.goal_status === "At risk"
                      ? progressBarWarningFilledStyle
                      : progressBarFailingFilledStyle),
                    width: `${task?.goal?.goal_completed_percent}%`, // The percentage width of the filled part
                    height: "100%",
                  }}
                />
              </ProgressBar>
              <span
                style={
                  task?.goal?.goal_status === "On track"
                    ? labelStyle
                    : task?.goal?.goal_status === "At risk"
                    ? labelWarningStyle
                    : labelFailingStyle
                }
              >
                {task?.goal?.name || "-"}
              </span>
            </div>
          </OverlayTrigger>
        </td>

        <td
          onClick={(event) => {
            event.preventDefault();
            setTaskDetailsinfo(task);
          }}
          style={{
            border: 2,
            borderColor: "white",
            borderStyle: "solid",
          }}
          className="sorting_1"
        >
          {task.task_owner.first_name[0]?.toUpperCase()}
          {task.task_owner.last_name[0]?.toUpperCase()}
        </td>

        <td
          // onClick={(event) => {
          //   event.preventDefault();
          //   setTaskDetailsinfo(task);
          // }}
          style={{
            border: 2,
            borderColor: "white",
            borderStyle: "solid",
          }}
        >
          {/* <div className="d-flex justify-content-center todo-button ">
          <button
            to=""
            title="To be scheduled"
            disabled={task?.task_schedule === null}
            className="outline-primary btn btn-primary me-2"
            onClick={(event) => {
              handleMarkAsTobeScheduled(event, task)
            }}
          >
            <i className="fas fa-clock"></i>
          </button>
        </div> */}

          <div style={{ display: "flex" }}>
            <div>{/* {goal?.status}  */}</div>
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={task?.status === "Active"}
                    onChange={handleToggle(task)}
                    name="statusToggle"
                    color="primary"
                  />
                }
              />
            </div>
          </div>
        </td>

        <td
          onClick={(event) => {
            event.preventDefault();
            setTaskDetailsinfo(task);
          }}
          style={{
            // backgroundColor:
            //   task.task_impact === "High" ? "#ADD8E6" :
            //     task.task_impact === "Medium" ? "#FFFF0080" :
            //       "#FFB6C1",

            border: 2,
            borderColor: "white",
            borderStyle: "solid",
          }}
        >
          {task.task_impact}
        </td>
        {/* <td
    onClick={(event) => {
      event.preventDefault();
      setTaskDetailsinfo(task);
    }}
    style={{
      border: 2,
      borderColor: "white",
      borderStyle: "solid",
    }}
  >
    {task.task_control}
  </td>

  <td
    onClick={(event) => {
      event.preventDefault();
      setTaskDetailsinfo(task);
    }}
    style={{
      border: 2,
      borderColor: "white",
      borderStyle: "solid",
    }}
  >
    {task.risk_status}
  </td> */}

        <td
          onClick={(event) => {
            event.preventDefault();
            setTaskDetailsinfo(task);
          }}
          style={{
            border: 2,
            borderColor: "white",
            borderStyle: "solid",
          }}
        >
          {getAdjustedTaskEffort(task.task_effort)}
        </td>
        <td
          onClick={(event) => {
            event.preventDefault();
            setTaskDetailsinfo(task);
          }}
          style={{
            // backgroundColor:
            //   task.task_type === "Game Changer" ? "#4B008280" :   // Deep Purple for Game Changer
            //     task.task_type === "Quick wins" ? "#FF69B4" :           // Hot Pink for Quick
            //       "#D3D3D3",
            border: 2,
            borderColor: "white",
            borderStyle: "solid",
          }}
        >
          {task.task_type}
        </td>
        {/* <td
        onClick={(event) => {
          event.preventDefault();
          setTaskDetailsinfo(task);
        }}
        style={{
          border: 2,
          borderColor: "white",
          borderStyle: "solid",
          
        }}
      >
        
        {task?.due_in === null ? "-" : task?.due_in}
      </td> */}
        <td
          onClick={(event) => {
            event.preventDefault();
            setTaskDetailsinfo(task);
          }}
          style={{
            border: "2px solid white",
            color: task?.due_in?.toString().includes("-") ? "red" : "#131f4c", // Red if `due_in` contains "-"
          }}
        >
          {/* {changedDateFormat(task.deadline_time)} */}
          {task?.due_in == null ? "-" : task?.due_in}
        </td>

        {/* <td
        onClick={(event) => {
          event.preventDefault();
          setTaskDetailsinfo(task);
        }}
        style={{
          border: 2,
          borderColor: "white",
          borderStyle: "solid",
        }}
      >
        {changedDateFormat(task.task_schedule)}
      </td> */}
        {/* <td style={{
      border: 2,
      borderColor: "white",
      borderStyle: "solid",
    }}>
      {task?.task_owner?.id ===
        userInfoDetails?.data?.id ||
        task?.task_creator?.id ===
        userInfoDetails?.data?.id ||
        userInfoDetails?.data?.user_role === "owner" ?
        (<div className="d-flex justify-content-center todo-button ">
          <button
            to=""
            title="Mark as complete"
            className="outline-primary btn btn-primary me-2"
            disabled={task.task_schedule === null}
            style={markAsStatus[task?.id] ? { backgroundColor: '#D4EBE2', color: '#26996d', border: "1px solid #26996d" } : {}}
            onClick={(event) => {
              handleMarkAsComplete(event, task, index)
            }}
          >
            <i className="fas fa-check" id="right-symbol-icon"></i>
          </button>
        </div>) : (<>
          <div className="d-flex justify-content-center todo-button " title="User does not have permission to perform this action">
            <button
              to=""
              className=" outline-primary btn btn-primary"
              disabled
              onClick={(event) => {
                setTaskDetails(task);
                setScheduledTasksSelected(task);
                setTaskDetails(task);
              }}
            >
              <i class=" fas fa-pen"></i>
            </button>
          </div>
        </>)}
    </td> */}

        <td
          style={{
            border: 2,
            borderColor: "white",
            borderStyle: "solid",
            // cursor: task?.task_status !== "Scheduled" ? 'not-allowed' : "pointer"
          }}
        >
          {/* <div className="">
          <div className="d-flex justify-content-center align-items-center m-0 p-0" >
            <a data-tooltip-id="my-tooltip"
              // data-tooltip-content={userInfoDetails?.data?.id !== taskDetailsinfo?.task_owner?.id || userInfoDetails?.data?.id !== taskDetailsinfo?.task_creator?.id ? "You don't have permission to edit this field!" : ""} style={userInfoDetails?.data?.id !== taskDetailsinfo?.task_owner?.id || userInfoDetails?.data?.id !== taskDetailsinfo?.task_creator?.id ? { cursor: "not-allowed" } : {}}
              data-tooltip-content={userInfoDetails?.data?.id !== task?.task_owner?.id && userInfoDetails?.data?.id !== task?.task_creator?.id && userInfoDetails?.data?.user_role !== "owner" ? "You don't have permission to edit this field!" : ""} style={userInfoDetails?.data?.id !== task?.task_owner?.id || userInfoDetails?.data?.id !== task?.task_creator?.id ? { cursor: "not-allowed" } : {}}
            >
              {task?.task_status !== "Scheduled" ? (
                <>
                  <Stack
                    gap={2}
                    direction={"row"}
                    borderRadius="50px"
                    padding={"1px"}
                    style={{
                      opacity: taskStatuses[index] ? 0.6 : 1,
                      cursor: "not-allowed",
                      textAlign: "center",
                      verticalAlign: "middle"
                    }}
                  >
                    {task?.task_success === "Beat" ? (
                      <FaCircleCheck size={22} color='#737373' style={{ cursor: "not-allowed" }} />
                    ) : (
                      <VscCircleLargeFilled size={22} color='#737373' style={{ cursor: "not-allowed" }} />
                    )}

                    {task?.task_success === "Hit" ? (
                      <FaCircleCheck size={22} color='#FF980090' style={{ cursor: "not-allowed" }} />
                    ) : (
                      <VscCircleLargeFilled size={24} color='#FF980090' style={{ cursor: "not-allowed" }} />
                    )}

                    {task?.task_success === "Miss" ? (
                      <FaCircleCheck size={22} color='#8BC34A' style={{ cursor: "not-allowed" }} />
                    ) : (
                      <VscCircleLargeFilled size={22} color='#8BC34A' style={{ cursor: "not-allowed" }} />
                    )}
                  </Stack>
                </>
              ) : (
                <>
                  <Stack gap={2} direction={"row"} borderRadius="50px" padding={"1px"}>
                    {taskStatuses[index] === "beat" ? (
                      <FaCircleCheck size={22} color='#737373' />
                    ) : (
                      <VscCircleLargeFilled
                        size={22} color='#737373'
                        onClick={(event) => handleCircleClick("beat", index, event, task)}
                      />
                    )}

                    {taskStatuses[index] === "hit" ? (
                      <FaCircleCheck size={22} color='#FF980090' />
                    ) : (
                      <VscCircleLargeFilled
                        size={22} color='#FF980090'
                        onClick={(event) => handleCircleClick("hit", index, event, task)}
                      />
                    )}

                    {taskStatuses[index] === "miss" ? (
                      <FaCircleCheck size={22} color='#8BC34A' />
                    ) : (
                      <VscCircleLargeFilled
                        size={24} color='#8BC34A'
                        onClick={(event) => handleCircleClick("miss", index, event, task)}
                      />
                    )}
                  </Stack>
                </>
              )}
            </a>
            <ReactTooltip effect="solid" id="my-tooltip" />
          </div>
          <div>
            {validationMessages[task.id] && <span className="text-danger">{validationMessages[task.id]}</span>}
          </div>
        </div> */}
          <div className="d-flex justify-content-center align-items-center m-0 p-0">
            <div className="d-flex mb-1 align-items-center">
              <Select
                className="p"
                name="task_status"
                placeholder={"Select"}
                value={
                  task?.task_success === "Beat"
                    ? { value: "beat", label: "Epic" }
                    : task?.task_success === "Hit"
                    ? { value: "hit", label: "Ok" }
                    : task?.task_success === "Miss"
                    ? { value: "miss", label: "Miss" }
                    : task?.task_success === "Not Complete"
                    ? { value: "not_complete", label: "Not Complete" }
                    : { value: "not_complete", label: "Not Complete" }
                }
                options={taskStatusOptions}
                onChange={(event) => handleCircleClick(index, event, task)}
                styles={{
                  control: (base) => ({
                    ...base,
                    width: "100px",
                  }),
                }}
              />
              <ReactTooltip effect="solid" id="my-tooltip" />
            </div>
          </div>
        </td>
        {/* <td
        onClick={(event) => {
          event.preventDefault();
          setTaskDetailsinfo(task);
        }}
        style={{
          border: 2,
          borderColor: "white",
          borderStyle: "solid",
        }}
        className="text-center"
      >
        <span
          className=" figbtn-success figstatus p-3 text-center"
          style={
            task.task_current_status === "Late"
              ? {
                border: "1px solid #fb7676",
                backgroundColor: "#f8b1b1",
                color: "#d80909",
                textAlign: "center !important",
                display: "flow-root",
              }
              : {
                border: "1px solid #a8d6c5",
              }
          }
        >
          {task?.task_current_status}ddd
        </span>
      </td> */}
        {/* <td className="">
    <div className="d-flex todo-button justify-content-center">
      <button
        to=""
        title="Mark as incomplete"
        className="outline-primary btn btn-primary me-2"
        onClick={(event) => {
          handleMarkAscomplte(event, task)
        }}
      >
        <i className="fas fa-check-circle"></i>
      </button>
    </div>
  </td> */}
        <td>
          {/* <div className="d-flex todo-button">
          {task?.task_owner?.id ===
            userInfoDetails?.data?.id ||
            task?.task_creator?.id ===
            userInfoDetails?.data?.id ||
            userInfoDetails?.data?.user_role === "owner" ? (
            <>
              <Link
                to=""
                className="outline-primary btn btn-primary"
                onClick={(event) => {
                  event.preventDefault();
                  setTaskDetails(task);
                  setScheduledTasksSelected(task);
                  setTaskDetailsinfo(task);
                }}
              >
                <i class=" fas fa-pen"></i>
              </Link>
              <button
                className="outline-primary btn btn-primary mx-2"
                onClick={(event) => {
                  selectedTask(event, task);
                  SetOpenDeleteModal(true);
                }}
              >
                <i className="fa fa-trash"></i>
              </button>
              <Link
                to=""
                className="outline-primary btn btn-primary"
                onClick={(event) => {
                  setShareModal(true)
                  setSelectedShareTask(task)
                }}
              >
                <i className="fa fa-share"></i>
              </Link>
            </>
          ) : (
            <>
              <div title="User does not have permission to perform this action">
                <button
                  to=""
                  className="outline-primary btn btn-primary"
                  disabled
                  onClick={(event) => {
                    setTaskDetails(task);
                    setScheduledTasksSelected(task);
                    setTaskDetails(task);
                  }}
                >
                  <i class=" fas fa-pen"></i>
                </button>
              </div>
              <div title="User does not have permission to perform this action">
                <button
                  disabled
                  className="outline-primary btn btn-primary mx-2"
                  onClick={(event) => { }}
                >
                  <i className="fa fa-trash"></i>
                </button>
              </div>
              <div title="User does not have permission to perform this action">
                <button
                  disabled
                  className="outline-primary btn btn-primary "
                  onClick={(event) => { }}
                >
                  <i className="fa fa-share"></i>
                </button>
              </div>
            </>
          )}
        </div> */}

          {/* {task?.task_owner?.id ===
            userInfoDetails?.data?.id ||
            task?.task_creator?.id ===
            userInfoDetails?.data?.id ||
            userInfoDetails?.data?.user_role === "owner" ? (<> */}

          <>
            <Dropdown className="ms-auto">
              <Dropdown.Toggle variant="" as="div" className="btn-link i-false">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="12.4999" cy="3.5" r="2.5" fill="#223A69" />
                  <circle cx="12.4999" cy="11.5" r="2.5" fill="#223A69" />
                  <circle cx="12.4999" cy="19.5" r="2.5" fill="#223A69" />
                </svg>
              </Dropdown.Toggle>

              {ReactDOM.createPortal(
                <Dropdown.Menu
                  alignRight={true}
                  data-testid="org-menu"
                  className="dropdown-menu-right"
                  style={{ position: "absolute", zIndex: 1050 }}
                >
                  <Dropdown.Item
                    data-testid="org-edit"
                    onClick={(event) => {
                      event.preventDefault();
                      setTaskDetails(task);
                      setScheduledTasksSelected(task);
                      setTaskDetailsinfo(task);
                    }}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    data-testid="org-delete"
                    onClick={(event) => {
                      setShareModal(true);
                      setSelectedShareTask(task);
                    }}
                  >
                    Share
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="text-danger"
                    data-testid="org-delete"
                    onClick={(event) => {
                      selectedTask(event, task);
                      SetOpenDeleteModal(true);
                    }}
                  >
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>,
                document.body // This ensures the Dropdown.Menu is rendered outside the regular DOM hierarchy
              )}
            </Dropdown>
          </>
          {/* </>) : (<>
              -
            </>)} */}
        </td>
      </tr>
    );
  };

  const progressBarFilledStyle = {
    backgroundColor: "#d4ebe2", // Custom fill color for the progress
  };

  const progressBarWarningFilledStyle = {
    backgroundColor: "#f5d9b0", // Custom fill color for the progress
  };

  const progressBarFailingFilledStyle = {
    backgroundColor: "#eeaeba", // Custom fill color for the progress
  };

  const progressBarStyle = {
    backgroundColor: "white",
    position: "relative",
    height: "40px",
    width: "100%",
    maxWidth: "100%",
    border: "1px solid #a8d6c5",
  };

  const progressWarningBarStyle = {
    backgroundColor: "white",
    position: "relative",
    height: "40px",
    width: "100%",
    maxWidth: "100%",
    border: "1px solid #f5d9b0",
  };

  const progressFailingBarStyle = {
    backgroundColor: "white",
    position: "relative",
    height: "40px",
    width: "100%",
    maxWidth: "100%",
    border: "1px solid #eeaeba",
  };

  const labelStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    textAlign: "center",
    color: "#26996d",
    // fontWeight: 'bold',
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis", // Displays '...' if the text is too long
    zIndex: 1,
    padding: "10px 20px", // Padding for some space around the text
  };

  const labelWarningStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    textAlign: "center",
    color: "#e5a139",
    // fontWeight: 'bold',
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis", // Displays '...' if the text is too long
    zIndex: 1,
    padding: "10px 20px", // Padding for some space around the text
  };

  const labelFailingStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    textAlign: "center",
    color: "#d43551",
    // fontWeight: 'bold',
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis", // Displays '...' if the text is too long
    zIndex: 1,
    padding: "10px 20px", // Padding for some space around the text
  };

  const renderTooltip = (props, objective) => {
    const getTooltipText = () => {
      if (!objective) {
        return "";
      }
      const { goal_completed_percent, goal_status } = objective;
      if (goal_completed_percent >= 80 && goal_status === "Failed") {
        return `${goal_completed_percent}%  complete. Status: ${goal_status} despite good progress due to poor task outcomes and delays. Improve outcome quality to recover.`;
      } else if (
        goal_completed_percent >= 50 &&
        goal_completed_percent < 80 &&
        goal_status === "Failed"
      ) {
        return `${goal_completed_percent}% complete. Status: ${goal_status} due to low task outcomes and significant delays.`;
      } else if (
        goal_completed_percent > 30 &&
        goal_completed_percent < 50 &&
        goal_status === "Failed"
      ) {
        return `${goal_completed_percent}% complete. Status: ${goal_status} due to poor outcomes and challenges.`;
      } else if (goal_completed_percent <= 30 && goal_status === "Failed") {
        return `${goal_completed_percent}% complete. Status: ${goal_status} due to low task outcomes and significant delays.`;
      } else if (goal_completed_percent >= 75 && goal_status === "Near Risk") {
        return `${goal_completed_percent}% complete. Status: ${goal_status} despite good progress due to inconsistent task outcomes and borderline delays.`;
      } else if (
        goal_completed_percent >= 60 &&
        goal_completed_percent < 75 &&
        goal_status === "Near Risk"
      ) {
        return `${goal_completed_percent}% complete. Status: ${goal_status} due to mixed task outcomes and moderate delays.`;
      } else if (
        goal_completed_percent >= 35 &&
        goal_completed_percent < 60 &&
        goal_status === "Near Risk"
      ) {
        return `${goal_completed_percent}% complete. Status: ${goal_status} with limited progress and inconsistent outcomes.`;
      } else if (goal_completed_percent < 35 && goal_status === "Near Risk") {
        return `${goal_completed_percent}% complete. Status: ${goal_status} with significant challenges and poor outcomes.`;
      } else if (goal_completed_percent >= 85 && goal_status === "On track") {
        return `${goal_completed_percent}% complete. Status: ${goal_status} with strong progress and positive task outcomes.`;
      } else if (
        goal_completed_percent >= 60 &&
        goal_completed_percent < 85 &&
        goal_status === "On track"
      ) {
        return `${goal_completed_percent}% complete. Status: ${goal_status} with steady progress and positive task outcomes.`;
      } else if (
        goal_completed_percent > 40 &&
        goal_completed_percent < 60 &&
        goal_status === "On track"
      ) {
        return `${goal_completed_percent}% complete. Status: ${goal_status} with moderate progress.`;
      } else if (goal_completed_percent <= 40 && goal_status === "On track") {
        return `${goal_completed_percent}% complete. Status: ${goal_status} despite limited progress.`;
      } else if (goal_completed_percent >= 25 && goal_status == "Failing") {
        return `${goal_completed_percent}% complete. Status: ${goal_status} due to suboptimal task outcomes and some delays.`;
      }
      return `${goal_completed_percent}% Complete. Status: ${goal_status}. Check details here.`;
    };

    return (
      <Tooltip id="progress-tooltip" {...props} className="fs-18">
        {getTooltipText()}
        {/* <IoArrowForwardCircleSharp /> */}
      </Tooltip>
    );
  };

  return (
    <>
      {shareModal && (
        <ShareTaskModal
          shareModel={shareModal}
          task={selectedShareTask}
          setShareModelParent={setShareModal}
        ></ShareTaskModal>
      )}

      {taskDetailsinfo && (
        <TaskEdit
          task={taskDetailsinfo}
          refresh={{
            getAllScheduledTasks,
            getUserSpecificTask,
            getHistoryTask,
          }}
          setTask={setTaskDetailsinfo}
          setIsTaskUpdated={setIsTaskUpdated}
          setIsTaskCompleted={setIsTaskCompleted}
        />
      )}

      {Shows && (
        <TaskModal
          show={Shows}
          goal={null}
          owner=""
          handle={setShows}
          setIsTaskCreated={setIsTaskCreated}
        />
      )}

      {taskList?.length >= 0 &&
        historyTaskList?.length >= 0 &&
        schedulesTasksList?.length >= 0 &&
        taskscreated &&
        toast(
          <>
            <div className="m-1 d-flex">
              <i
                class="bi bi-check-circle fs-26 me-3"
                style={{ color: "#131F4C" }}
              ></i>
              <div>
                <h2
                  style={{
                    // color: "#131F4C",
                    fontSize: "22px",
                  }}
                >
                  Completed
                </h2>
                <h3
                  style={{
                    // color: "#131F4C",
                    fontSize: "18px",
                  }}
                >
                  Your task has been created successfully!
                </h3>
              </div>
            </div>
          </>,
          {
            toastId: "task-create",
            className: "toast-success-container toast-success-container-after ",
          }
        )}
      {tasksdelete &&
        toast(
          <>
            <div className="m-1 d-flex">
              <i
                class="bi bi-check-circle fs-26 me-3"
                style={{ color: "#131F4C" }}
              ></i>
              <div>
                <h2
                  style={{
                    // color: "#131F4C",
                    fontSize: "22px",
                  }}
                >
                  Deleted
                </h2>
                <h3
                  style={{
                    // color: "#131F4C",
                    fontSize: "18px",
                  }}
                >
                  Your task has been deleted successfully!
                </h3>
              </div>
            </div>
          </>,
          {
            toastId: "task-schedule",
            className: "toast-success-container toast-success-container-after ",
          }
        )}
      {tasksupdate &&
        toast(
          <>
            <div className="m-1 d-flex">
              <i
                class="bi bi-check-circle fs-26 me-3"
                style={{ color: "#131F4C" }}
              ></i>
              <div>
                <h2
                  style={{
                    // color: "#131F4C",
                    fontSize: "22px",
                  }}
                >
                  Completed
                </h2>
                <h3
                  style={{
                    // color: "#131F4C",
                    fontSize: "18px",
                  }}
                >
                  Your task has been updated successfully!
                </h3>
              </div>
            </div>
          </>,
          {
            toastId: "task-update",
            className: "toast-success-container toast-success-container-after ",
          }
        )}

      {isTaskCompleted &&
        toast(
          <>
            <div className="m-1 d-flex">
              <i
                class="bi bi-check-circle fs-26 me-3"
                style={{ color: "#131F4C" }}
              ></i>
              <div>
                <h2
                  style={{
                    // color: "#131F4C",
                    fontSize: "22px",
                  }}
                >
                  Completed
                </h2>
                <h3
                  style={{
                    // color: "#131F4C",
                    fontSize: "18px",
                  }}
                >
                  Your task has been mark as completed successfully!
                </h3>
              </div>
            </div>
          </>,
          {
            toastId: "task-completed",
            className: "toast-success-container toast-success-container-after ",
          }
        )}

      {historyTaskList?.length === 0 &&
        schedulesTasksList?.length === 0 &&
        taskList?.length === 1 &&
        taskscreated &&
        toastInfo()}

      <Modal
        show={OpenWarningModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        top
        style={{ borderRadius: "0rem" }}
      >
        <Modal.Title className="my-3 mx-4" id="contained-modal-title-vcenter">
          <img
            className="float-start me-1 mt-1 p-2"
            alt="warning-img"
            title="warning-img"
            src={Vector3}
          />
          <h4 className="fs-20 p-2">Warning!</h4>
        </Modal.Title>
        <Modal.Body className="modal-body mx-4 p-0">
          {WarningMessage !== null ? (
            <p className="p-2 pt-0" style={{ color: "#717994" }}>
              Please create organization before{" "}
              {ScheduleWarning ? "scheduling" : "creating"} a task
              <Link className="figma-link mt-2 me-3" to="/organization-list">
                &nbsp;here...
              </Link>
            </p>
          ) : (
            <span className="p">{WarningMessage}</span>
          )}
        </Modal.Body>
        <div className="d-flex  justify-content-end mx-4 mb-3">
          <button
            className="figma-btn text-white"
            onClick={() => {
              setOpenWarningModal(false);
              setWarningMessage("");
              setScheduleWarning(false);
            }}
          >
            Ok
          </button>
        </div>
      </Modal>

      <Modal
        show={OpenDeleteModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ borderRadius: "0rem" }}
      >
        <Modal.Title className="my-3 mx-4" id="contained-modal-title-vcenter">
          <img
            className="float-start me-1 mt-1 p-2"
            alt="delete-modal"
            title="delete-modal"
            src={Vector3}
          />
          <h4 className="fs-20 p-2">Are you sure you want to delete Task?</h4>
        </Modal.Title>
        <Modal.Body className="modal-body mx-4 p-0">
          <p className="p-2 pt-0" style={{ color: "#717994" }}>
            You will not be able to recover this Task!
          </p>
        </Modal.Body>
        <div className="d-flex  justify-content-end mx-4 mb-3">
          <Link
            className="figma-link mt-2 me-3"
            to="/tasks"
            onClick={() => SetOpenDeleteModal(false)}
          >
            No, cancel it!
          </Link>
          <button
            className="figma-btn text-white"
            onClick={() => {
              handleDeleteTaskClick();
              SetOpenDeleteModal(false);
            }}
          >
            Yes, I am Sure!
          </button>
        </div>
      </Modal>

      <Modal
        show={openWarningModalForActive}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ borderRadius: "0rem" }}
      >
        <Modal.Title className="my-3 mx-4" id="contained-modal-title-vcenter">
          <img
            className="float-start me-1 mt-1 p-2"
            alt="delete-modal"
            title="delete-modal"
            src={Vector3}
          />
          <h4 className="fs-20 p-2">
            Are you sure you want to active this task?
          </h4>
        </Modal.Title>
        <Modal.Body className="modal-body mx-4 p-0">
          <p className="p-2 pt-0" style={{ color: "#717994" }}>
            If you set the task's active state, the goal status will also update
            to active.
          </p>
        </Modal.Body>
        <div className="d-flex  justify-content-end mx-4 mb-3">
          <Link
            className="figma-link mt-2 me-3"
            to="/tasks"
            onClick={() => setOpenWarningModalForActive(false)}
          >
            No, cancel it!
          </Link>
          <button
            className="figma-btn text-white"
            onClick={() => {
              handleActivateTaskClick();
              setOpenWarningModalForActive(false);
            }}
          >
            Yes, I am Sure!
          </button>
        </div>
      </Modal>

      <div className="row mb-2 mt-2">
        <div className="col" data-testid="navigate-header">
          {localStorage.getItem("previousLocation") !==
            localStorage.getItem("currentLocation") &&
            !showHistory && (
              <span className="float-start mt-2">
                <i
                  data-testid="navigate-back"
                  className="fa fa-reply fs-20"
                  title={`Navigate back to ${
                    localStorage.getItem("previousLocation").split("/")[1]
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(localStorage.getItem("previousLocation"));
                  }}
                />
              </span>
            )}
          {showHistory && (
            <span className="float-start mt-2">
              <i
                data-testid="navigate-back"
                className="fa fa-reply fs-20"
                title={"Hide History"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowHistory(!showHistory);
                }}
              />
            </span>
          )}
          {!showHistory && (
            <Link
              to=""
              onClick={() => {
                getUserSpecificTask();
                getAllScheduledTasks();
                getHistoryTask();
                setTaskDetails();
                setTaskDetailsinfo();
                navigate("/task");
              }}
              style={{ float: "left" }}
              className="p-1 h4"
              title="Refresh task list"
            >
              <i
                className="rounded-circle1 fa fa-repeat"
                aria-hidden="true"
              ></i>
              <span className="figma-link">Refresh</span>
            </Link>
          )}

          {!showHistory && (
            <Link
              to=""
              onClick={() => {
                setShowHistory(!showHistory);
              }}
              style={{
                float: "right",
                display: "inline-block",
                marginLeft: "1.5rem",
              }}
              className="p-1 h3"
            >
              <span className="figma-link">Archived</span>
            </Link>
          )}
          {!showHistory && (
            <Link
              to=""
              onClick={(event) => {
                if (localStorage.getItem("org_access") === "false") {
                  setOpenWarningModal(true);
                  setScheduleWarning(true);
                } else {
                  taskSchedule(event);
                  setScheduleWarning(true);
                }
              }}
              style={{
                float: "right",
                color: "black",
              }}
              className="p-1 h3"
            >
              <span className="figma-link">Update</span>
            </Link>
          )}
        </div>
      </div>
      {!showHistory ? (
        <>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="">
                    Here we have 7 days, upcoming and historical tasks
                  </h4>
                  {(ScheduledTaskNameFilter ||
                    seletcedScheduledTaskType ||
                    scheduledTaskDueInFilter ||
                    selectedScheduledTaskImpact ||
                    selectedScheduledTaskControl ||
                    selectedScheduledTaskRiskStatus ||
                    ScheduledTaskEffortFilter ||
                    ScheduledTaskOwnerFilter ||
                    ScheduledTasksGoalNameFilter ||
                    selectedScheduleTaskStatus ||
                    statusFilter ||
                    seletcedScheduledTaskStatus) && (
                    <span
                      className=" mx-3 py-0 float-end"
                      title="Clear all filter"
                      onClick={(e) => {
                        clearAllScheduleTaskFilter(e);
                      }}
                      style={{
                        cursor: "pointer",
                        color: "#DC5D74",
                      }}
                    >
                      Clear all filter
                      <i className="fas fa-filter fs-18"></i>
                      <span>x</span>
                    </span>
                  )}
                </div>
                <div className="card-body">
                  <DndProvider backend={HTML5Backend}>
                    <div className="table-responsive">
                      <div id="job_data" className="dataTables_wrapper">
                        {showSubTableSpinner ? (
                          <div
                            style={{
                              position: "relative",
                              top:
                                schedulesTasksList.length < 3
                                  ? "6rem"
                                  : schedulesTasksList.length >= 3
                                  ? "9rem"
                                  : "1rem",
                            }}
                            className="m-0 p-0"
                          >
                            <LoadingSpinner />
                          </div>
                        ) : (
                          ""
                        )}
                        <form onSubmit={formik.handleSubmit}>
                          <table
                            className="display w-100 dataTable "
                            id="example5"
                            role="grid"
                            aria-describedby="example5_info"
                            style={
                              schedulesTasksList.length < 2
                                ? { minHeight: "110px" }
                                : { minHeight: "200px" }
                            }
                          >
                            <thead>
                              <tr role="row">
                                <th
                                  className="sorting"
                                  style={{ width: "30%" }}
                                >
                                  <div className="d-flex align-items-center m-0 p-0">
                                    <span
                                      className="float-start"
                                      style={{
                                        paddingRight: "10px",
                                        fontWeight: "bold",
                                        color: "#131F4C",
                                        textAlign: "start",
                                      }}
                                    >
                                      <i
                                        class="fas fa-sort"
                                        onClick={() => {
                                          handleClick1("task", !sortAsc);
                                        }}
                                      ></i>
                                      Task
                                    </span>
                                    {filterScheduleTaskIcon ? (
                                      <Dropdown
                                        // className="setDropDownposition float-end"
                                        title="Filter"
                                        // style={{ right: "58%" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setScheduledTaskInputFieldOpen(
                                            !ScheduledTaskInputFieldOpen
                                          );
                                        }}
                                      >
                                        <Dropdown.Toggle
                                          style={{
                                            background: "none",
                                            border: "none",
                                          }}
                                          className="m-0 p-0"
                                        >
                                          <i
                                            className="fas fa-filter fs-12"
                                            style={{
                                              cursor: "pointer",
                                              color: ScheduledTaskNameFilter
                                                ? "#DC5D74"
                                                : "#898fa5",
                                            }}
                                          ></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          {
                                            <>
                                              <input
                                                type="text"
                                                name="name"
                                                className="figma-input p m-0 p-2"
                                                placeholder="Enter task name"
                                                autocomplete="off"
                                                value={ScheduledTaskNameFilter}
                                                onChange={(e) =>
                                                  setScheduledTaskNameFilter(
                                                    e.target.value
                                                  )
                                                }
                                                style={{
                                                  border: "1px solid black",
                                                  width: "150px",
                                                }}
                                              />
                                            </>
                                          }
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </th>
                                <th
                                  className="sorting "
                                  style={{ width: "20%" }}
                                >
                                  <div className="d-flex align-items-center m-0 p-0">
                                    <span
                                      className="float-start"
                                      style={{
                                        paddingRight: "10px",
                                        fontWeight: "bold",
                                        color: "#131F4C",
                                      }}
                                    >
                                      <i
                                        class="fas fa-sort"
                                        onClick={() => {
                                          handleClick1("goal", !sortAsc);
                                        }}
                                      ></i>
                                      Goal
                                    </span>
                                    {filterScheduleTaskIcon ? (
                                      <Dropdown
                                        // className="setDropDownposition float-end"
                                        title="Filter"
                                        // style={{ right: "68%" }}
                                        onClick={() => {
                                          setScheduledTasksGoalInputFieldOpen(
                                            !ScheduledTasksGoalInputFieldOpen
                                          );
                                        }}
                                      >
                                        <Dropdown.Toggle
                                          style={{
                                            background: "none",
                                            border: "none",
                                          }}
                                          className="m-0 p-0"
                                        >
                                          <i
                                            className="fas fa-filter fs-12"
                                            style={{
                                              cursor: "pointer",
                                              color:
                                                ScheduledTasksGoalNameFilter
                                                  ? "#DC5D74"
                                                  : "#898fa5",
                                            }}
                                          ></i>
                                        </Dropdown.Toggle>
                                        <div>
                                          <Dropdown.Menu
                                            menuposition="top-right"
                                            className=""
                                            style={{
                                              width: "auto",
                                              height: "200px",
                                              overflowY: "scroll",
                                              scrollbarWidth: "0px",
                                            }}
                                          >
                                            {goals?.map((goal, i) => (
                                              <Dropdown.Item
                                                key={i}
                                                style={
                                                  ScheduledTasksGoalNameFilter ===
                                                  goal?.id
                                                    ? {
                                                        backgroundColor:
                                                          "#B8E5F1",
                                                      }
                                                    : {
                                                        backgroundColor:
                                                          "#ffffff",
                                                      }
                                                }
                                                value={goal?.value}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setScheduledTasksGoalNameFilter(
                                                    goal?.id
                                                  );
                                                }}
                                                className="p fs-14"
                                              >
                                                {goal?.label}
                                              </Dropdown.Item>
                                            ))}
                                          </Dropdown.Menu>
                                        </div>
                                      </Dropdown>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </th>
                                <th
                                  className="sorting_asc status"
                                  style={{ width: "7%" }}
                                >
                                  <div className="d-flex  align-items-center m-0 p-0">
                                    <span
                                      className="float-start"
                                      style={{
                                        paddingRight: "10px",
                                        fontWeight: "bold",
                                        color: "#131F4C",
                                      }}
                                    >
                                      <i
                                        class="fas fa-sort"
                                        onClick={() => {
                                          handleClick1("owner", !sortAsc);
                                        }}
                                      ></i>
                                      Who
                                    </span>
                                    {filterScheduleTaskIcon ? (
                                      <Dropdown
                                        // className="setDropDownposition float-end"
                                        title="Filter"
                                        // style={{ right: "89.3%" }}
                                      >
                                        <Dropdown.Toggle
                                          style={{
                                            background: "none",
                                            border: "none",
                                          }}
                                          className="m-0 p-0"
                                        >
                                          <i
                                            className="fas fa-filter fs-12"
                                            style={{
                                              cursor: "pointer",
                                              color: ScheduledTaskOwnerFilter
                                                ? "#DC5D74"
                                                : "#898fa5",
                                            }}
                                          ></i>
                                        </Dropdown.Toggle>
                                        <div>
                                          <Dropdown.Menu
                                            menuposition="top-right"
                                            className=""
                                            style={{
                                              width: "auto",
                                              height: "200px",
                                              overflowY: "scroll",
                                              scrollbarWidth: "0px",
                                            }}
                                          >
                                            {options?.map((option, i) => (
                                              <Dropdown.Item
                                                key={i}
                                                value={option?.value}
                                                style={
                                                  ScheduledTaskOwnerFilter ===
                                                  option?.userId
                                                    ? {
                                                        backgroundColor:
                                                          "#B8E5F1",
                                                      }
                                                    : {
                                                        backgroundColor:
                                                          "#ffffff",
                                                      }
                                                }
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setScheduledTaskOwnerFilter(
                                                    option?.userId
                                                  );
                                                }}
                                                className="p fs-14"
                                              >
                                                {option?.value}
                                              </Dropdown.Item>
                                            ))}
                                          </Dropdown.Menu>
                                        </div>
                                      </Dropdown>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </th>
                                <th
                                  className="sorting perFields"
                                  style={{ width: "7%" }}
                                >
                                  <OverlayTrigger
                                    delay={{ hide: 100, show: 10 }}
                                    overlay={
                                      <Tooltip id="tooltip-top">
                                        <span className="fs-18">
                                          Toggle to make the task active.
                                          Inactive tasks won't be scheduled.
                                        </span>
                                      </Tooltip>
                                    }
                                    placement="top"
                                  >
                                    <div className="d-flex align-items-center m-0 p-0">
                                      <span
                                        className="float-start"
                                        style={{
                                          paddingRight: "10px",
                                          fontWeight: "bold",
                                          color: "#131F4C",
                                        }}
                                      >
                                        Active
                                      </span>

                                      {filterScheduleTaskIcon ? (
                                        <Dropdown
                                          // className="setDropDownposition float-end"
                                          title="Filter"
                                          // style={{ right: "31%" }}
                                        >
                                          <Dropdown.Toggle
                                            style={{
                                              background: "none",
                                              border: "none",
                                            }}
                                            className="m-0 p-0"
                                          >
                                            <i
                                              className="fas fa-filter fs-12"
                                              style={{
                                                cursor: "pointer",
                                                color: statusFilter
                                                  ? "#DC5D74"
                                                  : "#898fa5",
                                              }}
                                            ></i>
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            {StatusFilter?.map((scope, i) => (
                                              <Dropdown.Item
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setStatusFilter(scope.value);
                                                }}
                                                style={
                                                  statusFilter === scope?.name
                                                    ? {
                                                        backgroundColor:
                                                          "#B8E5F1",
                                                      }
                                                    : {
                                                        backgroundColor:
                                                          "#ffffff",
                                                      }
                                                }
                                                key={i}
                                                className="p fs-14"
                                              >
                                                {scope?.name}
                                              </Dropdown.Item>
                                            ))}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </OverlayTrigger>
                                </th>
                                <th
                                  className="sorting perFields"
                                  style={{ width: "7%" }}
                                >
                                  <div className="d-flex align-items-center m-0 p-0">
                                    <span
                                      className="float-start"
                                      style={{
                                        paddingRight: "10px",
                                        fontWeight: "bold",
                                        color: "#131F4C",
                                      }}
                                    >
                                      <i
                                        class="fas fa-sort"
                                        onClick={() => {
                                          handleClick1("impact", !sortAsc);
                                        }}
                                      ></i>
                                      Impact
                                    </span>
                                    {filterScheduleTaskIcon ? (
                                      <Dropdown
                                        // className="setDropDownposition float-end"
                                        title="Filter"
                                        // style={{ right: "49%" }}
                                      >
                                        <Dropdown.Toggle
                                          style={{
                                            background: "none",
                                            border: "none",
                                          }}
                                          className="m-0 p-0"
                                        >
                                          <i
                                            className="fas fa-filter fs-12"
                                            style={{
                                              cursor: "pointer",
                                              color: selectedScheduledTaskImpact
                                                ? "#DC5D74"
                                                : "#898fa5",
                                            }}
                                          ></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          {TaskImpact?.map((scope, i) => (
                                            <Dropdown.Item
                                              style={
                                                selectedScheduledTaskImpact ===
                                                scope.value
                                                  ? {
                                                      backgroundColor:
                                                        "#B8E5F1",
                                                    }
                                                  : {
                                                      backgroundColor:
                                                        "#ffffff",
                                                    }
                                              }
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setselectedScheduledTaskImpact(
                                                  scope.value
                                                );
                                              }}
                                              key={i}
                                              className="p fs-14"
                                            >
                                              {scope?.name}
                                            </Dropdown.Item>
                                          ))}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </th>
                                <th
                                  className="sorting perFields"
                                  style={{ width: "10.2%" }}
                                >
                                  <div className="d-flex align-items-center m-0 p-0">
                                    <span
                                      className="float-start"
                                      style={{
                                        paddingRight: "10px",
                                        fontWeight: "bold",
                                        color: "#131F4C",
                                      }}
                                    >
                                      <i
                                        class="fas fa-sort"
                                        onClick={() => {
                                          handleClick1("effort", !sortAsc);
                                        }}
                                      ></i>
                                      Effort
                                    </span>
                                    {filterScheduleTaskIcon ? (
                                      <Dropdown
                                        // className="setDropDownposition float-end"
                                        title="Filter"
                                        // style={{ right: "39%" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setScheduledTaskEffortFieldOpen(
                                            !ScheduledTaskEffortFieldOpen
                                          );
                                        }}
                                      >
                                        <Dropdown.Toggle
                                          style={{
                                            background: "none",
                                            border: "none",
                                          }}
                                          className="m-0 p-0"
                                        >
                                          <i
                                            className="fas fa-filter fs-12"
                                            style={{
                                              cursor: "pointer",
                                              color: ScheduledTaskEffortFilter
                                                ? "#DC5D74"
                                                : "#898fa5",
                                            }}
                                          ></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          {
                                            <input
                                              type="string"
                                              name="name"
                                              className="figma-input p m-0 p-2"
                                              placeholder="Enter task effort"
                                              autocomplete="off"
                                              // value={ScheduledTaskEffortFilter}
                                              value={
                                                ScheduledTaskEffortFilterForShow
                                              }
                                              onChange={(e) => {
                                                // setScheduledTaskEffortFilter(
                                                //   e.target.value);
                                                setScheduledTaskEffortFilterForShow(
                                                  e.target.value
                                                );
                                                reverseAdjustedTaskEffort(
                                                  e.target.value
                                                );
                                              }}
                                              style={{
                                                border: "1px solid black",
                                                width: "150px",
                                              }}
                                            />
                                          }
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </th>
                                <th
                                  className="sorting status"
                                  style={{ width: "7%" }}
                                >
                                  <OverlayTrigger
                                    delay={{ hide: 100, show: 10 }}
                                    overlay={
                                      <Tooltip id="tooltip-top">
                                        <span className="fs-18">
                                          AI sorted this task as [Game
                                          changers/Support/Quick wins] to help
                                          you prioritize effectively. Learn more
                                          here!
                                        </span>
                                      </Tooltip>
                                    }
                                    placement="top"
                                  >
                                    <div className="d-flex align-items-center m-0 p-0">
                                      <span
                                        className="float-start"
                                        style={{
                                          paddingRight: "10px",
                                          fontWeight: "bold",
                                          color: "#131F4C",
                                        }}
                                      >
                                        {" "}
                                        <i
                                          class="fas fa-sort"
                                          onClick={() => {
                                            handleClick1("type", !sortAsc);
                                          }}
                                        ></i>
                                        Type
                                      </span>
                                      {filterScheduleTaskIcon ? (
                                        <Dropdown
                                          // className="setDropDownposition float-end"
                                          title="Filter"
                                          // style={{ right: "79.5%" }}
                                        >
                                          <Dropdown.Toggle
                                            style={{
                                              background: "none",
                                              border: "none",
                                            }}
                                            className="m-0 p-0"
                                          >
                                            <i
                                              className="fas fa-filter fs-12"
                                              style={{
                                                cursor: "pointer",
                                                color: seletcedScheduledTaskType
                                                  ? "#DC5D74"
                                                  : "#898fa5",
                                              }}
                                            ></i>
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu
                                            style={{
                                              width: "auto",
                                              height: "120px",
                                            }}
                                          >
                                            {tasktype?.map((scope, i) => (
                                              <Dropdown.Item
                                                style={
                                                  seletcedScheduledTaskType ===
                                                  scope.value
                                                    ? {
                                                        backgroundColor:
                                                          "#B8E5F1",
                                                      }
                                                    : {
                                                        backgroundColor:
                                                          "#ffffff",
                                                      }
                                                }
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setselectedScheduledTaskType(
                                                    scope.value
                                                  );
                                                }}
                                                key={i}
                                                className="p fs-14"
                                              >
                                                {scope?.name}
                                              </Dropdown.Item>
                                            ))}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </OverlayTrigger>
                                </th>
                                <th
                                  className="sorting perFields"
                                  style={{ width: "7%" }}
                                >
                                  <div className="d-flex align-items-center m-0 p-0">
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        color: "#131F4C",
                                      }}
                                    >
                                      <i
                                        class="fas fa-sort"
                                        onClick={() => {
                                          handleClick1("due_in", !sortAsc);
                                        }}
                                      ></i>
                                      Due in
                                    </span>
                                    {filterScheduleTaskIcon ? (
                                      <Dropdown
                                        // className="setDropDownposition float-end"
                                        title="Filter"
                                        // style={{ right: "58%" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setScheduledTaskDueInFieldOpen(
                                            !scheduledTaskDueInFieldOpen
                                          );
                                        }}
                                      >
                                        <Dropdown.Toggle
                                          style={{
                                            background: "none",
                                            border: "none",
                                          }}
                                          className="m-0 p-0"
                                        >
                                          <i
                                            className="fas fa-filter fs-12"
                                            style={{
                                              cursor: "pointer",
                                              color: scheduledTaskDueInFilter
                                                ? "#DC5D74"
                                                : "#898fa5",
                                            }}
                                          ></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          {
                                            <>
                                              <input
                                                type="text"
                                                name="name"
                                                className="figma-input p m-0 p-2"
                                                placeholder="Enter due in"
                                                autocomplete="off"
                                                value={scheduledTaskDueInFilter}
                                                onChange={(e) =>
                                                  setScheduledTaskDueInFilter(
                                                    e.target.value
                                                  )
                                                }
                                                style={{
                                                  border: "1px solid black",
                                                  width: "150px",
                                                }}
                                              />
                                            </>
                                          }
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </th>
                                {/* <th className="sorting perFields">Schedule</th> */}

                                {/* <th>Complete</th> */}
                                <th
                                  className="sorting perFields"
                                  style={{ width: "20%" }}
                                >
                                  <div className="d-flex align-items-center m-0 p-0">
                                    <span
                                      className="float-start"
                                      style={{
                                        paddingRight: "10px",
                                        fontWeight: "bold",
                                        color: "#131F4C",
                                      }}
                                    >
                                      {" "}
                                      {/* <i
                                      class="fas fa-sort"
                                      onClick={() => {
                                        handleClick1("type", !sortAsc);
                                      }}
                                    ></i> */}
                                      Outcome
                                    </span>
                                    {filterScheduleTaskIcon ? (
                                      <Dropdown
                                        // className="setDropDownposition float-end"
                                        title="Filter"
                                        // style={{ right: "79.5%" }}
                                      >
                                        <Dropdown.Toggle
                                          style={{
                                            background: "none",
                                            border: "none",
                                          }}
                                          className="m-0 p-0"
                                        >
                                          <i
                                            className="fas fa-filter fs-12"
                                            style={{
                                              cursor: "pointer",
                                              color: seletcedScheduledTaskStatus
                                                ? "#DC5D74"
                                                : "#898fa5",
                                            }}
                                          ></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu
                                          style={{
                                            width: "auto",
                                          }}
                                        >
                                          {OutcomeFilter?.map((scope, i) => (
                                            <Dropdown.Item
                                              style={
                                                seletcedScheduledTaskStatus ===
                                                scope.value
                                                  ? {
                                                      backgroundColor:
                                                        "#B8E5F1",
                                                    }
                                                  : {
                                                      backgroundColor:
                                                        "#ffffff",
                                                    }
                                              }
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setseletcedScheduledTaskStatus(
                                                  scope.value
                                                );
                                              }}
                                              key={i}
                                              className="p fs-14"
                                            >
                                              {scope?.name}
                                            </Dropdown.Item>
                                          ))}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </th>

                                <th className="sorting col-sm-1">
                                  <div className="d-flex align-items-center m-0 p-0">
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        color: "#131F4C",
                                      }}
                                    >
                                      Action
                                    </span>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody
                              style={
                                showSubTableSpinner
                                  ? { background: "#f6f7f8", zIndex: "-1" }
                                  : {}
                              }
                            >
                              {schedulesTasksList &&
                                schedulesTasksList.map((task, index) => (
                                  <TableRows
                                    key={task.id}
                                    task={task}
                                    index={index}
                                    moveGoal={moveGoal}
                                  />
                                ))}
                            </tbody>
                            <>
                              {rowAddTask && (
                                <tr style={{ height: "125px" }}>
                                  <td
                                    className="p-2"
                                    style={{ position: "relative" }}
                                  >
                                    <input
                                      type="text"
                                      name="task_name"
                                      onChange={formik.handleChange}
                                      placeholder={"Enter task name"}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.task_name}
                                      style={{
                                        width: "100%",
                                        border: "1px solid #e2e2e2",
                                        height: "35px",
                                      }}
                                    />
                                    {formik.touched.task_name &&
                                    formik.errors.task_name ? (
                                      <div
                                        style={{
                                          color: "red",
                                          position: "absolute",
                                          left: "0",
                                        }}
                                      >
                                        {formik.errors.task_name}
                                      </div>
                                    ) : null}
                                  </td>
                                  <td className="p-2">
                                    <Select
                                      name="goal_owner"
                                      className="p"
                                      isClearable
                                      options={goalOption}
                                      value={defaultOptions}
                                      onChange={changeGoalOption}
                                      menuPlacement="auto"
                                      menuPortalTarget={document.body}
                                      styles={{
                                        control: (base) => ({
                                          ...base,
                                        }),
                                        menu: (base) => ({
                                          ...base,
                                          width: "150px",
                                        }),
                                        menuPortal: (base) => ({
                                          ...base,
                                          zIndex: 1,
                                        }),
                                      }}
                                    />
                                  </td>
                                  <td className="p-2">
                                    <Select
                                      options={options}
                                      onChange={(selectedOption) =>
                                        formik.setFieldValue(
                                          "task_owner",
                                          selectedOption
                                        )
                                      }
                                      value={formik.values.task_owner}
                                      menuPlacement="auto"
                                      menuPortalTarget={document.body}
                                      styles={{
                                        control: (base) => ({
                                          ...base,
                                        }),
                                        menu: (base) => ({
                                          ...base,
                                          width: "150px",
                                        }),
                                        menuPortal: (base) => ({
                                          ...base,
                                          zIndex: 1,
                                        }),
                                      }}
                                    />
                                  </td>
                                  <td className="p-2">
                                    <div className="d-flex  align-items-center m-0 p-0">
                                      -
                                    </div>
                                  </td>
                                  <td className="p-2">
                                    <Select
                                      options={[
                                        { value: "high", label: "High" },
                                        { value: "medium", label: "Medium" },
                                        { value: "low", label: "Low" },
                                      ]}
                                      onChange={(selectedOption) =>
                                        formik.setFieldValue(
                                          "task_impact",
                                          selectedOption
                                        )
                                      }
                                      value={formik.values.task_impact}
                                      menuPlacement="auto"
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({
                                          ...base,
                                          zIndex: 9999,
                                        }),
                                      }}
                                    />
                                  </td>

                                  <td
                                    className="p-2"
                                    style={{ position: "relative" }}
                                  >
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <TimePicker
                                        sx={{
                                          "& .MuiInputBase-root": {
                                            height: 39,
                                            overflow: "hidden",
                                          },
                                          "& .MuiInputBase-input": {
                                            height: 39,
                                            overflow: "hidden",
                                            border: "none",
                                          },
                                        }}
                                        height={80}
                                        ampm={false}
                                        views={["hours", "minutes"]}
                                        timeSteps={{ minutes: 1 }}
                                        minTime={dayjs()
                                          .set("hour", 0)
                                          .set("minute", 0)}
                                        maxTime={dayjs()
                                          .set("hour", 19)
                                          .set("minute", 59)}
                                        value={
                                          formik.values.task_effort
                                            ? dayjs(
                                                formik.values.task_effort,
                                                "HH:mm"
                                              )
                                            : null
                                        }
                                        onChange={(newValue) => {
                                          const formattedTime = newValue
                                            ? newValue.format("HH:mm")
                                            : "";
                                          formik.setFieldValue(
                                            "task_effort",
                                            formattedTime
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            // variant="outlined"
                                            size="small"
                                            placeholder="Select Time"
                                            fullWidth
                                          />
                                        )}
                                        // slots={{ actionBar: () => null }}
                                        slotProps={{
                                          actionBar: {
                                            actions: [""],
                                          },
                                          textField: {
                                            InputAdornmentProps: {
                                              sx: { display: "none" }, // Hides the input adornment
                                            },
                                          },
                                        }}
                                        InputProps={{
                                          // ...params.InputProps,
                                          endAdornment: null,
                                        }}
                                      />
                                    </LocalizationProvider>
                                    {formik.touched.task_effort &&
                                    formik.errors.task_effort ? (
                                      <div
                                        style={{
                                          color: "red",
                                          position: "absolute",
                                          left: "0",
                                        }}
                                      >
                                        {formik.errors.task_effort}
                                      </div>
                                    ) : null}
                                  </td>
                                  <td className="p-2">
                                    <div className="d-flex  align-items-center m-0 p-0">
                                      -
                                    </div>
                                  </td>

                                  <td className="p-2">
                                    <div className="d-flex  align-items-center m-0 p-0">
                                      -
                                    </div>
                                  </td>
                                  <td className="p-2">
                                    <div className="d-flex  align-items-center m-0 p-0">
                                      -
                                    </div>
                                  </td>
                                  {/* <td className="p-4" >-</td> */}
                                  <td className="p-2">
                                    <button
                                      className="submitButtonformikForm figma-btn text-white goal-save-button mx-3"
                                      style={{ width: "87px" }}
                                      type="submit"
                                    >
                                      Add
                                    </button>
                                  </td>
                                </tr>
                              )}

                              {!rowAddTask && (
                                <tr
                                  className="odd goals"
                                  style={{ borderTop: "3px solid #d7dae3" }}
                                  role="row"
                                >
                                  <OverlayTrigger
                                    delay={{ hide: 100, show: 10 }}
                                    overlay={
                                      <Tooltip id="tooltip-right">
                                        <span className="fs-18">
                                          Quick Add Task
                                        </span>
                                      </Tooltip>
                                    }
                                    placement="right"
                                  >
                                    <Button
                                      className="mt-4 mb-4"
                                      style={{
                                        background: "#356FD4",
                                        border: "1px solid #356FD4",
                                        height: "50px",
                                        width: "50px",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        // setshowTaskMoadal(true)
                                        setRowAddTask(true);
                                      }}
                                      // onClick={handleDoneButton}
                                      // onContextMenu={handleClick} // Right-click handler
                                    >
                                      <FaPlus color="#FFFFFF"></FaPlus>
                                    </Button>
                                  </OverlayTrigger>
                                </tr>
                              )}
                            </>
                          </table>
                        </form>
                        {schedulesTasksList?.length === 0 &&
                          !showSubTableSpinner && (
                            <Row className="p-0 m-0">
                              <span
                                className="th text-center "
                                style={{ top: "-25px", position: "relative" }}
                              >
                                No records found.
                              </span>
                            </Row>
                          )}
                      </div>
                    </div>
                  </DndProvider>
                  <Row className="pt-3 border-0">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div>
                        <label htmlFor="numberSelector">
                          Rows per page &nbsp;
                        </label>
                        <input
                          type="number"
                          list="numberOptions"
                          value={inputScheduled}
                          onChange={handleScheduleChange}
                          className="form-control pagination-row-input"
                          placeholder=""
                          style={{
                            width: "auto",
                            display: "inline-block",
                            maxWidth: "100px",
                            background: "#fff",
                            border: "0.0625rem solid #d7dae3",
                            padding: "0.3125rem 1.25rem",
                            color: "#6e6e6e",
                            borderRadius: "0rem",
                            height: "45px",
                          }}
                        />
                        <datalist id="numberOptions">
                          {numberOptions.map((number) => (
                            <option key={number} value={number} />
                          ))}
                        </datalist>
                      </div>
                      {pagination && (
                        <Pagination
                          pageCount={pageCount}
                          pageIndex={goToPage}
                          gotoPage={setGoToPage}
                        />
                      )}
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>

          {/* {(localStorage.getItem("TaskScheduledLateCount") == "false" || localStorage.getItem("TaskScheduledLateCount") == null) && (
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4> Upcoming tasks</h4>
                  {(UpcomingTaskNameFilter ||
                    seletcedUpcomingTaskType ||
                    selectedUpcomingTaskImpact || selectedUpcomingTaskControl || selectedUpcomingTaskRiskStatus ||
                    selectedTaskStatusItem ||
                    UpcomingTaskEffortFilter ||
                    UpcomingTaskOwnerFilter ||
                    UpcomingTasksGoalNameFilter) && (
                      <span
                        className=" mx-3 py-0 float-end"
                        title="Clear all filter"
                        onClick={(e) => {
                          clearAllUpcomingTaskFilter(e);
                        }}
                        style={{
                          cursor: "pointer",
                          color: "#DC5D74",
                        }}
                      >
                        Clear all filter
                        <i className="fas fa-filter fs-18"></i>
                        <span>x</span>
                      </span>
                    )}
                </div>
                <div className="card-body">
                  <div className="table-responsive" >
                    <div id="job_data" className="dataTables_wrapper">
                      {showTaskTableSpinner ? (
                        <div
                          style={{
                            position: "relative",
                            top:
                              taskList.length < 3
                                ? "6rem"
                                : taskList.length >= 3
                                  ? "9rem"
                                  : "1rem",
                          }}
                          className="m-0 p-0"
                        >
                          <LoadingSpinner />
                        </div>
                      ) : (
                        ""
                      )}
                      <form onSubmit={formik.handleSubmit}>
                        <table
                          className="display w-100 dataTable "
                          id="example5"
                          role="grid"
                          aria-describedby="example5_info"
                          style={
                            taskList.length < 2
                              ? { minHeight: "110px" }
                              : { minHeight: "200px" }
                          }
                        >
                          <thead>
                            <tr role="row">
                              <th className="sorting_asc   status">
                                {" "}
                                <div className="d-flex m-0 p-0">
                                  <span className="float-start" style={{ paddingRight: "10px" }}>
                                    {" "}
                                    <i
                                      class="fas fa-sort"
                                      onClick={() => {
                                        handleClick("owner", !sortAsc);
                                      }}
                                    ></i>
                                    Who
                                  </span>
                                  {filterUpcomingIcon ? (
                                    <Dropdown
                                      title="Filter"
                                    >
                                      <Dropdown.Toggle
                                        style={{
                                          background: "none",
                                          border: "none",
                                        }}
                                        className="m-0 p-0"
                                      >
                                        <i
                                          className="fas fa-filter fs-12"
                                          style={{
                                            cursor: "pointer",
                                            color: UpcomingTaskOwnerFilter
                                              ? "#DC5D74"
                                              : "#898fa5",
                                          }}
                                        ></i>
                                      </Dropdown.Toggle>
                                      <div>
                                        <Dropdown.Menu
                                          menuposition="top-right"
                                          className=""
                                          style={
                                            taskList?.length === 1
                                              ? {
                                                width: "200px",
                                                height: "110px",
                                                overflow: "scroll",
                                              }
                                              : {
                                                width: "200px",
                                                height: "150px",
                                                overflow: "scroll",
                                              }
                                          }
                                        >
                                          {options?.map((option, i) => (
                                            <Dropdown.Item
                                              key={i}
                                              value={option?.value}
                                              style={
                                                UpcomingTaskOwnerFilter ===
                                                  option?.userId
                                                  ? { backgroundColor: "#B8E5F1" }
                                                  : { backgroundColor: "#ffffff" }
                                              }
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setUpcomingTaskOwnerFilter(
                                                  option?.userId
                                                );
                                              }}
                                              className="p fs-14"
                                            >
                                              {option?.value}
                                            </Dropdown.Item>
                                          ))}
                                        </Dropdown.Menu>
                                      </div>
                                    </Dropdown>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </th>
                              <th className="sorting   perFields">
                                {" "}
                                <div className="d-flex m-0 p-0">
                                  <span className="float-start" style={{ paddingRight: "10px" }}>
                                    <i
                                      class="fas fa-sort"
                                      onClick={() => {
                                        handleClick("type", !sortAsc);
                                      }}
                                    ></i>
                                    Type
                                  </span>
                                  {filterUpcomingIcon ? (
                                    <Dropdown
                                      title="Filter"
                                    >
                                      <Dropdown.Toggle
                                        style={{
                                          background: "none",
                                          border: "none",
                                        }}
                                        className="m-0 p-0"
                                      >
                                        <i
                                          className="fas fa-filter fs-12"
                                          style={{
                                            cursor: "pointer",
                                            color: seletcedUpcomingTaskType
                                              ? "#DC5D74"
                                              : "#898fa5",
                                          }}
                                        ></i>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu
                                        style={
                                          taskList?.length < 5
                                            ? { overflow: "scroll", height: "110px" }
                                            : {}
                                        }
                                      >
                                        {tasktype?.map((scope, i) => (
                                          <Dropdown.Item
                                            style={
                                              seletcedUpcomingTaskType === scope.value
                                                ? { backgroundColor: "#B8E5F1" }
                                                : { backgroundColor: "#ffffff" }
                                            }
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setselectedUpcomingTaskType(
                                                scope.value
                                              );
                                            }}
                                            key={i}
                                            className="p fs-14"
                                          >
                                            {scope?.name}
                                          </Dropdown.Item>
                                        ))}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </th>
                              <th className="sorting  ">
                                <div className="d-flex m-0 p-0">
                                  <span className="float-start" style={{ paddingRight: "10px" }}>
                                    <i
                                      class="fas fa-sort"
                                      onClick={() => {
                                        handleClick("goal", !sortAsc);
                                      }}
                                    ></i>
                                    Objective
                                  </span>
                                  {filterUpcomingIcon ? (
                                    <Dropdown
                                      title="Filter"
                                      onClick={() => {
                                        setUpcomingTasksGoalInputFieldOpen(
                                          !UpcomingTasksGoalInputFieldOpen
                                        );
                                      }}
                                    >
                                      <Dropdown.Toggle
                                        style={{
                                          background: "none",
                                          border: "none",
                                        }}
                                        className="m-0 p-0"
                                      >
                                        <i
                                          className="fas fa-filter fs-12"
                                          style={{
                                            cursor: "pointer",
                                            color: UpcomingTasksGoalNameFilter
                                              ? "#DC5D74"
                                              : "#898fa5",
                                          }}
                                        ></i>
                                      </Dropdown.Toggle>
                                      <div>
                                        <Dropdown.Menu
                                          menuposition="top-right"
                                          className=""
                                          style={{
                                            width: "auto",
                                            height: "200px",
                                            overflowY: "scroll",
                                            scrollbarWidth: "0px",
                                          }}
                                        >
                                          {goals?.map((goal, i) => (
                                            <Dropdown.Item
                                              key={i}
                                              value={goal?.value}
                                              style={
                                                UpcomingTasksGoalNameFilter ===
                                                  goal?.id
                                                  ? { backgroundColor: "#B8E5F1" }
                                                  : { backgroundColor: "#ffffff" }
                                              }
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setUpcomingTasksGoalNameFilter(
                                                  goal?.id
                                                );
                                              }}
                                              className="p fs-14"
                                            >
                                              {goal?.label}
                                            </Dropdown.Item>
                                          ))}
                                        </Dropdown.Menu>
                                      </div>
                                    </Dropdown>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </th>
                              <th className="sorting">
                                <div className="d-flex m-0 p-0">
                                  <span className="float-start" style={{ paddingRight: "10px" }}>
                                    {" "}
                                    <i
                                      class="fas fa-sort"
                                      onClick={() => {
                                        handleClick("task", !sortAsc);
                                      }}
                                    ></i>
                                    Task
                                  </span>
                                  {filterUpcomingIcon ? (
                                    <Dropdown
                                      title="Filter"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setUpcomingTaskInputFieldOpen(
                                          !UpcomingTaskInputFieldOpen
                                        );
                                      }}
                                    >
                                      <Dropdown.Toggle
                                        style={{
                                          background: "none",
                                          border: "none",
                                        }}
                                        className="m-0 p-0"
                                      >
                                        <i
                                          className="fas fa-filter fs-12"
                                          style={{
                                            cursor: "pointer",
                                            color: UpcomingTaskNameFilter
                                              ? "#DC5D74"
                                              : "#898fa5",
                                          }}
                                        ></i>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {
                                          <>
                                            <input
                                              type="text"
                                              name="name"
                                              className="figma-input p m-0 p-2"
                                              placeholder="Enter task name"
                                              autocomplete="off"
                                              defaultValue={UpcomingTaskNameFilter}
                                              onKeyUp={(e) =>
                                                setTimeout(() => {
                                                  setUpcomingTaskNameFilter(
                                                    e.target.value
                                                  );
                                                }, 1000)
                                              }
                                              style={{
                                                border: "1px solid #9a9b9c",
                                                width: "150px",
                                                borderRadius: "0px",
                                              }}
                                            />
                                          </>
                                        }
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </th>
                              <th className="sorting  perFields">
                                {" "}
                                <div className="d-flex m-0 p-0">
                                  <span className="float-start" style={{ paddingRight: "10px" }}>
                                    {" "}
                                    <i
                                      class="fas fa-sort"
                                      onClick={() => {
                                        handleClick("impact", !sortAsc);
                                      }}
                                    ></i>
                                    Impact
                                  </span>
                                  {filterUpcomingIcon ? (
                                    <Dropdown
                                      title="Filter"
                                    >
                                      <Dropdown.Toggle
                                        style={{
                                          background: "none",
                                          border: "none",
                                        }}
                                        className="m-0 p-0"
                                      >
                                        <i
                                          className="fas fa-filter fs-12"
                                          style={{
                                            cursor: "pointer",
                                            color: selectedUpcomingTaskImpact
                                              ? "#DC5D74"
                                              : "#898fa5",
                                          }}
                                        ></i>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {TaskImpact?.map((scope, i) => (
                                          <Dropdown.Item
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setselectedUpcomingTaskImpact(
                                                scope.value
                                              );
                                            }}
                                            style={
                                              selectedUpcomingTaskImpact ===
                                                scope?.name
                                                ? { backgroundColor: "#B8E5F1" }
                                                : { backgroundColor: "#ffffff" }
                                            }
                                            key={i}
                                            className="p fs-14"
                                          >
                                            {scope?.name}
                                          </Dropdown.Item>
                                        ))}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </th>
                              <th className="sorting  perFields">
                                {" "}
                                <div className="d-flex m-0 p-0">
                                  <span className="float-start" style={{ paddingRight: "10px" }}>
                                    {" "}
                                    <i
                                      class="fas fa-sort"
                                      onClick={() => {
                                        handleClick("control", !sortAsc);
                                      }}
                                    ></i>
                                    Control
                                  </span>
                                  {filterUpcomingIcon ? (
                                    <Dropdown
                                      title="Filter"
                                    >
                                      <Dropdown.Toggle
                                        style={{
                                          background: "none",
                                          border: "none",
                                        }}
                                        className="m-0 p-0"
                                      >
                                        <i
                                          className="fas fa-filter fs-12"
                                          style={{
                                            cursor: "pointer",
                                            color: selectedUpcomingTaskControl
                                              ? "#DC5D74"
                                              : "#898fa5",
                                          }}
                                        ></i>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {TaskControl?.map((scope, i) => (
                                          <Dropdown.Item
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setselectedUpcomingTaskControl(
                                                scope.value
                                              );
                                            }}
                                            style={
                                              selectedUpcomingTaskControl ===
                                                scope?.name
                                                ? { backgroundColor: "#B8E5F1" }
                                                : { backgroundColor: "#ffffff" }
                                            }
                                            key={i}
                                            className="p fs-14"
                                          >
                                            {scope?.name}
                                          </Dropdown.Item>
                                        ))}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </th>
                              <th className="sorting  perFields">
                                {" "}
                                <div className="d-flex m-0 p-0">
                                  <span className="float-start" style={{ paddingRight: "10px" }}>
                                    {" "}

                                    Risk
                                  </span>
                                  {filterUpcomingIcon ? (
                                    <Dropdown
                                      title="Filter"
                                    >
                                      <Dropdown.Toggle
                                        style={{
                                          background: "none",
                                          border: "none",
                                        }}
                                        className="m-0 p-0"
                                      >
                                        <i
                                          className="fas fa-filter fs-12"
                                          style={{
                                            cursor: "pointer",
                                            color: selectedUpcomingTaskRiskStatus
                                              ? "#DC5D74"
                                              : "#898fa5",
                                          }}
                                        ></i>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {TaskImpact?.map((scope, i) => (
                                          <Dropdown.Item
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setselectedUpcomingTaskRiskStatus(
                                                scope.value
                                              );
                                            }}
                                            style={
                                              selectedUpcomingTaskRiskStatus ===
                                                scope?.name
                                                ? { backgroundColor: "#B8E5F1" }
                                                : { backgroundColor: "#ffffff" }
                                            }
                                            key={i}
                                            className="p fs-14"
                                          >
                                            {scope?.name}
                                          </Dropdown.Item>
                                        ))}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </th>
                              <th
                                className="sorting  status"
                                onClick={() => {
                                  handleClick("effort", !sortAsc);
                                }}
                              >
                                {" "}
                                <div className="d-flex m-0 p-0">
                                  <span className="float-start" style={{ paddingRight: "10px" }}>
                                    <i class="fas fa-sort"></i>Effort
                                  </span>
                                  {filterUpcomingIcon ? (
                                    <Dropdown
                                      title="Filter"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setUpcomingTaskEffortFieldOpen(
                                          !UpcomingTaskEffortFieldOpen
                                        );
                                      }}
                                    >
                                      <Dropdown.Toggle
                                        style={{
                                          background: "none",
                                          border: "none",
                                        }}
                                        className="m-0 p-0"
                                      >
                                        <i
                                          className="fas fa-filter fs-12"
                                          style={{
                                            cursor: "pointer",
                                            color: UpcomingTaskEffortFilter
                                              ? "#DC5D74"
                                              : "#898fa5",
                                          }}
                                        ></i>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {
                                          <input
                                            type="number"
                                            name="name"
                                            className="figma-input p m-0 p-2"
                                            placeholder="Enter task effort"
                                            autocomplete="off"
                                            value={UpcomingTaskEffortFilter}
                                            onChange={(e) =>
                                              setUpcomingTaskEffortFilter(
                                                e.target.value
                                              )
                                            }
                                            style={{
                                              border: "1px solid black",
                                              width: "150px",
                                            }}
                                          />
                                        }
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </th>
                              <th>Complete</th>
                              <th >Task success</th>
                              <th className="sorting perField">
                                <div className="d-flex m-0 p-0">
                                  <span className="float-start" style={{ paddingRight: "10px" }}> Status</span>
                                  {filterUpcomingIcon ? (
                                    <Dropdown
                                      title="Filter"
                                    >
                                      <Dropdown.Toggle
                                        style={{
                                          background: "none",
                                          border: "none",
                                        }}
                                        className="m-0 p-0"
                                      >
                                        <i
                                          className="fas fa-filter fs-12"
                                          style={{
                                            cursor: "pointer",
                                            color: selectedTaskStatusItem
                                              ? "#DC5D74"
                                              : "#898fa5",
                                          }}
                                        ></i>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu
                                        style={{
                                          width: "auto",
                                          height: "200px",
                                          overflowY: "scroll",
                                          scrollbarWidth: "0px",
                                        }}
                                      >
                                        {TaskStatus?.map((scope, i) => (
                                          <Dropdown.Item
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setSelectedTaskStatusItem(scope.value);
                                            }}
                                            style={
                                              selectedTaskStatusItem === scope?.name
                                                ? { backgroundColor: "#B8E5F1" }
                                                : { backgroundColor: "#ffffff" }
                                            }
                                            key={i}
                                            className="p fs-14"
                                          >
                                            {scope?.name}
                                          </Dropdown.Item>
                                        ))}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </th>
                              <th className="sorting col-sm-1">Action</th>
                            </tr>
                          </thead>
                          <tbody
                            style={
                              showTaskTableSpinner
                                ? { background: "#f6f7f8", zIndex: "-1" }
                                : {}
                            }
                          >
                            <>
                              {taskList &&
                                taskList.map(
                                  (task, index) =>
                                    (task.task_status === "Awaiting acceptance" ||
                                      task.task_status === "To be scheduled") && (
                                      <tr
                                        role="row"
                                        className="odd goals"
                                        style={
                                          taskKey === task?.id
                                            ? { backgroundColor: "#faeabb" }
                                            : {}
                                        }
                                      >
                                        <td
                                          onClick={(event) => {
                                            event.preventDefault();
                                            setTaskDetailsinfo(task);
                                            setFeedbackModal(false);
                                            setFeedbackButton(false);
                                          }}
                                          style={{
                                            border: 2,
                                            borderColor: "white",
                                            borderStyle: "solid",
                                          }}
                                          className="sorting_1"
                                        >
                                          {task?.task_owner?.first_name[0]?.toUpperCase()}
                                          {task?.task_owner?.last_name[0]?.toUpperCase()}
                                        </td>
                                        <td
                                          onClick={(event) => {
                                            event.preventDefault();
                                            setTaskDetailsinfo(task);
                                            setFeedbackModal(false);
                                            setFeedbackButton(false);
                                          }}
                                          style={{
                                            border: 2,
                                            borderColor: "white",
                                            borderStyle: "solid",
                                          }}
                                        >
                                          {task.task_type}
                                        </td>
                                        <td
                                          onClick={(event) => {
                                            event.preventDefault();
                                            setTaskDetailsinfo(task);
                                            setFeedbackModal(false);
                                            setFeedbackButton(false);
                                          }}
                                          style={{
                                            border: 2,
                                            borderColor: "white",
                                            borderStyle: "solid",
                                          }}
                                        >
                                          {task.goal === null
                                            ? "-"
                                            : task?.goal?.name}
                                        </td>
                                        <td
                                          onClick={(event) => {
                                            event.preventDefault();
                                            setTaskDetailsinfo(task);
                                            setFeedbackModal(false);
                                            setFeedbackButton(false);
                                          }}
                                          style={{
                                            border: 2,
                                            borderColor: "white",
                                            borderStyle: "solid",
                                          }}
                                        >
                                          {task.name === null ? "-" : task?.name}
                                        </td>
                                        <td
                                          onClick={(event) => {
                                            event.preventDefault();
                                            setTaskDetailsinfo(task);
                                            setFeedbackModal(false);
                                            setFeedbackButton(false);
                                          }}
                                          style={{
                                            border: 2,
                                            borderColor: "white",
                                            borderStyle: "solid",
                                          }}
                                        >
                                          {task.task_impact}
                                        </td>

                                        <td
                                          onClick={(event) => {
                                            event.preventDefault();
                                            setTaskDetailsinfo(task);
                                            setFeedbackModal(false);
                                            setFeedbackButton(false);
                                          }}
                                          style={{
                                            border: 2,
                                            borderColor: "white",
                                            borderStyle: "solid",
                                          }}
                                        >
                                          {task?.task_control}
                                        </td>
                                        <td
                                          onClick={(event) => {
                                            event.preventDefault();
                                            setTaskDetailsinfo(task);
                                            setFeedbackModal(false);
                                            setFeedbackButton(false);
                                          }}
                                          style={{
                                            border: 2,
                                            borderColor: "white",
                                            borderStyle: "solid",
                                          }}
                                        >
                                          {task?.risk_status}
                                        </td>
                                        <td
                                          onClick={(event) => {
                                            event.preventDefault();
                                            setTaskDetailsinfo(task);
                                            setFeedbackModal(false);
                                            setFeedbackButton(false);
                                          }}
                                          style={{
                                            border: 2,
                                            borderColor: "white",
                                            borderStyle: "solid",
                                          }}
                                        >
                                          {task.task_effort}
                                        </td>
                                        <td style={{
                                          border: 2,
                                          borderColor: "white",
                                          borderStyle: "solid",
                                        }}>
                                          {task?.task_owner?.id ===
                                            userInfoDetails?.data?.id ||
                                            task?.task_creator?.id ===
                                            userInfoDetails?.data?.id ||
                                            userInfoDetails?.data?.user_role === "owner" ? (<div className="d-flex justify-content-center todo-button ">
                                              <button
                                                to=""
                                                title="Mark as complete"
                                                className="outline-primary btn btn-primary me-2"
                                                style={markAsStatus[task?.id] ? { backgroundColor: '#D4EBE2', color: '#26996d', border: "1px solid #26996d" } : {}}
                                                onClick={(event) => {
                                                  handleMarkAsComplete(event, task, index)
                                                }}
                                              >
                                                <i className="fas fa-check" id="right-symbol-icon"></i>
                                              </button>
                                            </div>) : (<>
                                              <div
                                                className="d-flex justify-content-center todo-button "
                                                title="User does not have permission to perform this action"
                                              >
                                                <button
                                                  disabled
                                                  className="outline-primary btn btn-primary me-2"
                                                  onClick={(event) => { }}
                                                >
                                                  <i className="fas fa-check" id="right-symbol-icon"></i>
                                                </button>
                                              </div>
                                            </>)}
                                        </td>
                                        <td style={{
                                          border: 2,
                                          borderColor: "white",
                                          borderStyle: "solid",
                                        }}>
                                          <div className="">
                                            <div className="d-flex mb-1 align-items-center">
                                              <a data-tooltip-id="my-tooltip"
                                                data-tooltip-content={userInfoDetails?.data?.id !== task?.task_owner?.id && userInfoDetails?.data?.id !== task?.task_creator?.id && userInfoDetails?.data?.user_role !== "owner" ? "You don't have permission to edit this field!" : ""} style={userInfoDetails?.data?.id !== taskDetailsinfo?.task_owner?.id || userInfoDetails?.data?.id !== taskDetailsinfo?.task_creator?.id ? { cursor: "not-allowed" } : {}}
                                              >
                                                <Select
                                                  className='p'
                                                  name="task_status"
                                                  isClearable
                                                  options={taskStatusOptions}
                                                  value={taskStatus[task.id] || null}
                                                  onChange={(e) => changeTaskStatus(e, task)}
                                                  menuPlacement="auto"
                                                  menuPortalTarget={document.body}
                                                  styles={{
                                                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                  }}
                                                  isDisabled={userInfoDetails?.data?.id !== task?.task_owner?.id && userInfoDetails?.data?.id !== task?.task_creator?.id && userInfoDetails?.data?.user_role !== "owner" && task?.task_status !== 'Completed'}
                                                />
                                              </a>
                                              <ReactTooltip effect="solid" id="my-tooltip" />


                                            </div>
                                            <div>
                                              {validationMessages[task.id] && <span className="text-danger">{validationMessages[task.id]}</span>}
                                            </div>
                                          </div>
                                        </td>

                                        <td
                                          onClick={(event) => {
                                            event.preventDefault();
                                            setTaskDetailsinfo(task);
                                            setFeedbackModal(false);
                                            setFeedbackButton(false);
                                          }}
                                          style={{
                                            border: 2,
                                            borderColor: "white",
                                            borderStyle: "solid",
                                          }}
                                        >
                                          <span className=" figbtn-success figstatus p-3 text-center">
                                            {task.task_status}
                                          </span>
                                        </td>
                                        <td
                                          style={{
                                            border: 2,
                                            borderColor: "white",
                                            borderStyle: "solid",
                                          }}
                                        >
                                          {task?.task_owner?.id ===
                                            userInfoDetails?.data?.id ||
                                            task?.task_creator?.id ===
                                            userInfoDetails?.data?.id ||
                                            userInfoDetails?.data?.user_role === "owner" ? (
                                            <div className="d-flex todo-button ">
                                              <button
                                                to=""
                                                className="outline-primary btn btn-primary me-2"
                                                onClick={(event) => {
                                                  event.preventDefault()
                                                  setTaskDetailsinfo(task);
                                                  setTasksdelete(false);
                                                  setFeedbackButton(false);
                                                  setFeedbackModal(false);
                                                }}
                                              >
                                                <i className="fas fa-pen"></i>
                                              </button>
                                              <button
                                                to=""
                                                className="outline-primary btn btn-primary me-2"
                                                onClick={(event) => {
                                                  event.preventDefault()
                                                  selectedTask(event, task);
                                                  SetOpenDeleteModal(true);
                                                }}
                                              >
                                                <i className="fa fa-trash"></i>
                                              </button>
                                              <button
                                                to=""
                                                className="outline-primary btn btn-primary me-2"
                                                onClick={(event) => {
                                                  event.preventDefault()
                                                  setShareModal(true)
                                                  setSelectedShareTask(task)
                                                }}
                                              >
                                                <i className="fa fa-share"></i>
                                              </button>

                                            </div>
                                          ) : (
                                            <div
                                              className="d-flex todo-button "
                                              title="User does not have permission to perform this action"
                                            >
                                              <button
                                                disabled
                                                className="outline-primary btn btn-primary me-2"
                                                onClick={(event) => { }}
                                              >
                                                <i className="fas fa-pen"></i>
                                              </button>
                                              <button
                                                disabled
                                                className="outline-primary btn btn-primary me-2"
                                                onClick={(event) => { }}
                                              >
                                                <i className="fa fa-trash"></i>
                                              </button>
                                              <button
                                                disabled
                                                className="outline-primary btn btn-primary me-2"
                                                onClick={(event) => { }}
                                              >
                                                <i className="fa fa-share"></i>
                                              </button>
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    )
                                )}
                            </>
                          </tbody>
                          <>
                            <tr style={{ borderTop: "3px solid #d7dae3", height: "120px" }}
                            >
                              <td className="p-2">
                                <Select
                                  options={options}
                                  onChange={(selectedOption) => formik.setFieldValue("task_owner", selectedOption)}
                                  value={formik.values.task_owner}
                                  menuPlacement="auto"
                                  menuPortalTarget={document.body}
                                  styles={{
                                    control: (base) => ({
                                      ...base,
                                    }),
                                    menu: (base) => ({
                                      ...base,
                                      width: '150px',
                                    }),
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 1,
                                    }),
                                  }}
                                />
                              </td>
                              <td className="p-2">
                                -
                              </td>
                              <td className="p-2">
                                <Select
                                  name="goal_owner"
                                  className="p"
                                  isClearable
                                  options={goalOption}
                                  value={defaultOptions}
                                  onChange={changeGoalOption}
                                  menuPlacement="auto"
                                  menuPortalTarget={document.body}
                                  styles={{
                                    control: (base) => ({
                                      ...base,
                                    }),
                                    menu: (base) => ({
                                      ...base,
                                      width: '150px',
                                    }),
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 1,
                                    }),
                                  }}
                                />
                              </td>

                              <td className="p-2" style={{ position: 'relative' }}>
                                <input
                                  type="text"
                                  name="task_name"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.task_name}
                                  style={{ width: "100%", border: "1px solid #e2e2e2", height: "35px" }}
                                />
                                {formik.touched.task_name && formik.errors.task_name ? (
                                  <div style={{
                                    color: "red", position: 'absolute',
                                    left: '0',
                                  }}>{formik.errors.task_name}</div>
                                ) : null}
                              </td>

                              <td className="p-2">
                                <Select
                                  options={[
                                    { value: "high", label: "High" },
                                    { value: "medium", label: "Medium" },
                                    { value: "low", label: "Low" },
                                  ]}
                                  onChange={(selectedOption) => formik.setFieldValue("task_impact", selectedOption)}
                                  value={formik.values.task_impact}
                                  menuPlacement="auto"
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                                  }}
                                />
                              </td>
                              <td className="p-2">
                                <Select
                                  options={[
                                    { value: "high", label: "High" },
                                    { value: "medium", label: "Medium" },
                                    { value: "low", label: "Low" },

                                  ]}
                                  onChange={(selectedOption) => formik.setFieldValue("task_control", selectedOption)}
                                  value={formik.values.task_control}
                                  menuPlacement="auto"
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                                  }}
                                />
                              </td>
                              <td className="p-2">-</td>

                              <td className="p-2" style={{ position: 'relative' }}>
                                <input
                                  type="number"
                                  name="task_effort"
                                  className="p-2"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.task_effort}
                                  style={{ width: "100%", border: "1px solid #e2e2e2", height: "35px" }}
                                />
                                {formik.touched.task_effort && formik.errors.task_effort ? (
                                  <div style={{
                                    color: "red",
                                    position: 'absolute',
                                    left: '0',
                                  }}>{formik.errors.task_effort}</div>
                                ) : null}
                              </td>

                              <td className="p-4">
                                -
                              </td>
                              <td className="p-4">-</td>
                              <td className="p-4" >-</td>
                              <td className="p-2">
                                <button
                                  className="submitButtonformikForm figma-btn text-white goal-save-button mx-3"
                                  style={{ width: "87px" }}
                                  type="submit"

                                >
                                  Add
                                </button>
                              </td>
                            </tr>
                          </>

                        </table>
                      </form>
                      {taskList?.length === 0 && !showTaskTableSpinner && (
                        <Row className="p-0 m-0">
                          <span
                            className="th text-center "
                            style={{ top: "-106px", position: "relative" }}
                          >
                            No records found.
                          </span>
                        </Row>
                      )}
                    </div>
                  </div>
                  <Row className="pt-3 border-0">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                      <div>
                        <label htmlFor="numberSelector">Rows per page  &nbsp;</label>
                        <input
                          type="number"
                          list="numberOptions"
                          value={inputValue}
                          onChange={handleInputChange}
                          className="form-control pagination-row-input"
                          placeholder=""
                          style={{
                            width: "auto", display: "inline-block", maxWidth: "100px", background: "#fff",
                            border: "0.0625rem solid #d7dae3",
                            padding: "0.3125rem 1.25rem",
                            color: "#6e6e6e",
                            borderRadius: "0rem",
                            height: "45px",
                          }}
                        />
                        <datalist id="numberOptions">
                          {numberOptions.map((number) => (
                            <option key={number} value={number} />
                          ))}
                        </datalist>

                      </div>
                      {paginationUpcoming && (
                        <Pagination
                          pageCount={pageUpcomingCount}
                          pageIndex={goToUpcomingPage}
                          gotoPage={setGoToUpcomingPage}
                        />
                      )}
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          )} */}
        </>
      ) : (
        <>
          {/* {/History Table} */}
          {(localStorage.getItem("TaskScheduledLateCount") == "false" ||
            localStorage.getItem("TaskScheduledLateCount") == null) && (
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>History</h4>
                  {(HistoryTaskNameFilter ||
                    seletcedHistoryTaskType ||
                    selectedHistoryTaskImpact ||
                    selectedHistoryTaskControl ||
                    selectedHistoryTaskRiskStatus ||
                    HistoryTaskEffortFilter ||
                    HistoryTaskOwnerFilter ||
                    HistoryTasksGoalNameFilter ||
                    selectedHistoryTaskStatus ||
                    selectedHistoryTaskScheduleStatus) && (
                    <span
                      className=" mx-3 py-0 float-end"
                      title="Clear all filter"
                      onClick={(e) => {
                        clearAllHistoryTaskFilter(e);
                      }}
                      style={{
                        cursor: "pointer",
                        color: "#DC5D74",
                      }}
                    >
                      Clear all filter
                      <i className="fas fa-filter fs-18"></i>
                      <span>x</span>
                    </span>
                  )}
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <div id="job_data" className="dataTables_wrapper">
                      {showHistoryTableSpinner ? (
                        <div
                          style={{
                            position: "relative",
                            top:
                              historyTaskList.length < 3
                                ? "6rem"
                                : historyTaskList.length >= 3
                                ? "9rem"
                                : "1rem",
                          }}
                          className="m-0 p-0"
                        >
                          <LoadingSpinner />
                        </div>
                      ) : (
                        ""
                      )}
                      <table
                        className="display w-100 dataTable "
                        id="example5"
                        role="grid"
                        aria-describedby="example5_info"
                        style={
                          historyTaskList.length < 2
                            ? { minHeight: "110px" }
                            : { minHeight: "200px" }
                        }
                      >
                        <thead className="">
                          <tr role="row">
                            <th className="sorting_asc  perFields">
                              <div className="d-flex m-0 p-0">
                                <span
                                  className="float-start"
                                  style={{ paddingRight: "10px" }}
                                >
                                  {" "}
                                  <i
                                    class="fas fa-sort"
                                    onClick={() => {
                                      handleClick2("owner", !sortAsc);
                                    }}
                                  ></i>
                                  Who
                                </span>

                                {filterHistoryTaskIcon ? (
                                  <Dropdown
                                    // className="setDropDownposition float-end"
                                    title="Filter"
                                    // style={{ right: "90%" }}
                                  >
                                    <Dropdown.Toggle
                                      style={{
                                        background: "none",
                                        border: "none",
                                      }}
                                      className="m-0 p-0"
                                    >
                                      <i
                                        className="fas fa-filter fs-12"
                                        style={{
                                          cursor: "pointer",
                                          color: HistoryTaskOwnerFilter
                                            ? "#DC5D74"
                                            : "#898fa5",
                                        }}
                                      ></i>
                                    </Dropdown.Toggle>
                                    <div>
                                      <Dropdown.Menu
                                        menuposition="top-right"
                                        className=""
                                        style={{
                                          width: "auto",
                                          height: "150px",
                                          overflowY: "scroll",
                                          scrollbarWidth: "0px",
                                        }}
                                      >
                                        {options?.map((option, i) => (
                                          <Dropdown.Item
                                            key={i}
                                            style={
                                              HistoryTaskOwnerFilter ===
                                              option?.userId
                                                ? { backgroundColor: "#B8E5F1" }
                                                : { backgroundColor: "#ffffff" }
                                            }
                                            value={option?.value}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setHistoryTaskOwnerFilter(
                                                option?.userId
                                              );
                                            }}
                                            className="p fs-14"
                                          >
                                            {option?.value}
                                          </Dropdown.Item>
                                        ))}
                                      </Dropdown.Menu>
                                    </div>
                                  </Dropdown>
                                ) : (
                                  ""
                                )}
                              </div>
                            </th>
                            <th className="sorting  perFields">
                              <div className="d-flex m-0 p-0">
                                <span
                                  className="float-start"
                                  style={{ paddingRight: "10px" }}
                                >
                                  {" "}
                                  <i
                                    class="fas fa-sort"
                                    onClick={() => {
                                      handleClick2("type", !sortAsc);
                                    }}
                                  ></i>
                                  Type
                                </span>
                                {filterHistoryTaskIcon ? (
                                  <Dropdown
                                    // className="setDropDownposition float-end"
                                    title="Filter"
                                    // style={{ right: "80%" }}
                                  >
                                    <Dropdown.Toggle
                                      style={{
                                        background: "none",
                                        border: "none",
                                      }}
                                      className="m-0 p-0"
                                    >
                                      <i
                                        className="fas fa-filter fs-12"
                                        style={{
                                          cursor: "pointer",
                                          color: seletcedHistoryTaskType
                                            ? "#DC5D74"
                                            : "#898fa5",
                                        }}
                                      ></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                      style={{
                                        width: "auto",
                                        height: "120px",
                                      }}
                                    >
                                      {tasktype?.map((scope, i) => (
                                        <Dropdown.Item
                                          style={
                                            seletcedHistoryTaskType ===
                                            scope.value
                                              ? { backgroundColor: "#B8E5F1" }
                                              : { backgroundColor: "#ffffff" }
                                          }
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setselectedHistoryTaskType(
                                              scope.value
                                            );
                                          }}
                                          key={i}
                                          className="p fs-14"
                                        >
                                          {scope?.name}
                                        </Dropdown.Item>
                                      ))}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                ) : (
                                  ""
                                )}
                              </div>
                            </th>
                            <th className="sorting">
                              <div className="d-flex m-0 p-0">
                                <span
                                  className="float-start"
                                  style={{ paddingRight: "10px" }}
                                >
                                  {" "}
                                  <i
                                    class="fas fa-sort"
                                    onClick={() => {
                                      handleClick2("goal", !sortAsc);
                                    }}
                                  ></i>
                                  Objective
                                </span>
                                {filterHistoryTaskIcon ? (
                                  <Dropdown
                                    // className="setDropDownposition float-end"
                                    title="Filter"
                                    // style={{ right: "60%" }}
                                    onClick={() => {
                                      setHistoryTasksGoalInputFieldOpen(
                                        !HistoryTasksGoalInputFieldOpen
                                      );
                                    }}
                                  >
                                    <Dropdown.Toggle
                                      style={{
                                        background: "none",
                                        border: "none",
                                      }}
                                      className="m-0 p-0"
                                    >
                                      <i
                                        className="fas fa-filter fs-12"
                                        style={{
                                          cursor: "pointer",
                                          color: HistoryTasksGoalNameFilter
                                            ? "#DC5D74"
                                            : "#898fa5",
                                        }}
                                      ></i>
                                    </Dropdown.Toggle>
                                    <div>
                                      <Dropdown.Menu
                                        menuposition="top-right"
                                        className=""
                                        style={{
                                          width: "auto",
                                          height: "150px",
                                          overflowY: "scroll",
                                          scrollbarWidth: "0px",
                                        }}
                                      >
                                        {goals?.map((goal, i) => (
                                          <Dropdown.Item
                                            style={
                                              HistoryTasksGoalNameFilter ===
                                              goal?.id
                                                ? { backgroundColor: "#B8E5F1" }
                                                : { backgroundColor: "#ffffff" }
                                            }
                                            key={i}
                                            value={goal?.value}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setHistoryTasksGoalNameFilter(
                                                goal?.id
                                              );
                                            }}
                                            className="p fs-14"
                                          >
                                            {goal?.label}
                                          </Dropdown.Item>
                                        ))}
                                      </Dropdown.Menu>
                                    </div>
                                  </Dropdown>
                                ) : (
                                  ""
                                )}
                              </div>
                            </th>
                            <th className="sorting">
                              <div className="d-flex m-0 p-0">
                                <span
                                  className="float-start"
                                  style={{ paddingRight: "10px" }}
                                >
                                  {" "}
                                  <i
                                    class="fas fa-sort"
                                    onClick={() => {
                                      handleClick2("task", !sortAsc);
                                    }}
                                  ></i>
                                  Task
                                </span>
                                {filterHistoryTaskIcon ? (
                                  <Dropdown
                                    // className="setDropDownposition float-end"
                                    title="Filter"
                                    // style={{ right: "41%" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setHistoryTaskInputFieldOpen(
                                        !HistoryTaskInputFieldOpen
                                      );
                                    }}
                                  >
                                    <Dropdown.Toggle
                                      style={{
                                        background: "none",
                                        border: "none",
                                      }}
                                      className="m-0 p-0"
                                    >
                                      <i
                                        className="fas fa-filter fs-12"
                                        style={{
                                          cursor: "pointer",
                                          color: HistoryTaskNameFilter
                                            ? "#DC5D74"
                                            : "#898fa5",
                                        }}
                                      ></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {
                                        <>
                                          <input
                                            type="text"
                                            name="name"
                                            className="figma-input p m-0 p-2"
                                            placeholder="Enter task name"
                                            autocomplete="off"
                                            defaultValue={HistoryTaskNameFilter}
                                            onKeyUp={(e) =>
                                              setTimeout(() => {
                                                setHistoryTaskNameFilter(
                                                  e.target.value
                                                );
                                              }, 1000)
                                            }
                                            style={{
                                              border: "1px solid #9a9b9c",
                                              width: "150px",
                                              borderRadius: "0px",
                                            }}
                                          />
                                        </>
                                      }
                                    </Dropdown.Menu>
                                  </Dropdown>
                                ) : (
                                  ""
                                )}
                              </div>
                            </th>
                            <th className="sorting perFields">
                              {" "}
                              <div className="d-flex m-0 p-0">
                                <span
                                  className="float-start"
                                  style={{ paddingRight: "10px" }}
                                >
                                  {" "}
                                  <i
                                    class="fas fa-sort"
                                    onClick={() => {
                                      handleClick2("impact", !sortAsc);
                                    }}
                                  ></i>
                                  Impact
                                </span>
                                {filterHistoryTaskIcon ? (
                                  <Dropdown
                                    // className="setDropDownposition float-end"
                                    title="Filter"
                                    // style={{ right: "31%" }}
                                  >
                                    <Dropdown.Toggle
                                      style={{
                                        background: "none",
                                        border: "none",
                                      }}
                                      className="m-0 p-0"
                                    >
                                      <i
                                        className="fas fa-filter fs-12"
                                        style={{
                                          cursor: "pointer",
                                          color: selectedHistoryTaskImpact
                                            ? "#DC5D74"
                                            : "#898fa5",
                                        }}
                                      ></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {TaskImpact?.map((scope, i) => (
                                        <Dropdown.Item
                                          style={
                                            selectedHistoryTaskImpact ===
                                            scope.value
                                              ? { backgroundColor: "#B8E5F1" }
                                              : { backgroundColor: "#ffffff" }
                                          }
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setselectedHistoryTaskImpact(
                                              scope.value
                                            );
                                          }}
                                          key={i}
                                          className="p fs-14"
                                        >
                                          {scope?.name}
                                        </Dropdown.Item>
                                      ))}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                ) : (
                                  ""
                                )}
                              </div>
                            </th>

                            <th className="sorting perFields">
                              {" "}
                              <div className="d-flex m-0 p-0">
                                <span
                                  className="float-start"
                                  style={{ paddingRight: "10px" }}
                                >
                                  {" "}
                                  <i
                                    class="fas fa-sort"
                                    onClick={() => {
                                      handleClick2("control", !sortAsc);
                                    }}
                                  ></i>
                                  Control
                                </span>
                                {filterHistoryTaskIcon ? (
                                  <Dropdown
                                    // className="setDropDownposition float-end"
                                    title="Filter"
                                    // style={{ right: "31%" }}
                                  >
                                    <Dropdown.Toggle
                                      style={{
                                        background: "none",
                                        border: "none",
                                      }}
                                      className="m-0 p-0"
                                    >
                                      <i
                                        className="fas fa-filter fs-12"
                                        style={{
                                          cursor: "pointer",
                                          color: selectedHistoryTaskControl
                                            ? "#DC5D74"
                                            : "#898fa5",
                                        }}
                                      ></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {TaskControl?.map((scope, i) => (
                                        <Dropdown.Item
                                          style={
                                            selectedHistoryTaskControl ===
                                            scope.value
                                              ? { backgroundColor: "#B8E5F1" }
                                              : { backgroundColor: "#ffffff" }
                                          }
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setselectedHistoryTaskControl(
                                              scope.value
                                            );
                                          }}
                                          key={i}
                                          className="p fs-14"
                                        >
                                          {scope?.name}
                                        </Dropdown.Item>
                                      ))}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                ) : (
                                  ""
                                )}
                              </div>
                            </th>

                            <th className="sorting perFields">
                              {" "}
                              <div className="d-flex m-0 p-0">
                                <span
                                  className="float-start"
                                  style={{ paddingRight: "10px" }}
                                >
                                  {" "}
                                  {/* <i
                                  class="fas fa-sort"
                                  onClick={() => {
                                    handleClick2("risk_status", !sortAsc);
                                  }}
                                ></i> */}
                                  Risk
                                </span>
                                {filterHistoryTaskIcon ? (
                                  <Dropdown
                                    // className="setDropDownposition float-end"
                                    title="Filter"
                                    // style={{ right: "31%" }}
                                  >
                                    <Dropdown.Toggle
                                      style={{
                                        background: "none",
                                        border: "none",
                                      }}
                                      className="m-0 p-0"
                                    >
                                      <i
                                        className="fas fa-filter fs-12"
                                        style={{
                                          cursor: "pointer",
                                          color: selectedHistoryTaskRiskStatus
                                            ? "#DC5D74"
                                            : "#898fa5",
                                        }}
                                      ></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {TaskRiskStatus?.map((scope, i) => (
                                        <Dropdown.Item
                                          style={
                                            selectedHistoryTaskRiskStatus ===
                                            scope.value
                                              ? { backgroundColor: "#B8E5F1" }
                                              : { backgroundColor: "#ffffff" }
                                          }
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setselectedHistoryTaskRiskStatus(
                                              scope.value
                                            );
                                          }}
                                          key={i}
                                          className="p fs-14"
                                        >
                                          {scope?.name}
                                        </Dropdown.Item>
                                      ))}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                ) : (
                                  ""
                                )}
                              </div>
                            </th>

                            <th className="sorting perFields">
                              <div className="d-flex m-0 p-0">
                                <span
                                  className="float-start"
                                  style={{ paddingRight: "10px" }}
                                >
                                  {" "}
                                  <i
                                    class="fas fa-sort"
                                    onClick={() => {
                                      handleClick2("effort", !sortAsc);
                                    }}
                                  ></i>
                                  Effort
                                </span>
                                {filterHistoryTaskIcon ? (
                                  <Dropdown
                                    // className="setDropDownposition float-end"
                                    title="Filter"
                                    // style={{ right: "21%" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setHistoryTaskEffortFieldOpen(
                                        !HistoryTaskEffortFieldOpen
                                      );
                                    }}
                                  >
                                    <Dropdown.Toggle
                                      style={{
                                        background: "none",
                                        border: "none",
                                      }}
                                      className="m-0 p-0"
                                    >
                                      <i
                                        className="fas fa-filter fs-12"
                                        style={{
                                          cursor: "pointer",
                                          color: HistoryTaskEffortFilter
                                            ? "#DC5D74"
                                            : "#898fa5",
                                        }}
                                      ></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {
                                        <input
                                          type="number"
                                          name="name"
                                          className="figma-input p m-0 p-2"
                                          placeholder="Enter task effort"
                                          autocomplete="off"
                                          value={HistoryTaskEffortFilter}
                                          onChange={(e) =>
                                            setHistoryTaskEffortFilter(
                                              e.target.value
                                            )
                                          }
                                          style={{
                                            border: "1px solid black",
                                            width: "150px",
                                          }}
                                        />
                                      }
                                    </Dropdown.Menu>
                                  </Dropdown>
                                ) : (
                                  ""
                                )}
                              </div>
                            </th>
                            {/* <th className="sorting perFields">Deadline</th> */}
                            <th className="sorting perFields">
                              <div className="d-flex m-0 p-0">
                                <span
                                  className="float-start"
                                  style={{ paddingRight: "10px" }}
                                >
                                  Task status
                                </span>
                                {filterHistoryTaskIcon ? (
                                  <Dropdown
                                    // className="setDropDownposition float-end"
                                    title="Filter"
                                    // style={{ right: "12%" }}
                                  >
                                    <Dropdown.Toggle
                                      style={{
                                        background: "none",
                                        border: "none",
                                      }}
                                      className="m-0 p-0"
                                    >
                                      <i
                                        className="fas fa-filter fs-12"
                                        style={{
                                          cursor: "pointer",
                                          color:
                                            selectedHistoryTaskScheduleStatus
                                              ? "#DC5D74"
                                              : "#898fa5",
                                        }}
                                      ></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {Taskschedulestatus?.map((scope, i) => (
                                        <Dropdown.Item
                                          style={
                                            selectedHistoryTaskScheduleStatus ===
                                            scope.value
                                              ? { backgroundColor: "#B8E5F1" }
                                              : { backgroundColor: "#ffffff" }
                                          }
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setselectedHistoryTaskScheduleStatus(
                                              scope.value
                                            );
                                          }}
                                          key={i}
                                          className="p fs-14"
                                        >
                                          {scope?.name}
                                        </Dropdown.Item>
                                      ))}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                ) : (
                                  ""
                                )}
                              </div>
                            </th>
                            <th className="sorting perField  ">
                              <div className="d-flex m-0 p-0">
                                <span className="float-start">
                                  {" "}
                                  Task success{" "}
                                </span>
                                {filterHistoryTaskIcon ? (
                                  <Dropdown
                                    // className="setDropDownposition float-end"
                                    title="Filter"
                                    // style={{ right: "2%" }}
                                  >
                                    <Dropdown.Toggle
                                      style={{
                                        background: "none",
                                        border: "none",
                                      }}
                                      className="m-0 p-0"
                                    >
                                      <i
                                        className="fas fa-filter fs-12"
                                        style={{
                                          cursor: "pointer",
                                          color: selectedHistoryTaskStatus
                                            ? "#DC5D74"
                                            : "#898fa5",
                                        }}
                                      ></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {Taskstatus?.map((scope, i) => (
                                        <Dropdown.Item
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setselectedHistoryTaskStatus(
                                              scope.value
                                            );
                                          }}
                                          style={
                                            selectedHistoryTaskStatus ===
                                            scope.value
                                              ? { backgroundColor: "#B8E5F1" }
                                              : { backgroundColor: "#ffffff" }
                                          }
                                          key={i}
                                          className="p fs-14"
                                        >
                                          {scope?.name}
                                        </Dropdown.Item>
                                      ))}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                ) : (
                                  ""
                                )}{" "}
                              </div>
                            </th>
                            <th>Mark as incomplete</th>
                          </tr>
                        </thead>
                        <tbody
                          style={
                            showHistoryTableSpinner
                              ? { background: "#b3b5b6", zIndex: "-1" }
                              : {}
                          }
                        >
                          {historyTaskList &&
                            historyTaskList.map((task, index) => (
                              <tr
                                role="row"
                                key={index}
                                style={
                                  taskKey === task?.id
                                    ? { backgroundColor: "#faeabb" }
                                    : {}
                                }
                                className={
                                  task.task_success === "Miss"
                                    ? "bgdanger"
                                    : task.on_time_status === "On time" ||
                                      (task.on_time_status === "Ok" &&
                                        task.task_success === "Hit")
                                    ? "bgsuccess"
                                    : task.on_time_status === "Late" &&
                                      task.task_success === "Hit"
                                    ? // ||
                                      // task.task_success === "Hit"
                                      "bgwarning"
                                    : ""
                                }
                              >
                                <td
                                  onClick={(event) => {
                                    setTaskDetailsinfo(task);
                                  }}
                                  style={{
                                    border: 2,
                                    borderColor: "white",
                                    borderStyle: "solid",
                                  }}
                                  className="sorting_1 p"
                                >
                                  {task.task_owner.first_name[0]?.toUpperCase()}
                                  {task.task_owner.last_name[0]?.toUpperCase()}
                                </td>
                                <td
                                  onClick={(event) => {
                                    setTaskDetailsinfo(task);
                                  }}
                                  style={{
                                    border: 2,
                                    borderColor: "white",
                                    borderStyle: "solid",
                                  }}
                                  className="p fs-14"
                                >
                                  {task.task_type}
                                </td>
                                <td
                                  onClick={(event) => {
                                    setTaskDetailsinfo(task);
                                  }}
                                  style={{
                                    border: 2,
                                    borderColor: "white",
                                    borderStyle: "solid",
                                  }}
                                >
                                  {task.goal === null ? "-" : task?.goal?.name}
                                </td>
                                <td
                                  onClick={(event) => {
                                    setTaskDetailsinfo(task);
                                  }}
                                  style={{
                                    border: 2,
                                    borderColor: "white",
                                    borderStyle: "solid",
                                  }}
                                  className="p fs-14"
                                >
                                  {task.name}
                                </td>
                                <td
                                  onClick={(event) => {
                                    setTaskDetailsinfo(task);
                                  }}
                                  style={{
                                    border: 2,
                                    borderColor: "white",
                                    borderStyle: "solid",
                                  }}
                                  className="p"
                                >
                                  {task.task_impact}
                                </td>
                                <td
                                  onClick={(event) => {
                                    setTaskDetailsinfo(task);
                                  }}
                                  style={{
                                    border: 2,
                                    borderColor: "white",
                                    borderStyle: "solid",
                                  }}
                                  className="p"
                                >
                                  {task?.task_control}
                                </td>
                                <td
                                  onClick={(event) => {
                                    setTaskDetailsinfo(task);
                                  }}
                                  style={{
                                    border: 2,
                                    borderColor: "white",
                                    borderStyle: "solid",
                                  }}
                                  className="p"
                                >
                                  {task?.risk_status}
                                </td>
                                <td
                                  onClick={(event) => {
                                    setTaskDetailsinfo(task);
                                  }}
                                  style={{
                                    border: 2,
                                    borderColor: "white",
                                    borderStyle: "solid",
                                  }}
                                  className="p"
                                >
                                  {task.task_effort} H
                                </td>

                                <td
                                  onClick={(event) => {
                                    setTaskDetailsinfo(task);
                                  }}
                                  style={{
                                    border: 2,
                                    borderColor: "white",
                                    borderStyle: "solid",
                                  }}
                                  className={
                                    task.on_time_status === "Ok" ||
                                    (task.on_time_status === "On time" &&
                                      task.task_success === "Miss")
                                      ? "bgwarning"
                                      : task.on_time_status === "Late" &&
                                        task.task_success === "Hit"
                                      ? "bgdanger"
                                      : ""
                                  }
                                >
                                  {task.on_time_status}
                                </td>

                                <td
                                  onClick={(event) => {
                                    setTaskDetailsinfo(task);
                                  }}
                                  style={{
                                    border: 2,
                                    borderColor: "white",
                                    borderStyle: "solid",
                                  }}
                                  className={
                                    task.task_success === "Miss"
                                      ? "bgdanger"
                                      : (task.task_success === "Hit" &&
                                          task.on_time_status === "Late") ||
                                        task.on_time_status === "Ok"
                                      ? "bgsuccess"
                                      : task.task_success === "Hit" &&
                                        task.on_time_status === "Late"
                                      ? "bgWarning"
                                      : ""
                                  }
                                >
                                  {task.task_success === "Beat"
                                    ? "Epic"
                                    : task.task_success === "Hit"
                                    ? "Ok"
                                    : "Miss"}
                                </td>
                                <td
                                  style={{
                                    border: 2,
                                    borderColor: "white",
                                    borderStyle: "solid",
                                  }}
                                >
                                  <div className="d-flex justify-content-center todo-button ">
                                    <button
                                      to=""
                                      title="Mark as incomplete"
                                      className="outline-primary btn btn-primary me-2"
                                      onClick={(event) => {
                                        handleMarkAsIncomplte(event, task);
                                      }}
                                    >
                                      <i className="fas fa-times-circle"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {historyTaskList?.length === 0 &&
                        !showHistoryTableSpinner && (
                          <Row className="p-0 m-0 ">
                            <span
                              className="th text-center "
                              style={{ top: "-25px", position: "relative" }}
                            >
                              No records found.
                            </span>
                          </Row>
                        )}
                    </div>
                  </div>
                  <Row className="pt-3 border-0">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div>
                        <label htmlFor="numberSelector">
                          Rows per page &nbsp;
                        </label>
                        <input
                          type="number"
                          list="numberOptions"
                          value={inputHistory}
                          onChange={handleHistoryChange}
                          className="form-control pagination-row-input"
                          placeholder=""
                          style={{
                            width: "auto",
                            display: "inline-block",
                            maxWidth: "100px",
                            background: "#fff",
                            border: "0.0625rem solid #d7dae3",
                            padding: "0.3125rem 1.25rem",
                            color: "#6e6e6e",
                            borderRadius: "0rem",
                            height: "45px",
                          }}
                        />
                        <datalist id="numberOptions">
                          {numberOptions.map((number) => (
                            <option key={number} value={number} />
                          ))}
                        </datalist>
                      </div>
                      {paginationHistory && (
                        <Pagination
                          pageCount={pageHistoryCount}
                          pageIndex={goToHistoryPage}
                          gotoPage={setGoToHistoryPage}
                        />
                      )}
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Tasks;
