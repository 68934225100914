import { Card, Flex, Button, Typography } from "antd";
import React from "react";

import blogPost2 from "../../../../../../../src/images/blogs/blogPost2.svg";

import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

const { Text } = Typography;

const BlogCard2 = () => {
  const screens = useBreakpoint();
  return (
    <Card
      hoverable
      cover={
        <img alt="example" src={blogPost2} style={{ height: "15.75rem" }} />
      }
      style={{
        flex: "1",
        display: "flex",
        flexDirection: "column",
      }}
      styles={{
        body: {
          flex: 1,
          display: "flex",
          flexDirection: "column",
          padding: !screens.md
            ? "1.5rem 1.25rem 1.25rem 1.25rem"
            : "1.5rem 1.75rem",
        },
      }}
    >
      <Flex vertical style={{ flex: 1, gap: "0.5rem" }}>
        <Text className="blogcard-heading">
          How Plansom Helps You Turn Big Ideas Into Clear, Actionable Steps
        </Text>
        <Text className="qasubsec-text" style={{ flexGrow: 1 }}>
          Let’s face it—great ideas are easy to come by, but turning them into
          tangible results? That’s where things often stall. You start with
          enthusiasm, but soon the details pile up, and you’re left asking:
        </Text>
        <Button
          className="f-inter fw-600"
          style={{
            borderRadius: "2rem",
            marginTop: !screens.md && "0.5rem",
            height: "2.5rem",
            fontSize: "1rem",
            width: "100%", // Full width of the card
            textAlign: "center", // Centers the text inside the button
          }}
        >
          Read More
        </Button>
      </Flex>
    </Card>
  );
};

export default BlogCard2;
