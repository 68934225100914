import React from "react";
import { Layout } from "antd";

const { Content } = Layout;

const BlogsContent = ({ children }) => {
  return (
    <Content
      style={{
        marginBottom: "3rem",
        height: "100vh",
        overflow: "auto",
        overflowX: "hidden",
      }}
    >
      {children}
    </Content>
  );
};

export default BlogsContent;
