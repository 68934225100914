import React from 'react'
import { Row, Col, Layout } from "antd";
import BlogsHeader from './components/BlogsHeader';
import BlogsContent from './components/BlogsContent';

const BlogLayout = ({children}) => {
  return (
    <Layout
    className="aboutus-component"
    style={{
      width: "100vw",
      height: "100%",
      position: "fixed",
    }}
  >
    <Row>
      <Col span={24}>
        <BlogsHeader/>
        <BlogsContent>
          {children}
        </BlogsContent>
      </Col>
    </Row>
  </Layout>
  )
}

export default BlogLayout
