import React, { useState, useEffect, lazy, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col, Dropdown, Modal } from "react-bootstrap";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTask,
  editTask,
  taskFeedback
} from "../../../services/TaskService";
import {
  getOrgMembers,
  getTeamMemByTeamId
} from "../../../store/actions/TeamAction";
import {
  getGoalByScope,
  deleteGoalAction,
  editGoalAction,
  createGoalAction,
  resetGoalPage
} from "../../../store/actions/GoalsActions";
import { getUserdetail } from "../../../../src/store/actions/AuthActions";
import {
  getSubGoals,
  getAllGoalsByOrgID,
  getTasksByGoal
} from "../../../services/GoalService";
import Pagination from "../../components/Pagination/Pagination";
import Vector3 from "../../../images/vector/Vector3.png";
import { getThreadList } from "../../../store/actions/NotificationAction";
import LoadingSpinner from "../Component/LoadingSpinner";
import { getGoalById } from "../../../services/GoalService";
import { archieveGoal } from "../../../services/GoalService";
import ShareModal from "../Component/ShareModal";
import ShareTaskModal from "../Component/ShareTaskModal";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import ReactDOM from "react-dom";

import { useFormik } from "formik";
import * as Yup from "yup";
import { createTaskService } from "../../../services/TaskService";

import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ProgressBar from "react-bootstrap/ProgressBar";
import "bootstrap/dist/css/bootstrap.min.css";
import { Card } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { goalNavigationManage } from "../../../store/actions/TaskActions";
import { Button, Stack } from "@mui/material";
import { FaPlus } from "react-icons/fa6";
import { goalEditManage } from "../../../store/actions/TaskActions";
import { plannedGoalMyDay } from "../../../store/actions/TaskActions";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { markTaskAsIncomplete } from "../../../services/TaskService";
import { TextField } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const CreateTaskModal = lazy(() => import("../Component/CreateTaskModal"));
const GoalModal = lazy(() => import("../Component/GoalModal"));
const TaskEdit = lazy(() => import("./TaskEdit"));

const Goals = () => {
  const location = useLocation();
  const [ShowGoalModal, setShowGoalModal] = useState(
    false || location?.state?.key
  );
  const [CreateTask, setCreateTask] = useState(false);
  const stateData = useSelector((state) => state);
  const stateDataCopy = useSelector((state) => state?.team);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const userDetail = localStorage.getItem("userDetails");
  const orgId = parseInt(localStorage.getItem("organization_id"), 10);
  const scope = localStorage.getItem("scope");
  const [scopeOfTime, setScopeOfTime] = useState(localStorage.getItem("scope"));
  const timescope = localStorage.getItem("timescope");
  const [firstTimeScope, setFirstTimeScope] = useState("1 month");
  const [firstScope, setFirstScope] = useState("organization");
  const teamId = localStorage.getItem("team_id");
  const [teamList, getTeamDetails] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(teamId);
  const [showTaskMoadal, setshowTaskMoadal] = useState(false);
  const [options, setOptions] = useState([]);
  const [taskList, getTasksDetails] = useState();
  const [createSubGoal, setCreateSubGoal] = useState(false);
  const [goals, setGoals] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState([location?.state?.goal]);
  const [selectedGoals, setSelectedGoals] = useState([location?.state?.goal]);

  const [assignGoalModal, setAssignGoalEditModal] = useState(false);
  const [goalDetails, setGoalDetails] = useState(location?.state?.goal);
  const userInfoDetails = JSON.parse(userDetail);
  const userRole = userInfoDetails.data.user_role;
  const [goalOwner, setGoalOwnerValue] = useState("");
  const [goalsdelete, setGoalsdelete] = useState(false);
  const [goalsupdate, setGoalsupdate] = useState(false);
  const [goalscreated, setGoalscreated] = useState(false);
  const [taskdelete, setTaskdelete] = useState(false);
  const [taskupdate, setTaskupdate] = useState(false);
  const [taskcreated, setTaskcreated] = useState(false);
  const [parentGoalId, setParentGoalId] = useState("");
  const [allSubGoals, setAllSubGoals] = useState();
  const [EditTask, setEditTask] = useState(false || location?.state?.key);
  const [OpenDeleteModal, SetOpenDeleteModal] = useState(false);
  const [deleteParentGoal, setDeleteParentGoal] = useState(false);
  const [SelectedGoalDelete, setSelectedGoalDelete] = useState();
  const [SelectedTaskDelete, setSelectedTaskDelete] = useState();
  const [OpenWarningModal, setOpenWarningModal] = useState();
  const [scopes, setScopes] = useState(scope);
  const [toggleData, setToggleData] = useState(true);
  const [toggleTaskData, setToggleTaskData] = useState(true);
  const [taskOwner, setTaskOwnerValue] = useState("");
  let errorsObj = { goalName: "", goalOwner: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [createGoal, setCreateGoalForm] = useState({
    name: "",
    description: ""
  });
  const [showTableSpinner, setShowTableSpinner] = useState(true);
  const [showSubTableSpinner, setSubShowTableSpinner] = useState(true);
  const [showTaskTableSpinner, setTaskShowTableSpinner] = useState(true);
  const [AllGoals, setAllGoals] = useState([]);
  const [taskDetailsinfo, setTaskDetailsinfo] = useState();
  const [FileErrorMessage, setFileErrorMessage] = useState("");
  const [goToPage, setGoToPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [pagination, setPagination] = useState(false);
  const [goToSubGoalPage, setGoToSubGoalPage] = useState(0);
  const [subGoalPagination, setSubGoalPagination] = useState(false);
  const [subGoalpageCount, setSubGoalPageCount] = useState(1);
  const [goToTaskPage, setGoTotaskPage] = useState(0);
  const [taskPagination, setTaskPagination] = useState(false);
  const [taskPageCount, setTaskPageCount] = useState(1);
  const [sortAsc, setSortAsc] = useState(false);
  const [sortBy, setSortBy] = useState();
  const [sortOrder, setsortOrder] = useState();
  const [goalNameFilter, setGoalNameFilter] = useState();
  const [GoalInputFieldOpen, setGoalInputFieldOpen] = useState(false);
  const [selectedStatusItem, setSelectedStatusItem] = useState();
  const [selectedSubStatusItem, setSelectedSubStatusItem] = useState();
  const [subGoalNameFilter, setSubGoalNameFilter] = useState();
  const [subGoalInputFieldOpen, setSubGoalInputFieldOpen] = useState(false);
  const [selectedTaskStatusItem, setSelectedTaskStatusItem] = useState();
  const [selectedTaskTypeItem, setSelectedTaskTypeItem] = useState();
  const [selectedTaskImpactItem, setSelectedTaskImpactItem] = useState();
  const [selectedTaskControl, setSelectedTaskControl] = useState();
  const [selectedTaskRisk, setSelectedTaskRisk] = useState();
  const [taskNameFilter, setTaskNameFilter] = useState();
  const [taskEffortFilter, setTaskEffortFilter] = useState();
  const [taskEffortFilterForShow, setTaskEffortFilterForShow] = useState();
  const [taskInputFieldOpen, setTaskInputFieldOpen] = useState(false);
  const [taskOwnerFieldOpen, setTaskOwnerFieldOpen] = useState(false);
  const [taskWonerFilter, setTaskOwnerFilter] = useState();
  const [goalOwnerFilter, setGoalOwnerFilter] = useState();
  const [subGoalOwnerFilter, setSubGoalOwnerFilter] = useState();
  const [taskEffortFieldOpen, setTaskEffortFieldOpen] = useState();
  const [scheduledTaskDueInFieldOpen, setScheduledTaskDueInFieldOpen] =
    useState(false);
  const [scheduledTaskDueInFilter, setScheduledTaskDueInFilter] = useState();
  const [dropdownHeight, setdropdownHeight] = useState(false);
  const [filterIcon, setFilterIcon] = useState(true);
  const [filterSubGoalIcon, setFilterSubGoalIcon] = useState(true);
  const [filterTaskIcon, setFiltertaskIcon] = useState(true);
  const [isSubGoalCreated, setIsSubGoalCreated] = useState(false);
  const [isTaskCreated, setIsTaskCreated] = useState(false);
  const [isTaskUpdated, setIsTaskUpdated] = useState(false);
  const [isTaskDeleted, setIsTaskDeleted] = useState(false);

  const [CompleteStartRange, setCompleteStartRange] = useState();
  const [CompleteEndRange, setCompleteEndRange] = useState();
  const [WinsStartRange, setWinsStartRange] = useState();
  const [WinsEndRange, setWinsEndRange] = useState();
  const [OntimeStartRange, setOntimeStartRange] = useState();
  const [OntimeEndRange, setOntimeEndRange] = useState();

  const [RiskStartRange, setRiskStartRange] = useState();
  const [RiskEndRange, setRiskEndRange] = useState();

  const [subCompleteStartRange, setSubCompleteStartRange] = useState();
  const [subCompleteEndRange, setSubCompleteEndRange] = useState();
  const [subWinsStartRange, setSubWinsStartRange] = useState();
  const [subWinsEndRange, setSubWinsEndRange] = useState();
  const [subOntimeStartRange, setSubOntimeStartRange] = useState();
  const [subOntimeEndRange, setSubOntimeEndRange] = useState();

  const [subGoalHide, setSubGoalHide] = useState(false);
  const [taskHide, setTaskHide] = useState(false);

  const [selectedShareGoal, setSelectedShareGoal] = useState();
  const [shareModel, setShareModal] = useState(false);

  const [shareTaskModal, setShareTaskModal] = useState(false);
  const [selectedShareTask, setSelectedShareTask] = useState();
  const [riskFilter, setRiskFilter] = useState();
  const [order, setOrder] = useState("");

  const [subRiskFilter, setSubRiskFilter] = useState();
  const [statusFilter, setStatusFilter] = useState();
  const [subStatusFilter, setSubStatusFilter] = useState();
  const [editableGoal, setEditableGoal] = useState([]);
  const [rowAddGoals, setRowAddGoals] = useState(false);

  const [rowAddGoals1, setRowAddGoals1] = useState(false);

  const [validationMessages, setValidationMessages] = useState("");
  const [taskStatuses, setTaskStatuses] = useState({});
  const [markAsStatus, setMarkAsStatus] = useState("");
  const [taskStatus, setTaskStatusValue] = useState({ value: "", label: "" });
  const [taskActiveInactiveStatus, setTaskActiveInactiveStatus] = useState();
  const [taskCreater, setTaskCreatorValue] = useState("");
  const [taskImpact, setTaskImpactValue] = useState({
    value: "medium",
    label: "Medium"
  });
  const [schedulesTasksList, setScheduledTasks] = useState("");
  const [hideDataManageNavigation, setHideDataManageNavigation] =
    useState(false);

  const statusOption = [
    { value: "beat", label: "Epic" },
    { value: "hit", label: "OK" },
    { value: "miss", label: "Miss" },
    { value: "not_complete", label: "Not Complete" }
  ];

  const [taskStatusOptions, settaskStatusOptions] = useState(statusOption);

  const [openWarningModalForActive, setOpenWarningModalForActive] =
    useState(false);
  const [selectedTaskForActive, setSelecteTaskForActive] = useState();

  const [openWarningModalForGoalActive, setOpenWarningModalForGoalActive] =
    useState(false);
  const [selectedGoalForActive, setSelecteGoalForActive] = useState();

  const [
    openWarningModalForSubGoalActive,
    setOpenWarningModalForSubGoalActive
  ] = useState(false);
  const [selectedSubGoalForActive, setSelecteSubGoalForActive] = useState();
  const [filterScheduleTaskIcon, setFilterScheduleTaskIcon] = useState(true);
  const [inputValue, setInputValue] = useState(
    localStorage.getItem("goals_row_per_page") || "10"
  );
  const [inputSubgoalValue, setInputSubgoalValue] = useState(
    localStorage.getItem("subgoals_row_per_page") || "10"
  );
  const [inputTaskValue, setInputTaskValue] = useState(
    localStorage.getItem("tasks_row_per_page") || "10"
  );
  // const inputTaskValue = "10";

  const numberOptions = [5, 10, 25, 50, 100];

  const outComeOption = [
    {
      name: "Epic",
      value: "Beat"
    },
    {
      name: "Ok",
      value: "Hit"
    },
    {
      name: "Miss",
      value: "Miss"
    },
    {
      name: "Not Complete",
      value: "Not Complete"
    }
  ];

  const [createTask, setCreateTaskForm] = useState({
    task_name: "",
    description: "",
    task_effort: ""
  });

  const ObjectiveRisk = [
    {
      name: "Low",
      value: "Low"
    },
    {
      name: "Medium",
      value: "Medium"
    },
    {
      name: "High",
      value: "High"
    }
  ];

  const StatusFilter = [
    {
      name: "Active",
      value: "Active"
    },
    {
      name: "Inactive",
      value: "Inactive"
    }
  ];

  const SubstatusFilter = [
    {
      name: "Active",
      value: "Active"
    },
    {
      name: "Inactive",
      value: "Inactive"
    }
  ];

  // Get the current URL

  const filterName = [
    {
      name: "On track",
      value: "on_track"
    },
    {
      name: "At risk",
      value: "at_risk"
    },
    {
      name: "Failed",
      value: "failed"
    },
    {
      name: "Failing",
      value: "failing"
    }
  ];

  const tasktype = [
    {
      name: "Game changers",
      value: "Game changers"
    },
    {
      name: "Support",
      value: "Support"
    },
    {
      name: "Quick wins",
      value: "Quick wins"
    }
    // {
    //   name: "Thankless",
    //   value: "Thankless",
    // },
    // {
    //   name: "Black hole",
    //   value: "Black hole",
    // },
    // {
    //   name: "Low hanging fruit",
    //   value: "Low hanging fruit",
    // },
    // {
    //   name: "Game changer",
    //   value: "Game changer",
    // },
    // {
    //   name: "Core",
    //   value: "Core",
    // },
    // {
    //   name: "Hard-core",
    //   value: "Hard-core",
    // },
    // {
    //   name: "Support",
    //   value: "Support",
    // },
    // {
    //   name: "Time sink",
    //   value: "Time sink",
    // },
  ];

  const TaskImpact = [
    {
      name: "Low",
      value: "Low"
    },
    {
      name: "Medium",
      value: "Medium"
    },
    {
      name: "High",
      value: "High"
    }
  ];

  const TaskControl = [
    {
      name: "Low",
      value: "Low"
    },
    {
      name: "Medium",
      value: "Medium"
    },
    {
      name: "High",
      value: "High"
    }
  ];

  const TaskRisk = [
    {
      name: "Low",
      value: "Low"
    },
    {
      name: "Medium",
      value: "Medium"
    },
    {
      name: "High",
      value: "High"
    }
  ];

  const TaskStatus = [
    { value: "Epic", name: "Beat" },
    { value: "Ok", name: "Hit" },
    { value: "Miss", name: "Miss" }
  ];

  const [inputVisible, setInputVisible] = useState(false);
  const [visibleInputIndex, setVisibleInputIndex] = useState(null);
  const [inputValueForSrNo, setInputValueForSrNo] = useState(0);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const formik = useFormik({
    initialValues: {
      task_name: "",
      task_effort: "",
      task_owner: "",
      task_impact: { value: "medium", label: "Medium" },
      task_control: { value: "medium", label: "Medium" },
      task_success: { value: "", label: "" },
      goal_align: ""
    },
    validationSchema: Yup.object({
      task_name: Yup.string().required("*Task name is required"),
      task_effort: Yup.string().required("*Task effort is required")
    }),
    onSubmit: (values, { setSubmitting, setFieldValue, resetForm }) => {
      const [hours, minutes] = values?.task_effort.split(":").map(Number);
      const decimalMinutes = (minutes / 60).toFixed(2).slice(1);
      const fieldValue = parseFloat(hours + decimalMinutes);

      const createTask = {
        task_name: values?.task_name,
        // description: "",
        task_effort: fieldValue
      };
      const data = createTaskService(
        orgId,
        userInfoDetails.data.id,
        values?.task_owner?.userId,
        createTask,
        values?.task_impact?.value,
        values?.task_control?.value,
        goalDetails?.status,
        { id: goalDetails?.id },
        "",
        userInfoDetails.data.accessToken
      );
      // setSpinner(true);
      data?.then(async (res) => {
        getTasksByGoalId(goToTaskPage);
        toast(
          <>
            <div className="m-1 d-flex">
              <i
                class="bi bi-check-circle fs-26 me-3"
                style={{ color: "#131F4C" }}
              ></i>
              <div>
                <h2
                  style={{
                    // color: "#131F4C",
                    fontSize: "22px"
                  }}
                >
                  Completed
                </h2>
                <h3
                  style={{
                    // color: "#131F4C",
                    fontSize: "18px"
                  }}
                >
                  {res?.data?.message}
                </h3>
              </div>
            </div>
          </>,
          {
            toastId: "task-schedule",
            className: "toast-success-container toast-success-container-after "
          }
        );
        if (res?.data?.message === "Tasks created successfully") {
          resetForm();
        }
      });
      data?.catch(async (err) => {
        toast(
          <>
            <div className="m-1 d-flex">
              <i
                class="bi bi-check-circle fs-26 me-3"
                style={{ color: "#131F4C" }}
              ></i>
              <div>
                <h2
                  style={{
                    fontSize: "22px"
                  }}
                >
                  Failed
                </h2>
                <h3
                  style={{
                    fontSize: "18px"
                  }}
                >
                  {err?.response?.data?.message}
                </h3>
              </div>
            </div>
          </>,
          {
            toastId: "task-create",
            className: "toast-error-container toast-error-container-after "
          }
        );
      });
      // }
      setSubmitting(false);
    }
  });

  const inputRef = useRef(null);

  const handleInputChangeSrNo = (e, index, goal) => {
    // setInputValueForSrNo(goal?.oder);
    const newValue = e.target.value;
    setInputValueForSrNo(e.target.value);
  };
  useEffect(() => {
    const storedGoal = localStorage.getItem("selectedGoal");
    if (storedGoal) {
      try {
        const parsedGoal = JSON.parse(storedGoal);
        if (
          Array.isArray(parsedGoal) &&
          parsedGoal.length > 0 &&
          typeof parsedGoal[0] === "object"
        ) {
          setSelectedGoal(parsedGoal);
        }
      } catch (error) {
        console.error("Failed to parse storedGoal:", error);
        localStorage.removeItem("selectedGoal");
      }
    }
  }, []);

  // Update localStorage whenever selectedGoal changes
  useEffect(() => {
    if (
      Array.isArray(selectedGoal) &&
      selectedGoal.length > 0 &&
      typeof selectedGoal[0] === "object"
    ) {
      localStorage.setItem("selectedGoal", JSON.stringify(selectedGoal));
    }
  }, [selectedGoal]);

  const handleHideVisibleField = (e, goal) => {
    e.preventDefault();
    // setInputValueForSrNo(inputValueForSrNo)
    dispatch(
      editGoalAction(
        orgId,
        goalOwner.userId,
        selectedTeam,
        goal,
        goal,
        // scopes,
        parentGoalId?.id,
        userInfoDetails.data.accessToken,
        false,
        inputValueForSrNo
      )
    );

    setInputVisible(false);
    setVisibleInputIndex(null);
  };


  useEffect(() => {
    if (inputVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputVisible]);

  // const handleBlur = () => {
  //   setInputVisible(false);

  // };
  useEffect(() => {
    if (stateData.goal.isResetGoalPage) {
      setGoToPage(0);
      setSelectedGoal([]);
      setSelectedGoals([]);
      dispatch(resetGoalPage(false));
    }
  }, [stateData.goal.isResetGoalPage]);

  const handleTdClick = (index, goal) => {
    setInputValueForSrNo(goal?.order);
    setInputVisible(true);
    setVisibleInputIndex(index);
  };

  const [inputVisibleSubObjective, setInputVisibleSubObjective] =
    useState(false);
  const [visibleInputIndexSubObjective, setVisibleInputIndexSubObjective] =
    useState(null);
  const [inputValueForSrNoSubObjective, setInputValueForSrNoSubObjective] =
    useState(0);
  const [isToggled, setIsToggled] = useState(true);

  const handleToggleChange = (event) => {
    setIsToggled(event.target.checked);

    // Here you can add additional logic, for example, making an API call to update the status
    // handleFormChange(event); // If you have this function to handle form changes
    // handleToggless(goal); // If this function is used for toggling
  };

  const handleInputChangeSrNoSubObjective = (e, index, goal) => {
    // setInputValueForSrNo(goal?.oder);
    const newValue = e.target.value;
    setInputValueForSrNoSubObjective(e.target.value);
  };

  const handleHideVisibleFieldSubObjective = (e, goal) => {
    e.preventDefault();
    // setInputValueForSrNo(inputValueForSrNo)
    dispatch(
      editGoalAction(
        orgId,
        goalOwner.userId,
        selectedTeam,
        goal,
        goal,
        // scopes,
        parentGoalId?.id,
        userInfoDetails.data.accessToken,
        false,
        inputValueForSrNoSubObjective
      )
    );

    setInputVisibleSubObjective(false);
    setVisibleInputIndexSubObjective(null);
  };

  useEffect(() => {
    if (inputVisibleSubObjective && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputVisibleSubObjective]);

  // const handleBlur = () => {
  //   setInputVisible(false);

  // };

  const handleTdClickSubObjective = (index, goal) => {
    setInputValueForSrNoSubObjective(goal?.order);
    setInputVisibleSubObjective(true);
    setVisibleInputIndexSubObjective(index);
  };

  useEffect(() => {
    setScopeOfTime(localStorage.getItem("scope"));
  }, [localStorage.getItem("scope")]);

  useEffect(() => {
    getGoalsById();
  }, []);

  const getGoalsById = () => {
    var currentUrl = window.location.href;

    // Use a regular expression to extract the value of goal_id from the URL
    var match = currentUrl.match(/goal_id=([^&]*)/);

    // Check if a match is found
    //  if (match) {
    //      // Extracted goal_id value
    //      var goalId = match[1];
    //  } else {
    //  }
    let goalId;
    // Check if a match is found
    if (match) {
      // Extracted goal_id value
      goalId = match[1];
    } else {
      if (selectedGoal[0]?.goal_parent?.id) {
        goalId = selectedGoal[0]?.goal_parent?.id;
      } else {
        goalId = selectedGoal[0]?.id;
      }
    }
    const data = getGoalById(goalId, userInfoDetails?.data?.accessToken);
    data?.then(async (res) => {
      // if (hideDataManageNavigation) {

      // }
      setGoalDetails(res?.data?.data);
      // dispatch(goalEditManage(false))
      if (
        stateData?.goal?.successMessage === "Goal Data updated successfully"
      ) {
        if (selectedGoal[0]?.goal_parent == null) {
          setSelectedGoal([res?.data?.data]);
          setSelectedGoals([res?.data?.data]);
        } else {
          const subGoalData = getSubGoals(
            selectedGoal[0]?.goal_parent?.id,
            userInfoDetails.data.accessToken,
            "",
            sortAsc,
            "",
            "",
            "",
            subCompleteStartRange,
            subCompleteEndRange,
            subWinsStartRange,
            subWinsEndRange,
            subOntimeStartRange,
            subOntimeEndRange,
            subRiskFilter,
            subStatusFilter,
            "",
            parseInt(inputSubgoalValue)
          );
          subGoalData.then(async (res) => {
            const matchedGoal = res?.data?.data?.find(
              (goal) => goal.id === selectedGoal[0]?.id
            );
            if (matchedGoal) {
              setSelectedGoal([matchedGoal]);
              setSelectedGoals([matchedGoal]);
            }
          });
          subGoalData.catch(async (res) => { });
        }
      } else {
        setSelectedGoal([res?.data?.data]);
        setSelectedGoals([res?.data?.data]);
      }
      setHideDataManageNavigation(false);
    });
    data?.catch(async (err) => {
      if (err?.response?.data?.message === "You don't have access to this goal") {
        getAllOrgGoal();
        setSelectedGoal([]);
        setSelectedGoals([]);
      }
    });
  };

  const updateSelectedGoalAfterToggle = () => {
    const data = getGoalById(
      selectedGoal[0]?.id,
      userInfoDetails?.data?.accessToken
    );
    data?.then(async (res) => {
      setSelectedGoal([res?.data?.data]);
      setSelectedGoals([res?.data?.data]);
      setIsSubGoalCreated(false);
    });
  };

  const sorting = (col) => {
    if (order === "ASC" || order === "") {
      const sorted = [...goals].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setGoals(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...goals].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setGoals(sorted);
      setOrder("ASC");
    }
  };

  const sortingSubRisk = (col) => {
    if (order === "ASC" || order === "") {
      const sorted = [...allSubGoals].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setAllSubGoals(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...allSubGoals].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setAllSubGoals(sorted);
      setOrder("ASC");
    }
  };

  useEffect(() => {
    let receivedData = JSON.parse(localStorage.getItem("csvGoal"));
    navigate("/goals", { state: { goal: receivedData } });
    localStorage.removeItem("csvGoal");
  }, [window.location]);

  useEffect(() => {
    if (isTaskCreated) {
      setTaskcreated(true);
      setTimeout(() => {
        setTaskcreated(false);
        setIsTaskCreated(false);
      }, 1000);
    }
    if (isTaskUpdated) {
      setTaskupdate(true);
      setTimeout(() => {
        setIsTaskUpdated(false);
        setTaskupdate(false);
      }, 1000);
    }
    if (isTaskDeleted) {
      setTaskdelete(true);
      setTimeout(() => {
        setTaskdelete(false);
        setIsTaskDeleted(false);
      }, 1000);
    }
  }, [isTaskCreated || isTaskUpdated || isTaskDeleted || isTaskUpdated]);

  useEffect(() => {
    if (isTaskCreated) setTaskcreated(true);
    setTimeout(() => {
      setIsTaskCreated(false);
    }, 1000);
  }, [isTaskCreated]);

  useEffect(() => {
    setFilterIcon(filterIcon);
    setFilterSubGoalIcon(filterSubGoalIcon);
    setFiltertaskIcon(filterTaskIcon);
  }, [filterIcon, filterSubGoalIcon, filterTaskIcon]);

  // useEffect(() => {
  //   setShowGoalModal(location?.state?.key);
  // }, [location?.state?.key]);

  useEffect(() => {
    setGoalInputFieldOpen(GoalInputFieldOpen);
  }, [GoalInputFieldOpen]);

  useEffect(() => {
    setSubGoalInputFieldOpen(subGoalInputFieldOpen);
  }, [subGoalInputFieldOpen]);

  useEffect(() => {
    setTaskOwnerFieldOpen(taskOwnerFieldOpen);
    setTaskInputFieldOpen(taskInputFieldOpen);
    setTaskEffortFieldOpen(taskEffortFieldOpen);
  }, [taskInputFieldOpen, taskEffortFieldOpen, taskOwnerFieldOpen]);

  useEffect(() => {
    setTimeout(() => {
      setFileErrorMessage("");
    }, 4000);
  }, [FileErrorMessage]);

  useEffect(() => {
    getAllOrgGoal(
      goToPage,
      sortOrder,
      sortBy,
      goalNameFilter,
      selectedStatusItem,
      false,
      CompleteStartRange,
      CompleteEndRange,
      WinsStartRange,
      WinsEndRange,
      OntimeStartRange,
      OntimeEndRange,
      RiskStartRange,
      RiskEndRange,
      riskFilter,
      statusFilter
    );
  }, [
    orgId,
    scope,
    stateData?.fileUpload?.FileUploadId,
    goToPage,
    localStorage.getItem("FileUpload")
  ]);

  useEffect(() => {
    // if (selectedStatusItem || goalNameFilter ||CompleteStartRange|| CompleteEndRange, WinsStartRange, WinsEndRange, OntimeStartRange, OntimeEndRange
    //   ) {
    setGoToPage(() => 0);
    getAllOrgGoal(
      0,
      sortOrder,
      sortBy,
      goalNameFilter,
      selectedStatusItem,
      false,
      CompleteStartRange,
      CompleteEndRange,
      WinsStartRange,
      WinsEndRange,
      OntimeStartRange,
      OntimeEndRange,
      RiskStartRange,
      RiskEndRange,
      riskFilter,
      statusFilter,
      goalOwnerFilter
    );
    // }
  }, [
    goalNameFilter,
    selectedStatusItem,
    CompleteStartRange,
    CompleteEndRange,
    WinsStartRange,
    WinsEndRange,
    OntimeStartRange,
    OntimeEndRange,
    RiskStartRange,
    RiskEndRange,
    riskFilter,
    statusFilter,
    goalOwnerFilter
  ]);

  const clearAllGoalsFilter = (e) => {
    e.preventDefault();
    setGoalNameFilter(() => "");
    setSelectedStatusItem(() => "");
    setCompleteStartRange(() => "");
    setCompleteEndRange(() => "");
    setWinsStartRange(() => "");
    setWinsEndRange(() => "");
    setOntimeStartRange(() => "");
    setRiskStartRange(() => "");
    setRiskEndRange(() => "");
    setOntimeEndRange(() => "");
    getAllOrgGoal(0, sortOrder, sortBy, "", "");
    setRiskFilter(() => "");
    setStatusFilter(() => "");
    setGoalOwnerFilter(() => "");
  };

  useEffect(() => {
    if (selectedGoal[0]?.id) {
      getAllSubGoalData(
        selectedGoal[0]?.id,
        goToSubGoalPage,
        sortOrder,
        sortBy,
        subGoalNameFilter,
        selectedSubStatusItem,
        subCompleteStartRange,
        subCompleteEndRange,
        subWinsStartRange,
        subWinsEndRange,
        subOntimeStartRange,
        subOntimeEndRange,
        subRiskFilter,
        subStatusFilter,
        subGoalOwnerFilter
      );
    }
  }, [goToSubGoalPage]);

  useEffect(() => {
    if (selectedGoal[0]?.id) {
      setGoalDetails(goalDetails);
      setSelectedGoal(selectedGoal);
      setSelectedGoals(selectedGoals);
      setGoToSubGoalPage(() => 0);
      getAllSubGoalData(
        selectedGoal[0]?.id,
        0,
        sortOrder,
        sortBy,
        subGoalNameFilter,
        selectedSubStatusItem,
        subCompleteStartRange,
        subCompleteEndRange,
        subWinsStartRange,
        subWinsEndRange,
        subOntimeStartRange,
        subOntimeEndRange,
        subRiskFilter,
        subStatusFilter,
        subGoalOwnerFilter
      );
    }
  }, [
    subGoalNameFilter,
    selectedSubStatusItem,
    subCompleteStartRange,
    subCompleteEndRange,
    subWinsStartRange,
    subWinsEndRange,
    subOntimeStartRange,
    subOntimeEndRange,
    subRiskFilter,
    subStatusFilter,
    subGoalOwnerFilter
  ]);

  const clearAllSubGoalFilter = (e) => {
    e.preventDefault();
    setSubGoalNameFilter("");
    setSelectedSubStatusItem();
    setSubCompleteStartRange();
    setSubGoalOwnerFilter();
    setSubCompleteEndRange();
    setSubWinsStartRange();
    setSubWinsEndRange();
    setSubOntimeStartRange();
    setSubOntimeEndRange();
    setSubRiskFilter();
    setSubStatusFilter();
  };
  useEffect(() => {
    if (
      localStorage.getItem("previousLocation") === "/stripe" &&
      sessionStorage.getItem("strategyImported") === "false"
    ) {
      toast.info(
        <div className="m-1">
          <h3>Payment Pending for Strategy </h3>
        </div>,
        { toastId: "strategyPaymentFail" }
      );
      sessionStorage.removeItem("strategyImported");
    }
  }, []);

  useEffect(() => {
    if ((!showTaskMoadal || !EditTask || taskupdate) && selectedGoal[0]?.id)
      getTasksByGoalId(
        goToTaskPage,
        sortOrder,
        sortBy,
        taskNameFilter,
        selectedTaskTypeItem,
        selectedTaskImpactItem,
        selectedTaskStatusItem,
        taskEffortFilter,
        taskWonerFilter,
        selectedTaskControl,
        selectedTaskRisk,
        taskActiveInactiveStatus,
        scheduledTaskDueInFilter
      );
  }, [goToTaskPage, showTaskMoadal, EditTask, taskupdate]);

  useEffect(
    (e) => {
      if (selectedGoal[0]?.id) {
        setGoalDetails(goalDetails);
        setSelectedGoal(selectedGoal);
        setSelectedGoals(selectedGoals);
        getTasksByGoalId(
          0,
          sortOrder,
          sortBy,
          taskNameFilter,
          selectedTaskTypeItem,
          selectedTaskImpactItem,
          selectedTaskStatusItem,
          taskEffortFilter,
          taskWonerFilter,
          selectedTaskControl,
          selectedTaskRisk,
          taskActiveInactiveStatus,
          scheduledTaskDueInFilter
        );
      }
    },
    [
      taskNameFilter,
      selectedTaskTypeItem,
      selectedTaskImpactItem,
      taskActiveInactiveStatus,
      selectedTaskControl,
      selectedTaskRisk,
      selectedTaskStatusItem,
      taskEffortFilter,
      taskWonerFilter,
      scheduledTaskDueInFilter
    ]
  );

  const clearAllTaskFilter = (e) => {
    e.preventDefault();
    setSelectedTaskTypeItem();
    setSelectedTaskImpactItem();
    setSelectedTaskStatusItem();
    setSelectedTaskControl();
    setTaskNameFilter("");
    setTaskEffortFilter("");
    setTaskOwnerFilter("");
    setGoalOwnerFilter("");
    setTaskActiveInactiveStatus("");
    setScheduledTaskDueInFilter("");
    setSelectedTaskRisk();
    // setseletcedScheduledTaskStatus()
  };

  useEffect(() => {
    getTasksDetails(taskList);
  }, [taskList]);

  const getAllOrgGoal = (
    gotoPage,
    sortAsc = null,
    sortby = null,
    GoalsName = null,
    StatusItem = null,
    display = true,
    CompleteStartRange,
    CompleteEndRange,
    WinsStartRange,
    WinsEndRange,
    OntimeStartRange,
    OntimeEndRange,
    RiskStartRange,
    RiskEndRange,
    riskFilter,
    statusFilter,
    goalOwner
  ) => {
    setShowTableSpinner(true);
    const data = getAllGoalsByOrgID(
      userInfoDetails?.data?.accessToken,
      gotoPage,
      sortAsc,
      sortby,
      StatusItem,
      GoalsName,
      true,
      CompleteStartRange,
      CompleteEndRange,
      WinsStartRange,
      WinsEndRange,
      OntimeStartRange,
      OntimeEndRange,
      RiskStartRange,
      RiskEndRange,
      parseInt(inputValue),
      riskFilter,
      statusFilter,
      goalOwner
    );
    data?.then(async (res) => {
      setPageCount(Math.ceil(res?.data?.count / inputValue));
      setPagination(res?.data?.next || res?.data?.previous ? true : false);
      setGoals(res?.data?.data);
      setShowTableSpinner(false);
      if ((StatusItem || GoalsName) && res.data.length === 0) {
        setFilterIcon(false);
      }
    });
    data?.catch(async (err) => { });
  };

  const handleClick11 = (sort_by, sortAsc) => {
    setSortBy(sort_by);
    setsortOrder(sortAsc ? "asc" : "desc");
    getAllOrgGoal(goToPage, sortAsc ? "asc" : "desc", sort_by);
    setSortAsc(sortAsc);
  };

  useEffect(() => {
    getAllOrgGoal(0);
  }, [
    scope,
    localStorage.getItem("timescope"),
    localStorage.getItem("startDate"),
    localStorage.getItem("endDate")
  ]);

  useEffect(() => {
    setScopes("organization");
  }, [scope, teamId]);

  useEffect(() => {
    AllGoals.forEach((goal) => {
      if (selectedGoal[0]?.id === goal?.id) {
        setSelectedGoal([goal]);
        setSelectedGoals([goal]);
      }
    });
  }, [AllGoals]);

  const selectedTaskForDelete = (event, Task) => {
    event.preventDefault();
    setSelectedTaskDelete(Task);
  };

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  const handleDeleteTaskClick = () => {
    setGoalDetails(goalDetails);
    const data = deleteTask(
      SelectedTaskDelete?.id,
      userInfoDetails.data.accessToken
    );
    data?.then(async (res) => {
      SetOpenDeleteModal(false);
      setIsTaskDeleted(true);
      getTasksByGoalId(0);
      setGoalsdelete(false);
      setGoalsupdate(false);
      setGoalscreated(false);
      setTaskcreated(false);
      setTaskupdate(false);
      setTaskdelete(true);
      getMessagingList();
    });
    data?.catch(async (err) => { });
  };

  const handleActivateTaskClick = () => {
    const newStatus = "Active";
    const data = editTask(
      orgId,
      selectedTaskForActive?.id,
      selectedTeam,
      selectedTaskForActive?.goal?.id,
      taskOwner,
      taskCreater,
      taskImpact.value,
      (selectedTaskForActive?.task_control).toLowerCase(),
      {},
      userInfoDetails.data.accessToken,
      taskDetailsinfo?.task_status,
      "",
      newStatus,
      selectedTaskForActive?.order
    );
    data?.then(() => {
      updateSelectedGoalAfterToggle();
      getTasksByGoalId(goToTaskPage);
      setSelecteTaskForActive();
    });
    data?.catch(async (res) => { });
  };

  const handleActivateGoalClick = () => {
    const newStatus = "Active";
    setSubShowTableSpinner(true);
    dispatch(
      editGoalAction(
        orgId,
        selectedGoalForActive?.goal_owner?.id,
        selectedTeam,
        selectedGoalForActive,
        selectedGoalForActive,
        // localStorage.getItem("scope"),
        selectedGoalForActive?.goal_parent?.id,
        userInfoDetails.data.accessToken,
        false,
        selectedGoalForActive?.order,
        newStatus
      )
    );
  };

  const handleActivateSubGoalClick = () => {
    const newStatus = "Active";
    setSubShowTableSpinner(true);
    dispatch(
      editGoalAction(
        orgId,
        selectedSubGoalForActive?.goal_owner?.id,
        selectedTeam,
        selectedSubGoalForActive,
        selectedSubGoalForActive,
        // localStorage.getItem("scope"),
        selectedSubGoalForActive?.goal_parent?.id,
        userInfoDetails.data.accessToken,
        false,
        selectedSubGoalForActive?.order,
        newStatus
      )
    );
  };

  const getMessagingList = () => {
    const start = 0;
    const end = 10;
    dispatch(
      getThreadList(
        localStorage.getItem("organization_id"),
        start,
        end,
        userInfoDetails.data.accessToken
      )
    );
    localStorage.removeItem("ActiveChat");
  };

  const changeGoalOwner = (userRole) => {
    setGoalOwnerValue(userRole);
  };

  function getTasksByGoalId(
    gotoTaskPage,
    sortAsc,
    sortby,
    NameFilter,
    typeItem,
    impactItem,
    taskStatus,
    taskEffort,
    taskOwner,
    taskControl,
    taskRisk,
    taskActiveInactiveStatus,
    scheduledTaskDueInFilter
  ) {
    if (
      selectedGoal[0]?.id !== undefined ||
      selectedGoal[0]?.id !== null ||
      selectedGoal[0]?.id !== ""
    ) {
      setTaskShowTableSpinner(true);
      const data = getTasksByGoal(
        selectedGoal[0]?.id,
        userInfoDetails?.data?.accessToken,
        gotoTaskPage,
        sortAsc,
        sortby,
        NameFilter,
        typeItem,
        impactItem,
        taskStatus,
        taskEffort,
        taskOwner,
        taskControl,
        taskRisk,
        taskActiveInactiveStatus,
        scheduledTaskDueInFilter,
        inputTaskValue
      );
      data?.then((res) => {
        getTasksDetails(res?.data?.data);

        if (res?.data?.data?.length === 0) {
          if (
            sortAsc ||
            sortby ||
            NameFilter ||
            typeItem ||
            impactItem ||
            taskStatus ||
            taskEffort ||
            taskOwner
          ) {
            setTaskHide(true);
          } else {
            setTaskHide(false);
          }
        } else {
          setTaskHide(true);
        }

        if (!res?.data?.data.length) {
          setToggleTaskData(true);
        } else if (res?.data?.data?.length > 0) {
          setToggleTaskData(true);
        }
        setTaskShowTableSpinner(false);
        setTaskPageCount(Math.ceil(res?.data?.count / inputTaskValue));
        setTaskPagination(
          res?.data?.next || res?.data?.previous ? true : false
        );
        if (
          (NameFilter ||
            typeItem ||
            impactItem ||
            taskStatus ||
            taskEffort ||
            taskOwner) &&
          res?.data?.length === 0
        ) {
          setFiltertaskIcon(false);
        }
      });
      data?.catch((error) => { });
    }
  }

  const handleToggle = (goal) => (event) => {
    setShowTableSpinner(true);
    const newStatus = event.target.checked ? "Active" : "Inactive";
    dispatch(
      editGoalAction(
        orgId,
        goal?.goal_owner?.id,
        selectedTeam,
        goal,
        goal,
        // localStorage.getItem("scope"),
        goal?.goal_parent?.id,
        userInfoDetails.data.accessToken,
        false,
        goal?.order,
        newStatus
      )
    );
  };

  const handleTaskActiveToggle = (task) => (event) => {
    if (task?.goal?.status === "Active" || task?.status === "Active") {
      const newStatus = event.target.checked ? "Active" : "Inactive";
      const data = editTask(
        orgId,
        task?.id,
        selectedTeam,
        task?.goal?.id,
        taskOwner,
        taskCreater,
        (task?.task_impact).toLowerCase(),
        (task?.task_control).toLowerCase(),
        {},
        userInfoDetails.data.accessToken,
        taskDetailsinfo?.task_status,
        "",
        newStatus,
        task?.order
      );
      data?.then((res) => {
        getTasksByGoalId(goToTaskPage);
      });
      data?.catch(async (err) => { });
    } else {
      setSelecteTaskForActive(task);
      setOpenWarningModalForActive(true);
    }
  };

  const handleTogglesss = (goal) => (event) => {
    if (goal?.goal_parent?.status === "Active" || goal?.goal_parent === null) {
      setSubShowTableSpinner(true);
      const newStatus = event.target.checked ? "Active" : "Inactive";
      dispatch(
        editGoalAction(
          orgId,
          goal?.goal_owner?.id,
          selectedTeam,
          goal,
          goal,
          // localStorage.getItem("scope"),
          goal?.goal_parent?.id,
          userInfoDetails.data.accessToken,
          false,
          goal?.order,
          newStatus,
          parseInt(inputSubgoalValue)
        )
      );
    } else {
      setSelecteGoalForActive(goal);
      setOpenWarningModalForGoalActive(true);
    }
  };

  const handleToggless = (goal) => (event) => {
    if (goal?.goal_parent?.status === "Active" || goal?.goal_parent === null) {
      setSubShowTableSpinner(true);
      const newStatus = event.target.checked ? "Active" : "Inactive";
      dispatch(
        editGoalAction(
          orgId,
          goal?.goal_owner?.id,
          selectedTeam,
          goal,
          goal,
          // localStorage.getItem("scope"),
          goal?.goal_parent?.id,
          userInfoDetails.data.accessToken,
          false,
          goal?.order,
          newStatus
        )
      );
    } else {
      setSelecteSubGoalForActive(goal);
      setOpenWarningModalForSubGoalActive(true);
    }
  };

  const handleClick22 = (sort_by, sortAsc) => {
    setSortBy(sort_by);
    setsortOrder(sortAsc ? "asc" : "desc");
    getTasksByGoalId(goToTaskPage, sortAsc ? "asc" : "desc", sort_by);
    setSortAsc(sortAsc);
  };

  const changeGoal = (goal) => {
    AllGoals?.forEach((element) => {
      if (element?.id === goal?.id) {
        setTaskOwnerValue({
          value:
            element?.goal_owner?.first_name +
            " " +
            element?.goal_owner?.last_name,
          label:
            element?.goal_owner?.first_name +
            " " +
            element?.goal_owner?.last_name,
          userId: element?.goal_owner?.id
        });
      }
    });
    setGoalDetails(goal);
  };

  useEffect(() => {
    if (scopes === "personal") {
      let optionList = [];
      const data = {
        label: userInfoDetails.data.email,
        value: userInfoDetails.data.email,
        userId: userInfoDetails.data.id
      };
      optionList.push(data);
      setOptions(optionList);
    }
    getAlOrgMem();
    getTeamMem();
    setGoalsdelete(false);
    setGoalsupdate(false);
    setGoalscreated(false);
    setTaskcreated(false);
    setTaskupdate(false);
    setTaskdelete(false);
  }, []);

  useEffect(() => {
    if (selectedGoal[0]?.id) {
      getAllSubGoalData(selectedGoal[0]?.id, 0);
      getTasksByGoalId(goToTaskPage);
    }
    setToggleTaskData(true);
    setToggleData(true);
  }, [
    selectedGoal,
    localStorage.getItem("startDate"),
    localStorage.getItem("endDate"),
    timescope
  ]);

  useEffect(() => {
    if (stateData.task?.tasks) {
      getTasksDetails(stateData.task?.tasks);
      setTaskShowTableSpinner(false);
    }
    if (stateData.task?.message === "Tasks created successfully") {
      getTasksByGoalId(goToTaskPage);
    }
    if (stateData.task?.message === "Tasks updated successfully") {
      getTasksByGoalId(goToTaskPage);
    }
    if (stateData.team?.team?.message === "Teams found successfully") {
      let optionData = stateData?.team?.team?.data;
      let optionList = [];
      optionData?.forEach((option) => {
        const data = {
          label: option?.name,
          value: option?.name,
          userId: option?.id
        };
        optionList.push(data);
      });
      if (optionList?.length === 1) {
        setSelectedTeam(optionList[0]?.userId);
      }
      getTeamDetails(optionList);
    }
    if (stateData?.team?.successMessage === "Organization Members") {
      let optionData = stateData?.team?.orgMembers?.data;
      let optionList = [];
      optionData?.forEach((option) => {
        const data = {
          label: option.first_name
            ? option.first_name + " " + option.last_name
            : option?.email,
          value: option.first_name
            ? option.first_name + " " + option.last_name
            : option?.email,
          userId: option.id
        };
        optionList.push(data);
        if (option.id === userInfoDetails.data.id) {
          const data = {
            label: option?.first_name + " " + option?.last_name,
            value: option?.first_name + " " + option?.last_name,
            userId: option?.id
          };
          setGoalOwnerValue(data);
        }
      });
      setOptions(optionList);
    }

    if (stateData?.goal?.successMessage === "Goal created successfully") {
      getGoalsByScope();
      getAllOrgGoal(goToPage);
      if (selectedGoal[0]?.id)
        getAllSubGoalData(selectedGoal[0]?.id, goToSubGoalPage);
      setGoalscreated(true);
      setGoalsupdate(false);
      setGoalsdelete(false);
      setTaskcreated(false);
      setTaskupdate(false);
      setTaskdelete(false);
    }

    if (stateData?.goal?.successMessage === "Goal Data updated successfully") {
      getGoalsByScope();
      getAllOrgGoal(goToPage);
      if (selectedGoal[0]?.id) {
        getAllSubGoalData(selectedGoal[0]?.id, goToSubGoalPage);
        getGoalsById();
      }
      setGoalsdelete(false);
      setGoalsupdate(true);
      setGoalscreated(false);
      setTaskcreated(false);
      setTaskupdate(false);
      setTaskdelete(false);
      setInputValueForSrNo(stateData?.goal?.order);
    }
    if (
      stateData?.goal?.successMessage === "Goals found successfully" &&
      location?.state?.refresh === true
    ) {
      setAllGoals(stateData.goal.goals);
      setShowTableSpinner(false);
    }
    if (stateData?.goal?.successMessage === "Goals found successfully") {
      setAllGoals(stateData.goal.goals);
      setShowTableSpinner(false);
    }

    if (stateData?.goal?.successMessage === "Goal deleted successfully") {
      getGoalsByScope();
      getAlOrgMem();
      getTeamMem();
      getAllOrgGoal(0);
      getMessagingList();
      if (selectedGoal[0]?.id) getAllSubGoalData(selectedGoal[0]?.id, 0);
      setGoalsdelete(true);
      setGoalsupdate(false);
      setGoalscreated(false);
      setTaskcreated(false);
      setTaskupdate(false);
      setTaskdelete(false);
      SetOpenDeleteModal(false);
    }
  }, [stateData]);

  function getAlOrgMem() {
    dispatch(getOrgMembers(userInfoDetails.data.accessToken));
  }

  function getTeamMem() {
    if (teamId)
      dispatch(getTeamMemByTeamId(teamId, userInfoDetails.data.accessToken));
  }

  function getGoalsByScope() {
    if (scope && orgId)
      dispatch(
        getGoalByScope(scope, orgId, teamId, userInfoDetails.data.accessToken)
      );
  }

  function submitEditGoal(e) {
    setCreateGoalForm({
      name: goalDetails?.name,
      description: goalDetails?.description
    });
    e.preventDefault();
    dispatch(
      editGoalAction(
        orgId,
        goalOwner.userId,
        selectedTeam,
        createGoal,
        goalDetails,
        // scopes,
        parentGoalId?.id,
        userInfoDetails.data.accessToken
      )
    );
  }

  const handleGoalEditClick = (event, goal) => {
    event.preventDefault();
    let goalArr = [];
    goalArr.push(goal);
    setEditableGoal(goalArr);
    setShowGoalModal(true);
    setEditTask(true);
    setGoalsupdate(false);
    setGoalsdelete(false);
    setGoalscreated(false);
    setTaskcreated(false);
    setTaskupdate(false);
    setTaskdelete(false);
    dispatch(
      getUserdetail(goal?.goal_owner?.id, userInfoDetails.data.accessToken)
    );

    const goalState = {
      name: goal.name,
      description: goal.description
    };
    setGoalOwnerValue({
      label: goal?.goal_owner?.first_name + " " + goal?.goal_owner?.last_name,
      value: goal?.goal_owner?.first_name + " " + goal?.goal_owner?.last_name,
      userId: goal.goal_owner.id
    });
    setParentGoalId(goal?.goal_parent?.id);
    setCreateGoalForm(goalState);
  };

  const changedDateFormat = (date) => {
    if (date) {
      var today1 = new Date(date);
      return (
        String(today1.getDate()).padStart(2, "0") + "/" + today1.getMonth()
      );
    } else {
      return "-";
    }
  };

  const selectedGoalForDelete = (event, Goal) => {
    event.preventDefault();
    setSelectedGoalDelete(Goal);
  };

  const handleDeleteGoalClick = () => {
    setGoalsdelete(false);
    setGoalsupdate(false);
    setGoalscreated(false);
    setTaskcreated(false);
    setTaskupdate(false);
    setTaskdelete(false);
    dispatch(
      deleteGoalAction(SelectedGoalDelete?.id, userInfoDetails.data.accessToken)
    );
    // setSelectedGoal([]);
    if (goals?.length === 1) {
      setGoToPage(0);
    } else if (allSubGoals?.length === 1) {
      setGoToSubGoalPage(goToSubGoalPage - 1);
    } else {
      setGoToPage(0);
      setGoToSubGoalPage(goToSubGoalPage);
      setGoTotaskPage(goToTaskPage);
    }
    // {
    //   SelectedGoalDelete?.id == selectedGoal[0]?.id && navigate("/goals");
    // }
  };

  useEffect(() => {
    if (location?.state?.goal) {
      if (selectedGoal[0]?.id !== location?.state?.goal?.id)
        setSelectedGoal([location?.state?.goal]);
      setSelectedGoals([location?.state?.goal]);
      getTasksDetails(stateData.task?.tasks);
    }
  }, [location?.state?.goal, selectedGoal, timescope]);

  useEffect(() => {
    setSelectedTeam(teamId);
  }, [teamId]);

  const handleCreateSubGoal = (event, goal) => {
    setCreateSubGoal(true);
    setGoalsdelete(false);
    setGoalsupdate(false);
    setGoalscreated(false);
    setTaskcreated(false);
    setTaskupdate(false);
    setTaskdelete(false);
    event.preventDefault();
    setParentGoalId(goal);
    // setSelectedGoal(goal)
  };

  const getAllSubGoalData = (
    goalid,
    gotoSubGoalPage,
    sortAsc = null,
    sortby = null,
    nameFilter = null,
    subStatusItem = null,
    subCompleteStartRange,
    subCompleteEndRange,
    subWinsStartRange,
    subWinsEndRange,
    subOntimeStartRange,
    subOntimeEndRange,
    subRiskFilter,
    subStatusFilter,
    subGoalOwner
  ) => {
    setSubShowTableSpinner(true);
    const subGoalData = getSubGoals(
      goalid,
      userInfoDetails.data.accessToken,
      gotoSubGoalPage,
      sortAsc,
      sortby,
      nameFilter,
      subStatusItem,
      subCompleteStartRange,
      subCompleteEndRange,
      subWinsStartRange,
      subWinsEndRange,
      subOntimeStartRange,
      subOntimeEndRange,
      subRiskFilter,
      subStatusFilter,
      subGoalOwner,
      parseInt(inputSubgoalValue)
    );
    subGoalData.then(async (res) => {
      setSubShowTableSpinner(false);
      setAllSubGoals(res?.data?.data);
      if (res?.data?.data.length === 0) {
        if (
          sortAsc ||
          sortby ||
          nameFilter ||
          subStatusItem ||
          subCompleteStartRange ||
          subCompleteEndRange ||
          subWinsStartRange ||
          subWinsEndRange ||
          subOntimeStartRange ||
          subOntimeEndRange ||
          subRiskFilter ||
          subStatusFilter ||
          subGoalOwner
        ) {
          setSubGoalHide(true);
        } else {
          setSubGoalHide(false);
        }
      } else {
        setSubGoalHide(true);
      }

      if (!res?.data?.data.length) {
        setToggleData(true);
      } else if (res?.data?.data?.length > 0) {
        setToggleData(true);
      }
      setSubGoalPageCount(Math.ceil(res?.data?.count / inputSubgoalValue));
      setSubGoalPagination(
        res?.data?.next || res?.data?.previous ? true : false
      );
      setSubShowTableSpinner(false);
      if ((nameFilter || subStatusItem) && res?.data?.length === 0) {
        setFilterSubGoalIcon(false);
      }
    });
    subGoalData.catch(async (err) => {
      // setSubShowTableSpinner(false);
    });
  };

  const handleClick33 = (sort_by, sortAsc) => {
    setSortBy(sort_by);
    setsortOrder(sortAsc ? "asc" : "desc");
    getAllSubGoalData(
      selectedGoal[0]?.id,
      goToSubGoalPage,
      sortAsc ? "asc" : "desc",
      sort_by
    );
    setSortAsc(sortAsc);
  };

  useEffect(() => {
    setTaskDetailsinfo(taskDetailsinfo);
  }, [taskDetailsinfo?.id]);

  const handleClose = (event) => {
    setshowTaskMoadal(false);
    setShowGoalModal(false);
    setCreateSubGoal(false);
    setEditTask(false);
  };

  const close = () => {
    setGoalsdelete(false);
    setGoalsupdate(false);
    setGoalscreated(false);
    setTaskcreated(false);
    setTaskupdate(false);
    setTaskdelete(false);
  };

  useEffect(() => {
    if (goalscreated || goalsupdate || goalsdelete)
      setTimeout(() => {
        setGoalscreated(false);
        setGoalsupdate(false);
        setGoalsdelete(false);
      }, 3000);
    if (taskcreated || taskupdate || taskdelete)
      setTimeout(() => {
        setTaskcreated(false);
        setTaskupdate(false);
        setTaskdelete(false);
      }, 3000);
  }, [goalscreated, goalsupdate, goalsdelete]);

  let timeout;

  const handleInputChange = (event) => {
    clearTimeout(timeout);
    const newValue = event.target.value;
    setInputValue(newValue);
    localStorage.setItem("goals_row_per_page", newValue);

    setTimeout(() => {
      setShowTableSpinner(true);
      const data = getAllGoalsByOrgID(
        userInfoDetails?.data?.accessToken,
        0,
        sortOrder,
        sortBy,
        null,
        goalNameFilter,
        true,
        CompleteStartRange,
        CompleteEndRange,
        WinsStartRange,
        WinsEndRange,
        OntimeStartRange,
        OntimeEndRange,
        RiskStartRange,
        RiskEndRange,
        event.target.value,
        riskFilter,
        statusFilter,
        goalOwnerFilter
      );
      data?.then(async (res) => {
        // setPageCount(Math.ceil(res?.data?.count / event.target.value));
        setGoToPage(0);
        setPageCount(
          Math.ceil(
            res?.data?.count /
            (localStorage.getItem("goals_row_per_page") ||
              event?.target?.value)
          )
        );
        setPagination(res?.data?.next || res?.data?.previous ? true : false);
        setGoals(res?.data?.data);
        setShowTableSpinner(false);
        // if ((StatusItem || GoalsName) && res.data.length === 0) {
        //   setFilterIcon(false);
        // }
      });
      data?.catch(async (err) => {
        setShowTableSpinner(false);
      });
    }, 1500);
  };

  const handleSubgoalInputChange = (event) => {
    clearTimeout(timeout);
    const newValue = event.target.value;
    setInputSubgoalValue(newValue);
    localStorage.setItem("subgoals_row_per_page", newValue);

    setTimeout(() => {
      setSubShowTableSpinner(true);
      const subGoalData = getSubGoals(
        selectedGoal[0]?.id,
        userInfoDetails.data.accessToken,
        "",
        sortAsc,
        "",
        "",
        "",
        subCompleteStartRange,
        subCompleteEndRange,
        subWinsStartRange,
        subWinsEndRange,
        subOntimeStartRange,
        subOntimeEndRange,
        subRiskFilter,
        subStatusFilter,
        "",
        event.target.value
      );
      subGoalData.then(async (res) => {
        setGoToSubGoalPage(0);
        setSubGoalPageCount(
          Math.ceil(
            res?.data?.count /
            (localStorage.getItem("subgoals_row_per_page") ||
              event?.target?.value)
          )
        );
        setSubGoalPagination(
          res?.data?.next || res?.data?.previous ? true : false
        );
        setSubShowTableSpinner(false);
        setAllSubGoals(res?.data?.data);
      });
      subGoalData.catch(async (res) => { });
    }, 1500);
  };

  const handleInputTaskChange = (event) => {
    console.log(event.target.value, "ooppooo");
    clearTimeout(timeout);
    const newValue = event.target.value;
    setInputTaskValue(newValue);
    localStorage.setItem("tasks_row_per_page", newValue);

    setTimeout(() => {
      const data = getTasksByGoal(
        selectedGoal[0]?.id,
        userInfoDetails?.data?.accessToken,
        0,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        taskOwner,
        "",
        "",
        taskActiveInactiveStatus,
        scheduledTaskDueInFilter,
        event.target.value
      );
      data?.then((res) => {
        getTasksDetails(res?.data?.data);
        setTaskShowTableSpinner(false);
        setTaskPageCount(Math.ceil(res?.data?.count / inputTaskValue));
        setTaskPagination(
          res?.data?.next || res?.data?.previous ? true : false
        );
      });
      data?.catch((error) => { });
    }, 1500);
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setInputValue(selectedValue);
  };

  const archiveGoalHandler = (event, goal) => {
    const data = archieveGoal(goal?.id, userInfoDetails?.data?.accessToken);
    data.then(async (res) => {
      if (
        !selectedGoal[0]?.goal_parent &&
        stateData.task.goalNavigationManage
      ) {
        // getAllOrgGoal()
        setSelectedGoal([]);
        setSelectedGoals([]);
        dispatch(goalNavigationManage(false));
        // navigate("/goals")
        // window.location.replace("/goals");
        // navigate(localStorage.getItem("previousLocation"));
      } else if (!selectedGoal[0]?.goal_parent) {
        // navigate(localStorage.getItem("previousLocation"));
        getAllOrgGoal();
        setSelectedGoal([]);
        setSelectedGoals([]);
      } else {
        dispatch(goalNavigationManage(true));
        getGoalsById();
      }
      // setSelectedGoal(selectedGoal[0]?.goal_parent);
      // setSelectedGoal(selectedGoal[0]?.goal_parent)
      setGoalsdelete(false);
      setGoalsupdate(false);
      setGoalscreated(false);
      setTaskcreated(false);
      setTaskupdate(false);
      setTaskdelete(false);
      // getTasksByGoalId(goToTaskPage);
      toast(
        <>
          <div className="m-1 d-flex">
            <i
              class="bi bi-check-circle fs-26 me-3"
              style={{ color: "#131F4C" }}
            ></i>
            <div>
              <h2
                style={{
                  // color: "#131F4C",
                  fontSize: "22px"
                }}
              >
                Completed
              </h2>
              <h3
                style={{
                  // color: "#131F4C",
                  fontSize: "18px"
                }}
              >
                Your goal has been archieved successfully!
              </h3>
            </div>
          </div>
        </>,
        {
          toastId: "task-schedule",
          className: "toast-success-container toast-success-container-after "
        }
      );
    });
    data.catch(async (err) => {
      toast(
        <div className="m-1">
          <h3>{err?.response?.data?.message}</h3>
        </div>,
        { toastId: "strategyPaymentFail" }
      );
    });
  };

  const sortigTaskControl = (col) => {
    if (order === "ASC" || order === "") {
      const sorted = [...taskList].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      getTasksDetails(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...taskList].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      getTasksDetails(sorted);
      setOrder("ASC");
    }
  };

  const moveGoal = (dragIndex, hoverIndex) => {
    const dragItem = goals[dragIndex];
    // setGoals((prevGoals) => {
    //   const updatedGoals = [...prevGoals];
    //   updatedGoals.splice(dragIndex, 1);
    //   updatedGoals.splice(hoverIndex, 0, dragItem);
    //   return updatedGoals;
    // });
  };

  useEffect(() => {
    // Any setup code here (if needed)

    // Return the cleanup function for when the component unmounts
    return () => { };
  }, []);

  const handleGoalClick = (goal, event) => {
    dispatch(resetGoalPage(false));
    scrollToTop();
    setParentGoalId(goal);
    setSelectedGoal([goal]);
    setSelectedGoals([goal]);
    getAllSubGoalData(goal?.id, 0);
    setGoalDetails(goal);
    setGoalsdelete(false);
    setGoalsupdate(false);
    setGoalscreated(false);
    setTaskcreated(false);
    setTaskupdate(false);
    setTaskdelete(false);
    clearAllGoalsFilter(event);
  };

  const handleSubGoalClick = (goal, event) => {
    scrollToTop();
    clearAllSubGoalFilter(event);
    setParentGoalId(goal);
    setSelectedGoal([goal]);
    setSelectedGoals([goal]);
    getAllSubGoalData(goal?.id, 0);
    setGoalDetails(goal);
    setGoalsdelete(false);
    setGoalsupdate(false);
    setGoalscreated(false);
    setTaskcreated(false);
    setTaskupdate(false);
    setTaskdelete(false);
  };

  const handleGoalNavigation = (event) => {
    clearAllSubGoalFilter(event);
    setDeleteParentGoal(false);
    setGoToSubGoalPage(0);
    setGoTotaskPage(0);
    setHideDataManageNavigation(true);
    dispatch(goalEditManage(true));
    if (!selectedGoal[0]?.goal_parent && stateData.task.goalNavigationManage) {
      setSelectedGoal([]);
      setSelectedGoals([]);
      dispatch(goalNavigationManage(false));
    } else if (!selectedGoal[0]?.goal_parent) {
      localStorage.removeItem("selectedGoal");
      if (stateData?.task?.plannedGoalMyDay) {
        navigate(localStorage.getItem("previousLocation"));
        dispatch(plannedGoalMyDay(false));
      } else {
        getAllOrgGoal();
        setSelectedGoal([]);
        setSelectedGoals([]);
      }
    } else {
      // dispatch(goalNavigationManage(true));
      getGoalsById();
    }
    // Reset goal and task states
    setGoalsdelete(false);
    setGoalsupdate(false);
    setGoalscreated(false);
    setTaskcreated(false);
    setTaskupdate(false);
    setTaskdelete(false);
  };

  const TableRows = ({ goal, index }) => {
    const [{ isDragging }, drag, preview] = useDrag({
      type: "ROW",
      item: { index, goal },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    });

    const [, drop] = useDrop({
      accept: "ROW",
      drop: (item) => {
        if (item.index !== index) {
          moveGoal(item.index, index);
          item.index = index;
          dispatch(
            editGoalAction(
              orgId,
              item.goal?.goal_owner?.id,
              selectedTeam,
              item.goal,
              item.goal,
              // scopes,
              null,
              userInfoDetails.data.accessToken,
              false,
              goal?.order
            )
          );
        }
      }
    });

    return (
      <tr
        ref={(node) => drag(drop(node))}
        className="taskscheduledgoal odd goals"
        role="row"
        style={{
          cursor: "move",
          ...(showTableSpinner
            ? { backgroundColor: "#ebedee", zIndex: "-1" }
            : {})
        }}
      >
        <td onClick={(event) => handleGoalClick(goal, event)}>
          <OverlayTrigger
            placement="bottom" // Tooltip position
            overlay={(props) => renderTooltip(props, goal)} // Pass `objective` to `renderTooltip`
          >
            <div style={{ position: "relative", width: "80%" }}>
              <ProgressBar
                now={40} // Adjust the progress value as per your need
                label={null} // Label is handled manually
                style={
                  goal.goal_status === "On track"
                    ? progressBarStyle
                    : goal.goal_status === "At risk"
                      ? progressWarningBarStyle
                      : progressFailingBarStyle
                }
              >
                <div
                  style={{
                    ...(goal.goal_status === "On track"
                      ? progressBarFilledStyle
                      : goal.goal_status === "At risk"
                        ? progressBarWarningFilledStyle
                        : progressBarFailingFilledStyle),
                    width: `${goal?.goal_completed_percent}%`, // The percentage width of the filled part
                    height: "100%"
                  }}
                />
              </ProgressBar>
              <span
                style={
                  goal.goal_status === "On track"
                    ? labelStyle
                    : goal.goal_status === "At risk"
                      ? labelWarningStyle
                      : labelFailingStyle
                }
              >{`${goal?.name || "Goal Name"}`}</span>
            </div>
          </OverlayTrigger>
        </td>
        <td
          // onClick={(event) => {
          //   event.preventDefault();
          //   setTaskDetailsinfo(task);
          //   setFeedbackModal(false);
          //   setFeedbackButton(false);
          // }}
          // style={{
          //   border: 2,
          //   borderColor: "white",
          //   borderStyle: "solid",
          // }}
          className="sorting_1"
        >
          {goal?.goal_owner?.first_name[0]?.toUpperCase()}
          {goal?.goal_owner?.last_name[0]?.toUpperCase()}
        </td>
        {/* <td
          onClick={(event) => {
            setParentGoalId(goal);
            setSelectedGoal([goal]);
            getAllSubGoalData(goal?.id, goToSubGoalPage);
            setGoalDetails(goal);
            setGoalsdelete(false);
            setGoalsupdate(false);
            setGoalscreated(false);
            setTaskcreated(false);
            setTaskupdate(false);
            setTaskdelete(false);
          }}
         
        >
          <OverlayTrigger
                        placement="bottom" // Tooltip position
                        overlay={(props) => renderTooltip(props, goal)} // Pass `objective` to `renderTooltip`
                      >

<div style={{ position: 'relative', width: '100%' }}>
    <ProgressBar
      now={40} 
      label={null} 
      style={

        goal.goal_status === "On track"
      ? progressBarStyle
      : goal.goal_status === "At risk"
      ? progressWarningBarStyle
      : progressFailingBarStyle
      } 

    >
      <div
        style={{
          ...(goal.goal_status === "On track"
            ? progressBarFilledStyle
            : goal.goal_status === "At risk"
            ? progressBarWarningFilledStyle
            : progressBarFailingFilledStyle),
          width: `${goal?.goal_completed_percent}%`, // The percentage width of the filled part
          height: '100%',
        }}
      />
    </ProgressBar>
    <span style={
     goal.goal_status === "On track"
     ? labelStyle
     : goal.goal_status === "At risk"
     ? labelWarningStyle
     : labelFailingStyle

    } >{`${goal?.goal_status || 'Objective Status'}`}</span>
  </div>
  </OverlayTrigger>
        
        </td> */}

        {/* <td
          onClick={(event) => {
            setParentGoalId(goal);
            setSelectedGoal([goal]);
            getAllSubGoalData(goal?.id, goToSubGoalPage);
            setGoalDetails(goal);
            setGoalsdelete(false);
            setGoalsupdate(false);
            setGoalscreated(false);
            setTaskcreated(false);
            setTaskupdate(false);
            setTaskdelete(false);
          }}
        >
          {goal?.goal_completed_percent} %
        </td> */}
        {/* <td
          onClick={(event) => {
            setParentGoalId(goal);
            setSelectedGoal([goal]);
            getAllSubGoalData(goal?.id, goToSubGoalPage);
            setGoalDetails(goal);
            setGoalsdelete(false);
            setGoalsupdate(false);
            setGoalscreated(false);
            setTaskcreated(false);
            setTaskupdate(false);
            setTaskdelete(false);
          }}
        >
          {goal?.goal_win_percent} %
        </td> */}

        <td
        // onClick={(event) => {
        //   setParentGoalId(goal);
        //   setSelectedGoal([goal]);
        //   getAllSubGoalData(goal?.id, goToSubGoalPage);
        //   setGoalDetails(goal);
        //   setGoalsdelete(false);
        //   setGoalsupdate(false);
        //   setGoalscreated(false);
        //   setTaskcreated(false);
        //   setTaskupdate(false);
        //   setTaskdelete(false);
        // }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>{/* {goal?.status}  */}</div>
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={goal.status === "Active"}
                    onChange={handleToggle(goal)}
                    name="statusToggle"
                    color="primary"
                  />
                }
              />
            </div>
          </div>
        </td>
        {/* <td
          onClick={(event) => {
            setParentGoalId(goal);
            setSelectedGoal([goal]);
            getAllSubGoalData(goal?.id, goToSubGoalPage);
            setGoalDetails(goal);
            setGoalsdelete(false);
            setGoalsupdate(false);
            setGoalscreated(false);
            setTaskcreated(false);
            setTaskupdate(false);
            setTaskdelete(false);
          }}
        >
          {goal?.goal_on_time_percent} %
        </td> */}

        {/* <td
          onClick={(event) => {
            setParentGoalId(goal);
            setSelectedGoal([goal]);
            getAllSubGoalData(goal?.id, goToSubGoalPage);
            setGoalDetails(goal);
            setGoalsdelete(false);
            setGoalsupdate(false);
            setGoalscreated(false);
            setTaskcreated(false);
            setTaskupdate(false);
            setTaskdelete(false);
          }}
        >
          {goal?.goal_risk_average}
        </td> */}

        {/* <td>
          <div className="text-center ">
            {goal?.goal_creator?.id === userInfoDetails?.data?.id ||
            goal?.goal_owner?.id === userInfoDetails?.data?.id ||
            userInfoDetails?.data?.user_role === "owner" ? (
              <button
                className="figma-btn-round "
                onClick={() => {
                  setshowTaskMoadal(true);
                  changeGoal(goal);
                  setGoalsdelete(false);
                  setGoalsupdate(false);
                  setGoalscreated(false);
                  setTaskcreated(false);
                  setTaskupdate(false);
                  setTaskdelete(false);
                  setTaskOwnerValue({
                    label:
                      goal?.goal_owner?.first_name +
                      " " +
                      goal?.goal_owner?.last_name,
                    value:
                      goal?.goal_owner?.first_name +
                      " " +
                      goal?.goal_owner?.last_name,
                    userId: goal?.goal_owner?.id,
                  });
                }}
              >
                <i className="p-0 m-0">+</i>
              </button>
            ) : (
              <button
                className="figma-btn-round"
                onClick={() => {
                  setshowTaskMoadal(true);
                  changeGoal(goal);
                  setGoalsdelete(false);
                  setGoalsupdate(false);
                  setGoalscreated(false);
                  setTaskcreated(false);
                  setTaskupdate(false);
                  setTaskdelete(false);
                  setTaskOwnerValue({
                    label:
                      goal?.goal_owner?.first_name +
                      " " +
                      goal?.goal_owner?.last_name,
                    value:
                      goal?.goal_owner?.first_name +
                      " " +
                      goal?.goal_owner?.last_name,
                    userId: goal?.goal_owner?.id,
                  });
                }}
              >
                <i className="p-0 m-0">+</i>
              </button>
            )}
          </div>
        </td> */}
        <td>
          {/* {goal?.goal_creator?.id === userInfoDetails?.data?.id ||
            goal?.goal_owner?.id === userInfoDetails?.data?.id ||
            userInfoDetails?.data?.user_role === "owner" ? ( */}
          <>
            {/* <TfiMoreAlt size={15}></TfiMoreAlt> */}

            <Dropdown className="ms-auto">
              <Dropdown.Toggle variant="" as="div" className="btn-link i-false">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="12.4999" cy="3.5" r="2.5" fill="#131f4c" />
                  <circle cx="12.4999" cy="11.5" r="2.5" fill="#131f4c" />
                  <circle cx="12.4999" cy="19.5" r="2.5" fill="#131f4c" />
                </svg>
              </Dropdown.Toggle>

              {ReactDOM.createPortal(
                <Dropdown.Menu
                  alignRight={true}
                  data-testid="org-menu"
                  className="dropdown-menu-right"
                  style={{ position: "absolute", zIndex: 1050 }}
                >
                  <Dropdown.Item
                    data-testid="org-edit"
                    onClick={() => {
                      setshowTaskMoadal(true);
                      changeGoal(goal);
                      setGoalsdelete(false);
                      setGoalsupdate(false);
                      setGoalscreated(false);
                      setTaskcreated(false);
                      setTaskupdate(false);
                      setTaskdelete(false);
                      setTaskOwnerValue({
                        label:
                          goal?.goal_owner?.first_name +
                          " " +
                          goal?.goal_owner?.last_name,
                        value:
                          goal?.goal_owner?.first_name +
                          " " +
                          goal?.goal_owner?.last_name,
                        userId: goal?.goal_owner?.id
                      });
                    }}
                  >
                    Create Task
                  </Dropdown.Item>

                  <Dropdown.Item
                    data-testid="org-edit"
                    onClick={(event) => {
                      setGoalOwnerValue({
                        value:
                          goal?.goal_owner?.first_name +
                          " " +
                          goal?.goal_owner?.last_name,
                        label:
                          goal?.goal_owner?.first_name +
                          " " +
                          goal?.goal_owner?.last_name,
                        userId: goal?.goal_owner?.id
                      });
                      handleCreateSubGoal(event, goal);
                      setSelectedGoal([goal]);
                      setSelectedGoals([goal]);
                      setShowGoalModal(true);
                      setCreateSubGoal(true);
                      setCreateTask(true);
                    }}
                  >
                    Create SubGoal
                  </Dropdown.Item>

                  <Dropdown.Item
                    data-testid="org-edit"
                    onClick={(event) => {
                      // setSelectedGoal([goal]);
                      setSelectedGoals([goal]);
                      setShowGoalModal(true);
                      setEditTask(true);
                    }}
                  >
                    Edit
                  </Dropdown.Item>

                  <Dropdown.Item
                    data-testid="org-delete"
                    onClick={(event) => {
                      setShareModal(true);
                      setSelectedShareGoal(goal);
                    }}
                  >
                    Share
                  </Dropdown.Item>

                  <Dropdown.Item
                    data-testid="org-delete"
                    onClick={(event) => {
                      archiveGoalHandler(event, goal);
                    }}
                  >
                    Archive
                  </Dropdown.Item>

                  <Dropdown.Item
                    className="text-danger"
                    data-testid="org-delete"
                    onClick={(event) => {
                      selectedGoalForDelete(event, goal);
                      SetOpenDeleteModal(true);
                    }}
                  >
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>,
                document.body // This ensures the Dropdown.Menu is rendered outside the regular DOM hierarchy
              )}
            </Dropdown>
          </>
        </td>
      </tr>
    );
  };

  const moveGoalss = (dragIndex, hoverIndex) => {
    const dragItem = goals[dragIndex];
    // setGoals((prevGoals) => {
    //   const updatedGoals = [...prevGoals];
    //   updatedGoals.splice(dragIndex, 1);
    //   updatedGoals.splice(hoverIndex, 0, dragItem);
    //   return updatedGoals;
    // });
  };

  const TableRow = ({ goal, index }) => {
    const [{ isDragging }, drag, preview] = useDrag({
      type: "ROW",
      item: { index, goal },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    });

    const [, drop] = useDrop({
      accept: "ROW",
      drop: (item) => {
        if (item.index !== index) {
          moveGoalss(item.index, index);
          item.index = index;
          dispatch(
            editGoalAction(
              orgId,
              item.goal?.goal_owner?.id,
              selectedTeam,
              item.goal,
              item.goal,
              // scopes,
              item?.goal?.goal_parent?.id,
              userInfoDetails.data.accessToken,
              false,
              goal?.order
            )
          );
        }
      }
    });

    return (
      <tr
        ref={(node) => drag(drop(node))}
        style={{ maxHeight: "1px !important", cursor: "move" }}
        className="odd goals"
        role="row"
        onClick={() => {
          setGoalsdelete(false);
          setGoalsupdate(false);
          setGoalscreated(false);
          setTaskcreated(false);
          setTaskupdate(false);
          setTaskdelete(false);
        }}
      >
        <td onClick={(event) => handleSubGoalClick(goal, event)}>
          <OverlayTrigger
            placement="bottom" // Tooltip position
            overlay={(props) => renderTooltip(props, goal)} // Pass `objective` to `renderTooltip`
          >
            <div style={{ position: "relative", width: "80%" }}>
              <ProgressBar
                now={40} // Adjust the progress value as per your need
                label={null} // Label is handled manually
                style={
                  goal.goal_status === "On track"
                    ? progressBarStyle
                    : goal.goal_status === "At risk"
                      ? progressWarningBarStyle
                      : progressFailingBarStyle
                }
              >
                <div
                  style={{
                    ...(goal.goal_status === "On track"
                      ? progressBarFilledStyle
                      : goal.goal_status === "At risk"
                        ? progressBarWarningFilledStyle
                        : progressBarFailingFilledStyle),
                    width: `${goal?.goal_completed_percent}%`, // The percentage width of the filled part
                    height: "100%"
                  }}
                />
              </ProgressBar>
              <span
                style={
                  goal.goal_status === "On track"
                    ? labelStyle
                    : goal.goal_status === "At risk"
                      ? labelWarningStyle
                      : labelFailingStyle
                }
              >{`${goal?.name || "Goal Name"}`}</span>
            </div>
          </OverlayTrigger>
        </td>

        <td className="sorting_1">
          {goal?.goal_owner?.first_name[0]?.toUpperCase()}
          {goal?.goal_owner?.last_name[0]?.toUpperCase()}
        </td>
        <td>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>{/* {goal?.status}  */}</div>
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={goal.status === "Active"}
                    onChange={handleToggless(goal)}
                    name="statusToggle"
                    color="primary"
                  />
                }
              />
            </div>
          </div>
        </td>
        <td>
          {/* {goal?.goal_creator?.id === userInfoDetails?.data?.id ||
            goal?.goal_owner?.id === userInfoDetails?.data?.id ||
            userInfoDetails?.data?.user_role === "owner" ? ( */}

          <Dropdown className="ms-auto">
            <Dropdown.Toggle variant="" as="div" className="btn-link i-false">
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="12.4999" cy="3.5" r="2.5" fill="#131f4c" />
                <circle cx="12.4999" cy="11.5" r="2.5" fill="#131f4c" />
                <circle cx="12.4999" cy="19.5" r="2.5" fill="#131f4c" />
              </svg>
            </Dropdown.Toggle>

            {/* Render the Dropdown.Menu with ReactDOM.createPortal */}
            {ReactDOM.createPortal(
              <Dropdown.Menu
                alignRight={true}
                data-testid="org-menu"
                className="dropdown-menu-right"
                style={{ position: "absolute", zIndex: 1050 }} // Ensure proper positioning and z-index
              >
                <Dropdown.Item
                  data-testid="org-edit"
                  onClick={(event) => {
                    handleGoalEditClick(event, goal);
                    // setSelectedGoal([goal]);
                    setSelectedGoals([goal]);
                    setShowGoalModal(true);
                    setEditTask(true);
                  }}
                >
                  Edit
                </Dropdown.Item>
                <Dropdown.Item
                  data-testid="org-archive"
                  onClick={(event) => {
                    archiveGoalHandler(event, goal);
                  }}
                >
                  Archive
                </Dropdown.Item>
                <Dropdown.Item
                  data-testid="org-share"
                  onClick={(event) => {
                    setShareModal(true);
                    setSelectedShareGoal(goal);
                  }}
                >
                  Share
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-danger"
                  data-testid="org-delete"
                  onClick={(event) => {
                    selectedGoalForDelete(event, goal);
                    SetOpenDeleteModal(true);
                  }}
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>,
              document.body // Portal the dropdown menu to the body
            )}
          </Dropdown>
        </td>
      </tr>
    );
  };

  const moveTask = (dragIndex, hoverIndex) => {
    const dragItem = goals[dragIndex];
    // setGoals((prevGoals) => {
    //   const updatedGoals = [...prevGoals];
    //   updatedGoals.splice(dragIndex, 1);
    //   updatedGoals.splice(hoverIndex, 0, dragItem);
    //   return updatedGoals;
    // });
  };

  const TableTaskRow = ({ task, index }) => {
    const [{ isDragging }, drag, preview] = useDrag({
      type: "ROW",
      item: { index, task },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    });

    const [, drop] = useDrop({
      accept: "ROW",
      drop: (item) => {
        if (item.index !== index) {
          moveTask(item.index, index);
          item.index = index;

          const data = editTask(
            orgId,
            item?.task?.id,
            item?.task?.team?.id,
            item?.task?.goal?.id,
            item?.task?.task_owner,
            item?.task?.task_creator,
            item?.task?.task_impact?.toLowerCase(),
            item?.task?.task_control?.toLowerCase(),
            item?.task,
            userInfoDetails.data.accessToken,
            item?.task?.task_status,
            "",
            item?.task?.status,
            task?.task_order
          );
          data?.then((res) => {
            getTasksByGoalId(goToTaskPage);
          });
          data?.catch((err) => { });
        }
      }
    });

    console.log(
      goToSubGoalPage,
      goToTaskPage,
      "goToSubGoalPage,goToTaskPage -------------------------------------"
    );
    return (
      <tr
        ref={(node) => drag(drop(node))}
        className="odd goals"
        role="row"
        style={{
          cursor: "pointer"
        }}
        onClick={() => {
          setGoalsdelete(false);
          setGoalsupdate(false);
          setGoalscreated(false);
          setTaskcreated(false);
          setTaskupdate(false);
          setTaskdelete(false);
        }}
      >
        <td
          style={{
            border: 2,
            borderColor: "white",
            borderStyle: "solid",
            textAlign: "start"
          }}
          onClick={(event) => setTaskDetailsinfo(task)}
        >
          {task.name}
        </td>
        <td
          style={{
            border: 2,
            borderColor: "white",
            borderStyle: "solid"
          }}
          onClick={(event) => setTaskDetailsinfo(task)}
          className="sorting_1"
        >
          {task?.task_owner?.first_name[0]?.toUpperCase()}{" "}
          {task?.task_owner?.last_name[0]?.toUpperCase()}
        </td>
        <td
          style={{
            border: 2,
            borderColor: "white",
            borderStyle: "solid",
            textAlign: "center",
            verticalAlign: "middle"
          }}
        >
          <div style={{ display: "flex" }}>
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={task.status === "Active"}
                    onChange={handleTaskActiveToggle(task)}
                    name="statusToggle"
                    color="primary"
                  />
                }
              />
            </div>
          </div>
        </td>
        <td
          style={{
            border: 2,
            borderColor: "white",
            borderStyle: "solid"
          }}
          onClick={(event) => setTaskDetailsinfo(task)}
        >
          {task.task_impact}
        </td>
        <td
          style={{
            border: 2,
            borderColor: "white",
            borderStyle: "solid"
          }}
          onClick={(event) => setTaskDetailsinfo(task)}
        >
          {getAdjustedTaskEffort(task.task_effort)}
        </td>
        <td
          style={{
            border: 2,
            borderColor: "white",
            borderStyle: "solid"
          }}
          onClick={(event) => setTaskDetailsinfo(task)}
        >
          {task.task_type}
        </td>

        <td
          onClick={(event) => {
            event.preventDefault();
            setTaskDetailsinfo(task);
          }}
          style={{
            border: "2px solid white",
            color: task?.due_in?.toString().includes("-") ? "red" : "#131f4c" // Red if `due_in` contains "-"
          }}
        >
          {/* {changedDateFormat(task.deadline_time)} */}
          {task?.due_in == null ? "-" : task?.due_in}
        </td>
        {/* <td
        className={
          task.task_status === "To be assigned"
            ? "figbtn-success"
            : "figbtn-success"
        }
        onClick={(event) =>
          setTaskDetailsinfo(task)
        }
      >
        <span className="figstatus">
          {task.task_status}
        </span>
      </td> */}
        <td
          style={{
            border: 2,
            borderColor: "white",
            borderStyle: "solid"
            // cursor: task?.task_status !== "Scheduled" ? 'not-allowed' : "pointer"
          }}
        >
          <div className="d-flex  align-items-center m-0 p-0">
            <div className="d-flex mb-1 align-items-center">
              {/* <a data-tooltip-id="my-tooltip"
          <div className="d-flex  m-0 p-0">
            <div className="d-flex mb-1 ">
              <a data-tooltip-id="my-tooltip"
                data-tooltip-content={userInfoDetails?.data?.id !== task?.task_owner?.id && userInfoDetails?.data?.id !== task?.task_creator?.id ? "You don't have permission to edit this field!" : ""} style={userInfoDetails?.data?.id !== task?.task_owner?.id || userInfoDetails?.data?.id !== task?.task_creator?.id ? { cursor: "not-allowed" } : {}}
              > */}
              <Select
                className="p"
                name="task_status"
                placeholder={"Select"}
                options={taskStatusOptions}
                value={
                  task?.task_success === "Beat"
                    ? { value: "beat", label: "Epic" }
                    : task?.task_success === "Hit"
                      ? { value: "hit", label: "Ok" }
                      : task?.task_success === "Miss"
                        ? { value: "miss", label: "Miss" }
                        : task?.task_success === "Not Complete"
                          ? { value: "not_complete", label: "Not Complete" }
                          : { value: "not_complete", label: "Not Complete" }
                }
                onChange={(event) => handleCircleClick(index, event, task)}
                styles={{
                  control: (base) => ({
                    ...base,
                    width: "100px"
                  })
                }}
              // styles={customStyles(task?.task_success)}
              // isDisabled={
              //   task?.task_owner?.id !==
              //   userInfoDetails?.data?.id &&
              //   task?.task_creator?.id !==
              //   userInfoDetails?.data?.id
              // }
              />
              {/* </a> */}
              <ReactTooltip effect="solid" id="my-tooltip" />
            </div>
          </div>
        </td>
        <td>
          {/* {task?.task_owner?.id ===
            userInfoDetails?.data?.id ||
            task?.task_creator?.id ===
            userInfoDetails?.data?.id ||
            userInfoDetails?.data?.user_role === "owner" ? ( */}
          <>
            <Dropdown className="ms-auto">
              <Dropdown.Toggle variant="" as="div" className="btn-link i-false">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="12.4999" cy="3.5" r="2.5" fill="#223A69" />
                  <circle cx="12.4999" cy="11.5" r="2.5" fill="#223A69" />
                  <circle cx="12.4999" cy="19.5" r="2.5" fill="#223A69" />
                </svg>
              </Dropdown.Toggle>

              {ReactDOM.createPortal(
                <Dropdown.Menu
                  alignRight={true}
                  data-testid="org-menu"
                  className="dropdown-menu-right"
                  style={{ position: "absolute", zIndex: 1050 }}
                >
                  <Dropdown.Item
                    data-testid="org-edit"
                    onClick={(event) => {
                      event.preventDefault();
                      setTaskDetailsinfo(task);
                    }}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    data-testid="org-delete"
                    onClick={(event) => {
                      event.preventDefault();
                      setShareTaskModal(true);
                      setSelectedShareTask(task);
                      SetOpenDeleteModal(false);
                      setGoalsdelete(false);
                      setGoalsupdate(false);
                      setGoalscreated(false);
                      setTaskcreated(false);
                      setTaskupdate(false);
                      setTaskdelete(false);
                    }}
                  >
                    Share
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="text-danger"
                    data-testid="org-delete"
                    onClick={(event) => {
                      event.preventDefault();
                      selectedTaskForDelete(event, task);
                      SetOpenDeleteModal(true);
                      setGoalsdelete(false);
                      setGoalsupdate(false);
                      setGoalscreated(false);
                      setTaskcreated(false);
                      setTaskupdate(false);
                      setTaskdelete(false);
                    }}
                  >
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>,
                document.body // This ensures the Dropdown.Menu is rendered outside the regular DOM hierarchy
              )}
            </Dropdown>
          </>
          {/* </>) : (<>
              -
            </>)} */}
        </td>
      </tr>
    );
  };

  const [inputValues, setInputValues] = useState({
    srNo: "",
    status: "",
    objective: "",
    complete: "",
    wins: "",
    onTime: "",
    risk: "",
    objectiveStatus: ""
  });

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Validation logic
    const newErrors = {};

    if (!inputValues.objective) newErrors.objective = "Goal name is required";

    // If there are errors, set the error state and do not submit the form
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    const newGoal = {
      // id: goals.length + 1,
      order: inputValues.srNo,
      goal_status: inputValues.status,
      name: inputValues.objective,
      goal_completed_percent: inputValues.complete,
      goal_win_percent: inputValues.wins,
      goal_on_time_percent: inputValues.onTime,
      goal_risk_average: inputValues.risk,
      status: inputValues.objectiveStatus
    };
    dispatch(
      createGoalAction(
        orgId,
        userInfoDetails.data.id,
        goalOwner?.userId,
        selectedTeam,
        newGoal.name,
        // scopes,
        "", // newGoal.description,
        selectedGoal[0] !== null ? selectedGoal[0]?.id : null,
        [], // datas,
        userInfoDetails.data.accessToken,
        false, // isSuperParent,
        newGoal?.order,
        isToggled ? "Active" : "Inactive"
      )
    );

    setRowAddGoals(false);
    // rowAddGoals1(false)
    // setGoals((prevGoals) => [...prevGoals, newGoal]);
    setInputValues({
      srNo: "",
      status: "",
      objective: "",
      complete: "",
      wins: "",
      onTime: "",
      risk: "",
      objectiveStatus: ""
    });
    setErrors({});
  };

  const progressBarFilledStyle = {
    backgroundColor: "#d4ebe2" // Custom fill color for the progress
  };

  const progressBarWarningFilledStyle = {
    backgroundColor: "#f5d9b0" // Custom fill color for the progress
  };

  const progressBarFailingFilledStyle = {
    backgroundColor: "#eeaeba" // Custom fill color for the progress
  };

  const handleClick = () => {
    navigate("/goals");
  };

  const labelStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    textAlign: "center",
    color: "#26996d",
    // fontWeight: 'bold',
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis", // Displays '...' if the text is too long
    zIndex: 1,
    padding: "10px 20px" // Padding for some space around the text
  };

  const labelWarningStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    textAlign: "center",
    color: "#e5a139",
    // fontWeight: 'bold',
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis", // Displays '...' if the text is too long
    zIndex: 1,
    padding: "10px 20px" // Padding for some space around the text
  };

  const labelFailingStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    textAlign: "center",
    color: "#d43551",
    // fontWeight: 'bold',
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis", // Displays '...' if the text is too long
    zIndex: 1,
    padding: "10px 20px" // Padding for some space around the text
  };

  const progressBarStyle = {
    backgroundColor: "white",
    position: "relative",
    height: "40px",
    width: "100%",
    maxWidth: "100%",
    border: "1px solid #a8d6c5"
  };

  const progressWarningBarStyle = {
    backgroundColor: "white",
    position: "relative",
    height: "40px",
    width: "100%",
    maxWidth: "100%",
    border: "1px solid #f5d9b0"
  };

  const progressFailingBarStyle = {
    backgroundColor: "white",
    position: "relative",
    height: "40px",
    width: "100%",
    maxWidth: "100%",
    border: "1px solid #eeaeba"
  };

  const renderTooltip = (props, objective) => {
    const getTooltipText = () => {
      const { goal_completed_percent, goal_status } = objective;
      if (goal_completed_percent >= 80 && goal_status === "Failed") {
        return `${goal_completed_percent}%  complete. Status: ${goal_status} despite good progress due to poor task outcomes and delays. Improve outcome quality to recover.`;
      } else if (
        goal_completed_percent >= 50 &&
        goal_completed_percent < 80 &&
        goal_status === "Failed"
      ) {
        return `${goal_completed_percent}% complete. Status: ${goal_status} due to low task outcomes and significant delays.`;
      } else if (
        goal_completed_percent > 30 &&
        goal_completed_percent < 50 &&
        goal_status === "Failed"
      ) {
        return `${goal_completed_percent}% complete. Status: ${goal_status} due to poor outcomes and challenges.`;
      } else if (goal_completed_percent <= 30 && goal_status === "Failed") {
        return `${goal_completed_percent}% complete. Status: ${goal_status} due to low task outcomes and significant delays.`;
      } else if (goal_completed_percent >= 75 && goal_status === "Near Risk") {
        return `${goal_completed_percent}% complete. Status: ${goal_status} despite good progress due to inconsistent task outcomes and borderline delays.`;
      } else if (
        goal_completed_percent >= 60 &&
        goal_completed_percent < 75 &&
        goal_status === "Near Risk"
      ) {
        return `${goal_completed_percent}% complete. Status: ${goal_status} due to mixed task outcomes and moderate delays.`;
      } else if (
        goal_completed_percent >= 35 &&
        goal_completed_percent < 60 &&
        goal_status === "Near Risk"
      ) {
        return `${goal_completed_percent}% complete. Status: ${goal_status} with limited progress and inconsistent outcomes.`;
      } else if (goal_completed_percent < 35 && goal_status === "Near Risk") {
        return `${goal_completed_percent}% complete. Status: ${goal_status} with significant challenges and poor outcomes.`;
      } else if (goal_completed_percent >= 85 && goal_status === "On track") {
        return `${goal_completed_percent}% complete. Status: ${goal_status} with strong progress and positive task outcomes.`;
      } else if (
        goal_completed_percent >= 60 &&
        goal_completed_percent < 85 &&
        goal_status === "On track"
      ) {
        return `${goal_completed_percent}% complete. Status: ${goal_status} with steady progress and positive task outcomes.`;
      } else if (
        goal_completed_percent > 40 &&
        goal_completed_percent < 60 &&
        goal_status === "On track"
      ) {
        return `${goal_completed_percent}% complete. Status: ${goal_status} with moderate progress.`;
      } else if (goal_completed_percent <= 40 && goal_status === "On track") {
        return `${goal_completed_percent}% complete. Status: ${goal_status} despite limited progress.`;
      } else if (goal_completed_percent >= 25 && goal_status == "Failing") {
        return `${goal_completed_percent}% complete. Status: ${goal_status} due to suboptimal task outcomes and some delays.`;
      }
      return `${goal_completed_percent}% Complete. Status: ${goal_status}. Check details here.`;
    };

    return (
      <Tooltip id="progress-tooltip" {...props} className="fs-18">
        {getTooltipText()}
        {/* <IoArrowForwardCircleSharp /> */}
      </Tooltip>
    );
  };

  const handleMarkAsComplete = (task, status) => {
    if (status == "hit" || status == "beat" || status == "miss") {
      getfeedbackTask(task, status);
    } else if (
      status == "not_complete" &&
      task?.task_status != "To be scheduled"
    ) {
      const data2 = markTaskAsIncomplete(
        task?.id,
        userInfoDetails.data.accessToken
      );
      data2?.then(async (res) => {
        getAllSubGoalData(selectedGoal[0]?.id, goToSubGoalPage);
        getTasksByGoalId(goToTaskPage);
        getAllOrgGoal(goToPage);
        toast(
          <>
            <div className="m-1 d-flex">
              <i
                class="bi bi-check-circle fs-26 me-3"
                style={{ color: "#131F4C" }}
              ></i>
              <div>
                <h2
                  style={{
                    // color: "#131F4C",
                    fontSize: "22px"
                  }}
                >
                  Completed
                </h2>
                <h3
                  style={{
                    // color: "#131F4C",
                    fontSize: "18px"
                  }}
                >
                  Your task has been mark as incomplete successfully!
                </h3>
              </div>
            </div>
          </>,
          {
            toastId: "task-schedule",
            className: "toast-success-container toast-success-container-after "
          }
        );
      });
      data2?.catch(async (err) => {
        toast(
          <>
            <div className="m-1 d-flex">
              <i
                class="bi bi-check-circle fs-26 me-3"
                style={{ color: "#131F4C" }}
              ></i>
              <div>
                <h2
                  style={{
                    fontSize: "22px"
                  }}
                >
                  Failed
                </h2>
                <h3
                  style={{
                    fontSize: "18px"
                  }}
                >
                  {err?.response?.data?.message}
                </h3>
              </div>
            </div>
          </>,
          {
            toastId: "task-schedule",
            className: "toast-error-container toast-error-container-after"
          }
        );
      });
    } else {
      setValidationMessages((prev) => ({
        ...prev,
        [task.id]: `* Please select Task Success to mark it as complete`
      }));

      setMarkAsStatus((prev) => ({
        ...prev,
        marked: true,
        taskId: task.id
      }));
    }
  };

  const handleCircleClick = (index, event, task) => {
    setTaskStatuses((prevStatuses) => ({
      ...prevStatuses,
      [index]: event?.value // Update the status for the specific row (task) by its index
    }));

    handleMarkAsComplete(task, event?.value);
    changeTaskStatus(event?.value, task);
  };

  const changeTaskStatus = (selectedOption, task) => {
    setTaskStatusValue((prev) => ({ ...prev, [task?.id]: selectedOption }));
    setValidationMessages((prev) => ({ ...prev, [task?.id]: "" }));
    if (markAsStatus?.taskId === task?.id && selectedOption != "not_complete") {
      if (markAsStatus?.marked === true) {
        getfeedbackTask(task, selectedOption);
      }
    }
  };

  function getfeedbackTask(task, selectedTaskStatus) {
    const data = taskFeedback(
      task?.id,
      userInfoDetails?.data?.accessToken,
      true,
      selectedTaskStatus,
      task?.task_effort
    );
    data?.then(async (res) => {
      setTaskStatuses({});
      getAllSubGoalData(selectedGoal[0]?.id, goToSubGoalPage);
      getTasksByGoalId(goToTaskPage);
      getAllOrgGoal(goToPage);
      // getAllScheduledTasks();
      // getUserSpecificTask();
      // getHistoryTask();
      // getMyPanDetails()
      setTaskStatusValue({ value: "", label: "" });
      const data = getGoalById(
        selectedGoal[0]?.id,
        userInfoDetails?.data?.accessToken
      );
      data?.then(async (res) => {
        setSelectedGoal([res?.data?.data]);
      });
      toast(
        <>
          <div className="m-1 d-flex">
            <i
              class="bi bi-check-circle fs-26 me-3"
              style={{ color: "#131F4C" }}
            ></i>
            <div>
              <h2
                style={{
                  // color: "#131F4C",
                  fontSize: "22px"
                }}
              >
                Completed
              </h2>
              <h3
                style={{
                  // color: "#131F4C",
                  fontSize: "18px"
                }}
              >
                Your task has been mark as complete successfully!
              </h3>
            </div>
          </div>
        </>,
        {
          toastId: "task-schedule",
          className: "toast-success-container toast-success-container-after "
        }
      );
    });
    data?.catch(async (err) => {
      setTaskStatuses({});
      toast(
        <>
          <div className="m-1 d-flex">
            <i
              className="bi bi-check-circle fs-26 me-3"
              style={{ color: "#131F4C" }}
            ></i>
            <div>
              <h2 style={{ fontSize: "22px" }}>Failed</h2>
              <h3 style={{ fontSize: "18px" }}>
                {err?.response?.data?.message}
              </h3>
            </div>
          </div>
        </>,
        {
          toastId: "task-schedule",
          className: "toast-error-container toast-error-container-after"
        }
      );
    });
  }

  const getAdjustedTaskEffort = (effort) => {
    if (typeof effort !== "number") return effort;
    const integerPart = Math.floor(effort);
    const decimalPart = effort - integerPart;
    const adjustedMinutes = Math.round(decimalPart * 60);
    const formattedMinutes = adjustedMinutes.toString().padStart(2, "0");
    return `${integerPart}:${formattedMinutes}`;
  };

  const reverseAdjustedTaskEffort = (timeString) => {
    setTaskEffortFilter(timeString);
    const [hours, minutes] = timeString.split(":").map(Number);
    if (isNaN(hours) || isNaN(minutes)) return timeString;

    const decimalMinutes = (minutes / 60).toFixed(2).slice(1);
    setTaskEffortFilter(parseFloat(hours + decimalMinutes));
    return parseFloat(hours + decimalMinutes);
  };

  useEffect(() => {
    if (isSubGoalCreated || isTaskCreated) {
      updateSelectedGoalAfterToggle();
    }
  }, [isSubGoalCreated, isTaskCreated]);

  return (
    <>
      {shareModel && (
        <ShareModal
          shareModel={shareModel}
          goal={selectedShareGoal}
          setShareModelParent={setShareModal}
        ></ShareModal>
      )}

      {shareTaskModal && (
        <ShareTaskModal
          shareModel={shareTaskModal}
          task={selectedShareTask}
          setShareModelParent={setShareTaskModal}
        ></ShareTaskModal>
      )}

      {ShowGoalModal && (
        <GoalModal
          show={ShowGoalModal}
          goal={CreateTask}
          handle={handleClose}
          Subgoal={{ createSubGoal, selectedGoals, EditTask }}
          setIsSubGoalCreated={setIsSubGoalCreated}
        ></GoalModal>
      )}

      {showTaskMoadal && (
        <CreateTaskModal
          show={showTaskMoadal}
          goal={goalDetails}
          owner={taskOwner}
          handle={handleClose}
          setIsTaskCreated={setIsTaskCreated}
        ></CreateTaskModal>
      )}

      {taskDetailsinfo && (
        <TaskEdit
          task={taskDetailsinfo}
          setTask={setTaskDetailsinfo}
          setIsTaskUpdated={setIsTaskUpdated}
        />
      )}

      {goalsdelete &&
        toast(
          <>
            <div className="m-1 d-flex">
              <i
                class="bi bi-check-circle fs-26 me-3"
                style={{ color: "#131F4C" }}
              ></i>
              <div>
                <h2
                  style={{
                    // color: "#ffffff",
                    fontSize: "22px"
                  }}
                >
                  Completed
                </h2>
                <h3
                  style={{
                    // color: "#ffffff",
                    fontSize: "18px"
                  }}
                >
                  Your goal has been deleted successfully!
                </h3>
              </div>
            </div>
          </>,
          {
            toastId: "",
            className: "toast-success-container toast-success-container-after "
          }
        )}
      {goalsupdate
        ? toast(
          <>
            <div className="m-1 d-flex">
              <i
                class="bi bi-check-circle fs-26 me-3"
                style={{ color: "#131F4C" }}
              ></i>
              <div>
                <h2
                  style={{
                    // color: "#ffffff",
                    fontSize: "22px"
                  }}
                >
                  Completed
                </h2>
                <h3
                  style={{
                    // color: "#ffffff",
                    fontSize: "18px"
                  }}
                >
                  Your goal has been updated successfully!
                </h3>
              </div>
            </div>
          </>,
          {
            toastId: "",
            className:
              "toast-success-container toast-success-container-after "
          }
        )
        : ""}
      {taskdelete &&
        toast(
          <>
            <div className="m-1 d-flex">
              <i
                class="bi bi-check-circle fs-26 me-3"
                style={{ color: "#131F4C" }}
              ></i>
              <div>
                <h2
                  style={{
                    // color: "#ffffff",
                    fontSize: "22px"
                  }}
                >
                  Completed
                </h2>
                <h3
                  style={{
                    // color: "#ffffff",
                    fontSize: "18px"
                  }}
                >
                  Your task has been deleted successfully!
                </h3>
              </div>
            </div>
          </>,
          {
            toastId: "task-delete",
            className: "toast-success-container toast-success-container-after "
          }
        )}
      {taskupdate &&
        toast(
          <>
            <div className="m-1 d-flex">
              <i
                class="bi bi-check-circle fs-26 me-3"
                style={{ color: "#131F4C" }}
              ></i>
              <div>
                <h2
                  style={{
                    // color: "#ffffff",
                    fontSize: "22px"
                  }}
                >
                  Completed
                </h2>
                <h3
                  style={{
                    // color: "#ffffff",
                    fontSize: "18px"
                  }}
                >
                  Your task has been updated successfully!
                </h3>
              </div>
            </div>
          </>,
          {
            toastId: "task-update",
            className: "toast-success-container toast-success-container-after "
          }
        )}

      {/* Warning modal */}
      <Modal
        show={OpenWarningModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        top
        style={{ borderRadius: "0rem" }}
      >
        <Modal.Title className="my-3 mx-4" id="contained-modal-title-vcenter">
          <img
            className="float-start me-1 mt-1 p-2"
            alt="warning-img"
            title="warning-img"
            src={Vector3}
          />
          <h4 className="fs-20 p-2">Warning!</h4>
        </Modal.Title>
        <Modal.Body className="modal-body mx-4 p-0">
          <p className="p-2 pt-0" style={{ color: "#717994" }}>
            Please create organization before creating a goal
            <Link className="figma-link mt-2 me-3" to="/organization-list">
              &nbsp;here...
            </Link>
          </p>
        </Modal.Body>
        <div className="d-flex  justify-content-end mx-4 mb-3">
          <button
            className="figma-btn text-white"
            onClick={() => {
              setOpenWarningModal(false);
            }}
          >
            Ok
          </button>
        </div>
      </Modal>

      {/* Delete modal */}
      <Modal
        show={OpenDeleteModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ borderRadius: "0rem" }}
      >
        <Modal.Title className="my-3 mx-4" id="contained-modal-title-vcenter">
          <img
            className="float-start me-1 mt-1 p-2"
            alt="delete-modal"
            title="delete-modal"
            src={Vector3}
          />
          <h4 className="fs-20 p-2">
            Are you sure you want to delete{" "}
            {SelectedTaskDelete?.task_impact ? "Task?" : "Goal?"}
          </h4>
        </Modal.Title>
        <Modal.Body className="modal-body mx-4 p-0">
          <p className="p-2 pt-0" style={{ color: "#717994" }}>
            You will not be able to recover this{" "}
            {SelectedTaskDelete?.task_impact ? "Task!" : "Goal!"}
          </p>
        </Modal.Body>
        <div className="d-flex  justify-content-end mx-4 mb-3">
          <Link
            className="figma-link mt-2 me-3"
            to="/goals"
            onClick={(e) => {
              e.preventDefault();
              SetOpenDeleteModal(false);
              setDeleteParentGoal(false);
              setSelectedTaskDelete();
              setSelectedGoalDelete();
            }}
          >
            No, cancel it!
          </Link>
          <button
            className="figma-btn text-white"
            onClick={(event) => {
              setSelectedGoals([]);
              if (deleteParentGoal) {
                handleGoalNavigation(event);
              }
              SelectedTaskDelete?.task_impact
                ? handleDeleteTaskClick()
                : handleDeleteGoalClick();
              setSelectedTaskDelete();
              setSelectedGoalDelete();
            }}
          >
            Yes, I am Sure!
          </button>
        </div>
      </Modal>

      <Modal
        className="fade bd-example-modal-lg"
        show={assignGoalModal}
        size="md"
      >
        <Modal.Body>
          <div className="text-center m-0">
            <button
              variant=""
              className="btn-close btn-danger float-end align-center m-2 p-2"
              onClick={() => {
                setAssignGoalEditModal(false);
                setErrors(errorsObj);
                setCreateGoalForm({ name: "", description: "" });
              }}
            ></button>
          </div>
          <h2 className="text-center  my-1">Assign goal</h2>
          <form onSubmit={(e) => submitEditGoal(e)}>
            <h5 className="text-primary font-w600 font-h800 mt-3 mb-2">
              Goal name - <span className="p">{goalDetails?.name}</span>
            </h5>

            <Row className="mt-2">
              {scope === "organization" && (
                <Col>
                  <h5 className="text-primary pb-0">Owner</h5>
                  <Select
                    name="goal_owner"
                    className="p"
                    options={options}
                    styles={{ padding: "2px", borderRadius: "10px" }}
                    value={goalOwner}
                    onChange={changeGoalOwner}
                  />
                  {userRole === "owner" && (
                    <h5 className="float-end mt-1">
                      Create users
                      <Link className="figma-link" to={"/invite-users"}>
                        {" "}
                        <u>here</u>
                      </Link>
                    </h5>
                  )}
                  {errors.goalOwner && (
                    <div className="text-danger">{errors.goalOwner}</div>
                  )}
                </Col>
              )}
            </Row>
            <Col className="text-center mt-3">
              <button type="submit" className="figma-btn py-2">
                Assign
              </button>
            </Col>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={openWarningModalForActive}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ borderRadius: "0rem" }}
      >
        <Modal.Title className="my-3 mx-4" id="contained-modal-title-vcenter">
          <img
            className="float-start me-1 mt-1 p-2"
            alt="delete-modal"
            title="delete-modal"
            src={Vector3}
          />
          <h4 className="fs-20 p-2">
            Are you sure you want to active this task?
          </h4>
        </Modal.Title>
        <Modal.Body className="modal-body mx-4 p-0">
          <p className="p-2 pt-0" style={{ color: "#717994" }}>
            If you set the task's active state, the goal status will also update
            to active.
          </p>
        </Modal.Body>
        <div className="d-flex  justify-content-end mx-4 mb-3">
          <Link
            className="figma-link mt-2 me-3"
            onClick={() => setOpenWarningModalForActive(false)}
          >
            No, cancel it!
          </Link>
          <button
            className="figma-btn text-white"
            onClick={() => {
              handleActivateTaskClick();
              setOpenWarningModalForActive(false);
            }}
          >
            Yes, I am Sure!
          </button>
        </div>
      </Modal>

      <Modal
        show={openWarningModalForGoalActive}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ borderRadius: "0rem" }}
      >
        <Modal.Title className="my-3 mx-4" id="contained-modal-title-vcenter">
          <img
            className="float-start me-1 mt-1 p-2"
            alt="delete-modal"
            title="delete-modal"
            src={Vector3}
          />
          <h4 className="fs-20 p-2">
            Are you sure you want to active this goal?
          </h4>
        </Modal.Title>
        <Modal.Body className="modal-body mx-4 p-0">
          <p className="p-2 pt-0" style={{ color: "#717994" }}>
            If you set the goal's active state, the parent goal status will also
            update to active.
          </p>
        </Modal.Body>
        <div className="d-flex  justify-content-end mx-4 mb-3">
          <Link
            className="figma-link mt-2 me-3"
            onClick={() => setOpenWarningModalForGoalActive(false)}
          >
            No, cancel it!
          </Link>
          <button
            className="figma-btn text-white"
            onClick={() => {
              handleActivateGoalClick();
              setOpenWarningModalForGoalActive(false);
            }}
          >
            Yes, I am Sure!
          </button>
        </div>
      </Modal>

      <Modal
        show={openWarningModalForSubGoalActive}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ borderRadius: "0rem" }}
      >
        <Modal.Title className="my-3 mx-4" id="contained-modal-title-vcenter">
          <img
            className="float-start me-1 mt-1 p-2"
            alt="delete-modal"
            title="delete-modal"
            src={Vector3}
          />
          <h4 className="fs-20 p-2">
            Are you sure you want to active this goal?
          </h4>
        </Modal.Title>
        <Modal.Body className="modal-body mx-4 p-0">
          <p className="p-2 pt-0" style={{ color: "#717994" }}>
            If you set the goal's active state, the parent goal status will also
            update to active.
          </p>
        </Modal.Body>
        <div className="d-flex  justify-content-end mx-4 mb-3">
          <Link
            className="figma-link mt-2 me-3"
            onClick={() => setOpenWarningModalForSubGoalActive(false)}
          >
            No, cancel it!
          </Link>
          <button
            className="figma-btn text-white"
            onClick={() => {
              handleActivateSubGoalClick();
              setOpenWarningModalForSubGoalActive(false);
            }}
          >
            Yes, I am Sure!
          </button>
        </div>
      </Modal>

      {selectedGoal[0] ? (
        <>
          <div className="row d-flex mb-3 p-0">
            <div className="float-start">
              {/* <Link
                className="fa fa-reply fs-22 pt-2"
                to="/goals"
                onClick={() => {
                  getAllOrgGoal(goToPage);
                  setSelectedGoal("");
                  // setSelectedGoal(selectedGoal[0]?.goal_parent)
                  setGoalsdelete(false);
                  setGoalsupdate(false);
                  setGoalscreated(false);
                  setTaskcreated(false);
                  setTaskupdate(false);
                  setTaskdelete(false);
                }}
              /> */}

              <span
                className="fa fa-reply fs-22 pt-2"
                style={{ cursor: "pointer" }}
                // to="/goals"
                onClick={handleGoalNavigation}
              />
            </div>
          </div>

          <Row>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="">Goal</h3>
                </div>
                <div className="card-body ">
                  <div className="table-responsive">
                    <div className="table-responsive">
                      <div id="job_data" className="dataTables_wrapper">
                        <table
                          className="display w-100 dataTable "
                          id="example5"
                          role="grid"
                          aria-describedby="example5_info"
                          style={{ overflowY: "none" }}
                        >
                          <thead>
                            <tr role="row">
                              {/* <th className="sorting_asc  status">Status</th> */}
                              <th
                                className="sorting goalwidth"
                                style={{ fontWeight: "bold", color: "#131F4C" }}
                              >
                                Goal
                              </th>
                              <th
                                className="sorting  perFields"
                                style={{ fontWeight: "bold", color: "#131F4C" }}
                              >
                                Who
                              </th>
                              <th
                                className="sorting  perFields"
                                style={{ fontWeight: "bold", color: "#131F4C" }}
                              >
                                Active
                              </th>
                              {/* <th className="sorting" style={{ width: "20vw" }}>Description</th> */}
                              {/* <th className="sorting  perFields">Complete</th> */}
                              {/* <th className="sorting perFields">Wins</th> */}
                              {/* <th className="sorting perFields">On-time</th> */}
                              {/* <th className="sorting perFields">Risk</th>
                              <th className="sorting perFields">
                                Goal status
                              </th> */}
                              {/* <th>Status</th> */}
                              <th
                                className="status"
                                style={{ fontWeight: "bold", color: "#131F4C" }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <>
                              <tr
                                role="row"
                                onClick={(event) => {
                                  setGoalsdelete(false);
                                  setGoalsupdate(false);
                                  setGoalscreated(false);
                                  setTaskcreated(false);
                                  setTaskupdate(false);
                                  setTaskdelete(false);
                                }}
                              >
                                <td
                                  onClick={(event) => {
                                    setGoalsdelete(false);
                                    setGoalsupdate(false);
                                    setGoalscreated(false);
                                    setTaskcreated(false);
                                    setTaskupdate(false);
                                    setTaskdelete(false);
                                    handleGoalEditClick(event, selectedGoal[0]);
                                  }}
                                >
                                  <OverlayTrigger
                                    placement="bottom" // Tooltip position
                                    overlay={(props) =>
                                      renderTooltip(props, selectedGoal[0])
                                    } // Pass `objective` to `renderTooltip`
                                  >
                                    <div
                                      style={{
                                        position: "relative",
                                        width: "80%"
                                      }}
                                    >
                                      <ProgressBar
                                        now={40} // Adjust the progress value as per your need
                                        label={null} // Label is handled manually
                                        style={
                                          selectedGoal[0].goal_status ===
                                            "On track"
                                            ? progressBarStyle
                                            : selectedGoal[0].goal_status ===
                                              "At risk"
                                              ? progressWarningBarStyle
                                              : progressFailingBarStyle
                                        }
                                      >
                                        <div
                                          style={{
                                            ...(selectedGoal[0].goal_status ===
                                              "On track"
                                              ? progressBarFilledStyle
                                              : selectedGoal[0].goal_status ===
                                                "At risk"
                                                ? progressBarWarningFilledStyle
                                                : progressBarFailingFilledStyle),
                                            width: `${selectedGoal[0]?.goal_completed_percent}%`, // The percentage width of the filled part
                                            height: "100%"
                                          }}
                                        />
                                      </ProgressBar>
                                      <span
                                        style={
                                          selectedGoal[0].goal_status ===
                                            "On track"
                                            ? labelStyle
                                            : selectedGoal[0].goal_status ===
                                              "At risk"
                                              ? labelWarningStyle
                                              : labelFailingStyle
                                        }
                                      >{`${selectedGoal[0]?.name || "Goal Name"
                                        }`}</span>
                                    </div>
                                  </OverlayTrigger>
                                </td>

                                <td>
                                  {selectedGoal[0]?.goal_owner?.first_name[0]?.toUpperCase()}
                                  {selectedGoal[0]?.goal_owner?.last_name[0]?.toUpperCase()}
                                </td>

                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center"
                                    }}
                                  >
                                    <div>{/* {goal?.status}  */}</div>
                                    <div>
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            checked={
                                              selectedGoal[0].status ===
                                              "Active"
                                            }
                                            onChange={handleTogglesss(
                                              selectedGoal[0]
                                            )}
                                            name="statusToggle"
                                            color="primary"
                                          />
                                        }
                                      />
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <>
                                    <Dropdown className="pt-0 mt-0">
                                      <Dropdown.Toggle
                                        variant=""
                                        as="div"
                                        className="btn-link i-false"
                                      >
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            cx="12.4999"
                                            cy="3.5"
                                            r="2.5"
                                            fill="#131f4c"
                                          />
                                          <circle
                                            cx="12.4999"
                                            cy="11.5"
                                            r="2.5"
                                            fill="#131f4c"
                                          />
                                          <circle
                                            cx="12.4999"
                                            cy="19.5"
                                            r="2.5"
                                            fill="#131f4c"
                                          />
                                        </svg>
                                      </Dropdown.Toggle>

                                      {/* Use ReactDOM.createPortal to render the Dropdown.Menu outside */}
                                      {ReactDOM.createPortal(
                                        <Dropdown.Menu
                                          className="m-0 p-1"
                                          style={{
                                            position: "absolute",
                                            zIndex: 1050
                                          }}
                                        >
                                          <Dropdown.Item
                                            onClick={(event) => {
                                              setGoalOwnerValue({
                                                value:
                                                  selectedGoal[0]?.goal_owner
                                                    ?.first_name +
                                                  " " +
                                                  selectedGoal[0]?.goal_owner
                                                    ?.last_name,
                                                label:
                                                  selectedGoal[0]?.goal_owner
                                                    ?.first_name +
                                                  " " +
                                                  selectedGoal[0]?.goal_owner
                                                    ?.last_name,
                                                userId:
                                                  selectedGoal[0]?.goal_owner
                                                    ?.id
                                              });
                                              handleCreateSubGoal(
                                                event,
                                                selectedGoal[0]
                                              );
                                              setShowGoalModal(true);
                                              setCreateSubGoal(true);
                                              setCreateTask(true);
                                            }}
                                          >
                                            Create subgoal
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() => {
                                              setTaskOwnerValue({
                                                value:
                                                  selectedGoal[0]?.goal_owner
                                                    ?.first_name +
                                                  " " +
                                                  selectedGoal[0]?.goal_owner
                                                    ?.last_name,
                                                label:
                                                  selectedGoal[0]?.goal_owner
                                                    ?.first_name +
                                                  " " +
                                                  selectedGoal[0]?.goal_owner
                                                    ?.last_name,
                                                userId:
                                                  selectedGoal[0]?.goal_owner
                                                    ?.id
                                              });
                                              setGoalsdelete(false);
                                              setGoalsupdate(false);
                                              setGoalscreated(false);
                                              setTaskcreated(false);
                                              setTaskupdate(false);
                                              setTaskdelete(false);
                                              setSelectedTeam(
                                                teamList[0]?.userId
                                              );
                                              setGoalDetails(selectedGoal[0]);
                                              changeGoal(selectedGoal[0]);
                                              setshowTaskMoadal(true);
                                            }}
                                          >
                                            Create task
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={(event) => {
                                              handleGoalEditClick(
                                                event,
                                                selectedGoal[0]
                                              );
                                            }}
                                          >
                                            Edit
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={(event) => {
                                              archiveGoalHandler(
                                                event,
                                                selectedGoal[0]
                                              );
                                            }}
                                          >
                                            Archive
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={(event) => {
                                              setShareModal(true);
                                              setSelectedShareGoal(
                                                selectedGoal[0]
                                              );
                                            }}
                                          >
                                            Share
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            className="text-danger"
                                            onClick={(event) => {
                                              selectedGoalForDelete(
                                                event,
                                                selectedGoal[0]
                                              );
                                              SetOpenDeleteModal(true);
                                              setDeleteParentGoal(true);
                                            }}
                                          >
                                            Delete
                                          </Dropdown.Item>
                                        </Dropdown.Menu>,
                                        document.body // Portal the dropdown menu to the body
                                      )}
                                    </Dropdown>
                                  </>
                                </td>
                              </tr>
                            </>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
          {subGoalHide && (
            <Row className="">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3>
                      {" "}
                      Aligned subgoal for goal "
                      <span> {selectedGoal[0]?.name}</span> "
                    </h3>
                    <div className="float-end p-0 m-0">
                      {(subGoalNameFilter ||
                        selectedSubStatusItem ||
                        subCompleteStartRange ||
                        subCompleteEndRange ||
                        subWinsStartRange ||
                        subWinsEndRange ||
                        subOntimeStartRange ||
                        subOntimeEndRange ||
                        subRiskFilter ||
                        subGoalOwnerFilter ||
                        subStatusFilter) && (
                          <span
                            className=" mx-3 pt-2 "
                            title="Clear all filter"
                            onClick={(e) => {
                              clearAllSubGoalFilter(e);
                              setToggleData();
                            }}
                            style={{
                              cursor: "pointer",
                              color: "#DC5D74"
                            }}
                          >
                            Clear all filter
                            <i className="fas fa-filter fs-18"></i>
                            <span>x</span>
                          </span>
                        )}

                      {toggleData ? (
                        <i
                          title="Close"
                          class="bi bi-caret-up-fill fs-24"
                          onClick={() => {
                            setToggleData(!toggleData);
                            close();
                          }}
                        ></i>
                      ) : (
                        <i
                          title="Open"
                          class="bi bi-caret-down-fill fs-24" //fas fa-angle-down fs-30
                          onClick={() => {
                            setToggleData(!toggleData);
                            close();
                          }}
                        ></i>
                      )}
                    </div>
                  </div>
                  {toggleData && (
                    <div>
                      <div className=" card-body  ">
                        <DndProvider backend={HTML5Backend}>
                          <div className="table-responsive">
                            <div id="job_data" className="dataTables_wrapper">
                              {showSubTableSpinner ? (
                                <div
                                  style={{
                                    position: "relative",
                                    top:
                                      allSubGoals?.length < 3
                                        ? "6rem"
                                        : goals.length >= 3
                                          ? "9rem"
                                          : "1rem"
                                  }}
                                  className="m-0 p-0"
                                >
                                  <LoadingSpinner />
                                </div>
                              ) : (
                                ""
                              )}
                              <table
                                className="display w-100 dataTable "
                                id="example5"
                                role="grid"
                                aria-describedby="example5_info"
                                style={
                                  allSubGoals?.length < 2
                                    ? { minHeight: "110px" }
                                    : { minHeight: "200px" }
                                }
                              >
                                <thead>
                                  <tr role="row">
                                    {/* <th style=d{{paddingRight:"55px"}}>Sr. No</th>   */}

                                    {/* <th className="sorting_asc  status">
                                      <div className="d-flex m-0 p-0">
                                        <span
                                          className="float-start"
                                          style={{ paddingRight: "10px" }}
                                        >
                                          Status
                                        </span>
                                        {filterSubGoalIcon ? (
                                          <Dropdown
                                            // className="float-end setDropDownposition m-0 p-0"
                                            title="Filter"
                                            // style={{ right: "90%" }}
                                          >
                                            <Dropdown.Toggle
                                              style={{
                                                background: "none",
                                                border: "none",
                                              }}
                                              className="m-0 p-0"
                                            >
                                              <i
                                                className="fas fa-filter fs-12"
                                                style={{
                                                  cursor: "pointer",
                                                  color: selectedSubStatusItem
                                                    ? "#DC5D74"
                                                    : "#898fa5",
                                                }}
                                              ></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              {filterName?.map((scope, i) => (
                                                <Dropdown.Item
                                                  style={
                                                    selectedSubStatusItem?.name ===
                                                    scope?.name
                                                      ? {
                                                          backgroundColor:
                                                            "#B8E5F1",
                                                        }
                                                      : {
                                                          backgroundColor:
                                                            "#ffffff",
                                                        }
                                                  }
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    setSelectedSubStatusItem(
                                                      scope
                                                    );
                                                  }}
                                                  key={i}
                                                  className="p"
                                                >
                                                  {scope?.name}
                                                </Dropdown.Item>
                                              ))}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </th> */}
                                    <th
                                      className="sorting goalwidth"
                                      style={{ width: "20vw" }}
                                    >
                                      <div className="d-flex m-0 p-0">
                                        <span
                                          className="float-start"
                                          style={{
                                            paddingRight: "10px",
                                            fontWeight: "bold",
                                            color: "#131F4C"
                                          }}
                                        >
                                          <i
                                            class="fas fa-sort"
                                            onClick={() => {
                                              handleClick33("goal", !sortAsc);
                                            }}
                                          ></i>
                                          Goal
                                        </span>
                                        {filterSubGoalIcon ? (
                                          <Dropdown
                                            // className="setDropDownposition float-end"
                                            title="Filter"
                                            // style={{ right: "65%" }}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setSubGoalInputFieldOpen(
                                                !subGoalInputFieldOpen
                                              );
                                            }}
                                          >
                                            <Dropdown.Toggle
                                              style={{
                                                background: "none",
                                                border: "none"
                                              }}
                                              className="m-0 p-0"
                                            >
                                              <i
                                                className="fas fa-filter fs-12"
                                                style={{
                                                  cursor: "pointer",
                                                  color: subGoalNameFilter
                                                    ? "#DC5D74"
                                                    : "#898fa5"
                                                }}
                                              ></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              {
                                                <>
                                                  <input
                                                    type="text"
                                                    name="name"
                                                    className="figma-input p m-0 p-2"
                                                    placeholder="Enter goal name"
                                                    autocomplete="off"
                                                    defaultValue={
                                                      subGoalNameFilter
                                                    }
                                                    onKeyUp={(e) => {
                                                      setTimeout(() => {
                                                        setSubGoalNameFilter(
                                                          e.target.value
                                                        );
                                                      }, 1000);
                                                    }}
                                                    style={{
                                                      border:
                                                        "1px solid #9a9b9c",
                                                      width: "150px",
                                                      borderRadius: "0px"
                                                    }}
                                                  />
                                                </>
                                              }
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </th>
                                    <th className="sorting perFields">
                                      <div className="d-flex m-0 p-0">
                                        <span
                                          className="float-start"
                                          style={{
                                            marginRight: "10px",
                                            fontWeight: "bold",
                                            color: "#131F4C"
                                          }}
                                        >
                                          <i
                                            class="fas fa-sort"
                                            onClick={() => {
                                              handleClick33("owner", !sortAsc);
                                            }}
                                          ></i>
                                          Who
                                        </span>
                                        {filterTaskIcon ? (
                                          <Dropdown
                                            // className="setDropDownposition float-end"
                                            title="Filter"
                                            // style={{ right: "90%" }}
                                            onClick={() => {
                                              setTaskOwnerFieldOpen(
                                                !taskOwnerFieldOpen
                                              );
                                              if (dropdownHeight) {
                                                setdropdownHeight(false);
                                              }
                                            }}
                                          >
                                            <Dropdown.Toggle
                                              style={{
                                                background: "none",
                                                border: "none"
                                              }}
                                              className="m-0 p-0"
                                            >
                                              <i
                                                className="fas fa-filter fs-12"
                                                style={{
                                                  cursor: "pointer",
                                                  color: subGoalOwnerFilter
                                                    ? "#DC5D74"
                                                    : "#898fa5"
                                                }}
                                              ></i>
                                            </Dropdown.Toggle>
                                            <div>
                                              <Dropdown.Menu
                                                menuposition="top-right"
                                                className=""
                                                style={{
                                                  width: "auto",
                                                  maxHeight: "150px",
                                                  overflowY: "scroll"
                                                }}
                                              >
                                                {options?.map((option, i) => (
                                                  <Dropdown.Item
                                                    key={i}
                                                    style={
                                                      subGoalOwnerFilter ===
                                                        option?.userId
                                                        ? {
                                                          backgroundColor:
                                                            "#B8E5F1"
                                                        }
                                                        : {
                                                          backgroundColor:
                                                            "#ffffff"
                                                        }
                                                    }
                                                    value={option?.value}
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      setSubGoalOwnerFilter(
                                                        option?.userId
                                                      );
                                                    }}
                                                    className="p fs-14"
                                                  >
                                                    {option?.value}
                                                  </Dropdown.Item>
                                                ))}
                                              </Dropdown.Menu>
                                            </div>
                                          </Dropdown>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </th>

                                    <th className="sorting perFields">
                                      <div className="d-flex m-0 p-0">
                                        <span
                                          className="float-start"
                                          style={{
                                            paddingRight: "10px",
                                            fontWeight: "bold",
                                            color: "#131F4C",
                                          }}
                                        >
                                          Active
                                        </span>

                                        {filterIcon ? (
                                          <Dropdown title="Filter">
                                            <Dropdown.Toggle
                                              style={{
                                                background: "none",
                                                border: "none",
                                              }}
                                              className="m-0 p-0"
                                            >
                                              <i
                                                className="fas fa-filter fs-12"
                                                style={{
                                                  cursor: "pointer",
                                                  color: subStatusFilter
                                                    ? "#DC5D74"
                                                    : "#898fa5",
                                                }}
                                              ></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              {SubstatusFilter?.map(
                                                (scope, i) => (
                                                  <Dropdown.Item
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      setSubStatusFilter(
                                                        scope.value
                                                      );
                                                    }}
                                                    style={
                                                      subStatusFilter ===
                                                        scope?.name
                                                        ? {
                                                          backgroundColor:
                                                            "#B8E5F1",
                                                        }
                                                        : {
                                                          backgroundColor:
                                                            "#ffffff",
                                                        }
                                                    }
                                                    key={i}
                                                    className="p fs-14"
                                                  >
                                                    {scope?.name}
                                                  </Dropdown.Item>
                                                )
                                              )}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </th>
                                    {/* <th className="sorting perFields">
                                      <div className="d-flex m-0 p-0">
                                        <span
                                          className="float-start"
                                          style={{ paddingRight: "10px" }}
                                        >
                                          On-time
                                        </span>
                                        {filterIcon ? (
                                          <Dropdown
                                            // className="setDropDownposition float-end"
                                            title="Filter"
                                            // style={{ right: "11%" }}
                                            onClick={(e) => {
                                              e.preventDefault();
                                            }}
                                          >
                                            <Dropdown.Toggle
                                              style={{
                                                background: "none",
                                                border: "none",
                                              }}
                                              className="m-0 p-0"
                                            >
                                              <i
                                                className="fas fa-filter fs-12"
                                                style={{
                                                  cursor: "pointer",
                                                  color:
                                                    subOntimeStartRange ||
                                                    subOntimeEndRange
                                                      ? "#DC5D74"
                                                      : "#898fa5",
                                                }}
                                              ></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="p-2">
                                              {
                                                <>
                                                  <h5>
                                                    Enter start & end range
                                                  </h5>
                                                  <Row className="d-flex">
                                                    <Col>
                                                      <input
                                                        type="number"
                                                        name="name"
                                                        min={0}
                                                        max={100}
                                                        className="figma-input p m-0 p-2"
                                                        placeholder="Start"
                                                        autocomplete="off"
                                                        defaultValue={
                                                          subOntimeStartRange
                                                        }
                                                        onKeyUp={(e) => {
                                                          setTimeout(() => {
                                                            setSubOntimeStartRange(
                                                              e.target.value
                                                            );
                                                          }, 1000);
                                                        }}
                                                        style={{
                                                          border:
                                                            "1px solid #9a9b9c",
                                                          height: "30px",
                                                        }}
                                                      />
                                                    </Col>
                                                    _
                                                    <Col>
                                                      <input
                                                        type="number"
                                                        name="name"
                                                        min={0}
                                                        max={100}
                                                        className="figma-input p m-0 p-2 me-1"
                                                        placeholder="End"
                                                        autocomplete="off"
                                                        defaultValue={
                                                          subOntimeEndRange
                                                        }
                                                        onKeyUp={(e) => {
                                                          setTimeout(() => {
                                                            setSubOntimeEndRange(
                                                              e.target.value
                                                            );
                                                          }, 1000);
                                                        }}
                                                        style={{
                                                          border:
                                                            "1px solid #9a9b9c",
                                                          height: "30px",
                                                        }}
                                                      />
                                                    </Col>
                                                  </Row>
                                                </>
                                              }
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </th> */}

                                    {/* <th className="sorting perFields">
                                      <div className="d-flex m-0 p-0">
                                        <span
                                          className="float-start"
                                          style={{ paddingRight: "10px" }}
                                        >
                                          <i
                                            class="fas fa-sort fs-16"
                                            onClick={() => {
                                              // handleClick11("risk_average", !sortAsc);
                                              sortingSubRisk(
                                                "goal_risk_average"
                                              );
                                            }}
                                          ></i>
                                          Risk
                                        </span>

                                        {filterIcon ? (
                                          <Dropdown
                                            // className="setDropDownposition float-end"
                                            title="Filter"
                                            // style={{ right: "31%" }}
                                          >
                                            <Dropdown.Toggle
                                              style={{
                                                background: "none",
                                                border: "none"
                                              }}
                                              className="m-0 p-0"
                                            >
                                              <i
                                                className="fas fa-filter fs-12"
                                                style={{
                                                  cursor: "pointer",
                                                  color: subRiskFilter
                                                    ? "#DC5D74"
                                                    : "#898fa5"
                                                }}
                                              ></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              {ObjectiveRisk?.map(
                                                (scope, i) => (
                                                  <Dropdown.Item
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      setSubRiskFilter(
                                                        scope.value
                                                      );
                                                    }}
                                                    style={
                                                      subRiskFilter ===
                                                      scope?.name
                                                        ? {
                                                            backgroundColor:
                                                              "#B8E5F1"
                                                          }
                                                        : {
                                                            backgroundColor:
                                                              "#ffffff"
                                                          }
                                                    }
                                                    key={i}
                                                    className="p fs-14"
                                                  >
                                                    {scope?.name}
                                                  </Dropdown.Item>
                                                )
                                              )}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        ) : (
                                          ""
                                        )}

                                     
                                      </div>
                                    </th> */}

                                    {/* <th>Status</th> */}

                                    <th
                                      className="status sorting perFields"
                                      style={{
                                        fontWeight: "bold",
                                        color: "#131F4C"
                                      }}
                                    >
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody
                                  style={
                                    showSubTableSpinner
                                      ? { background: "#f5f6f7", zIndex: "-1" }
                                      : {}
                                  }
                                >
                                  {allSubGoals?.map((goal, index) => (
                                    <>
                                      <TableRow
                                        key={goal.id}
                                        goal={goal}
                                        index={index}
                                      // moveGoal={moveGoalss}
                                      />
                                    </>
                                  ))}
                                </tbody>
                                {rowAddGoals1 && (
                                  <tr
                                    className="odd goals"
                                    style={{ height: "83px" }}
                                    role="row"
                                  >
                                    {/* <td
                              style={{
                                padding: "8px 15px",
                                paddingLeft: "0px",
                              }}
                            >
                              <input
                                className="figma-input p"
                                type="number"
                                name="srNo"
                                value={inputValues.srNo}
                                onChange={handleFormChange}
                                placeholder="Sr. No"
                              />
                            </td> */}
                                    <td
                                      style={{
                                        padding: "8px 15px",
                                        position: "relative"
                                      }}
                                    >
                                      <input
                                        style={{
                                          padding: "10px 16px",
                                          width: "80%",
                                          borderRadius: "30px",
                                          borderColor: "#a8d6c5"
                                        }}
                                        className="figma-input p"
                                        type="text"
                                        required
                                        name="objective"
                                        value={inputValues.objective}
                                        onChange={handleFormChange}
                                        placeholder="Sub-goal name"
                                      />
                                      {errors?.objective && (
                                        <span
                                          className="mt-2"
                                          style={{
                                            color: "red",
                                            position: "absolute",
                                            top: "65%",
                                            left: "0",
                                            // fontSize: '14px',
                                            marginTop: "2px"
                                          }}
                                        >
                                          * {errors?.objective}
                                        </span>
                                      )}
                                    </td>
                                    <td
                                      style={{
                                        padding: "8px 15px",
                                        paddingLeft: "0px"
                                      }}
                                    >
                                      <Select
                                        name="goal_owner"
                                        className="p"
                                        options={options}
                                        // styles={{ }
                                        value={goalOwner}
                                        onChange={changeGoalOwner}
                                        menuPlacement="auto"
                                        menuPortalTarget={document.body}
                                        styles={{
                                          padding: "2px",
                                          borderRadius: "10px",
                                          menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 1
                                          })
                                        }}
                                        isDisabled={scopeOfTime === "personal"}
                                      />
                                    </td>
                                    {/* <td
                              style={{
                                marginTop:"45px",
                                padding: "8px 15px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              className="figbtn-success"
                            >
                              <span className="figstatus">On track</span>
                            </td> */}

                                    {/* <td style={{ padding: "8px 15px" }}>
                           
                              0 %
                            </td> */}
                                    <td style={{ padding: "8px 15px" }}>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center"
                                        }}
                                      >
                                        <div>{/* {goal?.status} */}</div>
                                        <div>
                                          <FormControlLabel
                                            control={
                                              <Switch
                                                checked={isToggled}
                                                onChange={handleToggleChange}
                                                name="statusToggle"
                                                color="primary"
                                              />
                                            }
                                          />
                                        </div>
                                      </div>
                                    </td>
                                    {/* <td style={{ padding: "8px 15px" }}>
                             
                              0 %
                            </td> */}
                                    {/* <td style={{ padding: "8px 15px" }}>
                            
                              0 %
                            </td>
                            <td style={{ padding: "8px 15px" }}>
                              -
                            </td> */}

                                    {/* <td style={{padding:'8px 15px'}}>
                <input
                className="figma-input p"
                  type="text"
                  name="objectiveStatus"
                  value={inputValues.objectiveStatus}
                  onChange={handleFormChange}
                  placeholder="Objective Status"
                />
              </td> */}
                                    {/* <td style={{ padding: "8px 15px" }}>-</td> */}
                                    <td style={{ padding: "8px 15px" }}>
                                      <button
                                        className="figma-btn text-white goal-save-button mx-3"
                                        type="submit"
                                        onClick={handleFormSubmit}
                                        style={{ width: "64%" }}
                                      >
                                        Add
                                      </button>
                                    </td>
                                  </tr>
                                )}
                                {!rowAddGoals1 && (
                                  <tr
                                    className="odd goals"
                                    style={{ borderTop: "1px solid #d7dae3" }}
                                    role="row"
                                  >
                                    <OverlayTrigger
                                      delay={{ hide: 100, show: 10 }}
                                      overlay={
                                        <Tooltip id="tooltip-right">
                                          <span className="fs-18">
                                            Quick Add Subgoal
                                          </span>
                                        </Tooltip>
                                      }
                                      placement="right"
                                    >
                                      <Button
                                        className="mt-4 mb-4"
                                        style={{
                                          background: "#356FD4",
                                          border: "1px solid #356FD4",
                                          height: "40px",
                                          width: "40px"
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          // setshowTaskMoadal(true)
                                          setRowAddGoals1(true);
                                        }}
                                      // onClick={handleDoneButton}
                                      // onContextMenu={handleClick} // Right-click handler
                                      >
                                        <FaPlus color="#FFFFFF"></FaPlus>
                                      </Button>
                                    </OverlayTrigger>
                                  </tr>
                                )}
                              </table>
                              {allSubGoals?.length === 0 &&
                                !showSubTableSpinner && (
                                  <Row className="p-0 m-0">
                                    <span
                                      className="th text-center "
                                      style={{
                                        top: "-25px",
                                        position: "relative"
                                      }}
                                    >
                                      No records found.
                                    </span>
                                  </Row>
                                )}
                            </div>
                          </div>
                        </DndProvider>

                        <Row className="pt-3 border-0 goal-row">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            <div>
                              <label htmlFor="numberSelector">
                                Rows per page &nbsp;
                              </label>
                              <input
                                type="number"
                                list="numberOptions"
                                value={inputSubgoalValue}
                                onChange={handleSubgoalInputChange}
                                className="form-control pagination-row-input"
                                placeholder=""
                                style={{
                                  width: "auto",
                                  display: "inline-block",
                                  maxWidth: "100px",
                                  background: "#fff",
                                  border: "0.0625rem solid #d7dae3",
                                  padding: "0.3125rem 1.25rem",
                                  color: "#6e6e6e",
                                  borderRadius: "0rem",
                                  height: "45px",
                                }}
                              />
                              <datalist id="numberOptions">
                                {numberOptions.map((number) => (
                                  <option key={number} value={number} />
                                ))}
                              </datalist>
                            </div>

                            {subGoalPagination && (
                              <>
                                <Pagination
                                  pageCount={subGoalpageCount}
                                  pageIndex={goToSubGoalPage}
                                  gotoPage={setGoToSubGoalPage}
                                />
                              </>
                            )}
                          </div>
                        </Row>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Row>
          )}
          {/* {taskHide &&  */}
          <Row>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="">
                    {" "}
                    Aligned tasks for goal "
                    <span> {selectedGoal[0]?.name}</span> "
                  </h3>
                  <div className="float-end">
                    {/* fas fa-angle-down fs-35 */}

                    {(taskNameFilter ||
                      selectedTaskTypeItem ||
                      selectedTaskImpactItem ||
                      taskActiveInactiveStatus ||
                      selectedTaskControl ||
                      selectedTaskRisk ||
                      selectedTaskStatusItem ||
                      taskEffortFilter ||
                      scheduledTaskDueInFilter ||
                      taskWonerFilter) && (
                        <span
                          className=" mx-3 pt-2"
                          title="Clear all filter"
                          onClick={(e) => {
                            clearAllTaskFilter(e);
                            setToggleTaskData(true);
                          }}
                          style={{
                            cursor: "pointer",
                            color: "#DC5D74"
                          }}
                        >
                          Clear all filter
                          <i className="fas fa-filter fs-18"></i>
                          <span>x</span>
                        </span>
                      )}
                    {toggleTaskData ? (
                      <i
                        title="Close"
                        class="bi bi-caret-up-fill fs-24"
                        onClick={() => {
                          setToggleTaskData(!toggleTaskData);
                          close();
                        }}
                      ></i>
                    ) : (
                      <i
                        title="Open"
                        class="bi bi-caret-down-fill fs-24"
                        onClick={() => {
                          setToggleTaskData(!toggleTaskData);
                          close();
                        }}
                      ></i>
                    )}
                  </div>
                </div>
                {toggleTaskData && (
                  <div className="card-body">
                    <DndProvider backend={HTML5Backend}>
                      <div className="table-responsive">
                        <div id="job_data" className="dataTables_wrapper">
                          {showTaskTableSpinner ? (
                            <div
                              style={{
                                position: "relative",
                                top:
                                  taskList?.length < 3
                                    ? "6rem"
                                    : goals.length >= 3
                                      ? "9rem"
                                      : "1rem"
                              }}
                              className="m-0 p-0"
                            >
                              <LoadingSpinner />
                            </div>
                          ) : (
                            ""
                          )}
                          <form onSubmit={formik.handleSubmit}>
                            <table
                              className="display w-100 dataTable "
                              id="example5"
                              role="grid"
                              aria-describedby="example5_info"
                              style={
                                taskList?.length < 2
                                  ? { minHeight: "110px" }
                                  : { minHeight: "200px" }
                              }
                            >
                              <thead>
                                <tr role="row">
                                  <th
                                    className="sorting "
                                    style={{ width: "40%" }}
                                  >
                                    {" "}
                                    <div className="d-flex align-items-start m-0 p-0">
                                      <span
                                        className="float-start"
                                        style={{
                                          marginRight: "10px",
                                          fontWeight: "bold",
                                          color: "#131F4C",
                                          textAlign: "start"
                                        }}
                                      >
                                        <i
                                          class="fas fa-sort"
                                          onClick={() => {
                                            handleClick22("task", !sortAsc);
                                          }}
                                        ></i>
                                        Task
                                      </span>
                                      {filterTaskIcon ? (
                                        <Dropdown
                                          // className="setDropDownposition float-end"
                                          title="Filter"
                                          // style={{ right: "51%" }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setTaskInputFieldOpen(
                                              !taskInputFieldOpen
                                            );
                                          }}
                                        >
                                          <Dropdown.Toggle
                                            style={{
                                              background: "none",
                                              border: "none"
                                            }}
                                            className="m-0 p-0"
                                          >
                                            <i
                                              className="fas fa-filter fs-12"
                                              style={{
                                                cursor: "pointer",
                                                color: taskNameFilter
                                                  ? "#DC5D74"
                                                  : "#898fa5"
                                              }}
                                            ></i>
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            {
                                              <>
                                                <input
                                                  type="text"
                                                  name="name"
                                                  className="figma-input p m-0 p-2"
                                                  placeholder="Enter task name"
                                                  autocomplete="off"
                                                  value={taskNameFilter}
                                                  onChange={(e) =>
                                                    setTaskNameFilter(
                                                      e.target.value
                                                    )
                                                  }
                                                  style={{
                                                    border: "1px solid black",
                                                    width: "150px"
                                                  }}
                                                />
                                              </>
                                            }
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </th>
                                  <th
                                    className="sorting_asc perFields"
                                    style={{ width: "7%" }}
                                  >
                                    <div className="d-flex align-items-center m-0 p-0">
                                      <span
                                        className="float-start"
                                        style={{
                                          marginRight: "10px",
                                          fontWeight: "bold",
                                          color: "#131F4C"
                                        }}
                                      >
                                        <i
                                          class="fas fa-sort"
                                          onClick={() => {
                                            handleClick22("owner", !sortAsc);
                                          }}
                                        ></i>
                                        Who
                                      </span>
                                      {filterTaskIcon ? (
                                        <Dropdown
                                          // className="setDropDownposition float-end"
                                          title="Filter"
                                          // style={{ right: "90%" }}
                                          onClick={() => {
                                            setTaskOwnerFieldOpen(
                                              !taskOwnerFieldOpen
                                            );
                                            if (dropdownHeight) {
                                              setdropdownHeight(false);
                                            }
                                          }}
                                        >
                                          <Dropdown.Toggle
                                            style={{
                                              background: "none",
                                              border: "none"
                                            }}
                                            className="m-0 p-0"
                                          >
                                            <i
                                              className="fas fa-filter fs-12"
                                              style={{
                                                cursor: "pointer",
                                                color: taskWonerFilter
                                                  ? "#DC5D74"
                                                  : "#898fa5"
                                              }}
                                            ></i>
                                          </Dropdown.Toggle>
                                          <div>
                                            <Dropdown.Menu
                                              menuposition="top-right"
                                              className=""
                                              style={{
                                                width: "auto",
                                                maxHeight: "150px",
                                                overflowY: "scroll"
                                              }}
                                            >
                                              {options?.map((option, i) => (
                                                <Dropdown.Item
                                                  key={i}
                                                  style={
                                                    taskWonerFilter ===
                                                      option?.userId
                                                      ? {
                                                        backgroundColor:
                                                          "#B8E5F1"
                                                      }
                                                      : {
                                                        backgroundColor:
                                                          "#ffffff"
                                                      }
                                                  }
                                                  value={option?.value}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    setTaskOwnerFilter(
                                                      option?.userId
                                                    );
                                                  }}
                                                  className="p fs-14"
                                                >
                                                  {option?.value}
                                                </Dropdown.Item>
                                              ))}
                                            </Dropdown.Menu>
                                          </div>
                                        </Dropdown>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </th>
                                  <th
                                    className="sorting perFields"
                                    style={{ width: "7%" }}
                                  >
                                    <OverlayTrigger
                                      delay={{ hide: 100, show: 10 }}
                                      overlay={
                                        <Tooltip id="tooltip-top">
                                          <span className="fs-18">
                                            Toggle to make the task active.
                                            Inactive tasks won't be scheduled.
                                          </span>
                                        </Tooltip>
                                      }
                                      placement="top"
                                    >
                                      <div className="d-flex justify-content-center align-items-center m-0 p-0">
                                        <span
                                          className="float-start"
                                          style={{
                                            paddingRight: "10px",
                                            fontWeight: "bold",
                                            color: "#131F4C"
                                          }}
                                        >
                                          Active
                                        </span>

                                        {filterTaskIcon ? (
                                          <Dropdown
                                            // className="setDropDownposition float-end"
                                            title="Filter"
                                          // style={{ right: "31%" }}
                                          >
                                            <Dropdown.Toggle
                                              style={{
                                                background: "none",
                                                border: "none"
                                              }}
                                              className="m-0 p-0"
                                            >
                                              <i
                                                className="fas fa-filter fs-12"
                                                style={{
                                                  cursor: "pointer",
                                                  color:
                                                    taskActiveInactiveStatus
                                                      ? "#DC5D74"
                                                      : "#898fa5"
                                                }}
                                              ></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              {StatusFilter?.map((scope, i) => (
                                                <Dropdown.Item
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    // setStatusFilter(scope.value);
                                                    setTaskActiveInactiveStatus(
                                                      scope.value
                                                    );
                                                  }}
                                                  style={
                                                    taskActiveInactiveStatus ===
                                                      scope?.name
                                                      ? {
                                                        backgroundColor:
                                                          "#B8E5F1"
                                                      }
                                                      : {
                                                        backgroundColor:
                                                          "#ffffff"
                                                      }
                                                  }
                                                  key={i}
                                                  className="p fs-14"
                                                >
                                                  {scope?.name}
                                                </Dropdown.Item>
                                              ))}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </OverlayTrigger>
                                  </th>
                                  <th className="sorting perFields">
                                    <div className="d-flex align-items-center m-0 p-0">
                                      <span
                                        className="float-start"
                                        style={{
                                          marginRight: "10px",
                                          fontWeight: "bold",
                                          color: "#131F4C"
                                        }}
                                      >
                                        <i
                                          class="fas fa-sort"
                                          onClick={() => {
                                            handleClick22("impact", !sortAsc);
                                          }}
                                        ></i>
                                        Impact
                                      </span>
                                      {filterTaskIcon ? (
                                        <Dropdown
                                          // className="setDropDownposition float-end"
                                          title="Filter"
                                        // style={{ right: "41%" }}
                                        >
                                          <Dropdown.Toggle
                                            style={{
                                              background: "none",
                                              border: "none"
                                            }}
                                            className="m-0 p-0"
                                          >
                                            <i
                                              className="fas fa-filter fs-12"
                                              style={{
                                                cursor: "pointer",
                                                color: selectedTaskImpactItem
                                                  ? "#DC5D74"
                                                  : "#898fa5"
                                              }}
                                            ></i>
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            {TaskImpact?.map((scope, i) => (
                                              <Dropdown.Item
                                                style={
                                                  selectedTaskImpactItem?.name ===
                                                    scope?.name
                                                    ? {
                                                      backgroundColor:
                                                        "#B8E5F1"
                                                    }
                                                    : {
                                                      backgroundColor:
                                                        "#ffffff"
                                                    }
                                                }
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setSelectedTaskImpactItem(
                                                    scope
                                                  );
                                                }}
                                                key={i}
                                                className="p"
                                              >
                                                {scope?.name}
                                              </Dropdown.Item>
                                            ))}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </th>
                                  <th
                                    className="sorting perFields"
                                    style={{ width: "9.9%" }}
                                  >
                                    <div className="d-flex align-items-center m-0 p-0">
                                      <span
                                        className="float-start"
                                        style={{
                                          marginRight: "10px",
                                          fontWeight: "bold",
                                          color: "#131F4C"
                                        }}
                                      >
                                        <i
                                          class="fas fa-sort"
                                          onClick={() => {
                                            handleClick22("effort", !sortAsc);
                                          }}
                                        ></i>
                                        Effort
                                      </span>
                                      {filterTaskIcon ? (
                                        <Dropdown
                                          // className="setDropDownposition float-end"
                                          title="Filter"
                                          // style={{ right: "31%" }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setTaskEffortFieldOpen(
                                              !taskEffortFieldOpen
                                            );
                                          }}
                                        >
                                          <Dropdown.Toggle
                                            style={{
                                              background: "none",
                                              border: "none"
                                            }}
                                            className="m-0 p-0"
                                          >
                                            <i
                                              className="fas fa-filter fs-12"
                                              style={{
                                                cursor: "pointer",
                                                color: taskEffortFilter
                                                  ? "#DC5D74"
                                                  : "#898fa5"
                                              }}
                                            ></i>
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            {
                                              <input
                                                type="string"
                                                name="name"
                                                className="figma-input p m-0 p-2"
                                                placeholder="Enter task effort"
                                                autocomplete="off"
                                                defaultValue={
                                                  taskEffortFilterForShow
                                                }
                                                onKeyUp={(e) =>
                                                  setTimeout(() => {
                                                    setTaskEffortFilterForShow(
                                                      e.target.value
                                                    );
                                                    reverseAdjustedTaskEffort(
                                                      e.target.value
                                                    );
                                                  }, 1000)
                                                }
                                                style={{
                                                  border: "1px solid black",
                                                  width: "120px"
                                                }}
                                              />
                                            }
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </th>
                                  <th className="sorting perFields">
                                    {" "}
                                    <div className="d-flex align-items-center m-0 p-0">
                                      <span
                                        className="float-start"
                                        style={{
                                          marginRight: "10px",
                                          fontWeight: "bold",
                                          color: "#131F4C"
                                        }}
                                      >
                                        <i
                                          class="fas fa-sort"
                                          onClick={() => {
                                            handleClick22("type", !sortAsc);
                                          }}
                                        ></i>
                                        Type
                                      </span>
                                      {filterTaskIcon ? (
                                        <Dropdown
                                          // className="setDropDownposition float-end"
                                          title="Filter"
                                        // style={{ right: "80%" }}
                                        >
                                          <Dropdown.Toggle
                                            style={{
                                              background: "none",
                                              border: "none"
                                            }}
                                            className="m-0 p-0"
                                          >
                                            <i
                                              className="fas fa-filter fs-12"
                                              style={{
                                                cursor: "pointer",
                                                color: selectedTaskTypeItem
                                                  ? "#DC5D74"
                                                  : "#898fa5"
                                              }}
                                            ></i>
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu
                                            style={{
                                              width: "auto",
                                              height: "120px"
                                            }}
                                          >
                                            {tasktype?.map((scope, i) => (
                                              <Dropdown.Item
                                                style={
                                                  selectedTaskTypeItem?.name ===
                                                    scope?.name
                                                    ? {
                                                      backgroundColor:
                                                        "#B8E5F1"
                                                    }
                                                    : {
                                                      backgroundColor:
                                                        "#ffffff"
                                                    }
                                                }
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setSelectedTaskTypeItem(
                                                    scope
                                                  );
                                                }}
                                                key={i}
                                                className="p fs-14"
                                              >
                                                {scope?.name}
                                              </Dropdown.Item>
                                            ))}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </th>
                                  <th
                                    className="sorting perFields"
                                    style={{ width: "7%" }}
                                  >
                                    <div className="d-flex  align-items-center m-0 p-0">
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          color: "#131F4C"
                                        }}
                                      >
                                        <i
                                          class="fas fa-sort"
                                          onClick={() => {
                                            handleClick22("due_in", !sortAsc);
                                          }}
                                        ></i>
                                        Due in
                                      </span>
                                      {filterTaskIcon ? (
                                        <Dropdown
                                          // className="setDropDownposition float-end"
                                          title="Filter"
                                          // style={{ right: "58%" }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setScheduledTaskDueInFieldOpen(
                                              !scheduledTaskDueInFieldOpen
                                            );
                                          }}
                                        >
                                          <Dropdown.Toggle
                                            style={{
                                              background: "none",
                                              border: "none"
                                            }}
                                            className="m-0 p-0"
                                          >
                                            <i
                                              className="fas fa-filter fs-12"
                                              style={{
                                                cursor: "pointer",
                                                color: scheduledTaskDueInFilter
                                                  ? "#DC5D74"
                                                  : "#898fa5"
                                              }}
                                            ></i>
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            {
                                              <>
                                                <input
                                                  type="text"
                                                  name="name"
                                                  className="figma-input p m-0 p-2"
                                                  placeholder="Enter due in"
                                                  autocomplete="off"
                                                  value={
                                                    scheduledTaskDueInFilter
                                                  }
                                                  onChange={(e) =>
                                                    setScheduledTaskDueInFilter(
                                                      e.target.value
                                                    )
                                                  }
                                                  style={{
                                                    border: "1px solid black",
                                                    width: "150px"
                                                  }}
                                                />
                                              </>
                                            }
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </th>
                                  <th
                                    className="sorting perFields"
                                    style={{ width: "20%" }}
                                  >
                                    <div className="d-flex  align-items-center m-0 p-0">
                                      <span
                                        className="float-start"
                                        style={{
                                          marginRight: "10px",
                                          fontWeight: "bold",
                                          color: "#131F4C"
                                        }}
                                      >
                                        Outcome
                                      </span>
                                      {filterScheduleTaskIcon ? (
                                        <Dropdown
                                          // className="setDropDownposition float-end"
                                          title="Filter"
                                        // style={{ right: "79.5%" }}
                                        >
                                          <Dropdown.Toggle
                                            style={{
                                              background: "none",
                                              border: "none"
                                            }}
                                            className="m-0 p-0"
                                          >
                                            <i
                                              className="fas fa-filter fs-12"
                                              style={{
                                                cursor: "pointer",
                                                color: selectedTaskStatusItem
                                                  ? "#DC5D74"
                                                  : "#898fa5"
                                              }}
                                            ></i>
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu
                                            style={{
                                              width: "auto",
                                              height: "auto"
                                            }}
                                          >
                                            {outComeOption?.map((scope, i) => (
                                              <Dropdown.Item
                                                style={
                                                  selectedTaskStatusItem ===
                                                    scope.value
                                                    ? {
                                                      backgroundColor:
                                                        "#B8E5F1"
                                                    }
                                                    : {
                                                      backgroundColor:
                                                        "#ffffff"
                                                    }
                                                }
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setSelectedTaskStatusItem(
                                                    scope.value
                                                  );
                                                }}
                                                key={i}
                                                className="p fs-14"
                                              >
                                                {scope?.name}
                                              </Dropdown.Item>
                                            ))}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </th>
                                  <th
                                    className="sorting perFields"
                                    style={{
                                      fontWeight: "bold",
                                      color: "#131F4C",
                                      width: "7%"
                                    }}
                                  >
                                    <div className="d-flex  align-items-center m-0 p-0">
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          color: "#131F4C"
                                        }}
                                      >
                                        Action
                                      </span>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody
                                style={
                                  showTaskTableSpinner
                                    ? { background: "#f5f6f7", zIndex: "-1" }
                                    : {}
                                }
                              >
                                <>
                                  {taskList &&
                                    taskList.map((task, index) => (
                                      <TableTaskRow
                                        key={task.id}
                                        task={task}
                                        index={index}
                                        moveGoal={moveTask}
                                      />
                                    ))}
                                </>
                              </tbody>
                              <>
                                <tr
                                  style={{
                                    borderTop: "3px solid #d7dae3",
                                    height: "126px"
                                  }}
                                >
                                  <td
                                    className="p-2"
                                    style={{ position: "relative" }}
                                  >
                                    <input
                                      type="text"
                                      name="task_name"
                                      placeholder="Enter task name"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.task_name}
                                      style={{
                                        width: "100%",
                                        border: "1px solid #e2e2e2",
                                        height: "35px"
                                      }}
                                    />
                                    {formik.touched.task_name &&
                                      formik.errors.task_name ? (
                                      <div
                                        style={{
                                          color: "red",
                                          position: "absolute",
                                          top: "65%",
                                          left: "0",
                                          // fontSize: '14px',
                                          marginTop: "2px"
                                        }}
                                      >
                                        {formik.errors.task_name}
                                      </div>
                                    ) : null}
                                  </td>
                                  <td className="p-2">
                                    <Select
                                      options={options}
                                      onChange={(selectedOption) =>
                                        formik.setFieldValue(
                                          "task_owner",
                                          selectedOption
                                        )
                                      }
                                      value={formik.values.task_owner}
                                      menuPlacement="auto"
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({
                                          ...base,
                                          zIndex: 9999
                                        })
                                      }}
                                      isDisabled={scopeOfTime === "personal"}
                                    />
                                  </td>
                                  <td className="p-2">
                                    <div className="d-flex align-items-center m-0 p-0">
                                      -
                                    </div>
                                  </td>
                                  <td className="p-2">
                                    <Select
                                      options={[
                                        { value: "high", label: "High" },
                                        { value: "medium", label: "Medium" },
                                        { value: "low", label: "Low" }
                                      ]}
                                      onChange={(selectedOption) =>
                                        formik.setFieldValue(
                                          "task_impact",
                                          selectedOption
                                        )
                                      }
                                      value={formik.values.task_impact}
                                      menuPlacement="auto"
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({
                                          ...base,
                                          zIndex: 1
                                        })
                                      }}
                                    />
                                  </td>
                                  <td
                                    className="p-2"
                                    style={{ position: "relative" }}
                                  >
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <TimePicker
                                        sx={{
                                          "& .MuiInputBase-root": {
                                            height: 39,
                                            overflow: "hidden"
                                          },
                                          "& .MuiInputBase-input": {
                                            height: 39,
                                            overflow: "hidden",
                                            border: "none"
                                          }
                                        }}
                                        ampm={false}
                                        views={["hours", "minutes"]}
                                        timeSteps={{ minutes: 1 }}
                                        minTime={dayjs()
                                          .set("hour", 0)
                                          .set("minute", 0)}
                                        maxTime={dayjs()
                                          .set("hour", 19)
                                          .set("minute", 59)}
                                        value={
                                          formik.values.task_effort
                                            ? dayjs(
                                              formik.values.task_effort,
                                              "HH:mm"
                                            )
                                            : null
                                        }
                                        onChange={(newValue) => {
                                          const formattedTime = newValue
                                            ? newValue.format("HH:mm")
                                            : "";
                                          formik.setFieldValue(
                                            "task_effort",
                                            formattedTime
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="outlined"
                                            size="small"
                                            placeholder="Select Time"
                                            fullWidth
                                          />
                                        )}
                                        // slots={{ actionBar: () => null }}
                                        slotProps={{
                                          actionBar: {
                                            actions: [""]
                                          },
                                          textField: {
                                            InputAdornmentProps: {
                                              sx: { display: "none" } // Hides the input adornment
                                            }
                                          }
                                        }}
                                        InputProps={{
                                          // ...params.InputProps,
                                          endAdornment: null
                                        }}
                                      />
                                    </LocalizationProvider>
                                    {formik.touched.task_effort &&
                                      formik.errors.task_effort ? (
                                      <div
                                        style={{
                                          color: "red",
                                          position: "absolute",
                                          left: "0"
                                        }}
                                      >
                                        {formik.errors.task_effort}
                                      </div>
                                    ) : null}
                                  </td>
                                  <td className="p-2">
                                    <div className="d-flex align-items-center m-0 p-0">
                                      -
                                    </div>
                                  </td>

                                  <td className="p-2">
                                    <div className="d-flex align-items-center m-0 p-0">
                                      -
                                    </div>
                                  </td>
                                  <td className="p-2">
                                    <div className="d-flex align-items-center m-0 p-0">
                                      -
                                    </div>
                                  </td>
                                  {/* <td className="p-4" >-</td> */}
                                  <td className="p-2">
                                    <button
                                      className="submitButtonformikForm figma-btn text-white goal-save-button mx-3"
                                      style={{ width: "87px" }}
                                      type="submit"
                                    >
                                      Add
                                    </button>
                                  </td>
                                </tr>
                              </>
                            </table>
                          </form>
                          {taskList?.length === 0 && !showTaskTableSpinner && (
                            <Row className="p-0 m-0">
                              <span
                                className="th text-center "
                                style={{ top: "-115px", position: "relative" }}
                              >
                                No records found.
                              </span>
                            </Row>
                          )}
                        </div>
                      </div>
                    </DndProvider>
                    <Row className="pt-3 border-0 goal-row">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div>
                          <label htmlFor="numberSelector">
                            Rows per page &nbsp;
                          </label>
                          <input
                            type="number"
                            list="numberOptions"
                            value={inputTaskValue}
                            onChange={handleInputTaskChange}
                            className="form-control pagination-row-input"
                            placeholder=""
                            style={{
                              width: "auto",
                              display: "inline-block",
                              maxWidth: "100px",
                              background: "#fff",
                              border: "0.0625rem solid #d7dae3",
                              padding: "0.3125rem 1.25rem",
                              color: "#6e6e6e",
                              borderRadius: "0rem",
                              height: "45px",
                            }}
                          />
                          <datalist id="numberOptions">
                            {numberOptions.map((number) => (
                              <option key={number} value={number} />
                            ))}
                          </datalist>
                        </div>
                        {taskPagination && (
                          <Pagination
                            pageCount={taskPageCount}
                            pageIndex={goToTaskPage}
                            gotoPage={setGoTotaskPage}
                          />
                        )}
                      </div>
                    </Row>
                  </div>
                )}
              </div>
            </div>
          </Row>
          {/* } */}
        </>
      ) : (
        <div className="col-12">
          {localStorage.getItem("previousLocation") !==
            localStorage.getItem("currentLocation") && (
              <span className="">
                <i
                  className="fa fa-reply fs-20"
                  title={`Navigate back to ${localStorage.getItem("previousLocation").split("/")[1]
                    }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    dispatch(goalEditManage(true));
                    localStorage.removeItem("selectedGoal");
                    navigate(localStorage.getItem("previousLocation"));
                  }}
                />
              </span>
            )}
          <div className="card">
            <div className="card-header">
              <h4 className="card-title objective-title">Goals</h4>
              <div className="float-end">
                {/* <Link className="figma-btn " style={{marginRight:"10px"}}  to={"/archieve"}>
                <span className="mt-1">See archieved goals </span>
              </Link> */}

                <button
                  className="figma-btnnn m-1"
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    navigate("/archieve");
                  }}
                >
                  <span className="mt-1">See archived goals</span>
                </button>

                <button
                  className="figma-btnnn m-1"
                  onClick={() => {
                    navigate("/track");
                  }}
                >
                  <span>Track goals</span>
                </button>
                {(selectedStatusItem ||
                  goalNameFilter ||
                  CompleteStartRange ||
                  CompleteEndRange ||
                  WinsStartRange ||
                  WinsEndRange ||
                  OntimeStartRange ||
                  OntimeEndRange ||
                  RiskStartRange ||
                  RiskEndRange ||
                  riskFilter ||
                  goalOwnerFilter ||
                  statusFilter) && (
                    <span
                      className=" mx-3 pt-1 float-end"
                      title="Clear all filter"
                      onClick={(e) => {
                        clearAllGoalsFilter(e);
                      }}
                      style={{
                        cursor: "pointer",
                        color: "#DC5D74"
                      }}
                    >
                      Clear all filter
                      <i className="fas fa-filter fs-18"></i>
                      <span>x</span>
                    </span>
                  )}
              </div>
            </div>

            <div className="card-body">
              <div
                id="job_data"
                className="dataTables_wrapper"
                onClick={() => {
                  setGoalsdelete(false);
                  setGoalsupdate(false);
                  setGoalscreated(false);
                  setTaskcreated(false);
                  setTaskupdate(false);
                  setTaskdelete(false);
                }}
              >
                <>
                  <DndProvider backend={HTML5Backend}>
                    <div className="table-responsive">
                      <div id="job_data" className="dataTables_wrapper">
                        {showTableSpinner ? (
                          <div
                            style={{
                              position: "relative",
                              top:
                                goals.length < 3
                                  ? "6rem"
                                  : goals.length >= 3
                                    ? "9rem"
                                    : "1rem"
                            }}
                            className="m-0 p-0"
                          >
                            <LoadingSpinner />
                          </div>
                        ) : (
                          ""
                        )}
                        <table
                          className="display w-100 dataTable "
                          id="example5"
                          role="grid"
                          aria-describedby="example5_info"
                          defaultPageSize={5}
                          style={
                            goals.length < 2
                              ? { minHeight: "110px" }
                              : { minHeight: "200px" }
                          }
                        >
                          <thead>
                            <tr role="row">
                              {/* <th style={{paddingRight:"55px"}}>Sr. No</th> */}

                              <th className="sorting goalwidth">
                                <div className="d-flex m-0 p-0">
                                  <span
                                    className="float-start"
                                    style={{
                                      paddingRight: "10px",
                                      fontWeight: "bold",
                                      color: "#131F4C"
                                    }}
                                  >
                                    <i
                                      class="fas fa-sort fs-16"
                                      onClick={() => {
                                        handleClick11("goal", !sortAsc);
                                      }}
                                    ></i>
                                    Goal
                                  </span>
                                  {filterIcon ? (
                                    <Dropdown
                                      // className="setDropDownposition float-end"
                                      title="Filter"
                                      // style={{ right: "51%" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setGoalInputFieldOpen(
                                          !GoalInputFieldOpen
                                        );
                                      }}
                                    >
                                      <Dropdown.Toggle
                                        style={{
                                          background: "none",
                                          border: "none"
                                        }}
                                        className="m-0 p-0"
                                      >
                                        <i
                                          className="fas fa-filter fs-12"
                                          style={{
                                            cursor: "pointer",
                                            color: goalNameFilter
                                              ? "#DC5D74"
                                              : "#898fa5"
                                          }}
                                        ></i>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {
                                          <>
                                            <input
                                              type="text"
                                              name="name"
                                              className="figma-input p m-0 p-2"
                                              placeholder="Enter goal name"
                                              autocomplete="off"
                                              defaultValue={goalNameFilter}
                                              onKeyUp={(e) => {
                                                setTimeout(() => {
                                                  setGoalNameFilter(
                                                    e.target.value
                                                  );
                                                }, 1000);
                                              }}
                                              style={{
                                                border: "1px solid #9a9b9c",
                                                width: "150px",
                                                borderRadius: "0px"
                                              }}
                                            />
                                          </>
                                        }
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </th>

                              <th className="sorting_asc perFields">
                                <div className="d-flex m-0 p-0">
                                  <span
                                    className="float-start"
                                    style={{
                                      marginRight: "10px",
                                      fontWeight: "bold",
                                      color: "#131F4C"
                                    }}
                                  >
                                    <i
                                      class="fas fa-sort"
                                      onClick={() => {
                                        handleClick11("owner", !sortAsc);
                                      }}
                                    ></i>
                                    Who
                                  </span>
                                  {filterTaskIcon ? (
                                    <Dropdown
                                      // className="setDropDownposition float-end"
                                      title="Filter"
                                      // style={{ right: "90%" }}
                                      onClick={() => {
                                        setTaskOwnerFieldOpen(
                                          !taskOwnerFieldOpen
                                        );
                                        if (dropdownHeight) {
                                          setdropdownHeight(false);
                                        }
                                      }}
                                    >
                                      <Dropdown.Toggle
                                        style={{
                                          background: "none",
                                          border: "none"
                                        }}
                                        className="m-0 p-0"
                                      >
                                        <i
                                          className="fas fa-filter fs-12"
                                          style={{
                                            cursor: "pointer",
                                            color: goalOwnerFilter
                                              ? "#DC5D74"
                                              : "#898fa5"
                                          }}
                                        ></i>
                                      </Dropdown.Toggle>
                                      <div>
                                        <Dropdown.Menu
                                          menuposition="top-right"
                                          className=""
                                          style={{
                                            width: "auto",
                                            maxHeight: "150px",
                                            overflowY: "scroll"
                                          }}
                                        >
                                          {options?.map((option, i) => (
                                            <Dropdown.Item
                                              key={i}
                                              style={
                                                goalOwnerFilter ===
                                                  option?.userId
                                                  ? {
                                                    backgroundColor: "#B8E5F1"
                                                  }
                                                  : {
                                                    backgroundColor: "#ffffff"
                                                  }
                                              }
                                              value={option?.value}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setGoalOwnerFilter(
                                                  option?.userId
                                                );
                                              }}
                                              className="p fs-14"
                                            >
                                              {option?.value}
                                            </Dropdown.Item>
                                          ))}
                                        </Dropdown.Menu>
                                      </div>
                                    </Dropdown>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </th>
                              <th className="sorting perFields">
                                <OverlayTrigger
                                  delay={{ hide: 100, show: 10 }}
                                  overlay={
                                    <Tooltip id="tooltip-top">
                                      <span className="fs-18">
                                        Toggle to make the goal active. Inactive
                                        goals won't be scheduled.
                                      </span>
                                    </Tooltip>
                                  }
                                  placement="top"
                                >
                                  <div className="d-flex m-0 p-0">
                                    <span
                                      className="float-start"
                                      style={{
                                        paddingRight: "10px",
                                        fontWeight: "bold",
                                        color: "#131F4C"
                                      }}
                                    >
                                      {/* <i
                                    class="fas fa-sort fs-16"
                                    onClick={() => {
                                      // handleClick11("risk_average", !sortAsc);
                                      sorting("goal_risk_average");
                                    }}
                                  ></i> */}
                                      Active
                                    </span>

                                    {filterIcon ? (
                                      <Dropdown
                                        // className="setDropDownposition float-end"
                                        title="Filter"
                                      // style={{ right: "31%" }}
                                      >
                                        <Dropdown.Toggle
                                          style={{
                                            background: "none",
                                            border: "none"
                                          }}
                                          className="m-0 p-0"
                                        >
                                          <i
                                            className="fas fa-filter fs-12"
                                            style={{
                                              cursor: "pointer",
                                              color: statusFilter
                                                ? "#DC5D74"
                                                : "#898fa5"
                                            }}
                                          ></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          {StatusFilter?.map((scope, i) => (
                                            <Dropdown.Item
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setStatusFilter(scope.value);
                                              }}
                                              style={
                                                statusFilter === scope?.name
                                                  ? {
                                                    backgroundColor: "#B8E5F1"
                                                  }
                                                  : {
                                                    backgroundColor: "#ffffff"
                                                  }
                                              }
                                              key={i}
                                              className="p fs-14"
                                            >
                                              {scope?.name}
                                            </Dropdown.Item>
                                          ))}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </OverlayTrigger>
                              </th>
                              {/* <th className="sorting perFields">
                                <div className="d-flex m-0 p-0">
                                  <span
                                    className="float-start"
                                    style={{ paddingRight: "10px" }}
                                  >
                                    On-time
                                  </span>
                                  {filterIcon ? (
                                    <Dropdown
                                      // className="setDropDownposition float-end"
                                      title="Filter"
                                      // style={{ right: "21%" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                      }}
                                    >
                                      <Dropdown.Toggle
                                        style={{
                                          background: "none",
                                          border: "none",
                                        }}
                                        className="m-0 p-0"
                                      >
                                        <i
                                          className="fas fa-filter fs-12"
                                          style={{
                                            cursor: "pointer",
                                            color:
                                              OntimeStartRange || OntimeEndRange
                                                ? "#DC5D74"
                                                : "#898fa5",
                                          }}
                                        ></i>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="p-2">
                                        {
                                          <>
                                            <h5>Enter start & end range</h5>
                                            <Row className="d-flex">
                                              <Col>
                                                <input
                                                  type="number"
                                                  name="name"
                                                  min={0}
                                                  max={100}
                                                  className="figma-input p m-0 p-2"
                                                  placeholder="Start"
                                                  autocomplete="off"
                                                  defaultValue={
                                                    OntimeStartRange
                                                  }
                                                  onKeyUp={(e) => {
                                                    setTimeout(() => {
                                                      setOntimeStartRange(
                                                        e.target.value
                                                      );
                                                    }, 1000);
                                                  }}
                                                  style={{
                                                    border: "1px solid #9a9b9c",
                                                    height: "30px",
                                                  }}
                                                />
                                              </Col>
                                              _
                                              <Col>
                                                <input
                                                  type="number"
                                                  name="name"
                                                  min={0}
                                                  max={100}
                                                  className="figma-input p m-0 p-2 me-1"
                                                  placeholder="End"
                                                  autocomplete="off"
                                                  defaultValue={OntimeEndRange}
                                                  onKeyUp={(e) => {
                                                    setTimeout(() => {
                                                      setOntimeEndRange(
                                                        e.target.value
                                                      );
                                                    }, 1000);
                                                  }}
                                                  style={{
                                                    border: "1px solid #9a9b9c",
                                                    height: "30px",
                                                  }}
                                                />
                                              </Col>
                                            </Row>
                                          </>
                                        }
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </th> */}
                              {/* <th className="sorting perFields">
                                <div className="d-flex m-0 p-0">
                                  <span
                                    className="float-start"
                                    style={{ paddingRight: "10px" }}
                                  >
                                    <i
                                      class="fas fa-sort fs-16"
                                      onClick={() => {
                                        // handleClick11("risk_average", !sortAsc);
                                        sorting("goal_risk_average");
                                      }}
                                    ></i>
                                    Risk
                                  </span>

                                  {filterIcon ? (
                                    <Dropdown
                                      // className="setDropDownposition float-end"
                                      title="Filter"
                                      // style={{ right: "31%" }}
                                    >
                                      <Dropdown.Toggle
                                        style={{
                                          background: "none",
                                          border: "none",
                                        }}
                                        className="m-0 p-0"
                                      >
                                        <i
                                          className="fas fa-filter fs-12"
                                          style={{
                                            cursor: "pointer",
                                            color: riskFilter
                                              ? "#DC5D74"
                                              : "#898fa5",
                                          }}
                                        ></i>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {ObjectiveRisk?.map((scope, i) => (
                                          <Dropdown.Item
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setRiskFilter(scope.value);
                                            }}
                                            style={
                                              riskFilter === scope?.name
                                                ? { backgroundColor: "#B8E5F1" }
                                                : { backgroundColor: "#ffffff" }
                                            }
                                            key={i}
                                            className="p fs-14"
                                          >
                                            {scope?.name}
                                          </Dropdown.Item>
                                        ))}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  ) : (
                                    ""
                                  )}

                                 
                                </div>
                              </th> */}

                              {/* <th>Status </th> */}

                              {/* <th
                                className="sorting perFields"
                                style={{ paddingRight: "10px" }}
                              >
                                Create task
                              </th> */}
                              <th
                                className="sorting perFields"
                                style={{ fontWeight: "bold", color: "#131F4C" }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {goals?.map((goal, index) => (
                              <TableRows
                                key={goal.id}
                                goal={goal}
                                index={index}
                              />
                            ))}
                          </tbody>

                          {!rowAddGoals && (
                            <tr
                              className="odd goals"
                              style={{ borderTop: "1px solid #d7dae3" }}
                              role="row"
                            >
                              <OverlayTrigger
                                delay={{ hide: 100, show: 10 }}
                                overlay={
                                  <Tooltip id="tooltip-right">
                                    <span className="fs-18">
                                      Quick Add Goal
                                    </span>
                                  </Tooltip>
                                }
                                placement="right"
                              >
                                <Button
                                  className="mt-4 mb-4"
                                  style={{
                                    background: "#356FD4",
                                    border: "1px solid #356FD4",
                                    height: "40px",
                                    width: "40px"
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    // setshowTaskMoadal(true)
                                    setRowAddGoals(true);
                                  }}
                                // onClick={handleDoneButton}
                                // onContextMenu={handleClick} // Right-click handler
                                >
                                  <FaPlus color="#FFFFFF"></FaPlus>
                                </Button>
                              </OverlayTrigger>
                            </tr>
                          )}

                          {rowAddGoals && (
                            <tr
                              className="odd goals"
                              style={{ height: "82px" }}
                              role="row"
                            >
                              {/* <td
                              style={{
                                padding: "8px 15px",
                                paddingLeft: "0px",
                              }}
                            >
                              <input
                                className="figma-input p"
                                type="number"
                                name="srNo"
                                value={inputValues.srNo}
                                onChange={handleFormChange}
                                placeholder="Sr. No"
                              />

                            </td> */}

                              <td
                                style={{
                                  padding: "8px 8px",
                                  position: "relative"
                                }}
                              >
                                <input
                                  style={{
                                    padding: "10px 16px",
                                    width: "80%",
                                    borderRadius: "30px",
                                    borderColor: "#a8d6c5"
                                  }}
                                  className="figma-input p"
                                  type="text"
                                  name="objective"
                                  value={inputValues.objective}
                                  onChange={handleFormChange}
                                  required
                                  placeholder="Enter Goal name"
                                />
                                {errors?.objective && (
                                  <span
                                    className="mt-2"
                                    style={{
                                      color: "red",
                                      position: "absolute",
                                      top: "65%",
                                      left: "0",
                                      // fontSize: '14px',
                                      marginTop: "2px"
                                    }}
                                  >
                                    * {errors?.objective}
                                  </span>
                                )}
                              </td>
                              <td
                                style={{
                                  padding: "8px 15px",
                                  paddingLeft: "0px"
                                }}
                              >
                                <Select
                                  name="goal_owner"
                                  className="p"
                                  options={options}
                                  // styles={{ }
                                  value={goalOwner}
                                  onChange={changeGoalOwner}
                                  menuPlacement="auto"
                                  menuPortalTarget={document.body}
                                  styles={{
                                    padding: "2px",
                                    borderRadius: "10px",
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 1
                                    })
                                  }}
                                  isDisabled={scopeOfTime == "personal"}
                                />
                              </td>
                              {/* <td
                              style={{
                                marginTop:"45px",
                                padding: "8px 15px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              className="figbtn-success"
                            >
                              <span className="figstatus">On track</span>
                            </td> */}

                              {/* <td style={{ padding: "8px 15px" }}>
                           
                              0 %
                            </td> */}
                              {/* <td style={{ padding: "8px 15px" }}>
                              
                              0 %
                            </td> */}
                              <td style={{ padding: "12px 8px" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center"
                                  }}
                                >
                                  <div>{/* {goal?.status} */}</div>
                                  <div>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={isToggled}
                                          onChange={handleToggleChange}
                                          name="statusToggle"
                                          color="primary"
                                        />
                                      }
                                    />
                                  </div>
                                </div>
                              </td>
                              {/* <td style={{ padding: "8px 15px" }}>
                             
                              0 % 
                            </td> */}
                              {/* <td style={{ padding: "8px 15px" }}>
                             
                              -
                            </td> */}

                              {/* <td style={{padding:'8px 15px'}}>
                <input
                className="figma-input p"
                  type="text"
                  name="objectiveStatus"
                  value={inputValues.objectiveStatus}
                  onChange={handleFormChange}
                  placeholder="Objective Status"
                />
              </td> */}
                              {/* <td style={{ padding: "8px 15px", display:"flex", alignItems:"flex-end", justifyContent:"center" }}>-</td> */}
                              <td style={{ padding: "12px 8px" }}>
                                <button
                                  className="figma-btn text-white goal-save-button mx-3"
                                  type="submit"
                                  onClick={handleFormSubmit}
                                  style={{ width: "74%" }}
                                >
                                  Add
                                </button>
                              </td>
                            </tr>
                          )}
                        </table>

                        {goals?.length === 0 && !showTableSpinner && (
                          <Row className="p-0 m-0">
                            <span
                              className="th text-center "
                              style={{ top: "-115px", position: "relative" }}
                            >
                              No records found.
                            </span>
                          </Row>
                        )}
                      </div>
                    </div>
                  </DndProvider>
                </>
              </div>
              <Row className="pt-3 border-0 goal-row">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end"
                  }}
                >
                  <div>
                    <label htmlFor="numberSelector">Rows per page &nbsp;</label>
                    <input
                      type="number"
                      list="numberOptions"
                      value={inputValue}
                      onChange={handleInputChange}
                      className="form-control pagination-row-input"
                      placeholder=""
                      style={{
                        width: "auto",
                        display: "inline-block",
                        maxWidth: "100px",
                        background: "#fff",
                        border: "0.0625rem solid #d7dae3",
                        padding: "0.3125rem 1.25rem",
                        color: "#6e6e6e",
                        borderRadius: "0rem",
                        height: "45px"
                      }}
                    />
                    <datalist id="numberOptions">
                      {numberOptions.map((number) => (
                        <option key={number} value={number} />
                      ))}
                    </datalist>
                  </div>

                  {pagination && (
                    <>
                      <Pagination
                        pageCount={pageCount}
                        pageIndex={goToPage}
                        gotoPage={setGoToPage}
                      />
                    </>
                  )}
                </div>
              </Row>
            </div>
            {/* } */}
          </div>
        </div>
      )}
    </>
  );
};

export default Goals;
