import React from "react";
import { Row, Col, Layout } from "antd";
import AboutUsHeader from "../components/AboutUsHeader";
import AboutUsContent from "../components/AboutUsContent";
import '../styles/common.css'



const AboutUsPage = () => {

  return (
    <Layout
      className="aboutus-component"
      style={{
        width: "100vw",
        height: "100%",
        position: "fixed",
      }}
    >
      <Row>
        <Col span={24}>
          <AboutUsHeader />

          <AboutUsContent />
        </Col>
      </Row>
    </Layout>
  );
};

export default AboutUsPage;
