import BlogLayout from "../components/BlogLayout/BlogLayout";
import BlogPostSection from "../components/contentSections/BlogPostSection";
import { Col, Row, Typography, Image, Flex, Button, Input, Layout } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import blogPost1 from "../../../../../../src/images/blogs/blogPost1.svg";
import QASection from "../components/contentSections/QASection";
import QASubSection from "../components/contentSections/QASubSection";
import "../styles/blogs.css";
import CardsSection from "../components/contentSections/CardsSection";
import BlogCard2 from "../components/cards/BlogCard2";
import BlogCard3 from "../components/cards/BlogCard3";
import BlogCard4 from "../components/cards/BlogCard4";
import PageFooter from "../../../../../jsx/layouts/landingPage/PageFooter";
import React, { useState, useEffect } from "react";
import GeneratePlanButtonLogo from "../../../../../images/Plansom/generatePlanButton.png";
import { PiTextTBold } from "react-icons/pi";
import ChatModal from "../../../../../jsx/layouts/ChatModal";

const { Text, Paragraph } = Typography;

const BlogPage1 = () => {
  const screens = useBreakpoint();
  const [openChatModal, setOpenChatModal] = useState(false);
  const [preDefinedInput, setPreDefinedInput] = useState("");
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 700);
  const [socketRef, setSocketRef] = useState(null);
  const [stateSocketRef, setStateSocketRef] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [assistanceContent, setAssistanceContent] = useState(""); // New state for content
  const [showAssistance, setShowAssistance] = useState(true); // State to control visibility
  const { Header, Content, Footer } = Layout;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setPreDefinedInput(e.target.value);
    if (newValue && !socketRef) {
      establishWebSocketConnection();
    }
  };

  const generateUniqueUID = () => {
    const timestamp = Date.now();
    const randomNum = Math.floor(Math.random() * 10000);
    return `${timestamp}${randomNum}`;
  };

  const getOrCreateUID = () => {
    let storedUID = localStorage.getItem("plansom_bot_id");
    if (!storedUID) {
      storedUID = generateUniqueUID();
      localStorage.setItem("plansom_bot_id", storedUID);
    }
    return storedUID;
  };

  const establishWebSocketConnection = () => {
    if (!socketRef) {
      console.log("Establishing WebSocket connection...");
      const socket = new WebSocket(
        `${
          process.env.REACT_APP_WEBSOCKET_URL
        }/ws/plansombot/${getOrCreateUID()}`
      );
      const stateSocket = new WebSocket(
        `${
          process.env.REACT_APP_WEBSOCKET_URL
        }/ws/plansombot/thread/state/notification/${getOrCreateUID()}`
      );

      socket.onopen = () => {
        console.log("WebSocket is connected");
        setIsConnected(true);
        setSocketRef(socket);
      };

      socket.onclose = () => {
        console.log("WebSocket is closed");
        setIsConnected(false);
        setSocketRef(null);
      };

      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
        setSocketRef(null);
      };

      stateSocket.onopen = () => {
        console.log("State WebSocket is connected");
        setIsConnected(true);
        setStateSocketRef(stateSocket);
      };

      stateSocket.onclose = () => {
        console.log("State WebSocket is closed");
        setIsConnected(false);
        setStateSocketRef(null);
      };

      stateSocket.onerror = (error) => {
        console.error("State WebSocket error:", error);
        setStateSocketRef(null);
      };
    }
  };

  useEffect(() => {
    if (!socketRef) {
      establishWebSocketConnection();
    }
  }, [socketRef]);

  useEffect(() => {
    return () => {
      if (socketRef) {
        socketRef.close();
      }
      if (stateSocketRef) {
        stateSocketRef.close();
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      if (socketRef) {
        socketRef.close();
      }
      if (stateSocketRef) {
        stateSocketRef.close();
      }
    };
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (preDefinedInput) {
        setOpenChatModal(true);
        setShowAssistance(true); // Show the assistance div when a message is sent
        setAssistanceContent("Processing your request..."); // Display a state message
      }
    }
  };

  useEffect(() => {
    if (preDefinedInput) {
      document.addEventListener("keypress", handleKeyPress);
    }
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [preDefinedInput]);

  return (
    <>
      {!openChatModal ? (
        <BlogLayout>
          <BlogPostSection>
            <Row
              style={{
                padding: !screens.md ? "0rem" : "1.75rem 0rem",
                width: "100%",
              }}
            >
              <Col span={24} style={{ paddingBottom: "1.5rem" }}>
                <Text
                  className="f-bricolage"
                  style={{
                    fontSize: !screens.md ? "2rem" : "3rem",
                    fontWeight: "700",
                    lineHeight: !screens.md ? "2.75rem" : "3.75rem",
                  }}
                >
                  What is Plansom? AI App That Turns Your Ideas Into Clear,
                  Actionable Plans
                </Text>
              </Col>
              <Col
                span={24}
                style={{
                  width: "100%",
                  paddingBottom: !screens.md ? "1.5rem" : "1.75rem",
                }}
              >
                <Flex justify="center">
                  <Image src={blogPost1} alt="" preview={false} />
                </Flex>
              </Col>
              <Col xl={16} lg={22} md={22} sm={24}>
                <Flex vertical style={{ gap: "1rem" }}>
                  <Text className="blogpost-text">
                    Are you sitting on a mountain of great ideas, yet struggling
                    to execute them? You’re not alone. For independent
                    professionals, solo entrepreneurs, and small business
                    owners, managing work can often feel like juggling
                    knives—one slip, and things start falling apart.
                  </Text>
                  <Text className="blogpost-text">
                    Enter <strong>Plansom</strong>, your new AI-powered best
                    friend that transforms ambitious ideas into clear,
                    actionable plans. Whether you’re battling task overload,
                    time constraints, or the overwhelming need for perfection,
                    Plansom simplifies your life and puts you back in control.
                  </Text>
                  <Text className="blogpost-text">
                    In this blog, we’ll explore what makes Plansom a
                    game-changing tool, how it works, and why it’s becoming a
                    must-have for professionals like you. Let’s dive in!
                  </Text>
                  <Text className="blogpost-text fw-600">
                    Let’s get started!
                  </Text>
                </Flex>
              </Col>
            </Row>
          </BlogPostSection>
          <QASection>
            <QASubSection>
              <Flex vertical style={{ gap: "1.5rem", marginBottom: "3rem" }}>
                <Text className="qasubsec-heading">
                  What is Plansom? The AI Mentor You Didn’t Know You Needed
                </Text>
                <Text className="qasubsec-text">
                  Imagine if you could take a vague idea or an overwhelming
                  project and turn it into a perfectly prioritized action plan
                  in minutes. That’s exactly what{" "}
                  <strong style={{ color: "var(--dark-blue)" }}>Plansom</strong>{" "}
                  does. It’s not just another to-do list app—it’s an intelligent
                  AI tool that converts your prompts into personalized action
                  plans designed to help you achieve your goals effortlessly.
                </Text>

                <Flex vertical style={{ gap: "0.75rem" }}>
                  <Text className="qasubsec-text">
                    <Text className="qasubsec-text">
                      <strong>Here’s how it works:</strong>
                    </Text>
                    <ol className="qasubsec-text">
                      <li>
                        You type a prompt (e.g., “launch a social media
                        campaign,” “grow my consulting business,” or even
                        “declutter my house”).
                      </li>
                      <li>
                        Plansom asks you a series of simple, clarifying
                        questions to understand your priorities and
                        expectations.
                      </li>
                      <li>
                        Boom! It generates a step-by-step action plan, complete
                        with goals, sub-goals, and prioritized tasks.
                      </li>
                    </ol>
                  </Text>
                </Flex>

                <Text className="qasubsec-text">
                  Think of it as an AI-powered roadmap that guides you from{" "}
                  <em>“Where do I start?”</em> to <em>“Done and dusted!”</em>
                </Text>
              </Flex>
            </QASubSection>
            <QASubSection>
              <Flex vertical style={{ gap: "1.5rem" }}>
                <Text className="qasubsec-heading">
                  Why Choose Plansom? Key Features You’ll Love
                </Text>
                <Flex vertical style={{ gap: "0.75rem" }}>
                  <Text className="qasubsec-heading-2">
                    1. Personalized Action Plans
                  </Text>
                  <Text className="qasubsec-text">
                    <Text className="qasubsec-text">
                      Let’s be real—generic plans rarely work. Plansom tailors
                      every action plan to your unique goals, timelines, and
                      workflow. Instead of endless to-do lists, you get a
                      roadmap with:
                    </Text>
                    <ul
                      className="qasubsec-text"
                      style={{ listStyleType: "disc" }}
                    >
                      <li>
                        <strong>Clear goals</strong>
                      </li>
                      <li>
                        <strong>Well-defined sub-goals</strong>
                      </li>
                      <li>
                        <strong>Tasks ranked by priority</strong>
                      </li>
                    </ul>
                  </Text>
                  <Text className="qasubsec-text">
                    This ensures that you always know what to do next, saving
                    you from analysis paralysis and boosting productivity.
                  </Text>
                  <Text className="qasubsec-text">
                    <Text className="qasubsec-text">
                      <strong>Example:</strong> If your goal is “Grow my client
                      base,” Plansom might break it into actionable steps like:
                    </Text>
                    <ul
                      className="qasubsec-text"
                      style={{ listStyleType: "disc" }}
                    >
                      <li>Research target markets</li>
                      <li>Create a LinkedIn outreach campaign</li>
                      <li>Follow up with potential leads</li>
                    </ul>
                  </Text>
                  <Text className="qasubsec-text">
                    No fluff—just focused, achievable steps.
                  </Text>
                </Flex>
                <Flex vertical style={{ gap: "0.75rem" }}>
                  <Text className="qasubsec-heading-2">
                    2. AI Coach: Your Personal Motivator
                  </Text>
                  <Text className="qasubsec-text">
                    <Text className="qasubsec-text">
                      Ever wish you had a mentor cheering you on? Plansom’s AI
                      Coach is like that wise friend who nudges you to stay on
                      track and celebrates your progress. It offers:
                    </Text>
                    <ul
                      className="qasubsec-text"
                      style={{ listStyleType: "disc" }}
                    >
                      <li>
                        <strong>Motivational reminders</strong> to keep you
                        focused
                      </li>
                      <li>
                        <strong>Real-time tips</strong>for overcoming challenges
                      </li>
                      <li>
                        <strong>Visual progress tracking</strong>to show how far
                        you’ve come
                      </li>
                    </ul>
                  </Text>
                  <Text className="qasubsec-text">
                    This emotional touch is invaluable, especially when you’re
                    working solo.
                  </Text>

                  <Text className="qasubsec-text">
                    <strong>Keyword Tip</strong>“AI productivity tools,” “AI
                    mentor for professionals.”
                  </Text>
                </Flex>

                <Flex vertical style={{ gap: "0.75rem" }}>
                  <Text className="qasubsec-heading-2">
                    3. Quick Wins for Instant Progress
                  </Text>
                  <Text>
                    <Paragraph className="qasubsec-text">
                      Big projects can be intimidating. Plansom breaks them into
                      manageable chunks, offering <strong> Quick Wins</strong>{" "}
                      to build momentum. Completing smaller tasks gives you a
                      boost of confidence and keeps you motivated
                    </Paragraph>
                    <Paragraph className="qasubsec-text">
                      <strong>It Matters:</strong>Why Research shows that
                      achieving small goals releases dopamine, which motivates
                      you to tackle bigger challenges. Plansom taps into this
                      psychology to help you stay engaged.
                    </Paragraph>
                  </Text>
                </Flex>
              </Flex>
            </QASubSection>
            <QASubSection>
              <Flex vertical style={{ gap: "1.5rem" }}>
                <Text className="qasubsec-heading">
                  How Plansom Works: From Idea to Execution
                </Text>
                <Text className="qasubsec-text">
                  Getting started with Plansom is as easy as 1-2-3:
                </Text>
                <Flex vertical style={{ gap: "0.75rem" }}>
                  <Text className="qasubsec-heading-2">
                    Step 1: Enter Your Prompt
                  </Text>

                  <Text className="qasubsec-text">
                    Head to the Plansom dashboard and type your goal, idea, or
                    project. Not sure where to start? Use pre-set Prompt Cards
                    to guide you (e.g., “Plan a product launch” or “Organize my
                    week”).
                  </Text>
                </Flex>
                <Flex vertical style={{ gap: "0.75rem" }}>
                  <Text className="qasubsec-heading-2">
                    Step 2: Answer a Few Questions
                  </Text>

                  <Text className="qasubsec-text">
                    Plansom will ask a series of questions to better understand
                    your priorities and challenges. Don’t worry—this part is
                    quick and easy.
                  </Text>
                </Flex>
                <Flex vertical style={{ gap: "0.75rem" }}>
                  <Text className="qasubsec-heading-2">
                    Step 3: Get Your Personalized Action Plan
                  </Text>
                  <Text className="qasubsec-text">
                    <Text className="qasubsec-text">
                      In minutes, Plansom generates a detailed action plan
                      broken down into:
                    </Text>
                    <ul
                      className="qasubsec-text"
                      style={{ listStyleType: "disc" }}
                    >
                      <li>
                        <strong>Goals</strong> (what you want to achieve)
                      </li>
                      <li>
                        <strong>Sub-goals</strong>(smaller milestones)
                      </li>
                      <li>
                        <strong>Tasks</strong>(step-by-step actions ranked by
                        priority)
                      </li>
                    </ul>
                  </Text>

                  <Text className="qasubsec-text">
                    <strong>Bonus:</strong> You can assign tasks to team
                    members, add attachments, and track progress—all in one
                    place.
                  </Text>
                </Flex>
              </Flex>
            </QASubSection>
            <QASubSection>
              <Flex vertical style={{ gap: "1.5rem" }}>
                <Text className="qasubsec-heading">
                  What Makes Plansom Unique? A Standout Among Productivity Tools
                </Text>
                <Text className="qasubsec-text">
                  You might be wondering, “How is Plansom different from other
                  productivity tools?” Here’s what sets it apart
                </Text>
                <Flex vertical style={{ gap: "0.75rem" }}>
                  <Text className="qasubsec-heading-2">1. Simplicity</Text>

                  <Text className="qasubsec-text">
                    While many tools overwhelm you with features, Plansom keeps
                    it simple. It cuts through the noise and focuses on helping
                    you get things done
                  </Text>
                </Flex>
                <Flex vertical style={{ gap: "0.75rem" }}>
                  <Text className="qasubsec-heading-2">2. Personalization</Text>

                  <Text className="qasubsec-text">
                    Plansom adapts to you—your work style, goals, and
                    priorities. It’s like having a productivity coach who “gets”
                    you.
                  </Text>
                </Flex>

                <Flex vertical style={{ gap: "0.75rem" }}>
                  <Text className="qasubsec-heading-2">3. Accountability</Text>

                  <Text className="qasubsec-text">
                    With reminders, progress tracking, and an AI Coach, Plansom
                    keeps you accountable without being pushy.
                  </Text>
                </Flex>
                <Flex vertical style={{ gap: "0.75rem" }}>
                  <Text className="qasubsec-heading-2">
                    4. Emotional Support
                  </Text>

                  <Text className="qasubsec-text">
                    Working solo can feel isolating. Plansom adds a human touch
                    to AI, offering encouragement and celebrating your wins.
                  </Text>
                </Flex>
                <Flex vertical style={{ gap: "0.75rem" }}>
                  <Text className="qasubsec-heading-2">5. Balance</Text>

                  <Text className="qasubsec-text">
                    Plansom isn’t just for work. You can create personal action
                    plans to manage home projects, health goals, or family time,
                    helping you achieve work-life balance.
                  </Text>
                </Flex>
              </Flex>
            </QASubSection>
            <QASubSection>
              <Flex vertical style={{ gap: "1.5rem" }}>
                <Text className="qasubsec-heading">
                  How Plansom Solves Real-Life Pain Points
                </Text>
                <Text className="qasubsec-text">
                  <Text className="qasubsec-text">
                    We all face challenges like:
                  </Text>
                  <ul
                    className="qasubsec-text"
                    style={{ listStyleType: "disc" }}
                  >
                    <li>
                      <strong>Overwhelm</strong>Too many tasks and no clear
                      starting point.
                    </li>
                    <li>
                      <strong>Procrastination:</strong> Delaying important work
                      due to lack of motivation.
                    </li>
                    <li>
                      <strong>Burnout:</strong>Overworking without tangible
                      results.
                    </li>
                    <li>
                      <strong>Delegation struggles:</strong> Not trusting others
                      to get the job done.
                    </li>
                  </ul>
                </Text>
                <Text className="qasubsec-text">
                  <Text className="qasubsec-text">
                    Plansom directly addresses these issues by:
                  </Text>
                  <ul
                    className="qasubsec-text"
                    style={{ listStyleType: "disc" }}
                  >
                    <li>Simplifying large projects into manageable steps</li>
                    <li>
                      Offering accountability through reminders and AI guidance
                    </li>
                    <li>
                      Helping you prioritize tasks based on impact and effort.
                    </li>
                  </ul>
                </Text>
                <Text className="qasubsec-text">
                  <strong> Result:</strong> You spend less time worrying about
                  how to get things done and more time actually doing them.
                </Text>
              </Flex>
            </QASubSection>
            <QASubSection>
              <Flex vertical style={{ gap: "1.5rem" }}>
                <Text className="qasubsec-heading">
                  Ready to Achieve More with Less Stress? Try Plansom Today
                </Text>
                <Text className="qasubsec-text">
                  <Text className="qasubsec-text">
                    In a world where professionals are constantly juggling
                    tasks, Plansom is the AI-powered solution that simplifies
                    your life. Whether you’re a freelancer, consultant, or small
                    business owner, Plansom helps you:
                  </Text>
                  <ul
                    className="qasubsec-text"
                    style={{ listStyleType: "disc" }}
                  >
                    <li>
                      <strong>Turn ideas into action</strong> with personalized
                      plans
                    </li>
                    <li>
                      <strong>Stay motivated</strong> with an AI Coach by your
                      side
                    </li>
                    <li>
                      <strong>Burnout:</strong>Overworking without tangible
                      results.
                    </li>
                    <li>
                      <strong>Achieve quick wins</strong> and track progress
                      effortlessly
                    </li>
                  </ul>
                </Text>

                <Text className="qasubsec-text">
                  No more endless to-do lists. No more feeling stuck. Just clear
                  plans, consistent progress, and the satisfaction of getting
                  things done—your way.
                </Text>
              </Flex>
            </QASubSection>
            <Text className="qasubsec-text">
              Ready to experience the magic of Plansom?{" "}
              <a>Start your free trial today!</a>
            </Text>
          </QASection>
          <CardsSection>
            <Row
              // 24px = 1.5rem
              gutter={!screens.md ? [0, 24] : [24, 24]} // Both horizontal & vertical spacing
              justify="start"
              align="center"
              style={{
                marginTop: !screens.md ? "2rem" : "2.5rem",
                width: "100%",
                display: "flex",
                alignItems: "stretch",
              }}
            >
              <Col
                xl={8}
                lg={12}
                md={12}
                sm={24}
                xs={24}
                style={{ display: "flex" }}
              >
                <BlogCard2 />
              </Col>
              <Col
                xl={8}
                lg={12}
                md={12}
                sm={24}
                xs={24}
                style={{
                  display: "flex",
                }}
              >
                <BlogCard3 />
              </Col>
              <Col
                xl={8}
                lg={0}
                md={0}
                sm={24}
                xs={24}
                style={{
                  display: !screens.xl ? " " : "flex",
                }}
              >
                <BlogCard4 />
              </Col>
            </Row>
          </CardsSection>

          <Layout>
            <Content
              style={{
                textAlign: "center",
                padding: "50px 20px",
                background:
                  "linear-gradient(74.47deg, #465EBD 0%, #1D3BAF 50%, #152A7C 100%)",
              }}
            >
              <h2
                style={{
                  fontSize: "38px",
                  color: "#FFFFFF",
                  fontWeight: "600",
                  fontFamily: "Bricolage Grotesque",
                }}
                className="sub-heading-title"
              >
                Start Your Journey to Success Today ✨
              </h2>
              <p
                style={{
                  color: "#FFFFFF",
                  fontSize: "16px",
                  fontFamily: "Inter",
                  fontWeight: "400",
                }}
              >
                Create Your First Plan with Plansom!
              </p>
              <Flex
                style={{ marginTop: "2%", marginBottom: "2%" }}
                justify="center"
              >
                <Col lg={12} md={16} xs={24} sm={24}>
                  <Input
                    style={{
                      borderRadius: "40px",
                      padding: isSmallScreen ? "2px" : "10px",
                    }}
                    prefix={
                      <PiTextTBold
                        size={28}
                        color="#90939B"
                        style={{ margin: "5px" }}
                      />
                    }
                    suffix={
                      <Button
                        variant="contained"
                        className="custom-login-button"
                        style={{
                          backgroundColor: "#1D3BAF",
                          borderRadius: "20px",
                          color: "#ffffff",
                          textTransform: "none",
                          padding: "20px",
                        }}
                        icon={<Image width={20} src={GeneratePlanButtonLogo} />}
                        disabled={!preDefinedInput}
                        onClick={() => {
                          if (preDefinedInput) {
                            setOpenChatModal(true);
                            setShowAssistance(true); // Show the assistance div when a message is sent
                            setAssistanceContent("Processing your request..."); // Display a state message
                          }
                        }}
                      >
                        {!isSmallScreen && "Generate Plan"}
                      </Button>
                    }
                    onKeyPress={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        if (preDefinedInput) {
                          setOpenChatModal(true);
                          setShowAssistance(true); // Show the assistance div when a message is sent
                          setAssistanceContent("Processing your request..."); // Display a state message
                        }
                      }
                    }}
                    value={preDefinedInput}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                    placeholder="Build a business plan for a ..."
                  />
                </Col>
              </Flex>
            </Content>
            <PageFooter isMobile={isMobile} />
          </Layout>
        </BlogLayout>
      ) : (
        <ChatModal
          openChatModal={openChatModal}
          setOpenChatModal={setOpenChatModal}
          preDefinedInput={preDefinedInput}
          setPreDefinedInput={setPreDefinedInput}
          existingSocket={socketRef}
          existingStateSocket={stateSocketRef}
          value={isConnected}
          reConnectWebSocket={setSocketRef}
        />
      )}
    </>
  );
};

export default BlogPage1;
