import React, { useState, useEffect, lazy } from "react";
import { Card, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { getAllTrackObjectives } from "../../../services/GoalService";
const CompleteChart = lazy(() => import("../Chartjs/CompleteChart"));
const OnTimeChart = lazy(() => import("../Chartjs/OnTimeChart"));
const WinsChart = lazy(() => import("../Chartjs/WinsChart"));
const TaskWorkTable = lazy(() => import("./TaskWorkTable"));
const BarChartObjective = lazy(() => import("../Chartjs/BarChartObjective"));
const PieNewChart = lazy(() => import("../Chartjs/PieNewChart"));

const TrackObjective = React.memo(({ scopes }) => {
  const [refreshGraph, setRefreshGraph] = useState(false);
  const scope = localStorage.getItem("scope");
  const timeScope = localStorage.getItem("timescope");
  const userDetail = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetail);
  const org_id = localStorage.getItem("organization_id");
  const team_id = localStorage.getItem("team_id");
  const location = useLocation();
  const [allParentGoals, setAllParentGoals] = useState([]);
  const [allSubGoals, setAllSubGoals] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedGoalType, setSelectedGoalType] = useState("");
  const [selectedSubGoalType, setSelectedSubGoalType] = useState(null);
  const [graphData, setGraphData] = useState([]);
  const [defaultTime, setDefaultTime] = useState("1 month");
  const [breadcrumbArray, setBreadcrumbArray] = useState([]);

  useEffect(() => {
    if (
      localStorage.getItem("previousLocation") === "/stripe" &&
      sessionStorage.getItem("strategyImported") === "false"
    ) {
      toast.info(
        <div className="m-1">
          <h3>Payment Pending for Strategy </h3>
        </div>,
        { toastId: "strategyPaymentFail" }
      );
      sessionStorage.removeItem("strategyImported");
    }
  }, []);

  useEffect(() => {
    getGoals(org_id, scope, userInfoDetails?.data?.accessToken, "");
  }, []);

  useEffect(() => {
    setRefreshGraph(true);
  }, [location?.state, timeScope, scope, team_id]);

  const getGoals = (org_id, scope, token, goal_id) => {
    setLoading(true);
    const data = getAllTrackObjectives(goal_id, token, scope);
    data?.then((res) => {
      if (!goal_id) {
        let parentgoalData = [];
        res?.data?.data?.goals.forEach((ele) => {
          parentgoalData.push({ value: ele?.id, label: ele?.name });
        });
        setAllParentGoals(parentgoalData);
        setSelectedGoalType(parentgoalData[0]);
        breadcrumbArray.push(parentgoalData[0]);
      }
      let parentgoalData = [];
      res?.data?.data?.goals.forEach((ele) => {
        parentgoalData.push({ value: ele?.id, label: ele?.name });
      });
      setAllParentGoals(parentgoalData);
      let subgoalData = [];
      res?.data?.data?.sub_goal.forEach((ele) => {
        subgoalData.push({ value: ele?.id, label: ele?.name });
      });
      setAllSubGoals(subgoalData);
      setLoading(false);
    });
    data?.catch((err) => {
      setLoading(false);
    });
  };

  const changeGoalTypeHandler = (selectedGoal) => {
    getGoals(
      org_id,
      scope,
      userInfoDetails?.data?.accessToken,
      selectedGoal?.value
    );

    setSelectedGoalType(selectedGoal);
    setSelectedSubGoalType(null); // Reset sub-goal selection
    setRefreshGraph(true);

    setBreadcrumbArray((prevArray) => {
      if (prevArray.length === 0) {
        return [{ value: selectedGoal.value, label: selectedGoal.label }];
      } else {
        const updatedArray = [...prevArray];
        updatedArray[updatedArray.length - 1] = {
          value: selectedGoal.value,
          label: selectedGoal.label,
        };
        return updatedArray;
      }
    });
  };

  const changeSubGoalTypeHandler = (selectedGoal) => {
    getGoals(
      org_id,
      scope,
      userInfoDetails?.data?.accessToken,
      selectedGoal?.value
    );

    setSelectedGoalType(selectedGoal);
    setRefreshGraph(true);

    setBreadcrumbArray((prevArray) => {
      if (prevArray.length === 0) {
        return [{ value: selectedGoal.value, label: selectedGoal.label }];
      }

      const updatedArray = [...prevArray];
      updatedArray.push({
        value: selectedGoal.value,
        label: selectedGoal.label,
      });

      return updatedArray;
    });
  };

  useEffect(() => {
    setSelectedSubGoalType(null);

    setAllParentGoals([]);
  }, [scope, team_id]);

  let goal_id = selectedSubGoalType?.value
    ? selectedSubGoalType?.value
    : selectedGoalType?.value
    ? selectedGoalType?.value
    : "";

  useEffect(() => {
    setDefaultTime("");
    setRefreshGraph(!refreshGraph);
  }, [
    org_id,
    localStorage.getItem("scope"),
    localStorage.getItem("timescope"),
    localStorage.getItem("startDate"),
    localStorage.getItem("endDate"),
  ]);

  const handleBreadcrumbClick = (goal) => {
    getGoals(org_id, scope, userInfoDetails?.data?.accessToken, goal.value);
    setSelectedGoalType(goal);
    setRefreshGraph(true);
    setBreadcrumbArray((prevArray) => {
      const clickedIndex = prevArray.findIndex(
        (item) => item.value === goal.value
      );
      return prevArray.slice(0, clickedIndex + 1);
    });
  };

  return (
    <>
      <div>
        <Row className="" style={{ marginLeft: "0.4px", marginRight: "0.4px" }}>
          <Card style={{ borderRadius: "0px 0px 0px 0px" }}>
            <div className="row">
              <div className="col-md-12 mr-5 mb-4">
                <div className="mb-2" style={{ fontSize: "20px" }}>
                  Goals Hierarchy :
                </div>
                <Breadcrumbs
                  maxItems={100}
                  className=""
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  {breadcrumbArray.map((breadcrumb, index) =>
                    breadcrumb.value ? (
                      <Link
                        key={index}
                        underline="hover"
                        color="inherit"
                        onClick={() => handleBreadcrumbClick(breadcrumb)}
                        style={{ cursor: "pointer", fontSize: "18px" }}
                      >
                        {breadcrumb.label}
                      </Link>
                    ) : (
                      <Typography key={index} sx={{ color: "text.primary" }}>
                        {breadcrumb.label}
                      </Typography>
                    )
                  )}
                </Breadcrumbs>
              </div>
            </div>
            <div className="col-md-6"></div>
            <div
              className="col-md-6 float-end"
              data-testid="parent-goal-dropdown"
            >
              <div className="row">
                <div className="col-md-6 mr-5 mb-4">
                  <div
                    className="mb-2"
                    style={{ marginLeft: "10px !important" }}
                  >
                    <Select
                      placeholder={
                        loading ? "Loading..." : "Please select goal type..."
                      }
                      onChange={changeGoalTypeHandler}
                      defaultValue={selectedGoalType}
                      value={selectedGoalType}
                      className="p add-member-select"
                      options={allParentGoals}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-2 ">
                    <Select
                      style={{ zIndex: "22222 !important" }}
                      className="p fs-14 w-100"
                      name="goalType"
                      placeholder={
                        loading
                          ? "Loading..."
                          : "Please select sub-goal type..."
                      }
                      options={allSubGoals}
                      styles={{ padding: "2px", borderRadius: "10px" }}
                      value={selectedSubGoalType}
                      onChange={changeSubGoalTypeHandler}
                      isClearable
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Row>
        {allParentGoals?.length == 0 && (
          <div>
            <Card>
              <center>
                <p className="mt-3 p-2">Please create goal to see the graph</p>
              </center>
            </Card>
          </div>
        )}
        {allParentGoals?.length !== 0 && (
          <div>
            <Row>
              <CompleteChart
                graphData={graphData}
                scope={scopes}
                trackScope={"objective"}
                goal_id={goal_id}
              />
              <OnTimeChart
                graphData={graphData}
                scope={scopes}
                trackScope={"objective"}
                goal_id={goal_id}
              />
              <WinsChart
                graphData={graphData}
                scope={scopes}
                trackScope={"objective"}
                goal_id={goal_id}
              />
            </Row>
            <TaskWorkTable
              scope={scopes}
              trackScope={"goal"}
              goal_id={goal_id}
            ></TaskWorkTable>
            <Row>
              <PieNewChart
                scope={scopes}
                trackScope={"objective"}
                goal_id={goal_id}
              ></PieNewChart>
              <BarChartObjective
                scope={scopes}
                trackScope={"objective"}
                goal_id={goal_id}
              ></BarChartObjective>
            </Row>
          </div>
        )}
      </div>
    </>
  );
});

export default TrackObjective;
