import { Row, Col } from "antd";
import React from "react";

const QASubSection = ({ children }) => {
  return (
    <Row style={{ width: "100%",marginBottom:"5rem" }}>
      <Col xl={16} lg={22} md={22} sm={24}>
      {children}
      </Col>
    </Row>
  );
};

export default QASubSection;
