import React, { useContext, useEffect, useState } from "react";
import { RingLoader } from "react-spinners";
import { lazy } from "react";
/// React router dom
import {  Routes, Route ,Redirect } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

import Nav from "./layouts/nav";
// import Footer from "./layouts/Footer";

import ScrollToTop from "./layouts/ScrollToTop";
import Registration from "./pages/Registration";
import ForgotPassword from "./pages/ForgotPassword";
import Login from "./pages/Login";
import OrganizationList from "./components/Menu/OrganizationList";
import InviteUsers from "./components/Menu/InviteUsers";
import AppProfile from "./components/Menu/AppProfile";
import Teams from "./components/Menu/Teams";
import StripePaymentForm from "./components/Menu/StripePaymentForm";
// import Home from "./components/Dashboard/Home";
import {  useLocation } from 'react-router-dom';

// side-nav imports



import Home from "./components/Menu/Home";
// import Goals from "./components/Menu/Goals";
import League from "./components/Menu/League";
// import Messages from "./components/Menu/Messages";
// import Performance from "./components/Menu/Performance";
import Pulse from "./components/Menu/Pulse";
// import Tasks from "./components/Menu/Tasks";
// import Time from "./components/Menu/Time";
import SubTeams from "./components/Menu/subTeams"
import Calendar from "./components/Calendar/Calendar";
import PaymentSucess from "./components/Menu/PaymentSucess";
import PaymentFail from "./components/Menu/PaymentFail";
// import SubscriptionPlans from "./components/Menu/SubscriptionPlans";
// import Template from "./components/Menu/Template";
import { useNavigate } from "react-router-dom";
import {  useDispatch } from "react-redux";
import { checkAutoLogin } from "../services/AuthService";
import ArchieveGoal from "./components/Component/ArchiveGoal";
import ViewFolder from "./components/Menu/ViewFolder";
import ChatbotQuestionSet from "./components/Menu/ChatbotQuestionSet";
// import Track from "./components/Menu/Track";
import TrackObjective from "./components/Menu/TrackObjective";
import TrackOraganization from "./components/Menu/TrackOrganization";
// const Home = lazy(() => import("./components/Menu/Home"));
import Goals from "./components/Menu/Goals";
import Tasks from "./components/Menu/Tasks";
import Messages from "./components/Menu/Messages"
import Time from "./components/Menu/Time";
import SubscriptionPlans from "./components/Menu/SubscriptionPlans";
import Template from "./components/Menu/Template";
import Track from "./components/Menu/Track"
import EmailNotification from "./components/Menu/EmailNotification";
import CoachSetting from "./components/Menu/CoachSetting";
import Revenue from "./components/Menu/Revenue";
import MyDay from "./components/Menu/MyDay";
import TeamDay from "./components/Menu/TeamDay";
import TemplateMenu from "./components/Menu/TemplateMenu";
import AiPlan from "./components/Menu/AiPlan";
// const Goals = lazy(()=>import("./components/Menu/Goals"))
// const Messages = lazy(()=>import("./components/Menu/Messages"))
// const Time = lazy(()=>import("./components/Menu/Time"))
// const Tasks = lazy(()=>import("./components/Menu/Tasks"))
// const SubscriptionPlans = lazy(()=>import("./components/Menu/SubscriptionPlans"))
// const Template = lazy(()=>import("./components/Menu/Template")) 
const Performance = lazy(()=>import("./components/Menu/Performance")) 



const Markup = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { menuToggle } = useContext(location?.state?.toggleSideBar);
  const [toggle , setTogle] =useState();
 
  const routes = [
    { url: "/", title: "Home", element: <MyDay/> },
    // { url: "/", title: "Home", element: <Home/> },
    { url: "login", title: "Login", element: <Login/> },
    { url: "page-register", title: "Registration", element: <Registration/> },
    { url: "page-forgot-password", title: "Forgot Password", element: <ForgotPassword/> },
    { url: "organization-list", title: "Organization List", element: <OrganizationList/> },
    { url: "invite-users", title: "Invite Users", element: <InviteUsers/> },
    { url: "app-profile", title: "App Profile", element: <AppProfile/> },
    { url: "email-notification", title: "App Profile", element: <EmailNotification/> },
    { url: "coach-setting", title: "Coach setting", element: <CoachSetting/> },
    { url: "template", title: "Template", element: <Template /> },
    { url: "templates", title: "Templates", element: <TemplateMenu/> },
    {url:"chatbots", title:"chatbots", element: <ChatbotQuestionSet/>},
    { url: "teams", title: "Teams", element: <Teams/> },
    {url:"archieve", title:"Archieve", element:<ArchieveGoal/>},
    {url:"viewFolder", title:"ViewFolder", element:<ViewFolder/>},
    // Side-menu
    { url: "home", title: "Home", element: <MyDay/> },
    { url: "my-day", title: "My Day", element: <MyDay/> },
    { url: "ai-plan", title: "Ai Plan", element: <AiPlan></AiPlan> },
    { url: "revenue", title: "Revenue", element: <Revenue/> },
    { url: "goals", title: "Objectives", element: <Goals/> },
    { url: "league", title: "League", element: <League/> },
    { url: "messages", title: "Messages", element: <Messages/> },
    { url: "performance", title: "Performance", element: <Performance/> },
    { url: "pulse", title: "Pulse", element: <Pulse/> },
    { url: "tasks", title: "Tasks", element: <Tasks/> },
    { url: "time", title: "Time", element: <Time/> },
    { url: "track", title: "Track", element: <Track/> },
    { url: "track-objective", title: "Track Objective", element: <TrackObjective/> },
    { url: "track-organization", title: "Track Organization", element: <TrackOraganization/> },

    { url: "SubTeams", title: "Sub Teams", element: <SubTeams/> },
    { url: "calendar", title: "Calendar", element: <Calendar /> },
  
    { url: "stripe", title: "Stripe", element: <StripePaymentForm /> },
    { url: "payment-success", title: "Payment Success", element: <PaymentSucess /> },
    { url: "payment-fail", title: "Payment Fail", element: <PaymentFail/> },
    { url: "subscription-plan", title: "Subscription Plans", element: <SubscriptionPlans /> },
    { url: "team-day", title: "Team day", element: <TeamDay /> },
       
  ];

  useEffect(()=> {
    setTogle(location?.state?.toggleSideBar)
  },[location?.state?.toggleSideBar])
  useEffect(()=> {
    if(location?.state?.toggleSideBar === undefined){
      setTogle(false)
    }
    
  },[])
  useEffect(() => {
    console.log("changed routes useEffect")
    routes.forEach(element => {
      if(element.url !== "/") {
        console.log("changed routes inside if")
        if(location.pathname === "/" + element.url ) {
          console.log("changed routes")
          checkAutoLogin(dispatch, navigate);
        }
      }
    });
    // if (
    //   location.pathname !== "/login" &&
    //   location.pathname !== "/page-forgot-password" &&
    //   location.pathname !== "/page-register" &&
    //   location.pathname !== "/store-front" &&
    //   location.pathname !== "/product-page" &&
    //   location.pathname !== "/category-page" &&
    //   location.pathname !== "/privacy" &&
    //   location.pathname !== "/terms-and-conditions" &&
    //   location.pathname !== "/cookie-policy" &&
    //   location.pathname !== "/about-us" &&
    //   location.pathname !== "/plansom-partner" &&
    //   location.pathname !== "/contactUs-support" &&
    //   location.pathname !== "/pricing"
    //   // location.pathname !== "/unsubscribe-mail"
    // ) {
    //   console.log("Authlogin 1111",location.pathname)
    //   checkAutoLogin(dispatch, navigate);
    // }
  },[location?.pathname])



  let path = location?.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");

  console.log(pagePath,"*****************")
  return (
    <>
    { pagePath ? <center>
        <div style={{padding:'20px', margin:'300px'}} data-testid="loading-spinner">
        <RingLoader color="#ff0000" size='100' loading cssOverride={{opacity: 1}}/>
	</div>
  </center> : <>
      <div
        // id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "vh-100"}  ${
          toggle ? "menu-toggle" : ""
        }`}
        style={!pagePath ? {
          overflow: "hidden",
        }:
      {}}
      >
      {!pagePath && <Nav />}
        <div className={`${!pagePath ? "content-body" : ""}`} style={localStorage.getItem("subNotification") === "true" ? {backgroundColor: '#f2f2f2',marginTop:"14rem",  minHeight: window.screen.height} : {backgroundColor: '#f2f2f2', minHeight: window.screen.height}}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            // style={{backgroundColor: '#f',}}
            // style={{ minHeight: window.screen.height}}
          >
            <Routes>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  element={data.element}
                />
              ))}
            </Routes>
          </div>
        </div>
      </div>
	  <ScrollToTop />
    </>
    }
    </>
  );
};

export default Markup;
