import { Card, Flex, Button, Typography } from "antd";
import React from "react";
import blogPost4 from "../../../../../../../src/images/blogs/blogpost4.jpg";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

const { Text } = Typography;

const BlogCard4 = () => {
  const screens = useBreakpoint();
  return (
    <Card
      hoverable
      cover={
        <img alt="example" src={blogPost4} style={{ height: "15.75rem" }} />
      }
      style={{
        flex: "1",
        display: "flex",
        flexDirection: "column",
      }}
      styles={{
        body: {
          flex: 1,
          display: "flex",
          flexDirection: "column",
          padding: !screens.md
            ? "1.5rem 1.25rem 1.25rem 1.25rem"
            : "1.5rem 1.75rem",
        },
      }}
    >
      <Flex vertical style={{ flex: 1, gap: "0.5rem" }}>
        <Text className="blogcard-heading">
          How Plansom’s Progress Tracking Makes Every Win Visible and Worth
          Celebrating
        </Text>
        <Text className="qasubsec-text" style={{ flexGrow: 1 }}>
          Let’s be honest: most of us get so caught up in what’s next that we
          forget to acknowledge how far we’ve come. Comple...
        </Text>
        <Button
          className="f-inter fw-600"
          style={{
            borderRadius: "2rem",
            marginTop: !screens.md && "0.5rem",
            height: "2.5rem",
            fontSize: "1rem",
            width: "100%", // Full width of the card
            textAlign: "center", // Centers the text inside the button
          }}
        >
          Read More
        </Button>
      </Flex>
    </Card>
  );
};

export default BlogCard4;
